import axios from 'axios'
import {useQuery} from 'react-query'

export interface workTypeList {
    worktypeid: number
    worktypecode: string
}

export interface poStatusList {
    statusid: number
    statuscode: string
}

export interface  PODropdowns {
    worktypelist: workTypeList[]
    postatuslist: poStatusList[]
}

export const PODropdownDefaults = {
    worktypelist: [],
    postatuslist: []
}

export const useFetchPODropdowns =  () => {
    return useQuery<PODropdowns>(
        ['podropdowns'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            placeholderData: PODropdownDefaults,
            keepPreviousData: false
        }
    )
}

export const globalFetcher = async () => {
    const { data } = await axios.get('/podropdowns')
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as PODropdowns
}
