import React, {useEffect, useState} from 'react'

import {
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, FormControlLabel, Grid, IconButton, useMediaQuery
} from '@material-ui/core'

import Button from '@mui/material/Button';
import styles from './MultipleTimeEntry.module.css'
import dayjs, { Dayjs } from 'dayjs';
import {
    DataGridPremium, GridCallbackDetails,
    GridCellParams,
    GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
    GridRenderCellParams, GridRowParams, gridSortedRowIdsSelector,
    GridSortModel, GridToolbarContainer, MuiEvent, useGridApiContext,
    useGridApiRef
} from "@mui/x-data-grid-premium";
import {GridSelectionModel, GridSortItem, GridValueFormatterParams} from "@material-ui/x-grid";
import {formatMoney} from "../../../util/formatHelpers";
import {Delete, Edit, FolderOpen, SettingsBackupRestore} from "@material-ui/icons";
import {Add, Save} from "@mui/icons-material";
import {styled} from "@mui/material";
import {TimeListData, useFetchDispatchMultipleSubmittedTime} from "../../../queries/useDispatchTimeEntry";
import {ButtonProps} from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {useFetchGridState} from "../../../queries/useGridState";
import axios from "axios";
import {BulkDropDowns} from "../../../queries/useBulkDropDowns";
import {FileViewerForm} from "../../FileViewerPage/FileViewerForm";
import {AdminTimeEntryForm} from "../../DispatchPage/AdminTimeEntryForm";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// import {DataGridPremium} from "@mui/x-data-grid-premium";
export const MultipleTimeEntryForm = (params) => {
    const title = "Time Entries"
    const gridTEStateVersion = 'time-entry-multiple-grid-v1.1'
    const { data: gridTEStateData } = useFetchGridState(gridTEStateVersion)
    const gridSortItem: GridSortItem = {
        field: 'schedulestartdate',
        sort: 'asc'
    }
    const basicProps = {
        disableColumnMenu: true,
        sortable: false,
        disableExport: true,
        disableReorder: true,
        editable: false,
        filterable: false,
        groupable: false,
        hideable: false,
        pinnable: false
    }
    const [gridShowing, setGridShowing] = useState(false)
    const defaultGridSortModel: GridSortModel = [gridSortItem]
    const [gridSortModel, setGridSortModel] = React.useState<GridSortModel>(defaultGridSortModel)
    const [dataRows, setDataRows] = React.useState([])
    const [timeObject, setTimeObject] =  React.useState<TimeListData>()
    const [openFileModal, setOpenFileModal] = React.useState( {openDialog: false, scheduledescription: '', woid: 0, scheduleid: 0} )
    const [woid, setWoid] = useState<number>(0)
    const [scheduleid, setScheduleid] = useState<number>(0)
    const [scheduledescription, setScheduledescription] = useState<string>('')
    const [gridParams, setGridParams] = useState({})
    const [reloadTEForm, setReloadTEForm] = useState<number>(0)
    const [teEdit, setTEEdit] = React.useState(false)
    const [isTEFormShowing, setIsTEFormShowing] = useState(false)
    const gridAPIRef = useGridApiRef()
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([1]);
    const [dialogObjDel, setDialogObjDel] = React.useState({msg: "", dispatchid: 0, timeid: 0})
    const [dialogObj, setDialogObj] = React.useState({msg: "", gridState: "", okButtonText: "Save"})
    let _sortModelState: any = null

    const { data: dispatchMultipleSubmittedTImeDate } = useFetchDispatchMultipleSubmittedTime(params.dispatchid, params.timeid, params.entityid)

    useEffect(() => {
        if (dispatchMultipleSubmittedTImeDate && dispatchMultipleSubmittedTImeDate.timelist && dispatchMultipleSubmittedTImeDate.timelist.length > 0) {
            const dataRows = getMappedRecords()
            setDataRows(dataRows)
        }
    }, [dispatchMultipleSubmittedTImeDate])

    useEffect(() => {
        setWoid(params.dispatchList.dispatchwoid)
        setScheduleid(params.dispatchList.dispatchschedulenumber)
        setScheduledescription(params.dispatchList.dispatchcustomerinfo)
    }, [params.dispatchList])

    const getMappedRecords = () => {
        return dispatchMultipleSubmittedTImeDate?.timelist.map((timeList: TimeListData, i) => {
            return {
                id: timeList.timeid,
                readonly: timeList.readonly,
                canedit: timeList.canedit,
                multipletimefordate: timeList.multipletimefordate,
                candelete: timeList.candelete,
                dispatchid: timeList.dispatchid,
                timeid: timeList.timeid,
                employeeid: timeList.employeeid,
                employee: timeList.employee,
                starttravel: timeList.starttravel,
                onjob: timeList.onjob,
                leavejob: timeList.leavejob,
                endtravel: timeList.endtravel,
                vehicleid: timeList.vehicleid,
                vehicle: timeList.vehicle,
                perdiem: timeList.perdiem,
                lunch: timeList.lunch,
                comments: timeList.comments,
                shiftpay: timeList.shiftpay,
                paylevel: timeList.paylevel,
                travel: timeList.travel,
                missedmeal: timeList.missedmeal,
                additionalot: timeList.additionalot,
                additionaldt: timeList.additionaldt,
                st: timeList.st,
                ot: timeList.ot,
                dt: timeList.dt,
                meals: timeList.meals,
                overridecalc: timeList.overridecalc,
                timeisposted: timeList.timeisposted,
                transferred: timeList.transferred,
                employeecolor: timeList.employeecolor,
                employeehover: timeList.employeehover,
            }
        })
    }
    const buildColumnDefs = () => {
        const gridObj = parseGridObject()
        const parsedGridColumns = gridObj.gridDefs
        var gridColumns: GridColDef[] = [
            gridEditSaveButtonDef,
            ...parsedGridColumns,
            gridButtonsDef
        ]
        return gridColumns
    }

    const gridEditSaveButtonDef: GridColDef = {
        field: '',
        renderHeader: () => {
            return (
                <>
                    {params?.dispatchSubmittedTImeDate?.canaddtime &&
                        <IconButton
                            className={styles.submitButton}
                            onClick={() => handleAdd()}
                            size="small">
                            <Add />
                        </IconButton>
                    }
                </>
            )
        },
        width: 80,
        align: "left",
        headerAlign: "center",
        pinnable: true,
        cellClassName: 'pinnedColumn',
        ...basicProps,
        renderCell: (params) => (
            <>
                <IconButton className={styles.button} onClick={() => {
                    const selectedRowData = dataRows.find((row) => row.id === params.id)
                    console.log(selectedRowData)
                    // resetGridParams(params.id)
                    setOpenFileModal({openDialog: true, scheduledescription: scheduledescription, woid: woid, scheduleid: scheduleid})
                }}>
                    <FolderOpen />
                </IconButton>

                <IconButton className={styles.button} disabled={!params.row.canedit} onClick={() => {
                    HandleEdit(params)
                }}>
                    <Edit/>
                </IconButton>
            </>
        )
    }

    const HandleEdit = async (gridParams: GridCellParams) => {
        const timeObj = dispatchMultipleSubmittedTImeDate?.timelist.filter((item) => item.timeid === Number(gridParams.id))[0]
        setGridParams({
            id: timeObj.timeid,
            readonly: timeObj.readonly,
            canedit: timeObj.canedit,
            multipletimefordate: timeObj.multipletimefordate,
            candelete: timeObj.candelete,
            dispatchid: timeObj.dispatchid,
            timeid: timeObj.timeid,
            employeeid: timeObj.employeeid,
            employee: timeObj.employee,
            starttravel: timeObj.starttravel,
            onjob: timeObj.onjob,
            leavejob: timeObj.leavejob,
            endtravel: timeObj.endtravel,
            vehicleid: timeObj.vehicleid,
            vehicle: timeObj.vehicle,
            perdiem: timeObj.perdiem,
            lunch: timeObj.lunch,
            comments: timeObj.comments,
            shiftpay: timeObj.shiftpay,
            paylevel: timeObj.paylevel,
            travel: timeObj.travel,
            missedmeal: timeObj.missedmeal,
            additionalot: timeObj.additionalot,
            additionaldt: timeObj.additionaldt,
            st: timeObj.st,
            ot: timeObj.ot,
            dt: timeObj.dt,
            meals: timeObj.meals,
            overridecalc: timeObj.overridecalc,
            timeisposted: timeObj.timeisposted,
            transferred: timeObj.transferred,
            employeecolor: timeObj.employeecolor,
            employeehover: timeObj.employeehover,
            // lastwipposted: timeObj.lastwipposted,
            // lasttimeposted: timeObj.lasttimeposted,
            // dispatchsudmitteddate: timeObj.dispatchsudmitteddate,
        })

        setTEEdit(true)
        setReloadTEForm(reloadTEForm + 1)
        setIsTEFormShowing(true)
    }

    const parseGridObject = () => {
        var sortModel: GridSortModel = [gridSortItem]
        var gridStateString = JSON.stringify({gridType: "multipletimeentrydispatch", gridState: JSON.stringify(gridDefaults)})
        // if (params.gridStateData && params.gridStateData.length > 0) {
        //     gridStateString = params.gridStateData[0].gridState
        //     const gridStateData = JSON.parse(gridStateString)
        //     const sendObj = {
        //         gridType: 'multipletimeentrydispatch',
        //         gridState: JSON.stringify(gridStateData.columnDefs)
        //     }
        //     gridStateString = JSON.stringify(sendObj)
        //
        //     if (gridStateData && gridStateData.sortModel !== null && gridStateData.sortModel.length > 0) {
        //         // gridAPIRef?.current?.setSortModel(gridStateData.sortModel)
        //         const dataSort = gridStateData.sortModel
        //         sortModel = dataSort
        //     }
        // }

        var gridDefs: GridColDef[] = [];

        if (gridStateString.length > 0) {
            const gridObject = JSON.parse(gridStateString)
            if (gridObject.gridType === 'multipletimeentrydispatch') {
                const gridState = JSON.parse(gridObject?.gridState)
                gridState?.forEach(state => {
                    var col: GridColDef = {...state}

                    if (col.field === 'starttravel' || col.field === 'onjob' || col.field === 'leavejob' || col.field === 'endtravel') {
                        col.valueFormatter = formatDate
                    }
                    else if (col.field === 'perdiem') {
                        col.valueFormatter = formatMoneyParam
                    }
                    else if (col.field === 'lunch') {
                        col.valueFormatter = formatBoolean
                    }
                    else if (col.field === 'employee') {
                        col.renderCell = formatCell
                    }

                    gridDefs.push(col)
                })
            }
        }
        return {gridDefs: gridDefs, sortModel: sortModel}
    }

    const gridButtonsDef: GridColDef = {
        field: 'Delete',
        headerName: '',
        width: 40,
        align: "center",
        headerAlign: "center",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <>
                    {params.row.candelete &&
                        <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                            <Delete />
                        </IconButton>
                    }
                </>
            )
        }
    }

    const handleAdd = () => {
        // setTEEdit(false)
        // setReloadTEForm(reloadTEForm + 1)
        // setGridParams(null)
        // setIsTEFormShowing(true)
    }
    const handleDelete = (params: GridCellParams) => {
        // setDialogObjDel({
        //     msg: 'Are you sure you want to delete this time entry for employee \"' + params.row.employee.toString() + "\"?",
        //     dispatchid: Number(params.row.dispatchid),
        //     timeid: Number(params.row.timeid)
        // })
    }

    const formatCell = (params: GridRenderCellParams) => {
        var employeeColor = 'rgba(255,255,255,0)'
        if (params?.row?.employeecolor === '#FFFFFF') {
            employeeColor = 'rgba(255,255,255,0)'
        }
        else if (params?.row?.employeecolor) {
            employeeColor = params.row.employeecolor
        }
        return (
            <>
                <div title={params.row.employeehover} style={{
                    paddingLeft: '10px', width: '100%', 'backgroundColor': employeeColor, display: 'flex', textAlign: 'center', verticalAlign: 'middle', color: "black", fontSize:18}}>{params.value}
                </div>
                {/*</div>*/}
            </>
        )
    }

    const formatDate = (params: GridValueFormatterParams) => {
        return (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DDTHH:mm:ss").format('MM/DD hh:mm A') : '')
    }

    const formatMoneyParam = (params: GridValueFormatterParams) => {
        return formatMoney(Number(params.value))
    }

    const formatBoolean = (params: GridValueFormatterParams) => {
        return (params.value ? 'Yes' : 'No')
    }

    const gridDefaults: GridColDef[] = [
        {
            "field": "employee",
            "headerName": "Employee",
            "headerAlign": "left",
            // "type": "string",
            "width": 300,
            "align": "left",
            renderCell: formatCell
        },
        // {
        //     "field": "employeecolor",
        //     "headerName": "Employee Color",
        //     "headerAlign": "left",
        //     "type": "string",
        //     "width": 300,
        //     "align": "left",
        // },
        {
            "field": "starttravel",
            "headerName": "Start Travel",
            "headerAlign": "left",
            "type": "string",
            "width": 140,
            "align": "left",
            valueFormatter: formatDate
        },
        {
            "field": "onjob",
            "headerName": "On Job",
            "headerAlign": "left",
            "type": "string",
            "width": 140,
            "align": "left",
            valueFormatter: formatDate
        },
        {
            "field": "leavejob",
            "headerName": "Leave Job",
            "headerAlign": "left",
            "type": "string",
            "width": 140,
            "align": "left",
            valueFormatter: formatDate
        },
        {
            "field": "endtravel",
            "headerName": "On Travel",
            "headerAlign": "left",
            "type": "string",
            "width": 140,
            "align": "left",
            valueFormatter: formatDate
        },
        {
            "field": "vehicle",
            "headerName": "Vehicle",
            "headerAlign": "left",
            "type": "string",
            "width": 125,
            "align": "left"
        },
        {
            "field": "lunch",
            "headerName": "Missing Lunch",
            "headerAlign": "right",
            "type": "string",
            "width": 150,
            align: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                console.log(params.value)
                return formatBoolean(params)
            },
        },
        {
            "field": "perdiem",
            "headerName": "Per/Diem",
            "headerAlign": "right",
            "type": "string",
            "width": 125,
            align: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            "field": "st",
            "headerName": "ST",
            "headerAlign": "right",
            "type": "number",
            "width": 125,
            align: "right",
        },
        {
            "field": "ot",
            "headerName": "OT",
            "headerAlign": "right",
            "type": "number",
            "width": 125,
            align: "right",
        },
        {
            "field": "dt",
            "headerName": "DT",
            "headerAlign": "right",
            "type": "number",
            "width": 125,
            align: "right",
        },
        {
            "field": "meals",
            "headerName": "Meals",
            "headerAlign": "right",
            "type": "number",
            "width": 125,
            align: "right",
        },
        {
            "field": "travel",
            "headerName": "Travel",
            "headerAlign": "right",
            "type": "string",
            "width": 125,
            align: "right",
        },
        {
            "field": "transferred",
            "headerName": "Transferred",
            "headerAlign": "right",
            "type": "string",
            "width": 125,
            align: "right",
        },
    ]

    function CustomGridToolbar() {
        const apiRef = useGridApiContext();

        const handleExport = (options: GridCsvExportOptions) =>
            apiRef.current.exportDataAsCsv(options);

        const buttonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <FileDownloadOutlinedIcon />,
        };

        const saveButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <Save />,
        };

        const restoreButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <SettingsBackupRestore />,
        };

        const getColumnStringToSave = () => {
            var fieldObjects = []
            const gridState = gridAPIRef.current.getAllColumns()
            gridState.forEach(stateColumnDef => {
                if (stateColumnDef.sortable && stateColumnDef.pinnable && stateColumnDef.groupable) {
                    fieldObjects.push({
                        field: stateColumnDef.field,
                        headerName: stateColumnDef.headerName,
                        headerAlign: stateColumnDef.headerAlign,
                        type: stateColumnDef.type,
                        width: stateColumnDef.width,
                        align: stateColumnDef.align,
                        description: stateColumnDef.description,
                        hide: stateColumnDef.hideable,
                        valueFormatter: stateColumnDef.valueFormatter
                    })
                }
            })

            const sortModelDefs = gridAPIRef.current.getSortModel()
            const strObject = JSON.stringify({columnDefs: fieldObjects, sortModel: sortModelDefs})
            return strObject
        }

        const handleSave = () => {
            setGridShowing(false)
            const columnObjsting = getColumnStringToSave()
            setDialogObj({
                msg: 'Are you sure you want to save the current grid state?',
                gridState: columnObjsting,
                okButtonText: 'Save'
            })
        }

        const handleRestore = () => {
            setGridShowing(false)
            const sortModelDefs = gridAPIRef.current.getSortModel()
            const strObject = JSON.stringify({columnDefs: gridDefaults, sortModel: sortModelDefs})
            setDialogObj({
                msg: 'Are you sure you want to reset the grid state to default?',
                gridState: strObject,
                okButtonText: 'RESET'
            })
        }

        return (
            <GridToolbarContainer >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        {...saveButtonBaseProps}
                        onClick={() => handleSave()}
                    >
                        SAVE GRID SETTINGS
                    </Button>
                    <Button
                        {...restoreButtonBaseProps}
                        onClick={() => handleRestore()}
                    >
                        RESTORE GRID SETTINGS
                    </Button>
                    <Button
                        {...buttonBaseProps}
                        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
                    >
                        EXPORT
                    </Button>
                    {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                </Grid>
            </GridToolbarContainer>
        )
    }


    const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({

    }))

    const handleRowClick = (gridParams: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        const timeListData = dataRows.filter((dispatchFilterList) => { return dispatchFilterList.timeid === Number(gridParams.id)})

        if (timeListData && timeListData.length > 0) {
            setTimeObject(timeListData[0])
        }
    }

    const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
        gridSortedRowIdsSelector(apiRef);


    return (
        <>
            <FileViewerForm isOpen={openFileModal} setIsOpen={setOpenFileModal} ></FileViewerForm>
            <AdminTimeEntryForm
                isOpen={isTEFormShowing}
                setIsOpen={setIsTEFormShowing}
                reloadForm={reloadTEForm}
                employeeList={params.employeeList}
                vehicleList={params.vehicleList}
                timeEntries={params.timeListData}
                setTimeEntries={params.setTimeListData}
                gridParams={gridParams}
                isEdit={teEdit}
                dispatchid={params.dispatchList.dispatchid}
                timeid={timeObject?.timeid}
                entityid={params.entityId}
                reloadData={params.reloadData}
                setReloadData={params.setReloadData}
            />
            <Dialog open={params.isOpen} fullWidth maxWidth="xl" className={styles.dialog}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <StyledDataGrid
                                sx={{
                                    "& .MuiDataGrid-pinnedColumns": {
                                        boxShadow: "none",
                                        backgroundColor: "transparent"
                                    },
                                    "& .MuiDataGrid-pinnedColumnHeaders": {
                                        boxShadow: "none",
                                        backgroundColor: "transparent"
                                    },
                                    "& .MuiDataGrid-dataGridCustomRow": {
                                        boxShadow: "none",
                                        backgroundColor: "red"
                                    }
                                }}
                                apiRef={gridAPIRef}
                                rows={dataRows}
                                getRowClassName={(params) => `super-app-theme--${params.row.status}`}
                                columns={buildColumnDefs()}
                                autoHeight={true}
                                density={'compact'}
                                // pageSize={10}
                                sortModel={gridSortModel}
                                // disableMultipleSelection={true}
                                onSortModelChange={(model: GridSortModel) => setGridSortModel(model)}
                                onStateChange={(state) => {
                                    _sortModelState = state.sorting.sortModel

                                    return state
                                }}
                                initialState={{
                                    pinnedColumns: {left: ['Edit'], right: ['Notes', 'Delete']},
                                    sorting: {
                                        sortModel: gridSortModel
                                    },
                                    aggregation: {
                                        model: {
                                            ot: 'sum',
                                        },
                                    },
                                }}
                                onRowClick={handleRowClick}

                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                selectionModel={selectionModel}
                                components={{
                                    Toolbar: CustomGridToolbar,
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        className={styles.deleteBtn}
                        onClick={() => params.setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className={styles.submitButton}
                        type="submit"
                        form="items-form">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
