import React, {useEffect, useRef, useState} from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import PdfViewerComponent from '../../components/PdfViewerComponent';



const drawerWidth = 240;


const TestPage = (props) => {


    return (
        <div className="PDF-viewer">
            <PdfViewerComponent
                document={"ACI_PoleBill.pdf"}
            />
        </div>
    )
}

export default TestPage
