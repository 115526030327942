import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import styles from '../WAListingPage/WALiastingPage.module.css'
import {useUpdateWAAmounts} from "../../../queries/useWAAmounts";
import TextInput from "../../../components/common/inputs/TextInput";
import SelectInput from "../../../components/common/inputs/SelectInput";
import MaskedInput from "../../../components/common/inputs/MaskedInput";
import DateTimeInput from "../../../components/common/inputs/DateTimeInput";

interface FormData {
    waaid: number
    waaamount: number
    waastatusid: string
    waadaterequested: string
    waadateapproved: string
    waacomment: string
    waaamountlastupdated: string
    waaaddeddate: string
}

export const WAAmountsForm = ({ isOpen, setIsOpen, gridParams, reloadForm, walid, waamountsstatuslist }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit Amounts" : "Create Amounts"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateAmounts = useUpdateWAAmounts()
    const [amount, setAmount] = useState<number>(0)

    useEffect(() => {
        reset({
            waaid: (gridParams ? gridParams.waaid : 0),
            waaamount: (gridParams ? gridParams.waaamount : 0),
            waastatusid: (gridParams ? gridParams.waastatusid : 1),
            waadaterequested: (gridParams ? gridParams.waadaterequested : ''),
            waadateapproved: (gridParams ? gridParams.waadateapproved : ''),
            waacomment: (gridParams ? gridParams.waacomment : ''),
            waaamountlastupdated: (gridParams ? gridParams.waaamountlastupdated : ''),
            waaaddeddate: (gridParams ? gridParams.waaaddeddate : '')
        })
    }, [reloadForm])

    const handleAmount = (event) => {
        setAmount(event.floatValue)
    }

    const onSubmit = async (data: FormData) => {
        var finalData = {
            walid: walid,
            waaamount: amount,
            waastatusid: data.waastatusid,
            waadaterequested: data.waadaterequested,
            waadateapproved: data.waadateapproved,
            waacomment: data.waacomment
        }
        if (data.waaid > 0) {
            finalData['waaid'] = data.waaid
        }
        const { data: response } = await updateAmounts.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        console.log(parsedResponse)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }

    const getStatusCodes = () => {
        return waamountsstatuslist.map(item => {
                return { label: item.statuscode, value: item.statusid }
            })

    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const currencyFormat = (num) => {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return (
        <Dialog open={isOpen}
                fullWidth={true}
                maxWidth="xl"
                className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>
                    <div className={styles.multipleInputContainer}>
                        <SelectInput
                            name="waastatusid"
                            label="Status Code"
                            options={getStatusCodes()}
                            required
                        />
                        <MaskedInput
                            name="waaamount"
                            label="Amount"
                            defaultValue="0"
                            onValueChange={handleAmount}
                        />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <DateTimeInput name="waadaterequested" label="Requested Date" defaultValue={new Date().toISOString()} required={true} />
                        <DateTimeInput name="waadateapproved" label="Approved Date" defaultValue={new Date().toISOString()} required={true} />
                    </div>

                    <TextInput
                        name="waacomment"
                        label="Comment"
                        rules={{
                            required: 'Password field is required',
                            minLength: {
                                value: 1,
                                message: 'Must be at least 1 characters long'
                            },
                            maxLength: {
                                value: 500,
                                message: 'Must be less than 500 characters long'
                            }
                        }}
                        required
                    />
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
