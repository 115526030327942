import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface customerList {
    custid: number
    custcode: string
}

export interface statusList {
    statusid: number
    statuscode: string
}

export interface customerdivisionList {
    custid: number
    customerdivisiond: number
    customerdivisioncode: string
    customerdivisionactive: boolean
}

export interface pricingmethodList {
    pricingmethodid: number
    pricingmethodcode: string
}

export interface customerprojectmanagerList {
    customerprojectmanagerid: number
    customerprojectmanagername: string
}

export interface firezoneList {
    firezoneid: number
    firezonecode: string
    firezoneactive: boolean
}

export interface insurancecategoryList {
    insurancecategoryid: number
    insurancecategorycode: string
    insurancecategoryactive: boolean
}



export interface  WorkOrderDropdowns {
    custlist: customerList[]
    statuslist: statusList[]
    customerdivisionlist: customerdivisionList[]
    pricingmethodlist: pricingmethodList[]
    customerprojectmanagerlist: customerprojectmanagerList[]
    firezonelist: firezoneList[],
    insurancecategorylist: insurancecategoryList[],
}


// export const CWADropdownDefaults = {
//     custlist: [],
//     cwaamountsstatuslist: []
//     pricingmethodlist: p
// }

export const useFetchWorkOrderDropdowns =  () => {
    return useQuery<WorkOrderDropdowns>(
        ['workorderdropdowns'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            // placeholderData: CWADropdownDefaults,
            keepPreviousData: false
        }
    )
}

export const globalFetcher = async () => {
    const { data } = await axios.get('/workorderdropdowns')
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as WorkOrderDropdowns
}
