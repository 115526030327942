import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  Entity {
    entityid: number
    entityname: string
    entityaccountingjob: string
    entityaccountingphase: string
    entityspectrum: boolean
    entityspectrumcompanycode: string
    entityactive: boolean
}

export const EntityDefaults = {
    entityid: 0,
    entityname: '',
    entityaccountingjob: '',
    entityaccountingphase: '',
    entityspectrum: true,
    entityspectrumcompanycode: '',
    entityactive: true
}

export const useFetchEntity =  () => {
    return useQuery<Entity[]>(
        ['entity'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdateEntity = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`entity`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['entity'])
        }
    })
}

export const useDeleteEntity = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`entity`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['entity'])
        }
    })
}

export const globalFetcher = async () => {
    const { data } = await axios.post('/entity', {})
    const parsedData = JSON.parse(data.jsonString)

    return parsedData.entitylist as Entity[]
}
