import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'
import MaskedInput from "../../../components/common/inputs/MaskedInput";

import HookForm from '../../../components/common/HookForm'
import styles from './Employee.module.css'
import TextInput from "../../../components/common/inputs/TextInput";
import SelectInput from "../../../components/common/inputs/SelectInput";
import {useUpdateEmployee} from "../../../queries/useEmployee";
import {useFetchLookupCategory} from "../../../queries/useLookupCategory";
import { phoneRegex } from '../../../util/formValidation'
import { emailRegex } from '../../../util/formValidation'

interface FormData {
    id: number
    entityid: number
    code : string
    title : string
    isforeman : boolean
    isgeneralforeman : boolean
    first : string
    last : string
    address1 : string
    address2 : string
    address3 : string
    city : string
    state : string
    zip : string
    phone : string
    email : string
    active : boolean
}

export const EmployeeForm = ({ isOpen, setIsOpen, gridParams, reloadForm }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit Employee" : "Create Employee"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateEmployee = useUpdateEmployee()
    const [amount, setAmount] = useState<number>(0)
     const { data: dropDownData, isLoading: dropdownLoading } = useFetchLookupCategory()

    const getEntity= () => {
        if(dropDownData){
            return dropDownData.entitylist.map(item => {
                    return { label: item.entityname, value: item.entityid }
                })
    
            }
            else return []
    }
    useEffect(() => {
        reset({
            id: (gridParams ? gridParams.id : 0),
            first: (gridParams ? gridParams.first : ''),
            last: (gridParams ? gridParams.last : ''),
            entityid: (gridParams ? gridParams.entityid : ''),
            phone: (gridParams ? gridParams.phone : ''),
            email: (gridParams ? gridParams.email : ''),
            code: (gridParams ? gridParams.code : ''),
            active: (gridParams ? gridParams.active : true)
        })
    }, [reloadForm])

    const onSubmit = async (data: FormData) => {
        var finalData = {
            first: data.first,
            last: data.last,
            entityid: data.entityid,
            phone: data.phone,
            email: data.email,
            code: data.code,
            active: data.active
        }
        if (data.id > 0) {
            finalData['id'] = data.id
        }
        const { data: response } = await updateEmployee.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    return (
        <Dialog open={isOpen} maxWidth="xl" className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>

                    <div className={styles.multipleInputContainer}>
                        <TextInput
                            name="first"
                            label="First"
                            rules={{
                                required: 'Name is required',
                                minLength: {
                                    value: 1,
                                    message: 'Must be at least 1 characters'
                                },
                                maxLength: {
                                    value: 200,
                                    message: 'Must be 200 or less characters'
                                }
                            }}
                            required
                        />
                        <TextInput
                            name="last"
                            label="Last"
                            rules={{
                                required: 'Name is required',
                                minLength: {
                                    value: 1,
                                    message: 'Must be at least 1 characters'
                                },
                                maxLength: {
                                    value: 200,
                                    message: 'Must be 200 or less characters'
                                }
                            }}
                            required
                        />
                        <MaskedInput
                            name="phone"
                            label="Phone"
                            defaultValue=""
                            thousandSeparator={false}
                            thousandsGroupStyle={""}
                            prefix={""}
                            format={"(###) ###-####"}
                            mask={"_"}
                            rules={{
                                pattern: {
                                    value: phoneRegex,
                                    message: 'Please enter a valid phone number.'
                                }
                            }}
                        />              
                        <TextInput
                        name="email"
                        label="Email"
                        rules={{
                            pattern: {
                              value: emailRegex,
                              message: 'Enter a valid email'
                            }
                          }}
                    />
                    </div>
                    <div className={styles.multipleInputContainer}>

                         <TextInput
                            name="code"
                            label="Code"
                        /> 
                         <SelectInput
                            name="entityid"
                            label="Entity"
                            options={getEntity()}
                        />
                    </div>
                    <SelectInput
                            name="active"
                            label="Active"
                            options={[
                                { label: 'Active', value: true },
                                { label: 'Inactive', value: false }
                            ]}
                            required
                        />
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
