import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import { customerList } from './useWorkOrderDropdowns'

export interface  BulkDropDowns {
   employeelist : EmployeeList[]
   customerlist : CustomerList[]
}

export interface  EmployeeList {
    employeeid: number
    entityid: number
    employeecode : string
    employeeinfo : string
}
export interface CustomerList {
    entityid: number
    customerid: number
    customercode: string
    customerinfo: string
}

export const useFetchBulkDropDowns =  () => {
    return useQuery(
        ['bulkdropdown'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const globalFetcher = async () => {
    const { data } = await axios.post('/bulkdispatchdropdowns', {
        entityid : 4
    })
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    return parsedData as BulkDropDowns
}