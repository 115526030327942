import React from "react";
import styles from './DynamicSchedulePage.module.css'
import {AppBar, Typography} from "@material-ui/core";
import {Grid} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useFetchGridState} from "../../queries/useGridState";
import {useFetchWorkOrderDropdowns} from "../../queries/useWorkOrderDropdowns";
import {WorkOrderData} from "../../queries/useWorkOrder";
import {useParams} from "react-router";
import SchedulesDynamicPage from "./SchedulesDynamic/SchedulesDynamicPage";

interface Params {
    entityId: string
}

const DynamicSchedulePage = () => {
    const { entityId } = useParams<Params>()
    const gridScheduleStateVersion = 'schedules-grid-v0'
    const gridDynamicScheduleStateVersion = 'schedules-dynamic-grid-v0-'
    const { data: gridScheduleStateData } = useFetchGridState(gridScheduleStateVersion)

    const [tabSel, setTabSel] = React.useState<number>(0);
    const { data: woDropDownData } = useFetchWorkOrderDropdowns()
    const [workOrderObj, setWorkOrderObj] = React.useState<WorkOrderData>(null);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabSel(newValue)
    }


    return (
        <div className={styles.root}>
            <Grid container spacing={1}>
                <AppBar position="static" color="default">
                    <Typography
                        variant="h6"
                        color="primary"
                        align="center"
                        component="div">
                        Dynamic Schedules
                    </Typography>
                    <SchedulesDynamicPage
                        gridDynamicScheduleStateVersion={gridDynamicScheduleStateVersion}
                        woDropDownData={woDropDownData}
                        entityId={entityId}
                    />
                </AppBar>
            </Grid>
        </div>
      )
}

export default DynamicSchedulePage
