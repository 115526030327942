import { useSelector } from 'react-redux'

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AppState } from '../App/store'

export type CurrentUser = any

export type AuthToken = {
  aud: string
  email: string
  exp: number
  iss: string
  jti: string
  sitecode: string
  siteid: string
  sub: string
  userid: string
}
export interface AuthState {
  currentUser: CurrentUser
  token: AuthToken
  isLoggedIn: boolean
  resetPasswordKey: string
  networkOnline: boolean
}

const initialState: AuthState = {
  currentUser: '',
  token: null,
  isLoggedIn: false,
  resetPasswordKey: '',
  networkOnline: true
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (
      state,
      { payload }: PayloadAction<AuthState['currentUser']>
    ) => {
      state.currentUser = Object.assign(state.currentUser, payload)
    },
    loginUser: (state, { payload }: PayloadAction<AuthToken>) => {
      state.token = payload
      state.isLoggedIn = true
    },
    setNetworkState: (state, { payload }: PayloadAction<boolean>) => {
      state.networkOnline = payload
    },
    logout: (state) => {
      localStorage.removeItem('token')
      state.currentUser = initialState.currentUser
      state.isLoggedIn = false
      state.token = null
    },
    setResetPasswordKey: (state, { payload }: PayloadAction<string>) => {
      state.resetPasswordKey = payload
    },
    setToken: (state, { payload }: PayloadAction<AuthToken>) => {
      state.token = payload
    }
  }
})

/** ACTION EXPORTS */
export const {
  setCurrentUser,
  loginUser,
  logout,
  setResetPasswordKey,
  setToken,
  setNetworkState
} = authSlice.actions

/** REDUCER EXPORT */
export default authSlice.reducer

/** STATE HOOK EXPORTS - hooks for components to get state */
export const useAuthState = () => useSelector((state: AppState) => state.auth)

export const useCurrentUser = () =>
  useSelector(({ auth }: AppState) => auth.currentUser)

export const useAuthToken = () =>
  useSelector(({ auth }: AppState) => auth.token)

export const useIsLoggedIn = () =>
  useSelector(({ auth }: AppState) => auth.isLoggedIn)

export const useResetPasswordKey = () =>
  useSelector(({ auth }: AppState) => auth.resetPasswordKey)

/** SAGA SELECTORS - selects state for sagas */
export const selectResetPasswordKey = ({ auth }: AppState) =>
  auth.resetPasswordKey

export const useNetworkOnline = () =>
    useSelector(({ auth }: AppState) => auth.networkOnline)
