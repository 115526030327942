import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {EmployeeData, VehicleData} from "./useDispatch";

export interface  TimeListDataObject {
    status: string
    message: string
    canadd: boolean
    timelist: TimeListData[]
    vehiclelist: TEVehicleData[]
    employeelist: TEEmployeeData[]
    timerecap: TimeRecapData[]
}

export interface  TimeRecapData {
    title: string
    hrs: string
    dollars: string
}
export interface  TEVehicleData {
    vehicle: string
    vehicleid: number
}

export interface  TEEmployeeData {
    employee: string
    employeeid: number
}

export interface  TimeListData {
    readonly: boolean
    canedit: boolean
    multipletimefordate: boolean
    candelete: boolean
    canposttime: boolean
    canpostwip: boolean
    dispatchid: number
    timeid: number
    employeeid: number
    employee: string
    starttravel: string
    onjob: string
    leavejob: string
    endtravel: string
    vehicleid: number
    vehicle: string
    perdiem: number
    lunch: boolean
    comments: string
    shiftpay: boolean
    paylevel: string
    travel: number
    missedmeal: number
    additionalot: number
    additionaldt: number
    st: number
    ot: number
    dt: number
    meals: number
    overridecalc: boolean
    timeisposted: boolean
    transferred: string
    employeecolor: string
    employeehover: string
}

export const TimeListDataDefaults = {
    readonly: true,
    canedit: true,
    candelete: true,
    canposttime: false,
    canpostwip: false,
    dispatchid: 0,
    timeid: 0,
    employeeid: 0,
    employee: "",
    starttravel: "",
    onjob: "",
    leavejob: "",
    endtravel: "",
    vehicleid: 0,
    vehicle: "",
    perdiem: 0,
    lunch: false,
    comments: "",
    shiftpay: false,
    paylevel: "",
    travel: 0,
    missedmeal: 0,
    additionalot: 0,
    additionaldt: 0,
    st: 0,
    ot: 0,
    dt: 0,
    meals: 0,
    overridecalc: false,
    timeisposted: false,
}

export const useFetchDispatchSubmittedTime = (dispatchId: number) => {
    return useQuery<TimeListDataObject>(
        ['dispatchsubmittedtime', dispatchId],
        () => globaDispatchSubmittedTimeFetcher(dispatchId),
        {
            staleTime: 12000,
            keepPreviousData: false
        }
    )
}

export const useFetchDispatchMultipleSubmittedTime = (dispatchId: number, timeId: number, entityId: number) => {
    return useQuery<TimeListDataObject>(
        ['dispatchsubmittedtime', dispatchId, timeId, entityId],
        () => globalDispatchMultipleSubmittedTimeFetcher(dispatchId, timeId, entityId),
        {
            staleTime: 1000,
            keepPreviousData: false
        }
    )
}

export const useUpdateDispatchSubmittedTIme = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`dispatchsubmittedtime`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['dispatchsubmittedtime'])
        }
    })
}

export const useDeleteDispatchSubmittedTIme = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`dispatchsubmittedtime`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['dispatchsubmittedtime'])
        }
    })
}

export const globaDispatchSubmittedTimeFetcher = async (dispatchId: number) => {
    const { data } = await axios.post('/dispatchsubmittedtime', {
        dispatchid: dispatchId
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as TimeListDataObject
}

export const globalDispatchMultipleSubmittedTimeFetcher = async (dispatchId: number, timeId: number, entityId: number) => {
    var parsedData = null;
    if (dispatchId > 0 && timeId > 0 && entityId > 0) {
        const { data } = await axios.post('/dispatchsubmittedtimemultiple', {
            dispatchid: dispatchId,
            timeid: timeId,
            entityid: entityId
        })
        parsedData = JSON.parse(data.jsonString)
    }
    return parsedData
}