import React, {useState} from "react";
import styles from './CWAMainPage.module.css'
import Tab from '@mui/material/Tab';

import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Typography
} from "@material-ui/core";
import {
    DataGridPro,
    GridCallbackDetails,
    GridCellParams,
    GridColDef,
    GridRowParams,
    MuiEvent,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import {CWAListingForm} from "./CWAListingPage/CWAListForm";
import {CWAListing, CWAListingDefaults, useDeleteCWAListing, useFetchCWAListing, sendCWRExport} from "../../queries/useCWAListing";
import {Add} from "@mui/icons-material";
import {Delete, Edit} from "@material-ui/icons";
import {GridSelectionModel, GridValueFormatterParams} from "@material-ui/x-grid";
import {formatMoney} from "../../util/formatHelpers";
import {useFetchPODropdowns} from "../../queries/usePODropdowns";
import {useFetchCWADropdowns} from "../../queries/useCWADropdowns";
import CWAAmountsTable from "./CWAAmounts/CWAAmountsTable";
import POMainPage from "./POMainPage/POMainPage";
import {Alert, Grid} from "@mui/material";
import {NotesIcon} from "../../components/common/NotesLayout/NotesLayout";
import Tabs from "@mui/material/Tabs";
import WOListingTable from "../WorkOrderPage/ListingPages/WOListingTable";
import WOFormTabPage from "../WorkOrderPage/WorkOrderFormTabPage/WOFormTabPage";
import DivisionsPage from "../WorkOrderPage/Divisions/DivisionsPage";
import WorkAuthorizationsPage from "../WorkOrderPage/WorkAuthorizations/WorkAuthorizationsPage";
import SchedulesPage from "../WorkOrderPage/Schedules/SchedulesPage";
import DocumentsPage from "../WorkOrderPage/Documents/DocumentsPage";
import DependenciesPage from "../WorkOrderPage/Dependencies/DependenciesPage";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import dayjs from "dayjs";
import {dispatchpwrpush} from "../../queries/useSendDispatch";
import Snackbar from "@mui/material/Snackbar";

const CWAMainPage = () => {
    const gridAPIRef = useGridApiRef()
    const { data: listingData } = useFetchCWAListing()
    const [tabSel, setTabSel] = React.useState<number>(0);
    const deleteCWAListing = useDeleteCWAListing()
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState(CWAListingDefaults)
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [listingString, setListingString] = useState<string>('')
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const [listingIDSelected, setListingIDSelected] = React.useState<number>(null);
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([19]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabSel(newValue)
    }

    const columns: GridColDef[] = [
        {
            field: 'Edit',
            renderHeader: () => {
                return (
                    <IconButton
                        className={styles.submitButton}
                        onClick={() => handleAdd()}
                        size="small">
                        <Add />
                    </IconButton>
                )
            },
            sortable: false,
            width: 50,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
                    <Edit />
                </IconButton>
            )
        },
        {
            field: 'id',
            hide: true
        },
        {
            field: 'cwalentityid',
            type: 'string',
            headerName: 'Entity',
            width: 100,
            valueFormatter: (params) =>
                getEntityDescription(Number(params.value))
        },
        {
            field: 'cwalcustomerinfo',
            type: 'string',
            headerName: 'Customer',
            width: 300,
            // valueFormatter: (params) =>
            //     getCustomerCodeDescription(Number(params.value))
        },
        {
            field: 'cwalnumber',
            type: 'string',
            headerName: 'CWA No.',
            width: 200
        },
        {
            field: 'cwaladdeddate',
            type: 'string',
            headerName: 'Added',
            width: 125,
            valueFormatter: (params) =>
                (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DD").format('MM/DD/YYYY') : 'N?A')
        },
        {
            field: 'cwalcurrentapproved',
            type: 'number',
            headerName: 'Approved',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            field: 'cwalcurrentpending',
            type: 'string',
            headerName: 'Pending',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            field: 'Delete',
            headerName: '',
            width: 80,
            align: "center",
            headerAlign: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <>
                    <NotesIcon
                        id={Number(params.id)}
                        notesid={params.getValue(params.id, 'notesid')}
                        notestitle={params.getValue(params.id, 'notestitle')}
                        notestypeid={params.getValue(params.id, 'notestypeid')}
                        noteslinkid={params.getValue(params.id, 'noteslinkid')}
                        notescount={params.getValue(params.id, 'notescount')}
                        queryType={['cwalisting']}
                    />
                    <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                        <Delete />
                    </IconButton>
                </>
            )
        }
    ]

    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const handleEdit = (params: GridCellParams) => {
        const cwaListing = listingData.filter((item) => item.cwalid === Number(params.id))[0]
        setReloadForm(reloadForm+1)
        setGridParams({
            cwalid: cwaListing.cwalid,
            cwalentityid: cwaListing.cwalentityid,
            cwalcustomercodeid: cwaListing.cwalcustomercodeid,
            cwalcustomerinfo: cwaListing.cwalcustomerinfo,
            cwalnumber: cwaListing.cwalnumber,
            cwaldescription: cwaListing.cwaldescription,
            cwalamountlastupdated: cwaListing.cwalamountlastupdated,
            cwaladdeddate: cwaListing.cwaladdeddate,
            cwalactive: cwaListing.cwalactive,
            cwalexpirationdate: cwaListing.cwalexpirationdate,
            cwalcurrentapproved: cwaListing.cwalcurrentapproved,
            cwalcurrentpending: cwaListing.cwalcurrentpending,
            notestypeid: cwaListing.notestypeid,
            notestitle: cwaListing.notestitle,
            noteslinkid: cwaListing.noteslinkid,
            notescount: cwaListing.notescount
        })
        setIsFormShowing(true)
    }

    const { data: dropDownData, isLoading: dropdownLoading } = useFetchCWADropdowns()
    const getCustomerCodeDescription = (custId: number) => {
        const value = dropDownData.custlist.filter((e) => e.custid === custId)
        return value[0]?.custcode
    }
    const getEntityDescription = (entid: number) => {
        const value = dropDownData.entitylist.filter((e) => e.entityid === entid)
        return value[0]?.entityname
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObj({
            msg: 'Are you sure you want to delete CWA # \"' + params.getValue(params.id, 'cwalnumber').toString() + "\"?",
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteCWAListing.mutateAsync({ cwalid: dialogObj.id })
        setListingString('')
        setListingIDSelected(null)
        closeDialog()
    }

    const closeDialog = () => {
        setDialogObj({
            msg: '',
            id: 0
        })
    }

    const handleListingRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        setListingIDSelected(Number(param.id))
        const listingsSeletedData: CWAListing[] = listingData.filter((listing) => { return listing.cwalid === param.id})
        setListingString(listingsSeletedData[0].cwalnumber + " - " + listingsSeletedData[0].cwalcustomerinfo)
    }

    var listingRows = []
    if (listingData && listingData.length > 0) {
        listingRows = listingData.map((listing: CWAListing, i) => {
            return {
                id: listing.cwalid,
                cwalentityid: listing.cwalentityid,
                cwalcustomercodeid: listing.cwalcustomercodeid,
                cwalcustomerinfo: listing.cwalcustomerinfo,
                cwano: listing.cwalnumber,
                cwaladdeddate: listing.cwaladdeddate,
                cwalcurrentapproved: listing.cwalcurrentapproved,
                cwalcurrentpending: listing.cwalcurrentpending,
                cwalnumber: listing.cwalnumber,
                notestypeid: listing.notestypeid,
                notestitle: listing.notestitle,
                noteslinkid: listing.noteslinkid,
                notescount: listing.notescount
            }
        })
    }


    React.useEffect(() => {
        if (listingData && listingData.length > 0) {
            // console.log(listingData.length)
            const id = listingData[0].cwalid
            const listStirng = listingData[0].cwalnumber + " - " + listingData[0].cwalcustomerinfo
            setListingIDSelected(id)
            setListingString(listStirng)

            // if (gridAPIRef.current.getRowsCount() > 0) {
            setTimeout(() => {
                gridAPIRef.current?.selectRows([id])
            })
            // }
        }
    }, [listingData]);

    const [snackbarOpen, setSnackbarOpen] = React.useState({ open: false, message: '' })

    const handleCWRExport = () => {
        sendCWR()
    }

    const sendCWR = async () => {
        const response = await sendCWRExport()
        setSnackbarOpen({ open: true, message: response.message })
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen({ open: false, message: ""  });
    }

    return (
        <div className={styles.root}>
            <>
                <Snackbar
                    open={snackbarOpen.open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                >
                    <Alert severity="success">
                        {snackbarOpen.message}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>
            <AppBar position="static" color="default">
                <Typography
                    variant="h6"
                    color="primary"
                    align="center"
                    component="div">
                    CWA
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    component="div">
                    CWA NO. {listingString}
                </Typography>
                <Tabs
                    value={tabSel}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab value={0} label={'CWA'}></Tab>
                    <Tab value={1} label={'CWA Amounts'}></Tab>
                    <Tab value={2} label={'PO'}></Tab>
                </Tabs>

                <CWAListingForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} customerCodes={dropDownData.custlist} entitylist={dropDownData.entitylist}/>
                {tabSel === 0 && (
                    <>
                        <DataGridPro
                            apiRef={gridAPIRef}
                            rows={listingRows}
                            columns={columns}
                            autoHeight={true}
                            density={'compact'}
                            pageSize={10}
                            loading={!listingData}
                            disableMultipleSelection={true}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            selectionModel={selectionModel}
                            onRowClick={handleListingRowClick}
                        />
                        <Grid container direction="row" justifyContent="flex-end">
                            <Button style={{width: '180px', height: '30px', marginLeft: '40px', marginTop: '20px', marginRight: '10px', marginBottom: '20px'}} variant="contained" onClick={handleCWRExport}>
                                CWA Export
                            </Button>
                        </Grid>
                    </>

                    // <CWAListingPage></CWAListingPage>
                )}
                {tabSel === 1 && (
                    <CWAAmountsTable id={listingIDSelected} listingDisplay={listingString} cwaamountsstatuslist={dropDownData.cwaamountsstatuslist}/>
                )}
                {tabSel === 2 && (
                    <Grid container spacing={1}>
                        <Grid item xs={12} >
                            <POMainPage id={listingIDSelected} listingDisplay={listingString} />
                        </Grid>
                    </Grid>
                    // id={listingIDSelected} listingDisplay={listingString} cwaamountsstatuslist={dropDownData.cwaamountsstatuslist}/>
                )}


            </AppBar>


        </div>
      )
}


export default CWAMainPage
