import React, { useState } from 'react'
import { useEffect } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {useFetchMenu} from "../../../../queries/useMenu";

const MainMenu = (isMenuOpen, setIsMenuOpen ) => {

  const {data: menuData} = useFetchMenu()

  const history = useHistory()
  const { pathname } = useLocation()

  const drawerWidth = 240;
  const [currentlyOpen, setCurrentlyOpen] = React.useState<string>("")

  useEffect(() => {
        if (window && window.location && window.location.pathname) {
          const currentOpen = window.location.pathname
          setCurrentlyOpen(currentOpen)
        }
      }
  , [window.location.pathname])

  const openSubMenu = (linkTo: string) => {
    if (linkTo === window.location.pathname) {
      window.location.reload()
    }
    else {
      history.push(linkTo)
    }
    setCurrentlyOpen(linkTo)
  }

  const openMenuLink = (menu) => {
    if (!menu.submenuitems) {
      const linkTo = menu.link
      if (linkTo === window.location.pathname) {
        window.location.reload()
      }
      else {
        history.push(linkTo)
      }
    }
    setCurrentlyOpen(menu.title)
  }

  const isMenuSelected = (menu) => {
    return menu.link === currentlyOpen
  }

  const buildMenu = () => {
    if (menuData && menuData.length > 0) {
      const mainMenuData = menuData.map(menu => {
        var subMenuData
        var isOpen = false
        if (menu && menu.submenuitems) {
          isOpen = menu.title === currentlyOpen || menu.submenuitems.find((item: any) => item.link === currentlyOpen)
          subMenuData = buildSubMenu(menu.submenuitems, menu)
        }

        return (
            <>
              <ListItemButton selected={isMenuSelected(menu)}
                              onClick={() => {openMenuLink(menu)}}>
                <ListItemText primary={menu.title} />
                {subMenuData ? isOpen ? <ExpandLess /> : <ExpandMore /> : <></>}
              </ListItemButton>
              {subMenuData}
            </>
        )
      })

      return mainMenuData
    }
  }

  const buildSubMenu = (subMenuData, mainMenu) => {
    if (subMenuData && subMenuData.length > 0) {
      // const isOpen = subMenuData.find(item => item.link === currentlyOpen)

      var menuItems = subMenuData.map(menu => {
        return (
            <>
                <ListItemButton selected={window.location.pathname === menu.link} sx={{ pl: 4 }} onClick={() => {openSubMenu(menu.link)}}>
                  <ListItemText primary={menu.title} />
                </ListItemButton>
            </>
        )
      })

      return (
          <Collapse in={mainMenu.title === currentlyOpen || subMenuData.find(item => item.link === currentlyOpen)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {menuItems}
            </List>
          </Collapse>
      );
    }
  }

  return (
      <Drawer
          anchor={'left'}
          open={isMenuOpen}
          onClose={() => { setIsMenuOpen(!isMenuOpen) }}
      >
        <Toolbar />
          <Box
              sx={{ width: 250 }}
              role="presentation"
          >
          <List>
            {buildMenu()}
          </List>
        </Box>
      </Drawer>
  )
}
export default MainMenu
