import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface gridState {
    gridid: number
    gridType: string
    gridState: string
}

export const gridStateDefaults = {
    gridid: 0,
    gridType: '',
    gridState: ''
}

export const useFetchGridState =  (gridType: string) => {
    return useQuery<gridState[]>(
        ['gridstate', gridType],
        () => globalFetcher(gridType),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdateGridState = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`gridstate`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['gridstate'])
        }
    })
}

export const useDeleteGridState = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`gridstate`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['gridstate'])
        }
    })
}

export const globalFetcher = async (type: string) => {
    const { data } = await axios.post('/gridstate', {
        gridType: type
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.gridlist as gridState[]
}
