import React, {useEffect, useMemo, useState} from "react";
import styles from './TimeEntry.module.css'
import {GridSelectionModel, GridSortItem, GridValueFormatterParams} from "@material-ui/x-grid";
import {useQueryClient} from 'react-query'
import {
    DataGridPro,
    GridCallbackDetails,
    GridCellParams,
    GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams, GridRenderCellParams,
    GridRowParams, gridSortedRowIdsSelector,
    GridSortModel, GridToolbarContainer,
    MuiEvent, useGridApiContext,
    useGridApiRef,
} from "@mui/x-data-grid-pro";

import {useFetchGridState, useUpdateGridState} from "../../../queries/useGridState";
import {
    dispatchsend,
    dispatchtransfertime,
    dispatchtransferwip
} from "../../../queries/useSendDispatch";
import {Alert, Badge, BadgeProps, FormGroup, Grid, MenuItem, Select, SelectChangeEvent, styled} from "@mui/material";
import {NotesIcon} from "../../../components/common/NotesLayout/NotesLayout";
import {Box, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Typography} from "@material-ui/core";
import {Delete, PhoneIphone, Edit, SettingsBackupRestore, FolderOpen} from "@material-ui/icons";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Add, Save} from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import dayjs from "dayjs";
import {TimeListData, useFetchDispatchSubmittedTime, useDeleteDispatchSubmittedTIme} from "../../../queries/useDispatchTimeEntry";
import {formatMoney} from "../../../util/formatHelpers";
import {ButtonProps} from "@mui/material/Button";
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {AdminTimeEntryForm} from "../../DispatchPage/AdminTimeEntryForm";
import {FileViewerForm} from "../../FileViewerPage/FileViewerForm";
import {MultipleTimeEntryForm} from "./MultipleTimeEntryForm";

const TimeEntryPage = (params) => {
    const gridTEStateVersion = 'time-entry-dispatch-grid-v1.1'
    const { data: gridTEStateData } = useFetchGridState(gridTEStateVersion)
    const deleteTimeEntry = useDeleteDispatchSubmittedTIme()
    let _sortModelState: any = null
    const [gridShowing, setGridShowing] = useState(false)
    const [gridParams, setGridParams] = useState({})
    const [dialogObjDel, setDialogObjDel] = React.useState({msg: "", dispatchid: 0, timeid: 0})
    const [dialogObj, setDialogObj] = React.useState({msg: "", gridState: "", okButtonText: "Save"})
    const [snackbarOpen, setSnackbarOpen] = React.useState({ open: false, message: '' })
    const [dataRows, setDataRows] = React.useState([]);
    const [timeRecap, setTimeRecap] = React.useState([]);
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([1]);
    const gridAPIRef = useGridApiRef()
    const updateGridState = useUpdateGridState()
    const [vehicleList, setVehicleList] = useState([])
    const [employeeList, setEmployeeList] = useState([])
    const [isTEFormShowing, setIsTEFormShowing] = useState(false)
    const [handleMultiple, setHandleMultiple] = useState(false)
    const [reloadTEForm, setReloadTEForm] = useState<number>(0)
    const [teEdit, setTEEdit] = React.useState(false)
    const [timeObject, setTimeObject] =  React.useState<TimeListData>()
    const [openFileModal, setOpenFileModal] = React.useState( {openDialog: false, scheduledescription: '', woid: 0, scheduleid: 0} );
    const [woid, setWoid] = useState<number>(0)
    const [scheduleid, setScheduleid] = useState<number>(0)
    const [scheduledescription, setScheduledescription] = useState<string>('')


    const gridSortItem: GridSortItem = {
        field: 'schedulestartdate',
        sort: 'asc'
    }
    const defaultGridSortModel: GridSortModel = [gridSortItem]
    const [gridSortModel, setGridSortModel] = React.useState<GridSortModel>(defaultGridSortModel)

    useEffect(() => {
        console.log(params.dispatchList)
        setWoid(params.dispatchList.dispatchwoid)
        setScheduleid(params.dispatchList.dispatchschedulenumber)
        setScheduledescription(params.dispatchList.dispatchcustomerinfo)
    }, [params.dispatchList])

    // const { data: dispatchSubmittedTImeDate } = useFetchDispatchSubmittedTime(params.dispatchList.dispatchid)
    useEffect(() => {
        setGridSortModel(getColumnSortModel())

        setEmployeeList(params?.dispatchSubmittedTImeDate.employeelist)
        setVehicleList(params?.dispatchSubmittedTImeDate.vehiclelist)
        if (params?.dispatchSubmittedTImeDate?.timelist && params?.dispatchSubmittedTImeDate?.timelist?.length > 0) {
            const dataRows = getMappedRecords()
            setDataRows(dataRows)
        }
        else {
            setDataRows([])
        }

        if (params?.dispatchSubmittedTImeDate?.timerecap && params?.dispatchSubmittedTImeDate?.timerecap?.length > 0) {
            console.log(params?.dispatchSubmittedTImeDate?.timerecap)
            setTimeRecap(params?.dispatchSubmittedTImeDate?.timerecap)
        }
        else {
            setTimeRecap([])
        }


    }, [params?.dispatchSubmittedTImeDate])
    const canDispatch = (params: GridCellParams) => {
        return params.row.schedulecandispatch
    }
    const getMappedRecords = () => {
        return params?.dispatchSubmittedTImeDate?.timelist.map((timeList: TimeListData, i) => {
            return {
                id: timeList.timeid,
                readonly: timeList.readonly,
                canedit: timeList.canedit,
                multipletimefordate: timeList.multipletimefordate,
                candelete: timeList.candelete,
                dispatchid: timeList.dispatchid,
                timeid: timeList.timeid,
                employeeid: timeList.employeeid,
                employee: timeList.employee,
                starttravel: timeList.starttravel,
                onjob: timeList.onjob,
                leavejob: timeList.leavejob,
                endtravel: timeList.endtravel,
                vehicleid: timeList.vehicleid,
                vehicle: timeList.vehicle,
                perdiem: timeList.perdiem,
                lunch: timeList.lunch,
                comments: timeList.comments,
                shiftpay: timeList.shiftpay,
                paylevel: timeList.paylevel,
                travel: timeList.travel,
                missedmeal: timeList.missedmeal,
                additionalot: timeList.additionalot,
                additionaldt: timeList.additionaldt,
                st: timeList.st,
                ot: timeList.ot,
                dt: timeList.dt,
                meals: timeList.meals,
                overridecalc: timeList.overridecalc,
                timeisposted: timeList.timeisposted,
                transferred: timeList.transferred,
                employeecolor: timeList.employeecolor,
                employeehover: timeList.employeehover,
            }
        })
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen({ open: false, message: ""  });
    }

    const basicProps = {
        disableColumnMenu: true,
        sortable: false,
        disableExport: true,
        disableReorder: true,
        editable: false,
        filterable: false,
        groupable: false,
        hideable: false,
        pinnable: false
    }

    const HandleOpenMultiple = async (gridParams: GridCellParams) => {
        setHandleMultiple(true)
    }

    const HandleEdit = async (gridParams: GridCellParams) => {

        const timeObj = params?.dispatchSubmittedTImeDate?.timelist.filter((item) => item.timeid === Number(gridParams.id))[0]
        console.log(timeObj)
        setGridParams({
            id: timeObj.timeid,
            readonly: timeObj.readonly,
            canedit: timeObj.canedit,
            multipletimefordate: timeObj.multipletimefordate,
            candelete: timeObj.candelete,
            dispatchid: timeObj.dispatchid,
            timeid: timeObj.timeid,
            employeeid: timeObj.employeeid,
            employee: timeObj.employee,
            starttravel: timeObj.starttravel,
            onjob: timeObj.onjob,
            leavejob: timeObj.leavejob,
            endtravel: timeObj.endtravel,
            vehicleid: timeObj.vehicleid,
            vehicle: timeObj.vehicle,
            perdiem: timeObj.perdiem,
            lunch: timeObj.lunch,
            comments: timeObj.comments,
            shiftpay: timeObj.shiftpay,
            paylevel: timeObj.paylevel,
            travel: timeObj.travel,
            missedmeal: timeObj.missedmeal,
            additionalot: timeObj.additionalot,
            additionaldt: timeObj.additionaldt,
            st: timeObj.st,
            ot: timeObj.ot,
            dt: timeObj.dt,
            meals: timeObj.meals,
            overridecalc: timeObj.overridecalc,
            timeisposted: timeObj.timeisposted,
            transferred: timeObj.transferred,
            employeecolor: timeObj.employeecolor,
            employeehover: timeObj.employeehover,
            lastwipposted: timeObj.lastwipposted,
            lasttimeposted: timeObj.lasttimeposted,
            dispatchsudmitteddate: timeObj.dispatchsudmitteddate,
        })

        setTEEdit(true)
        setReloadTEForm(reloadTEForm + 1)
        setIsTEFormShowing(true)
    }

    const HandleDispatchSend = (params: GridCellParams) => {
       sendDispatch(params.id)
    }

    const handleAdd = () => {
        setTEEdit(false)
        setReloadTEForm(reloadTEForm + 1)
        setGridParams(null)
        setIsTEFormShowing(true)
    }

    const canEdit = (params: GridCellParams) => {
        return params.row.canedit
    }

    const buildColumnDefs = () => {
        const gridObj = parseGridObject()
        const parsedGridColumns = gridObj.gridDefs
        var gridColumns: GridColDef[] = [
            gridEditSaveButtonDef,
            ...parsedGridColumns,
            gridButtonsDef
        ]
        return gridColumns
    }

    const gridButtonsDef: GridColDef = {
        field: 'Delete',
        headerName: '',
        width: 40,
        align: "center",
        headerAlign: "center",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <>
                    {params.row.candelete &&
                        <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                            <Delete />
                        </IconButton>
                    }
                </>
            )
        }
    }
    const handleDelete = (params: GridCellParams) => {
        setDialogObjDel({
            msg: 'Are you sure you want to delete this time entry for employee \"' + params.row.employee.toString() + "\"?",
            dispatchid: Number(params.row.dispatchid),
            timeid: Number(params.row.timeid)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteTimeEntry.mutateAsync({ dispatchid: dialogObjDel.dispatchid, timeid: dialogObjDel.timeid })
        params.setReloadData(params.reloadData + 1)
        closeDialog()
    }

    const getColumnSortModel = () => {
        const gridObj = parseGridObject()
        return gridObj.sortModel
    }

    const formatCell = (params: GridRenderCellParams) => {
        var employeeColor = 'rgba(255,255,255,0)'
        if (params?.row?.employeecolor === '#FFFFFF') {
            employeeColor = 'rgba(255,255,255,0)'
        }
        else if (params?.row?.employeecolor) {
            employeeColor = params.row.employeecolor
        }
        return (
            <>
                {/*<div style={{float: 'left', clear: 'none', width: '100%', height: '100%', 'backgroundColor': params.row.employeecolor}}>*/}
                    <div title={params.row.employeehover} style={{
                        paddingLeft: '10px', width: '100%', 'backgroundColor': employeeColor, display: 'flex', textAlign: 'center', verticalAlign: 'middle', color: "black", fontSize:18}}>{params.value}
                    </div>
                {/*</div>*/}
            </>
        )
    }


    const formatDate = (params: GridValueFormatterParams) => {
        return (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DDTHH:mm:ss").format('MM/DD hh:mm A') : '')
    }

    // const formatDate = (params: GridValueFormatterParams) => {
    //     return (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DDTHH:mm:ss").format('MM/DD/YYYY hh:mmA') : '')
    // }

    const formatMoneyParam = (params: GridValueFormatterParams) => {
        return formatMoney(Number(params.value))
    }

    const formatBoolean = (params: GridValueFormatterParams) => {
        return (params.value ? 'Yes' : 'No')
    }

    const parseGridObject = () => {
        var sortModel: GridSortModel = [gridSortItem]
        var gridStateString = JSON.stringify({gridType: "timeentrydispatch", gridState: JSON.stringify(gridDefaults)})
        if (params.gridStateData && params.gridStateData.length > 0) {
            gridStateString = params.gridStateData[0].gridState
            const gridStateData = JSON.parse(gridStateString)
            const sendObj = {
                gridType: 'timeentrydispatch',
                gridState: JSON.stringify(gridStateData.columnDefs)
            }
            gridStateString = JSON.stringify(sendObj)

            if (gridStateData && gridStateData.sortModel !== null && gridStateData.sortModel.length > 0) {
                // gridAPIRef?.current?.setSortModel(gridStateData.sortModel)
                const dataSort = gridStateData.sortModel
                sortModel = dataSort
            }
        }

        var gridDefs: GridColDef[] = [];

        if (gridStateString.length > 0) {
            const gridObject = JSON.parse(gridStateString)
            if (gridObject.gridType === 'timeentrydispatch') {
                const gridState = JSON.parse(gridObject?.gridState)
                gridState?.forEach(state => {
                    var col: GridColDef = {...state}

                    if (col.field === 'starttravel' || col.field === 'onjob' || col.field === 'leavejob' || col.field === 'endtravel') {
                        col.valueFormatter = formatDate
                    }
                    else if (col.field === 'perdiem') {
                        col.valueFormatter = formatMoneyParam
                    }
                    else if (col.field === 'lunch') {
                        col.valueFormatter = formatBoolean
                    }
                    else if (col.field === 'employee') {
                        col.renderCell = formatCell
                    }

                    gridDefs.push(col)
                })
            }
        }
        return {gridDefs: gridDefs, sortModel: sortModel}
    }

    // employeecolor
    const gridDefaults: GridColDef[] = [
        {
            "field": "employee",
            "headerName": "Employee",
            "headerAlign": "left",
            // "type": "string",
            "width": 300,
            "align": "left",
            renderCell: formatCell
        },
        // {
        //     "field": "employeecolor",
        //     "headerName": "Employee Color",
        //     "headerAlign": "left",
        //     "type": "string",
        //     "width": 300,
        //     "align": "left",
        // },
        {
            "field": "starttravel",
            "headerName": "Start Travel",
            "headerAlign": "left",
            "type": "string",
            "width": 140,
            "align": "left",
            valueFormatter: formatDate
        },
        {
            "field": "onjob",
            "headerName": "On Job",
            "headerAlign": "left",
            "type": "string",
            "width": 140,
            "align": "left",
            valueFormatter: formatDate
        },
        {
            "field": "leavejob",
            "headerName": "Leave Job",
            "headerAlign": "left",
            "type": "string",
            "width": 140,
            "align": "left",
            valueFormatter: formatDate
        },
        {
            "field": "endtravel",
            "headerName": "On Travel",
            "headerAlign": "left",
            "type": "string",
            "width": 140,
            "align": "left",
            valueFormatter: formatDate
        },
        {
            "field": "vehicle",
            "headerName": "Vehicle",
            "headerAlign": "left",
            "type": "string",
            "width": 125,
            "align": "left"
        },
        {
            "field": "lunch",
            "headerName": "Missing Lunch",
            "headerAlign": "right",
            "type": "string",
            "width": 150,
            align: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                console.log(params.value)
                return formatBoolean(params)
            },
        },
        {
            "field": "perdiem",
            "headerName": "Per/Diem",
            "headerAlign": "right",
            "type": "string",
            "width": 125,
            align: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            "field": "st",
            "headerName": "ST",
            "headerAlign": "right",
            "type": "number",
            "width": 125,
            align: "right",
        },
        {
            "field": "ot",
            "headerName": "OT",
            "headerAlign": "right",
            "type": "number",
            "width": 125,
            align: "right",
        },
        {
            "field": "dt",
            "headerName": "DT",
            "headerAlign": "right",
            "type": "number",
            "width": 125,
            align: "right",
        },
        {
            "field": "meals",
            "headerName": "Meals",
            "headerAlign": "right",
            "type": "number",
            "width": 125,
            align: "right",
        },
        {
            "field": "travel",
            "headerName": "Travel",
            "headerAlign": "right",
            "type": "string",
            "width": 125,
            align: "right",
        },
        {
            "field": "transferred",
            "headerName": "Transferred",
            "headerAlign": "right",
            "type": "string",
            "width": 125,
            align: "right",
        },
    ]

    const gridEditSaveButtonDef: GridColDef = {
        field: '',
        renderHeader: () => {
            return (
                <>
                    {params?.dispatchSubmittedTImeDate?.canaddtime &&
                        <IconButton
                            className={styles.submitButton}
                            onClick={() => handleAdd()}
                            size="small">
                            <Add />
                        </IconButton>
                    }
                </>
            )
        },
        width: 100,
        align: "left",
        headerAlign: "left",
        pinnable: true,
        cellClassName: 'pinnedColumn',
        ...basicProps,
        renderCell: (params) => (
            <>
                <IconButton className={styles.button} onClick={() => {
                    const selectedRowData = dataRows.find((row) => row.id === params.id)
                    console.log(selectedRowData)
                    // resetGridParams(params.id)
                    setOpenFileModal({openDialog: true, scheduledescription: scheduledescription, woid: woid, scheduleid: scheduleid})
                }}>
                    <FolderOpen />
                </IconButton>

                <IconButton className={styles.button} disabled={!params.row.multipletimefordate} onClick={() => { HandleOpenMultiple(params) }}>
                    <OpenInNewIcon />
                </IconButton>

                <IconButton className={styles.button} disabled={!params.row.canedit} onClick={() => {
                    HandleEdit(params)
                }}>
                    <Edit/>
                </IconButton>
            </>
        )
    }

    const gridIdDef: GridColDef = {
        field: 'id',
        hide: true,
        ...basicProps
    }

    const getColumnStringToSave = () => {
        var fieldObjects = []
        const gridState = gridAPIRef.current.getAllColumns()
        gridState.forEach(stateColumnDef => {
            if (stateColumnDef.sortable && stateColumnDef.pinnable && stateColumnDef.groupable) {
                fieldObjects.push({
                    field: stateColumnDef.field,
                    headerName: stateColumnDef.headerName,
                    headerAlign: stateColumnDef.headerAlign,
                    type: stateColumnDef.type,
                    width: stateColumnDef.width,
                    align: stateColumnDef.align,
                    description: stateColumnDef.description,
                    hide: stateColumnDef.hide,
                    valueFormatter: stateColumnDef.valueFormatter
                })
            }
        })

        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: fieldObjects, sortModel: sortModelDefs})
        return strObject
    }

    const handleRestore = () => {
        setGridShowing(false)
        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: gridDefaults, sortModel: sortModelDefs})
        setDialogObj({
            msg: 'Are you sure you want to reset the grid state to default?',
            gridState: strObject,
            okButtonText: 'RESET'
        })
    }

    const sendDispatch = async  (scheduleID) => {
        const response = await dispatchsend(scheduleID)
        setSnackbarOpen( { open: true, message: response.message })
    }

    const handleSave = () => {
        setGridShowing(false)
        const columnObjsting = getColumnStringToSave()
        setDialogObj({
            msg: 'Are you sure you want to save the current grid state?',
            gridState: columnObjsting,
            okButtonText: 'Save'
        })
    }

    const handleOkConfirm = async () => {
        var finalData = {
            gridType: params.gridStateVersion,
            gridState: dialogObj.gridState
        }
        const {data: response} = await updateGridState.mutateAsync(finalData)
        closeDialog()
        setGridShowing(true)
        setSnackbarOpen({ open: true, message: "Time Entry settings have been saved" })
    }

    const closeDialog = () => {
        setGridShowing(true)
        setDialogObj({
            msg: '',
            gridState: '',
            okButtonText: ''
        })
        setDialogObjDel({
            msg: '',
            dispatchid: 0,
            timeid: 0
        })
    }

    const handleRowClick = (gridParams: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        const timeListData = params?.dispatchSubmittedTImeDate.timelist.filter((dispatchList) => { return dispatchList.timeid === Number(gridParams.id)})

        if (timeListData && timeListData.length > 0) {
            setTimeObject(timeListData[0])
        }
    }

    const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
        gridSortedRowIdsSelector(apiRef);

    const sendTime = async () => {
        const response = await dispatchtransfertime(params.dispatchList.dispatchid)
        params.setReloadData(params.reloadData + 1)
        setSnackbarOpen({ open: true, message: response.message })
    }

    function CustomGridToolbar() {
        const apiRef = useGridApiContext();

        const handleExport = (options: GridCsvExportOptions) =>
            apiRef.current.exportDataAsCsv(options);

        const buttonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <FileDownloadOutlinedIcon />,
        };

        const saveButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <Save />,
        };

        const restoreButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <SettingsBackupRestore />,
        };

        return (
            <GridToolbarContainer >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        {...saveButtonBaseProps}
                        onClick={() => handleSave()}
                    >
                        SAVE GRID SETTINGS
                    </Button>
                    <Button
                        {...restoreButtonBaseProps}
                        onClick={() => handleRestore()}
                    >
                        RESTORE GRID SETTINGS
                    </Button>
                    <Button
                        {...buttonBaseProps}
                        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
                    >
                        EXPORT
                    </Button>
                    {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                </Grid>
            </GridToolbarContainer>
        )
    }

    const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({

    }))

    return (
        <>
        <>
            <Snackbar
                open={snackbarOpen.open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert severity="success">
                    {snackbarOpen.message}
                </Alert>
            </Snackbar>
        </>
        <>
            <Dialog
                open={dialogObj.msg.length > 0}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogObj.msg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={handleOkConfirm}>{dialogObj.okButtonText}</Button>
                </DialogActions>
            </Dialog>
        </>

        <>
            <Dialog
                open={dialogObjDel.msg.length > 0}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogObjDel.msg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm}>DELETE</Button>
                </DialogActions>
            </Dialog>
        </>
        <>
            <FileViewerForm isOpen={openFileModal} setIsOpen={setOpenFileModal} ></FileViewerForm>
        </>
        <AdminTimeEntryForm
            isOpen={isTEFormShowing}
            setIsOpen={setIsTEFormShowing}
            reloadForm={reloadTEForm}
            employeeList={employeeList}
            vehicleList={vehicleList}
            timeEntries={params.timeListData}
            setTimeEntries={params.setTimeListData}
            gridParams={gridParams}
            isEdit={teEdit}
            dispatchid={params.dispatchList.dispatchid}
            timeid={timeObject?.timeid}
            entityid={params.entityId}
            reloadData={params.reloadData}
            setReloadData={params.setReloadData}
        />
        <MultipleTimeEntryForm
            isOpen={handleMultiple}
            setIsOpen={setHandleMultiple}
            reloadForm={reloadTEForm}
            employeeList={employeeList}
            vehicleList={vehicleList}
            dispatchid={params.dispatchList.dispatchid}
            timeid={timeObject?.timeid}
            entityid={params.entityId}
            dispatchList={params.dispatchList}
            reloadData={params.reloadData}
            setReloadData={params.setReloadData}
        />
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <StyledDataGrid
                    sx={{
                        "& .MuiDataGrid-pinnedColumns": {
                            boxShadow: "none",
                            backgroundColor: "transparent"
                        },
                        "& .MuiDataGrid-pinnedColumnHeaders": {
                            boxShadow: "none",
                            backgroundColor: "transparent"
                        },
                        "& .MuiDataGrid-dataGridCustomRow": {
                            boxShadow: "none",
                            backgroundColor: "red"
                        }
                    }}
                    apiRef={gridAPIRef}
                    rows={dataRows}
                    getRowClassName={(params) => `super-app-theme--${params.row.status}`}
                    columns={buildColumnDefs()}
                    autoHeight={true}
                    density={'compact'}
                    pageSize={10}
                    sortModel={gridSortModel}
                    disableMultipleSelection={true}
                    onSortModelChange={(model: GridSortModel) => setGridSortModel(model)}
                    onStateChange={(state) => {
                        _sortModelState = state.sorting.sortModel

                        return state
                    }}
                    initialState={{
                        pinnedColumns: {left: ['Edit'], right: ['Notes', 'Delete']},
                        sorting: {
                            sortModel: gridSortModel
                        }
                    }}
                    onRowClick={handleRowClick}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    components={{
                        Toolbar: CustomGridToolbar,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    container
                    spacing={1}
                >
                    <Grid item xs={2}>
                        <Typography
                            variant="subtitle1"
                            color="primary"
                            align="center"
                            component="div">
                            Time Recap
                        </Typography>
                        {/*<div className={styles.recapHeader} style={{textAlign: 'center'}}>*/}
                        {/*</div>*/}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography
                            variant="subtitle1"
                            color="primary"
                            align="center"
                            component="div">
                            Hours
                        </Typography>
                        {/*<div className={styles.recapHeader} style={{textAlign: 'center'}}>*/}
                        {/*    Hours*/}
                        {/*</div>*/}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography
                            variant="subtitle1"
                            color="primary"
                            align="center"
                            component="div">
                            Dollars
                        </Typography>
                        {/*<div className={styles.recapHeader}>*/}
                        {/*    Dollars*/}
                        {/*</div>*/}
                    </Grid>
                </Grid>
                {timeRecap && timeRecap.map((recap) => (
                    <Grid
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        container
                        spacing={1}
                    >
                        <Grid item xs={2}>
                            <div className={styles.recapText} style={{textAlign: 'center'}}>
                                {recap.title}
                            </div>
                        </Grid>
                        <Grid item justifyContent="center" alignItems="center" xs={2}>
                            <div className={styles.recapText} style={{textAlign: 'center'}}>
                                {recap.hrs}
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className={styles.recapText} style={{textAlign: 'right'}}>
                                {recap.dollars}
                            </div>
                        </Grid>
                    </Grid>
                ))}
                <Grid container direction="row" justifyContent="flex-end">
                    <Button
                        style={{width: '180px', height: '30px', marginLeft: '40px', marginTop: '20px', marginBottom: '20px'}}
                        variant="contained"
                        disabled={!params?.dispatchSubmittedTImeDate?.canposttime}
                        onClick={() => { sendTime() }}
                    >
                        Post Time
                    </Button>
                </Grid>
            </Grid>
        </Grid>
            </>
    );
}
export default TimeEntryPage
