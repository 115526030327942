import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {WorkOrderData} from "./useWorkOrder";

export interface  DispatchDataObject {
    dispatchsendid: string
    title: string
    sectiondata: SectionData[]
    isreadonly: boolean
    sections: Sections[]
    status: string
    message: string
}

export interface  SectionData {
    RowNumber: number
    label: string
    value: string
}

export interface  EquipmentData {
    equipment: string
    equipmentid: number
}

export interface  VehicleData {
    vehicle: string
    vehicleid: number
}

export interface  EmployeeData {
    employee: string
    employeeid: number
}

export interface  CompanyRepList {
    companyrep: string
    companyrepid: number
}

export interface  FileData {
    id: string
    name: string
    path: string
}

export interface  Sections {
    RowNumber: number
    sectionname: string
    filelist: FileData[]
    sectiondata: SectionData[]
    equipmentlist: EquipmentData[]
    companyreplist: CompanyRepList[]
    vehiclelist: VehicleData[]
    employeelist: EmployeeData[]
    data: []
}

export const DivisionsDataDefaults = {
    RowNumber: 0,
    label: "",
    value: ""
}

export const useFetchDispatch = (userGuid: string) => {
    return useQuery<DispatchDataObject>(
        ['dispatch'],
        () => globalFetcher(userGuid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdateTempDispatch = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`dispatchsave`, body), {
        onSuccess: () => {
            // queryClient.invalidateQueries(['dispatch'])
        }
    })
}

export const useUpdateDispatch = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`dispatch`, body), {
        onSuccess: () => {
            // queryClient.invalidateQueries(['dispatch'])
        }
    })
}

export const useDeleteDispatch = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`dispatch`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['dispatch'])
        }
    })
}

export const globalFetcher = async (userGuid: string) => {
    const { data } = await axios.get('/dispatch/' + userGuid, {})
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as DispatchDataObject
}
