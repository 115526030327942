import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import SignatureCanvas from 'react-signature-canvas'

import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, FormControlLabel, Grid, useMediaQuery
} from '@material-ui/core'

import HookForm from '../../components/common/HookForm'
import styles from './DispatchPage.module.css'
import {useUpdateLookup} from "../../queries/useLookup";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import AdapterDateFns from "@date-io/date-fns";
import {MobileDateTimePicker} from "@mui/x-date-pickers/MobileDateTimePicker";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from 'dayjs';
import Typography from "@mui/material/Typography";
import {useUpdateDispatchSubmittedTIme} from "../../queries/useDispatchTimeEntry"
import MaskedInput from "../../components/common/inputs/MaskedInput";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import AutocompleteSelect from "../../components/common/inputs/AutoCompleteSelectInput/AutocompleteSelect"

interface FormData {
    employeeid: number
    employee: string
    vehicleid: number
    vehicle: string
    missingLunch: boolean
    perdiem: number
}

export const TimeEntryForm = ({ isOpen, setIsOpen, reloadForm, employeeList, vehicleList, timeEntries, setTimeEntries, gridParams, isEdit, dispatchid, timeid, shouldSubmit }) => {
    const formMethods = useForm<FormData>()
    const title = (isEdit ? "Edit Time Entry" : "Create Time Entry")
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateLookup = useUpdateLookup()
   // const [employeeActiveSelect, setEmployeeActiveSelect] = React.useState<string>("")
   // const [employeeActiveSelectId, setEmployeeActiveSelectId] = React.useState<number>()
   // const [vehicleActiveSelect, setVehicleActiveSelect] = React.useState<string>("")
   // const [vehicleActiveSelectId, setVehicleActiveSelectId] = React.useState<number>()
    const signEmployee = React.useRef() as React.MutableRefObject<any>
    const [perdiem, setPerdiem] = useState<number>(0)

    const startDateNearest = new Date()
    startDateNearest.setMinutes(Math.round(startDateNearest.getMinutes() / 15) * 15)
    const dateNearest = new Date()
    startDateNearest.setMinutes(Math.round(startDateNearest.getMinutes() / 15) * 15)

    const [startTravelValue, setStartTravelValue] = React.useState<Dayjs>(dayjs())
    const [onJobValue, setOnJobValue] = React.useState<Date | null>(dayjs().toDate())
    const [endTravelValue, setEndTravelValue] = React.useState<Date | null>(dateNearest)
    const [leaveJobValue, setLeaveJobValue] = React.useState<Date | null>(dateNearest)
    const [employeeSigned, setEmployeeSigned] = React.useState()
    const [missingLunch, setMissingLunch] = React.useState<boolean>(false)
    const updateTimeentry = useUpdateDispatchSubmittedTIme()

    const handleStartTravelChange = (newValue: Dayjs | null) => {
        setStartTravelValue(dayjs(newValue));
    }

    const handleOnJobChance = (newValue: Date | null) => {
        setOnJobValue(newValue);
    }

    const handleOnJobValidation = (newValue: Date | null) => {
        const stV = dayjs(newValue)
        var diffInMinutes = stV.diff(startTravelValue, 'minutes')
        if (diffInMinutes < 0) {
            setOnJobValue(startTravelValue.toDate())
            setDialogMsg("The 'On Job' date must be after the travel start date. Please correct the 'On Job' date.")
        }
        else {
            setOnJobValue(newValue);
        }
    }

    const handleLeaveJobChange = (newValue: Date | null) => {
        setLeaveJobValue(newValue);
    }

    const handleLeaveJobValidation = (newValue: Date | null) => {
        const stV = dayjs(newValue)
        var diffInMinutes = stV.diff(onJobValue, 'minutes')
        if (diffInMinutes < 0) {
            setLeaveJobValue(onJobValue)
            setDialogMsg("The 'Leave Job' date must be after the on job date. Please correct the 'Leave Job' date.")
        }
        else {
            setLeaveJobValue(newValue);
        }
    }

    const handleEndTravelChange = (newValue: Date | null) => {
        setEndTravelValue(newValue);
    }

    const handleEndTravelValidation = (newValue: Date | null) => {
        const stV = dayjs(newValue)
        var diffInMinutes = stV.diff(leaveJobValue, 'minutes')
        if (diffInMinutes < 0) {
            setEndTravelValue(leaveJobValue)
            setDialogMsg("The 'Travel End' date must be after the leave job date. Please correct the 'Travel End' date.")
        }
        else {
            setEndTravelValue(newValue);
        }
    }

    const handlePerdiem = (event) => {
        setPerdiem(event.floatValue)
    }

    useEffect(() => {
        reset({
            employeeid:(isEdit ? gridParams.employeeid : null),
            employee: "",
            vehicleid: (isEdit ? gridParams.vehicleid : null),
            vehicle: "",
        })
        if (gridParams) {
            if (isEdit) {
                console.log('isEdit')
                console.log(gridParams)
               // setEmployeeActiveSelect(gridParams.employee)
                //setEmployeeActiveSelectId(gridParams.employeeid)
                //setVehicleActiveSelect(gridParams.vehicle)
                //setVehicleActiveSelectId(gridParams.vehicleid)
                setEmployeeSigned(gridParams.employeesignature)
                setPerdiem(gridParams.perdiem)
                setValue("perdiem", gridParams.perdiem)
            }
            else {
              //  setEmployeeActiveSelect(null)
                //setEmployeeActiveSelectId(null)
               // setVehicleActiveSelect(null)
               // setVehicleActiveSelectId(null)
                setEmployeeSigned(null)
                setPerdiem(0)
                setValue("perdiem", 0)
            }


            gridParams.starttravel === undefined ?
                setStartTravelValue(dayjs())
                :
                setStartTravelValue(dayjs(gridParams.starttravel))

            gridParams.onjob === undefined ?
                setOnJobValue(dayjs().toDate())
                :
                setOnJobValue(gridParams.onjob)

            gridParams.endtravel === undefined ?
                setEndTravelValue(dateNearest)
                :
                setEndTravelValue(gridParams.endtravel)

            gridParams.leavejob === undefined ?
                setLeaveJobValue(dayjs().toDate())
                :
                setLeaveJobValue(gridParams.leavejob)
        }
        else {
            //setEmployeeActiveSelect(null)
            //setEmployeeActiveSelectId(null)
           // setVehicleActiveSelect(null)
            //setVehicleActiveSelectId(null)
            setPerdiem(0)
            setValue("perdiem", 0)
            setStartTravelValue(dayjs())
            setOnJobValue(dayjs().toDate())
            setLeaveJobValue(dayjs().toDate())
            setEndTravelValue(dayjs().toDate())
        }

    }, [reloadForm])

    const onSubmit = async (data: FormData) => {
        const stV = dayjs(onJobValue)
        const diffInMinutes = stV.diff(startTravelValue, 'minutes')

        const ljV = dayjs(leaveJobValue)
        const diffLJVInMinutes = ljV.diff(onJobValue, 'minutes')

        const teV = dayjs(endTravelValue)
        const diffTEVInMinutes = teV.diff(leaveJobValue, 'minutes')

        if (diffInMinutes < 0) {
            setDialogMsg("The 'On Job' date must be after the travel start date. Please correct the 'On Job' date.")
        }
        else if (diffLJVInMinutes < 0) {
            setDialogMsg("The 'Leave Job' date must be after the on job date. Please correct the 'Leave Job' date.")
        }
        else if (diffTEVInMinutes < 0) {
            setDialogMsg("The 'Travel End' date must be after the leave job date. Please correct the 'Travel End' date.")
        }
        else if (!shouldSubmit && signEmployee.current.isEmpty()) {
            setDialogMsg("Missing a valid signature. Please sign the time card.")
        }
        else {
            if (shouldSubmit) {
                onSubmitProcessing(data)
            }
            else {
                var finalData = {}

                const selection = employeeList?.filter((item) => item.employeeid === data.employeeid)
                let employee = ""
                if (selection && selection.length > 0) {
                    employee = selection[0].employee
                }

                const vehicleSelection = vehicleList?.filter((item) => item.vehicleid === data.vehicleid)
                let vehicle = ""
                if (vehicleSelection && vehicleSelection.length > 0) {
                    vehicle = vehicleSelection[0].vehicle
                }

                if (isEdit) {
                    const filterData = [...timeEntries]
                    const itsIt = filterData.filter((item) => (item.starttravel !== startTravelValue && item.employeeid !== data.employeeid))
                    finalData = [...itsIt, {
                        employeeid: data.employeeid,
                        employee: employee,
                        vehicleid: data.vehicleid,
                        vehicle: vehicle,
                        lunch: missingLunch,
                        starttravel: dayjs(startTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                        onjob: dayjs(onJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                        endtravel: dayjs(endTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                        leavejob: dayjs(leaveJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                        employeesignature: signEmployee.current.getTrimmedCanvas().toDataURL('image/png').split(';base64,')[1],
                    }]
                    setTimeEntries(finalData)
                }
                else {
                    finalData = [...timeEntries, {
                        employeeid: data.employeeid,
                        employee: employee,
                        vehicleid: data.vehicleid,
                        vehicle: vehicle,
                        lunch: missingLunch,
                        starttravel: dayjs(startTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                        onjob: dayjs(onJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                        endtravel: dayjs(endTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                        leavejob: dayjs(leaveJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                        employeesignature: signEmployee.current.getTrimmedCanvas().toDataURL('image/png').split(';base64,')[1],
                    }]

                    setTimeEntries(finalData)
                }
                setIsOpen(false)
            }
        }
    }

    const onSubmitProcessing = async (data: FormData) => {
        var finalData = {}

        const selection = employeeList?.filter((item) => item.employeeid === data.employeeid)
        let employee = ""
        if (selection && selection.length > 0) {
            employee = selection[0].employee
        }

        const vehicleSelection = vehicleList?.filter((item) => item.vehicleid === data.vehicleid)
        let vehicle = ""
        if (vehicleSelection && vehicleSelection.length > 0) {
            vehicle = vehicleSelection[0].vehicle
        }

        if (isEdit) {
            finalData = {
                dispatchid: dispatchid,
                timeid: timeid,
                employeeid: data.employeeid,
                employee: employee,
                vehicleid: data.vehicleid,
                vehicle: vehicle,
                lunch: missingLunch,
                starttravel: dayjs(startTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                onjob: dayjs(onJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                endtravel: dayjs(endTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                leavejob: dayjs(leaveJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                perdiem: perdiem
            }
        }
        else {
            finalData = {
                dispatchid: dispatchid,
                employeeid: data.employeeid,
                employee: employee,
                vehicleid: data.vehicleid,
                vehicle: vehicle,
                lunch: missingLunch,
                starttravel: dayjs(startTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                onjob: dayjs(onJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                endtravel: dayjs(endTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                leavejob: dayjs(leaveJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                perdiem: perdiem
            }
        }

        console.log(finalData)

        const { data: response } = await updateTimeentry.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failed') {
            setDialogMsg(parsedResponse.message)
        }
        setIsOpen(false)
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const clearEmployee = () => {
        signEmployee.current.clear()
    }

    // const handleEmployeeChange = (event) => {
    //     setEmployeeActiveSelect(event.target.value as string)
    //     const selection = employeeList?.filter((item) => item.employee === event.target.value as string)
    //     if (selection && selection.length > 0) {
    //        // setEmployeeActiveSelectId(selection[0].employeeid)
    //     }
    // }

    // const handleVehicleChange = (event) => {
    //     setVehicleActiveSelect(event.target.value as string)
    //     const selection = vehicleList?.filter((item) => item.vehicle === event.target.value as string)
    //     if (selection && selection.length > 0) {
    //         setVehicleActiveSelectId(selection[0].vehicleid)
    //     }
    // }

    const getEmployeeOptions = (employeeList) => {
        return employeeList?.map(item => {
             return {value: item.employeeid, label: item.employee}
        })
    }
    // const getEmployeeOptions = (employeeList) => {
    //     return employeeList?.map(item => (
    //         <MenuItem key={item.employeeid} value={item.employee}>
    //             {item.employee}
    //         </MenuItem>
    //     ))
    // }
    const getVehicleOptions = () => {
               return vehicleList?.map(item => {
            return {value: item.vehicleid, label: item.vehicle}
        })
    }

    const handleMissingLunch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMissingLunch(event.target.checked);
    }

    return (
        <Dialog open={isOpen} fullWidth maxWidth="xl" className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent >
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}
                >
                    <FormControl>
                        {/* <InputLabel>Employee*</InputLabel> */}
                        <AutocompleteSelect 
                            name="employeeid"
                            label="Employee" 
                            options={getEmployeeOptions(employeeList)}
                            required
                        />
                        {/* <Select
                            name="employee"
                            label="Employee"
                            value={employeeActiveSelect}
                            size={'small'}
                            required={true}
                            variant="outlined"
                            disabled={false}
                            onChange={(e) => { handleEmployeeChange(e) }}
                        >
                            {getEmployeeOptions(employeeList)}
                        </Select> */}
                    </FormControl>

                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDateTimePicker
                                label="Travel Start"
                                value={startTravelValue}
                                onChange={handleStartTravelChange}
                                minutesStep={15}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDateTimePicker
                                label="On Job"
                                value={onJobValue}
                                onAccept={handleOnJobValidation}
                                onChange={handleOnJobChance}
                                minutesStep={15}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDateTimePicker
                                label="Leave Job"
                                value={leaveJobValue}
                                onChange={handleLeaveJobChange}
                                onAccept={handleLeaveJobValidation}
                                minutesStep={15}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDateTimePicker
                                label="Travel End"
                                value={endTravelValue}
                                onChange={handleEndTravelChange}
                                onAccept={handleEndTravelValidation}
                                minutesStep={15}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl fullWidth>
                    <AutocompleteSelect 
                            name="vehicleid"
                            label="Vehicle" 
                            options={getVehicleOptions()}
                        />
                        {/* <InputLabel>Vehicle</InputLabel>
                        <Select
                            name="vehicle"
                            label="Vehicle"
                            value={vehicleActiveSelect}
                            size={'small'}
                            required={false}
                            variant="outlined"
                            disabled={false}
                            onChange={(e) => { handleVehicleChange(e) }}
                        >
                            {getVehicleOptions()}
                        </Select> */}
                    </FormControl>

                    
                    {shouldSubmit &&
                        <FormControl fullWidth>
                            <MaskedInput
                                name="perdiem"
                                label="Per/Diem"
                                defaultValue={perdiem ? perdiem.toString() : "0"}
                                onValueChange={handlePerdiem}
                                required={false}
                            />
                        </FormControl>
                    }
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={'missingLunch'}
                                    defaultChecked={false}
                                    onChange={(e) => {handleMissingLunch(e)}}
                                    checked={missingLunch}
                                    color="primary"
                                />
                            }
                            label="Missing Lunch"
                        />
                    </FormControl>


                    
                        <FormControl fullWidth>
                            <>
                                {employeeSigned
                                    ?
                                    <>
                                        <Typography variant="body2" gutterBottom>Current Signature</Typography>
                                        <img height={'100px'} src={`data:image/png;base64,${employeeSigned}`}
                                             alt="Signature"/>
                                    </>
                                    :
                                    null
                                }
                                <>
                                    {!shouldSubmit &&
                                        <>
                                            <Typography variant="body2" gutterBottom>Signature Pad</Typography>
                                            <SignatureCanvas clearOnResize={false} canvasProps={{className: styles.sigPad}}
                                                             ref={signEmployee}/>
                                            <button className={styles.buttons} onClick={clearEmployee}>
                                                Clear
                                            </button>
                                        </>
                                    }
                                </>
                            </>
                        </FormControl>

                    {/*</div>*/}
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
