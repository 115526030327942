import React from "react";
import styles from '../CWAListingPage/CWALiastingPage.module.css'
import {AppBar} from '@material-ui/core'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import POListingsTable from "./POListingPage/POListingsTable";
import {useFetchPODropdowns} from "../../../queries/usePODropdowns";
import POAmountsTable from "./POAmountsPage/POAmountsTable";
import {POListings} from "../../../queries/usePOListings";

const POMainPage = (pageParams) => {
    const [poListingObject, setPOListingObject] = React.useState<POListings>(null);
    const [tabListingSel, setTabListingSel] = React.useState<number>(0);

    const handleListingTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabListingSel(newValue)
    }

    const { data: poDropDownData, isLoading: poDropdownLoading } = useFetchPODropdowns()
    return (
        <div className={styles.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabListingSel}
                    onChange={handleListingTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab disabled={(pageParams.id <= 0)} value={0} label={'PO Listings'}></Tab>
                    <Tab disabled={(pageParams.id <= 0 || poListingObject?.polid <= 0)} value={1} label={'PO Amounts'}></Tab>
                </Tabs>
                {tabListingSel === 0 && (
                    <POListingsTable
                        cwalid={pageParams.id}
                        postatuslist={poDropDownData.postatuslist}
                        poListingObject={poListingObject}
                        setPOListingObject={setPOListingObject}
                    />
                )}
                {tabListingSel === 1 && poListingObject?.polid > 0 && (
                    <POAmountsTable
                        cwalid={pageParams.id}
                        poListingObject={poListingObject}
                        worktypelist={poDropDownData.worktypelist}
                    />
                )}
            </AppBar>
        </div>
    );
}
export default POMainPage
