import React, {useEffect, useState} from "react";
import styles from './DivisionTabPage2.module.css'
import {AppBar, Box, Typography} from "@material-ui/core";
import {Grid} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {gridState, useFetchGridState} from "../../queries/useGridState";
import {useFetchWorkOrderDropdowns} from "../../queries/useWorkOrderDropdowns";
import {WorkOrderData} from "../../queries/useWorkOrder";
import Schedules2Page from "./Schedules2/Schedules2Page";
import {useParams} from "react-router";
import Calendar2Page from "./Calendar2/Calendar2Page";
import DynamicSchedulePage from "../DynamicSchedules";
import Schedules2DynamicPage from "../DynamicSchedules2/SchedulesDynamic2/Schedules2DynamicPage";
import axios from "axios";
import Button, {ButtonProps} from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";
import {fil} from "date-fns/locale";

interface Params {
    entityId: string
}

const DivisionTabPage2 = () => {
    const { entityId } = useParams<Params>()
    const gridScheduleStateVersion = 'schedules-grid-v0'
    const gridDynamicScheduleStateVersion = 'schedules-dynamic2-grid-v2-'
    const { data: gridScheduleStateData } = useFetchGridState(gridScheduleStateVersion)
    const [dynamicGridVersion, setDynamicGridVersion] = React.useState<string>(gridDynamicScheduleStateVersion);
    const [gridScheduleDynamicStateData, setGridScheduleDynamicStateData] = React.useState<gridState[]>();
    const [tabSel, setTabSel] = React.useState<number>(0);
    const { data: woDropDownData } = useFetchWorkOrderDropdowns()
    const [workOrderObj, setWorkOrderObj] = React.useState<WorkOrderData>(null);

    const filteredDate = localStorage.getItem('currentfilterWeekDate')
    var defaultDate = dayjs().day(1).format("MM-DD-YYYY")
    if (filteredDate) {
        defaultDate = filteredDate
    }
    const [currentdate, setcurrentdate] = useState(defaultDate);


    const [previousdate, setpreviousdate] = useState(dayjs().subtract(7, 'day').day(1).format("MM-DD-YYYY"));
    const [nextdate, setnextdate] = useState(dayjs().add(7, 'day').day(1).format("MM-DD-YYYY"));


    const weekDateFilter = localStorage.getItem('filterWeekDate')
    const [filterWeekDate, setFilterWeekDate] = useState(weekDateFilter === 'true')
    const [currentDayColor, setCurrentDayColor] = useState(weekDateFilter === 'true' ? '#494949' : '#bdbdbd');


    const nextbuttonBaseProps: ButtonProps = {
        color: 'primary',
        size: 'small',
        endIcon: <ArrowForwardIcon />,
    };

    const backButtonBaseProps: ButtonProps = {
        color: 'primary',
        size: 'small',
        startIcon: <ArrowBackIcon />,
    };


    useEffect(() => {
        console.log(dynamicGridVersion)

        axios.post('/gridstate', {
            gridType: dynamicGridVersion
        }).then((response) => {
            const dataJson = JSON.parse(response.data.jsonString)
            const dataToSave = dataJson.gridlist as gridState[]
            console.log(dataToSave)
            setGridScheduleDynamicStateData(dataToSave)
        })
    }, [dynamicGridVersion])

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabSel(newValue)
    }

    const handleCurrentDate = () => {
        if (currentDayColor === '#bdbdbd') {
            setCurrentDayColor('#494949')
            setFilterWeekDate(true)
            localStorage.setItem('filterWeekDate', 'true')
        }
        else {
            setCurrentDayColor('#bdbdbd')
            setFilterWeekDate(false)
            localStorage.setItem('filterWeekDate', 'false')
            setcurrentdate(dayjs().day(1).format("MM-DD-YYYY"))
        }
    }

    const handlePreviousDate = () => {
        setcurrentdate(previousdate)
        localStorage.setItem('currentfilterWeekDate', previousdate)
    }

    const handleNextDate = () => {
        setcurrentdate(nextdate)
        localStorage.setItem('currentfilterWeekDate', nextdate)
    }

    return (
        <div className={styles.root}>
            <Grid container spacing={1}>
                <AppBar position="static" color="default">
                    <Typography
                        variant="h6"
                        color="primary"
                        align="center"
                        component="div">
                        UTC Division
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid
                            direction="row"
                            justifyContent="left"
                            alignItems="flex-start"
                            xs={tabSel === 0 ? 2 : 6}
                            sm={tabSel === 0 ? 3 : 12}
                            md={tabSel === 0 ? 3 : 12}
                        >
                            <Tabs
                                value={tabSel}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label=""
                            >
                                <Tab value={0} label={'Dynamic Schedules'}></Tab>
                                {/*<Tab value={1} label={'Schedules'}></Tab>*/}
                                <Tab value={2} label={'Calender'}></Tab>

                            </Tabs>
                        </Grid>
                        {tabSel === 0 && (
                            <>
                                <Grid
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={5} sm={4} md={6}
                                    // style={{border: '4px solid green'}}
                                >
                                    <Box textAlign='center'>
                                        {filterWeekDate &&
                                            <Button
                                                {...backButtonBaseProps}
                                                variant="contained"
                                                onClick={() => handlePreviousDate()}
                                            >
                                                Previous Week
                                            </Button>
                                        }

                                        <Button
                                            onClick={() => handleCurrentDate()}
                                            style={{backgroundColor: currentDayColor, color: '#ffffff'}}
                                        >
                                            {filterWeekDate ? currentdate : 'Click to Filter By Week'}
                                        </Button>
                                        {filterWeekDate &&
                                            <Button
                                                {...nextbuttonBaseProps}
                                                variant="contained"
                                                onClick={() => handleNextDate()}
                                            >
                                                Next Week
                                            </Button>
                                        }
                                    </Box>
                                </Grid>
                                <Grid
                                    direction="row"
                                    justifyContent="right"
                                    alignItems="flex-end"
                                    // style={{border: '4px solid green'}}
                                    xs={2} sm={2} md={3}
                                >
                                </Grid>
                            </>
                        )}

                        {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                    </Grid>


                    {tabSel === 0 && (
                        <Schedules2DynamicPage
                            gridDynamicScheduleStateVersion={gridDynamicScheduleStateVersion}
                            woDropDownData={woDropDownData}
                            entityId={entityId}
                            previousdate={previousdate}
                            setpreviousdate={setpreviousdate}
                            currentdate={currentdate}
                            setcurrentdate={setcurrentdate}
                            nextdate={nextdate}
                            setnextdate={setnextdate}
                            filterWeekDate={filterWeekDate}
                        />
                    )}
                    {tabSel === 1 && (
                        <Schedules2Page
                            gridStateData={gridScheduleStateData}
                            gridStateVersion={gridScheduleStateVersion}
                            woDropDownData={woDropDownData}
                            entityId={entityId}
                            previousdate={previousdate}
                            setpreviousdate={setpreviousdate}
                            currentdate={currentdate}
                            setcurrentdate={setcurrentdate}
                            nextdate={nextdate}
                            setnextdate={setnextdate}
                            filterWeekDate={filterWeekDate}
                        />
                    )}
                    {tabSel === 2 && (
                        <Calendar2Page
                            gridStateData={gridScheduleStateData}
                            gridStateVersion={gridScheduleStateVersion}
                            woDropDownData={woDropDownData}
                            entityId={entityId}
                            calendarGuid={null}
                            showLegend={true}
                        />
                    )}
                </AppBar>
            </Grid>
        </div>
      )
}

export default DivisionTabPage2
