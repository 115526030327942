import {
    Avatar,
    Badge,
    BadgeProps,
    Divider,
    Grid,
    ListItemText,
    styled,
    Typography,
    ListItem, TextFieldProps,
} from "@mui/material";
import {
    Button,
    createTheme,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    Input,
    ListItemAvatar, MuiThemeProvider
} from "@material-ui/core";
import stylesNotes from "../../../pages/WorkOrderPage/ListingPages/WOListings.module.css";
import {Add, Cancel, Edit, Save, TextSnippet} from "@mui/icons-material";
import React, {FC, FunctionComponent, useMemo, useRef, useState} from "react";
import {NotesData, useDeleteNotes, useFetchNotes, useUpdateNotes} from "../../../queries/useNotes";
import { useFetchNotesEmail } from "../../../queries/useNotesEmail";
import dayjs from "dayjs";
import List from "@mui/material/List";
import {Delete} from "@material-ui/icons";
import MUIRichTextEditor, {TAutocompleteItem, TMUIRichTextEditorRef} from "mui-rte";
import Tooltip from '@mui/material/Tooltip';
import {
    ContentState,
    convertFromHTML, convertFromRaw,
    convertToRaw,
    EditorState,
    RawDraftContentBlock,

    RawDraftContentState
} from "draft-js";
import { sanitize } from 'dompurify'
import {stateToHTML} from "draft-js-export-html";
import Box from "@mui/material/Box";
import {useQueryClient} from "react-query";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import axios from "axios";
import TextField from "@mui/material/TextField";
import TextInput from "../inputs/TextInput";

// const NotesButton = styled(IconButton) `  width: 10px;`

const handleNotes = ({id, notesid, notestitle, notestypeid, noteslinkid, notescount, setIsDialogShowing, setObj}) => {
    setObj({
        id: id,
        notesid: notesid,
        notestitle: notestitle,
        notestypeid: notestypeid,
        noteslinkid: noteslinkid,
        notescount: notescount
    })
    setIsDialogShowing(true)
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -1,
        top: 3,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

export const NotesIcon = ({id, notesid, notestitle, notestypeid, noteslinkid, notescount, queryType}) => {
    const [isDialogShowing, setIsDialogShowing] = useState(false)
    const [obj, setObj] = React.useState({})

    const iconButton =
        <>
            <Tooltip title='Notes'>
                <IconButton
                    style={{ marginRight: '10px'}}
                    className={stylesNotes.button}
                    onClick={() => {
                        handleNotes({
                            id: id,
                            notesid: notesid,
                            notestitle: notestitle,
                            notestypeid: notestypeid,
                            noteslinkid: noteslinkid,
                            notescount: notescount,
                            setIsDialogShowing: setIsDialogShowing,
                            setObj: setObj
                        })
                    }}>
                    {notescount === 0 ? (
                            <TextSnippet/>
                        )
                        : (
                            <StyledBadge badgeContent={notescount} color="success">
                                <TextSnippet color="success"/>
                            </StyledBadge>
                        )}
                </IconButton>
            </Tooltip>
        </>

    return (
        <>
            {iconButton}
            <NotesDialog isOpen={isDialogShowing} setIsOpen={setIsDialogShowing} obj={obj} queryType={queryType} />
        </>
    )
}

const NotesDialog = ({ isOpen, setIsOpen, obj, queryType }) => {
    const queryClient = useQueryClient()
    const textRef = useRef()
    let _editorSubjectState: any = null
    let _editorState: any = null
    const updateNotes = useUpdateNotes()
    const [dialogObjDel, setDialogObjDel] = React.useState({ msg: "", id: 0 })
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [showTextEditor, setShowTextEditor] = useState({notesid: 0})
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [subjectText, setSubjectText] = React.useState('')
    let { data } = useFetchNotes(obj.notestypeid, obj.noteslinkid)

    let listData = []
    if (data && data.length > 0) {
        listData = data?.sort((a, b) => {
            const a1 = dayjs(a.notesaddeddate as string)
            const b1 = dayjs(b.notesaddeddate as string)
            return (a1 < b1) ? 1 : -1
        })
    }

    const [dialogMsg, setDialogMsg] = React.useState("")
    const [notesSubjectData, setNotesSubjectData] = React.useState<string>()
    const [notesRTData, setNotesRTData] = React.useState<string>()
    const deleteNotes = useDeleteNotes()
    const topOfList = React.createRef();

    const handleDelete = (item) => {
        if (showTextEditor.notesid === item.notesid) { // Cancel
            setShowTextEditor({notesid: 0})
        }
        else { // Delete
            setDialogObjDel({
                msg: 'Are you sure you want to delete this note created by ' + item.notesaddeduser + ' on ' + dayjs(item.notesaddeddate as string).format('MM-DD-YYYY hh:mm A') + '?',
                id: item.notesid
            })
        }
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteNotes.mutateAsync({ notesid: dialogObjDel.id })
        closeDialog()
        queryClient.invalidateQueries(queryType)
    }

    const handleAdd = (obj) => {
        setReloadForm(reloadForm+1)
        setIsFormShowing(true)
    }

    const handleCancelAdd = () => {
        setReloadForm(reloadForm+1)
        setIsFormShowing(false)
    }

    const handleSaveAdd = () => {
        const dataAdd: NotesData = {
            notestypeid: obj.notestypeid,
            noteslinkid: obj.noteslinkid
        }
        onSubmit(dataAdd)
        setIsFormShowing(false)
    }

    const closeDialog = () => {
        setDialogObjDel({
            msg: '',
            id: 0
        })
        setIsFormShowing(false)
    }

    const convertToDraftState = (initialData) => () => {
        if (initialData) {
            const clean = sanitize(initialData)
            const blocksFromHTML = convertFromHTML(clean)
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            )
            const editorState: EditorState = EditorState.createWithContent(state)
            const rawDraftContentState: RawDraftContentState = convertToRaw(
                editorState.getCurrentContent()
            )
            return JSON.stringify(rawDraftContentState)
        }
        return null
    }

    const defaultSubjectState = useMemo(
        convertToDraftState(notesSubjectData),
        [notesSubjectData]
    )

    const defaultNoteState = useMemo(
        convertToDraftState(notesRTData),
        [notesRTData]
    )

    const onSubmit = async (item: NotesData) => {
        setNotesSubjectData(_editorSubjectState.getCurrentContent())
        setNotesRTData(stateToHTML(_editorState.getCurrentContent()))

        var finalData = {
            notestypeid: item.notestypeid,
            noteslinkid: item.noteslinkid,
            notesnote: stateToHTML(_editorState.getCurrentContent()),
            notessubject: stateToHTML(_editorSubjectState.getCurrentContent()),
        }
        if (item.notesid > 0) {
            finalData['notesid'] = item.notesid
        }
        const { data: response } = await updateNotes.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        setShowTextEditor({notesid: 0})
        queryClient.invalidateQueries(queryType)
    }

    type TStaff = {
        job: string
        name: string
        color: string
    }

    const Staff: FunctionComponent<TStaff> = (props) => {
        return (
            <>
                <ListItemAvatar>
                    <Avatar style={{
                        backgroundColor: props.color
                    }}>{props.name.substr(0, 1)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={props.name}
                    secondary={props.job}
                />
            </>
        )
    }

    const {data: notesEmailData} = useFetchNotesEmail()
    let staff = []
    if (notesEmailData && notesEmailData.length > 0) {
        staff = notesEmailData.map(contact => {
            return {
                keys: [contact?.email, contact?.firstname, contact?.firstname?.toLowerCase(), contact?.firstname?.toUpperCase(), contact?.lastname, contact?.lastname?.toLowerCase(), contact?.lastname?.toUpperCase()],
                value: "@(" + contact.email + ")",
                content: <Staff name={contact.firstname + " " + contact.lastname + " - " + contact.email} job="" color="tomato" />,
            }
        })
    }

    // const staff = [
    //     {
    //         keys: ["all", "scott", "leathers", "developer"],
    //         value: "Scott Leathers",
    //         content: <Staff name="Scott Leathers" job="Developer" color="tomato" />,
    //     },
    //     {
    //         keys: ["all", "dan", "jagielski", "developer"],
    //         value: "Dan Jagielski",
    //         content: <Staff name="Dan Jagielski" job="Developer" color="orange" />,
    //     },
    //     {
    //         keys: ["all", "dennis", "mueller", "manager"],
    //         value: "Dennis Mueller",
    //         content: <Staff name="Dennis Mueller" job="Manager" color="dodgerblue" />,
    //     }
    // ]

    const subjectTheme = createTheme();

    Object.assign(subjectTheme, {
        overrides: {
            MUIRichTextEditor: {
                root: {
                    marginTop: 0,
                    width: '100%',
                    backgroundColor: '#ebebeb'
                },
                editor: {
                    height: '35px',
                    minHeight: '35px',
                    maxHeight: '70px',
                    overflow: 'auto',
                    paddingLeft: 10
                },
                placeHolder: {
                    height: '200px',
                    minHeight: '200px',
                    maxHeight: '200px',
                    overflow: 'auto',
                    paddingLeft: 10,
                    borderTop: 10
                }
            }
        }
    });


    const defaultTheme = createTheme();

    Object.assign(defaultTheme, {
        overrides: {
            MUIRichTextEditor: {
                root: {
                    marginTop: 2,
                    width: "100%",
                    marginLeft: 0,
                    marginBottom: 20,
                    backgroundColor: "white",
                    paddingBottom: 24,
                },
                editor: {
                    // border: "1px solid red",
                    marginLeft: 15,
                },
                container: {
                    // border: "1px solid green"
                },
                editorcontainer: {
                    // border: "1px solid blue"
                }
            }
        }
    });
    //
    // const onSubjectChange = (e, notesId) => {
    //     console.log(notesId)
    //     const newVal = e.target.value
    //     console.log(newVal)
    //     setSubjectText(newVal)
    //
    //     e.stopPropagation()
    //     e.preventDefault()
    // }


    const ariaLabel = { 'aria-label': 'description' };

    const NotesList = (props) => {
            const listItems = listData?.map((item) =>
            <>
                <ListItem key={`listdata-${item.notesid}`} alignItems="flex-start"
                    secondaryAction={
                        <>
                            <IconButton edge="end" aria-label="delete" onClick={() => {handleDelete(item)}}>
                                {(showTextEditor.notesid !== item.notesid) &&
                                    <Delete/>
                                }
                            </IconButton>
                        </>
                    }
                >

                    {(showTextEditor.notesid === item.notesid) &&
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{ width: '100%' }}>
                                        <MuiThemeProvider theme={subjectTheme}>
                                            <MUIRichTextEditor
                                                controls={[]}
                                                label="Subject: "
                                                onChange={(value: EditorState) => {
                                                    _editorSubjectState = value
                                                }}
                                                defaultValue={defaultSubjectState}
                                            />
                                        </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={12} style={{ width: '100%' }}>
                                <MuiThemeProvider theme={defaultTheme}>
                                    <MUIRichTextEditor
                                        autocomplete={{
                                            strategies: [
                                                {
                                                    items: staff,
                                                    triggerChar: "@"
                                                }
                                            ]
                                        }}
                                        onSave={() => {
                                            onSubmit(item)
                                        }}
                                        inlineToolbar={true}
                                        customControls={[
                                            {
                                                name: "saveCallback",
                                                icon: <Save />,
                                                type: "callback",
                                                onClick: (editorState, name, anchor) => {
                                                    onSubmit(item)
                                                }
                                            },
                                            {
                                                name: "cancelCallback",
                                                icon: <Cancel />,
                                                type: "callback",
                                                onClick: (editorState, name, anchor) => {
                                                    handleDelete(item)
                                                }
                                            }
                                        ]}
                                        controls={[
                                            'saveCallback',
                                            'cancelCallback',
                                            'title',
                                            'bold',
                                            'italic',
                                            'underline',
                                            'strikethrough',
                                            'highlight',
                                            'numberList',
                                            'bulletList',
                                            'quote'
                                        ]}
                                        onChange={(value: EditorState) => {
                                            _editorState = value
                                        }}
                                        defaultValue={defaultNoteState}
                                    />
                                </MuiThemeProvider>
                                </Grid>
                            </Grid>
                        </>
                    }
                    {(showTextEditor.notesid !== item.notesid) &&
                        <>
                        <ListItemAvatar>
                            <Avatar alt="item.notesaddeduser">{item.notesaddeduser.substr(0, 1)}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            onDoubleClick={() => {
                                setNotesRTData(item?.notesnote)
                                setNotesSubjectData(item?.notessubject)
                                setShowTextEditor({ notesid: item.notesid } )
                            }}
                            primary={item.notesaddeduser + ' ' + dayjs(item.notesaddeddate as string).format('MM-DD-YYYY hh:mm A')}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h1"
                                        color="text.primary"
                                        fontSize={18}
                                    >
                                        <div dangerouslySetInnerHTML={{__html: item.notessubject}} />
                                    </Typography>

                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h1"
                                        color="text.primary"
                                        fontSize={15}
                                    >
                                        <div dangerouslySetInnerHTML={{__html: item.notesnote}} />
                                    </Typography>
                                </React.Fragment>
                            }
                            secondaryTypographyProps={{
                                fontSize: 15,
                                paddingRight: 5,
                            }}
                        />
                        </>
                    }
                </ListItem>

                <Divider variant="inset" component="li" />
            </>
        );

        return (
            <>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }} >
                    {isFormShowing &&
                        <>
                            <Box sx={{height: '30vh', marginTop: '2vh'}}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="">{'Add'}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={'Add a new note.'}
                                        secondary={
                                            <React.Fragment>
                                                <Grid item xs={12} style={{ width: '100%' }}>
                                                    <MuiThemeProvider theme={subjectTheme}>
                                                        <MUIRichTextEditor
                                                            controls={[]}
                                                            label="Subject: "
                                                            onChange={(value: EditorState) => {
                                                                _editorSubjectState = value
                                                            }}
                                                            // defaultValue={defaultSubjectState}
                                                        />
                                                    </MuiThemeProvider>
                                                </Grid>

                                                <MUIRichTextEditor
                                                    autocomplete={{
                                                        strategies: [
                                                            {
                                                                items: staff,
                                                                triggerChar: "@"
                                                            }
                                                        ]
                                                    }}
                                                    label={"Please add note here."}
                                                    inlineToolbar={true}
                                                    customControls={[
                                                        {
                                                            name: "saveCallback",
                                                            icon: <Save />,
                                                            type: "callback",
                                                            onClick: (editorState, name, anchor) => {
                                                                handleSaveAdd()
                                                                // return EditorState.createEmpty()
                                                            }
                                                        },
                                                        {
                                                            name: "cancelCallback",
                                                            icon: <Cancel />,
                                                            type: "callback",
                                                            onClick: (editorState, name, anchor) => {
                                                                handleCancelAdd()
                                                            }
                                                        }
                                                    ]}
                                                    controls={[
                                                        'saveCallback',
                                                        'cancelCallback',
                                                        'title',
                                                        'bold',
                                                        'italic',
                                                        'underline',
                                                        'strikethrough',
                                                        'highlight',
                                                        'numberList',
                                                        'bulletList',
                                                        'quote'
                                                    ]}
                                                    onChange={(value: EditorState) => {
                                                        _editorState = value
                                                    }}
                                                />
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </Box>
                            <Divider variant="inset" component="li" />
                        </>
                    }
                    {listItems}
                </List>
            </>
        );
    }

    return (
        <div className={stylesNotes.root}>
        <>
            <Dialog
                open={dialogObjDel.msg.length > 0}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogObjDel.msg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm}>DELETE</Button>
                </DialogActions>
            </Dialog>
        </>

        <Dialog
            fullWidth={true}
            open={isOpen}
            maxWidth={'xl'}
            className={stylesNotes.dialog}>
            <DialogTitle>{obj.notestitle}</DialogTitle>

            <DialogContent>
                {/*{showTextEditor.notesid > 0 &&*/}
                {/*    <Grid container spacing={2}>*/}
                {/*        <Grid item xs={12} style={{ width: '100%' }}>*/}
                {/*            <TextField onChange={onSubjectChange} value={subjectText} id="standard-basic" label="Subject" variant="standard" style={{marginLeft: '25px',  width: '90%'}} />*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*}*/}

                {data?.length === undefined && <div>No notes to display</div>}
                <NotesList></NotesList>
                {/*{isFormShowing &&*/}
                {/*    <NotesForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} reloadForm={reloadForm} noteObj={obj}></NotesForm>*/}
                {/*}*/}
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginLeft: '15px', marginTop: '15px'}}>
                        <IconButton
                            className={stylesNotes.submitButton}
                            size="small"
                            onClick={() => { handleAdd(obj) }}>
                            <Add />
                        </IconButton>
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    className={stylesNotes.submitButton}
                    type="submit"
                    onClick={() => {
                        setIsOpen(false)
                        setIsFormShowing(false)
                    }}
                    form="items-form">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    )
}

