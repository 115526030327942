import React, {useEffect, useMemo, useState} from "react";
import styles from './Dependencies.module.css'
import {
    useDeleteDependencies,
    useFetchDependencies,
    DependenciesData,
    DependenciesDataObject,
    DependenciesDataDefaults
} from "../../../queries/useDependencies";
import dayjs from "dayjs";
import {GridSelectionModel, GridSortItem, GridValueFormatterParams} from "@material-ui/x-grid";
import {useQueryClient} from 'react-query'
import {
    DataGridPro,
    GridCallbackDetails,
    GridCellParams,
    GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
    GridRowParams, gridSortedRowIdsSelector,
    GridSortModel, GridToolbarContainer,
    MuiEvent, useGridApiContext,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import {useUpdateGridState} from "../../../queries/useGridState";
import {Alert, Badge, BadgeProps, FormGroup, Grid, MenuItem, Select, SelectChangeEvent, styled} from "@mui/material";
import {NotesIcon} from "../../../components/common/NotesLayout/NotesLayout";
import {Box, Dialog, DialogActions, DialogContent, DialogContentText, IconButton} from "@material-ui/core";
import {Delete, Edit, SettingsBackupRestore} from "@material-ui/icons";
import {Add, Save} from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import {useFetchDependencyDropdowns, DependencyDropdowns} from "../../../queries/useDependencyDropdowns";
import {DependenciesForm} from "./Dependencies.Form";
import {ButtonProps} from "@mui/material/Button";
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const DependenciesPage = (params) => {
    let _sortModelState: any = null
    const { data: dependencyDropdowns } = useFetchDependencyDropdowns(params?.workOrderObj?.woid)
    const [gridShowing, setGridShowing] = useState(false)
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState({})
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObjDel, setDialogObjDel] = React.useState({msg: "", id: 0})
    const [dialogObj, setDialogObj] = React.useState({msg: "", gridState: "", okButtonText: "Save"})
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const [dataRows, setDataRows] = React.useState([]);
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([1]);
    const gridAPIRef = useGridApiRef()
    const updateGridState = useUpdateGridState()
    const deleteDependencies = useDeleteDependencies()
    const schedulesWorkorderSelectionKey = "schedulesWorkorderSelection"
    const schedulesWorkorderSelection = localStorage.getItem(schedulesWorkorderSelectionKey)
    const templateSelection = localStorage.getItem("schedulesTemplateSelection")
    const [workOrderActiveSelect, setWorkORderActiveSelect] = React.useState<string>( schedulesWorkorderSelection !== null ? schedulesWorkorderSelection : 'all');
    const [templateSelect, setTemplateSelect] = React.useState<string>( templateSelection !== null ? templateSelection : '');


    const gridSortItem: GridSortItem = {
        field: 'wocustomercode',
        sort: 'asc'
    }
    const defaultGridSortModel: GridSortModel = [gridSortItem]
    const [gridSortModel, setGridSortModel] = React.useState<GridSortModel>(defaultGridSortModel)

    // Query for the divisions relalted to the work order
    const queryClient = useQueryClient()
    React.useEffect(() => {
        queryClient.invalidateQueries(['dependenciesdata'])
    }, [params.workOrderObj])

    const {data} = useFetchDependencies(params?.workOrderObj?.woid)
    useEffect(() => {
        setGridSortModel(getColumnSortModel())
        if (data?.dependencylist && data?.dependencylist?.length > 0) {
            const dataRows = getMappedRecords()
            setDataRows(dataRows)
        }
    }, [data])

    const getMappedRecords = () => {
        return data?.dependencylist.map((dependenciesData: DependenciesData, i) => {
            return {
                id: dependenciesData.dependencyid,
                readonly: dependenciesData.readonly,
                canedit: dependenciesData.canedit,
                candelete: dependenciesData.candelete,
                dependencyid: dependenciesData.dependencyid,
                woid: dependenciesData.woid,
                dependencydivisionid: dependenciesData.dependencydivisionid,
                dependencydivision: dependenciesData.dependencydivision,
                dependencyscheduleid: dependenciesData.dependencyscheduleid,
                dependencytypeid: dependenciesData.dependencytypeid,
                dependencytype: dependenciesData.dependencytype,
                dependencycategoryid: dependenciesData.dependencycategoryid,
                dependencycategory: dependenciesData.dependencycategory,
                dependencyaction: dependenciesData.dependencyaction,
                dependencydateactiontaken: dependenciesData.dependencydateactiontaken,
                dependencydatecleartowork: dependenciesData.dependencydatecleartowork,
                dependencydateexpiration: dependenciesData.dependencydateexpiration,
                dependencyreferenceinformation: dependenciesData.dependencyreferenceinformation,
                dependencypermanentlycleared: dependenciesData.dependencypermanentlycleared,
                dependencyaddeduser: dependenciesData.dependencyaddeduser,
                dependencyaddeduserkey: dependenciesData.dependencyaddeduserkey,
                dependencyaddeddate: dependenciesData.dependencyaddeddate,
                dependencyupdateduser: dependenciesData.dependencyupdateduser,
                dependencyupdateduserkey: dependenciesData.dependencyupdateduserkey,
                dependencyupdateddate: dependenciesData.dependencyupdateddate,
                dependencyactive: dependenciesData.dependencyactive,
                notestypeid: dependenciesData.notestypeid,
                notestitle: dependenciesData.notestitle,
                noteslinkid: dependenciesData.noteslinkid,
                notescount: dependenciesData.notescount
            }
        })
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    }

    const basicProps = {
        disableColumnMenu: true,
        sortable: false,
        disableExport: true,
        disableReorder: true,
        editable: false,
        filterable: false,
        groupable: false,
        hideable: false,
        pinnable: false
    }

    const HandleEdit = async (params: GridCellParams) => {
        const dependenciesData = data.dependencylist.filter((item) => item.dependencyid === Number(params.id))[0]

        setGridParams({
            readonly: dependenciesData.readonly,
            candelete: dependenciesData.candelete,
            dependencyid: dependenciesData.dependencyid,
            woid: dependenciesData.woid,
            dependencydivisionid: dependenciesData.dependencydivisionid,
            dependencyscheduleid: dependenciesData.dependencyscheduleid,
            dependencytypeid: dependenciesData.dependencytypeid,
            dependencytype: dependenciesData.dependencytype,
            dependencycategoryid: dependenciesData.dependencycategoryid,
            dependencycategory: dependenciesData.dependencycategory,
            dependencyaction: dependenciesData.dependencyaction,
            dependencydateactiontaken: dependenciesData.dependencydateactiontaken,
            dependencydatecleartowork: dependenciesData.dependencydatecleartowork,
            dependencydateexpiration: dependenciesData.dependencydateexpiration,
            dependencyreferenceinformation: dependenciesData.dependencyreferenceinformation,
            dependencypermanentlycleared: dependenciesData.dependencypermanentlycleared,
            dependencyaddeduser: dependenciesData.dependencyaddeduser,
            dependencyaddeduserkey: dependenciesData.dependencyaddeduserkey,
            dependencyaddeddate: dependenciesData.dependencyaddeddate,
            dependencyupdateduser: dependenciesData.dependencyupdateduser,
            dependencyupdateduserkey: dependenciesData.dependencyupdateduserkey,
            dependencyupdateddate: dependenciesData.dependencyupdateddate,
            dependencyactive: dependenciesData.dependencyactive,
            notestypeid: dependenciesData.notestypeid,
            notestitle: dependenciesData.notestitle,
            noteslinkid: dependenciesData.noteslinkid,
            notescount: dependenciesData.notescount
        })
        setReloadForm(reloadForm + 1)
        setIsFormShowing(true)
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObjDel({
            msg: 'Are you sure you want to delete this dependancy',
            id: Number(params.id)
        })
    }

    const canEdit = (params: GridCellParams) => {
        return params.row.canedit
    }

    const canDelete = (params: GridCellParams) => {
        return params.row.candelete
    }

    const handleDeleteConfirm = async () => {
        console.log(dialogObjDel.id)
        const { data: response } = await deleteDependencies.mutateAsync({ dependencyid: dialogObjDel.id })
        closeDialog()
    }

    const buildColumnDefs = () => {
        const gridObj = parseGridObject()
        const parsedGridColumns = gridObj.gridDefs
        var gridColumns: GridColDef[] = [
            gridEditSaveButtonDef,
            gridIdDef,
            ...parsedGridColumns,
            gridButtonsDef
        ]
        return gridColumns
    }

    const getColumnSortModel = () => {
        const gridObj = parseGridObject()
        return gridObj.sortModel
    }

    const formatActive = (params: GridValueFormatterParams) => {
        return (params.value ? "Active" : "Inactive")
    }

    const formatDate = (params: GridValueFormatterParams) => {
        return (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DD").format('MM/DD/YYYY') : '')
    }

    const parseGridObject = () => {
        var sortModel: GridSortModel = [gridSortItem]
        var gridStateString = JSON.stringify({gridType: "dependencies", gridState: JSON.stringify(gridDefaults)})
        if (params.gridStateData && params.gridStateData.length > 0) {
            gridStateString = params.gridStateData[0].gridState
            const gridStateData = JSON.parse(gridStateString)
            const sendObj = {
                gridType: 'dependencies',
                gridState: JSON.stringify(gridStateData.columnDefs)
            }
            gridStateString = JSON.stringify(sendObj)

            if (gridStateData && gridStateData.sortModel !== null && gridStateData.sortModel.length > 0) {
                // gridAPIRef?.current?.setSortModel(gridStateData.sortModel)
                const dataSort = gridStateData.sortModel
                sortModel = dataSort
            }
        }

        var gridDefs: GridColDef[] = [];
        if (gridStateString.length > 0) {
            const gridObject = JSON.parse(gridStateString)
            if (gridObject.gridType === 'dependencies') {
                const gridState = JSON.parse(gridObject?.gridState)
                gridState?.forEach(state => {
                    var col: GridColDef = {...state}

                    if (col.field === 'woactive') {
                        col.valueFormatter = formatActive
                    }
                    if (col.field === 'dependencydateactiontaken' || col.field === 'dependencydatecleartowork' ||
                        col.field === 'dependencydateexpiration') {
                        col.valueFormatter = formatDate
                    }

                    gridDefs.push(col)
                })
            }
        }
        return {gridDefs: gridDefs, sortModel: sortModel}
    }

    const gridDefaults: GridColDef[] = [
        {
            "field": "dependencydivision",
            "headerName": "Division",
            "headerAlign": "left",
            "type": "string",
            "width": 100,
            "align": "left"
        },
        {
            "field": "dependencyscheduleid",
            "headerName": "Schedule",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "field": "dependencytype",
            "headerName": "Type",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "field": "dependencyaction",
            "headerName": "Action",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "field": "dependencycategory",
            "headerName": "Category",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "field": "dependencydateactiontaken",
            "headerName": "Action Taken",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "field": "dependencydatecleartowork",
            "headerName": "Clear To Work",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "field": "dependencydateexpiration",
            "headerName": "Expiration",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        }
    ]

    const StyledBadge = styled(Badge)<BadgeProps>(({theme}) => ({
        '& .MuiBadge-badge': {
            right: -1,
            top: 3,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }));

    const gridButtonsDef: GridColDef = {
        field: 'Delete',
        headerName: '',
        width: 80,
        align: "center",
        headerAlign: "center",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <>
                    <NotesIcon
                        id={Number(params.id)}
                        notesid={params.row.notesid}
                        notestitle={params.row.notestitle}
                        notestypeid={params.row.notestypeid}
                        noteslinkid={params.row.noteslinkid}
                        notescount={params.row.notescount}
                        queryType={['dependencydata']}
                    />
                    {(canDelete(params)) &&
                    <IconButton className={styles.button} onClick={() => {
                        handleDelete(params)
                    }}>
                        <Delete/>
                    </IconButton>
                    }

                </>
            )
        }
    }

    const gridEditSaveButtonDef: GridColDef = {
        field: '',
        renderHeader: () => {
            return (
                <>
                    <IconButton
                        className={styles.submitButton}
                        onClick={() => handleAdd()}
                        size="small">
                        <Add />
                    </IconButton>
                </>
            )
        },
        width: 50,
        align: "center",
        headerAlign: "center",
        pinnable: true,
        cellClassName: 'pinnedColumn',
        ...basicProps,
        renderCell: (params) => (
            <>
                {(canEdit(params)) &&
                <IconButton className={styles.button} onClick={() => {
                    HandleEdit(params)
                }}>
                    <Edit/>
                </IconButton>
                }
            </>
        )
    }

    const gridIdDef: GridColDef = {
        field: 'id',
        hide: true,
        ...basicProps
    }

    const getColumnStringToSave = () => {
        var fieldObjects = []
        const gridState = gridAPIRef.current.getAllColumns()
        gridState.forEach(stateColumnDef => {
            if (stateColumnDef.sortable && stateColumnDef.pinnable && stateColumnDef.groupable) {
                fieldObjects.push({
                    field: stateColumnDef.field,
                    headerName: stateColumnDef.headerName,
                    headerAlign: stateColumnDef.headerAlign,
                    type: stateColumnDef.type,
                    width: stateColumnDef.width,
                    align: stateColumnDef.align,
                    description: stateColumnDef.description,
                    hide: stateColumnDef.hide,
                    valueFormatter: stateColumnDef.valueFormatter
                })
            }
        })

        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: fieldObjects, sortModel: sortModelDefs})
        return strObject
    }

    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const handleRestore = () => {
        setGridShowing(false)
        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: gridDefaults, sortModel: sortModelDefs})
        setDialogObj({
            msg: 'Are you sure you want to reset the grid state to default?',
            gridState: strObject,
            okButtonText: 'RESET'
        })
    }

    const handleSave = () => {
        setGridShowing(false)
        const columnObjsting = getColumnStringToSave()
        setDialogObj({
            msg: 'Are you sure you want to save the current grid state?',
            gridState: columnObjsting,
            okButtonText: 'Save'
        })
    }

    const handleOkConfirm = async () => {
        var finalData = {
            gridType: params.gridStateVersion,
            gridState: dialogObj.gridState
        }
        const {data: response} = await updateGridState.mutateAsync(finalData)
        closeDialog()
        setGridShowing(true)
        setSnackbarOpen(true);
    }

    const closeDialog = () => {
        setGridShowing(true)
        setDialogObj({
            msg: '',
            gridState: '',
            okButtonText: ''
        })
        setDialogObjDel({
            msg: '',
            id: 0
        })
    }

    const handleWorkOrderSelectionChange = (event: SelectChangeEvent) => {
        setWorkORderActiveSelect(event.target.value as string);
        localStorage.setItem(schedulesWorkorderSelectionKey, event.target.value)
        // filterRowsToSelection(event.target.value)
    }


    const handleRowClick = (param: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        // const woObjectList = data.workorderlist.filter((workOrder) => { return workOrder.woid === Number(param.id)})
        // if (woObjectList && woObjectList.length > 0) {
        //     params.setWorkOrderObj(woObjectList[0])
        // }
    }

    const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
        gridSortedRowIdsSelector(apiRef);

    function CustomGridToolbar() {
        const apiRef = useGridApiContext();

        const handleExport = (options: GridCsvExportOptions) =>
            apiRef.current.exportDataAsCsv(options);

        const buttonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <FileDownloadOutlinedIcon />,
        };

        const saveButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <Save />,
        };

        const restoreButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <SettingsBackupRestore />,
        };

        return (
            <GridToolbarContainer >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        {...saveButtonBaseProps}
                        onClick={() => handleSave()}
                    >
                        SAVE GRID SETTINGS
                    </Button>
                    <Button
                        {...restoreButtonBaseProps}
                        onClick={() => handleRestore()}
                    >
                        RESTORE GRID SETTINGS
                    </Button>
                    <Button
                        {...buttonBaseProps}
                        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
                    >
                        EXPORT
                    </Button>
                    {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                </Grid>
            </GridToolbarContainer>
        )
    }

    return (
        <div className={styles.root}>
            <>
                <DependenciesForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} dependencyDropdowns={dependencyDropdowns} workOrderObj={params?.workOrderObj} reloadForm={reloadForm} />
            </>
            <>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={4000}
                    onClose={handleClose}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                >
                    <Alert severity="success">
                        Work order settings have been saved!
                    </Alert>
                </Snackbar>
            </>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleOkConfirm}>{dialogObj.okButtonText}</Button>
                    </DialogActions>
                </Dialog>
            </>

            <>
                <Dialog
                    open={dialogObjDel.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObjDel.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {/*{gridShowing ?*/}
                    <DataGridPro
                        sx={{
                            "& .MuiDataGrid-pinnedColumns": {
                                boxShadow: "none",
                                backgroundColor: "transparent"
                            },
                            "& .MuiDataGrid-pinnedColumnHeaders": {
                                boxShadow: "none",
                                backgroundColor: "transparent"
                            }
                        }}
                        apiRef={gridAPIRef}
                        rows={dataRows}
                        columns={buildColumnDefs()}
                        autoHeight={true}
                        density={'compact'}
                        pageSize={10}
                        // headerHeight={gridShowing ? 75 : 0}
                        // loading={!data}
                        sortModel={gridSortModel}
                        disableMultipleSelection={true}
                        onSortModelChange={(model: GridSortModel) => setGridSortModel(model)}
                        onStateChange={(state) => {
                            // setGridSortModel(state.sorting.sortModel)
                            _sortModelState = state.sorting.sortModel

                            return state
                        }}
                        initialState={{
                            pinnedColumns: {left: ['Edit'], right: ['Notes', 'Delete']},
                            sorting: {
                                sortModel: gridSortModel
                            }
                        }}
                        onRowClick={handleRowClick}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                        components={{
                            Toolbar: CustomGridToolbar,
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
}
export default DependenciesPage
