import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { parseJwt } from '../pages/AuthPage/LoginForm/useLoginMutation'
import { useValidToken } from '../queries/useAuthenication'
import { AuthToken, loginUser } from '../slices/authSlice'

export const useAutoAuth = () => {
  const dispatch = useDispatch()
  const token = localStorage.getItem('token')
  const parsedToken: AuthToken = token ? parseJwt(token) : null
  const history = useHistory()
  
  return useValidToken({
    onSuccess: (data) => {
      if (data.status === 'failure') {
        if (!history.location.pathname.includes('/dispatch/')) {
          history.push('/auth/login')
        }
      }
      else {
        if (parsedToken && parsedToken.userid) {
          dispatch(loginUser(parsedToken))
        }
        else {
          history.push('/auth/login')
        }
      }
    },
    onError: () => {
      if (!history.location.pathname.includes('/dispatch/')) {
        history.push('/auth/login')
      }
    }
  })
}
