
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch
} from 'react-router-dom'

import { Logo } from '../../icons/Logo'
import styles from './AuthPage.module.css'
import ForgotPasswordForm from './ForgotPasswordForm'
import LoginForm from './LoginForm'
import ResetPasswordForm from './ResetPasswordForm'

export enum AuthPageSubPaths {
  login = '/login',
  forgotPassword = '/forgotPassword',
  resetPassword = '/resetPassword/:token'
}

const AuthPage = () => {
  const { path } = useRouteMatch()

  return (
    <div className={styles.root}>
      <div className={styles.backgroundContainer}>
        <div className={styles.header}>
          <h1>Work Order</h1>
        </div>
        <Switch>
          <Route exact path={`${path}`}>
            <Redirect to={`${path}${AuthPageSubPaths.login}`} />
          </Route>
          <Route path={`${path}${AuthPageSubPaths.login}`}>
            <LoginForm />
          </Route>
          <Route path={`${path}${AuthPageSubPaths.forgotPassword}`}>
            <ForgotPasswordForm />
          </Route>
          <Route path={`${path}${AuthPageSubPaths.resetPassword}`}>
            <ResetPasswordForm />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default AuthPage
