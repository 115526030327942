import React, {useEffect, useMemo, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import styles from './QuickAdd.module.css'
import TextInput from "../../../components/common/inputs/TextInput";
import DateInput from "../../../components/common/inputs/DateInput";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import LoadingBackdrop from "../../../components/common/LoadingBackdrop";
import AutocompleteSelect from '../../../components/common/inputs/AutoCompleteSelectInput/AutocompleteSelect'
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {statesArray} from "../../../util/formValidation";

interface FormData {
    woentityid: number
    wocustomercodeid: number
    wocustomerworkorder: string
    wocustomerrelease: string
    wopolid: number
    wostate: string
    wolatlong: string
    woaddress: string
    wocity: string
    divisioncustomercodeid: number
    divisionjpid: number
    divisioncontractid: number
    schedulestartdate: string
    schedulestarttime: string
    userid: number
}

export const QuickAddForm = ({ isOpen, setIsOpen, gridParams, reloadForm, woDropDownData, setNewWOID }) => {
    const formMethods = useForm<FormData>()
    const title = "UTC Quick Add Work Order"
    const { watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const [woState, setWoState] = useState<{ value: any; label: string }>()
    const [entityActiveSelect, setEntityActiveSelect] = React.useState<string>("")
    const [entityActiveSelectId, setEntityActiveSelectId] = React.useState<number>(0)
    const [customerOptions, setCustomerOptions] = React.useState([])
    const [customerDivisionOptions, setCustomerDivisionOptions] = React.useState([])
    const [jobOptions, setJobOptions] = React.useState([])
    const [contractOptions, setContractOptions] = React.useState([])
    const [customerPOOptions, setCustomerPOOptions] = React.useState([])
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const watchCustcodeID = watch("wocustomercodeid"); // you can also target specific fields by their names
    const [dropdownData, setDropdownData] = React.useState({
        contractlist: [],
        custlist: [],
        entitylist: [],
        jobphaselist: [],
        polist: [],
    })

    const [timeValue, setTimeValue] = useState(null)
    const statesArray1 = statesArray

    useEffect(() => {
        axios.get(`utcworkorderdropdowns`, {}).then((response) => {
            setDropdownData(JSON.parse(response.data.jsonString))
        })

        reset({
            woentityid: null,
            wocustomercodeid: null,
            wocustomerworkorder: null,
            wocustomerrelease: null,
            wopolid: null,
            woaddress: null,
            wocity: null,
            wostate: 'CA',
            divisioncustomercodeid: null,
            divisionjpid: null,
            divisioncontractid: null,
            schedulestartdate:  new Date().toISOString(),
            schedulestarttime: null
        })

        let selectedwoState = {value: "", label:""}
        const selectedState = statesArray1?.filter((st) => st.value === 'CA')
        if (selectedState.length > 0){
            selectedwoState = selectedState[0];
        }
        setWoState(selectedwoState)
    }, [isOpen === true])


    useEffect(() => {
        if (dropdownData && dropdownData?.entitylist && dropdownData?.entitylist.length > 0) {
            const entity = dropdownData?.entitylist.find(item => item.entityname === "OAG")
            if (entity) {
                setEntityActiveSelect(entity.entityname)
                setEntityActiveSelectId(entity.entityid)
                getCustomerOptions(entity.entityid)
            }
        }

        setCustomerDivisionOptions([])
        if (dropdownData && dropdownData.custlist && dropdownData.custlist.length > 0) {
            // Hard code to UTC for now
            const customerDivisionOptions = dropdownData?.custlist?.filter((item) => (item.entityid === 4))
            if (customerDivisionOptions && customerDivisionOptions.length > 0) {
                setCustomerDivisionOptions(customerDivisionOptions?.map(item => {
                    return {label: item.custinfo, value: item.custid}
                }))
            }
        }

        setJobOptions([])
        if (dropdownData && dropdownData.jobphaselist && dropdownData.jobphaselist.length > 0) {
            setJobOptions(dropdownData.jobphaselist?.map(item => {
                return {label: item.jpname, value: item.jpid}
            }))
        }

        setContractOptions([])
        if (dropdownData && dropdownData.contractlist && dropdownData.contractlist.length > 0) {
            setContractOptions(dropdownData.contractlist?.map(item => {
                return {label: item.contractname, value: item.contractid}
            }))
        }
    }, [dropdownData?.entitylist])

    const onSubmit = async (data: FormData) => {
        setIsLoading(true)

        var finalData = {
            woentityid: entityActiveSelectId,
            wocustomercodeid: data.wocustomercodeid,
            wocustomerworkorder: data.wocustomerworkorder,
            wocustomerrelease: data.wocustomerrelease,
            wopolid: data.wopolid,
            woaddress: data.woaddress,
            wocity: data.wocity,
            wostate: data.wostate,
            wolatlong: data.wolatlong,
            divisioncustomercodeid: data.divisioncustomercodeid,
            divisionjpid: data.divisionjpid,
            divisioncontractid: data.divisioncontractid,
            schedulestartdate: data.schedulestartdate,
            schedulestarttime: timeValue
        }
        // console.log(finalData)


        const { data: returnResponse } = await axios.put('/utcworkorder', finalData)
        const parsedData = JSON.parse(returnResponse.jsonString)
        // console.log(parsedData)
        if (parsedData?.status === 'failure') {
            setDialogMsg(parsedData.message)
        }
        else {
            setNewWOID(parsedData.woid)
            setIsOpen(false)
        }
        setIsLoading(false)
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const handleEntityChange = (event: SelectChangeEvent) => {
        setEntityActiveSelect(event.target.value as string);
        const filteredList = dropdownData?.entitylist?.filter(entity => entity.entityname === (event.target.value as string))
        if (filteredList && filteredList.length > 0) {
            const entityID = filteredList[0].entityid
            setEntityActiveSelectId(entityID)
            getCustomerOptions(entityID)
        }
    }

    const getCustomerOptions = (entityId) => {
        setCustomerOptions([])
        if (dropdownData && dropdownData.custlist && dropdownData.custlist.length > 0) {
            const customerOptions = dropdownData?.custlist?.filter((item) => (item.entityid === entityId))
            // console.log(customerOptions)
            if (customerOptions && customerOptions.length > 0) {
                setCustomerOptions(customerOptions?.map(item => {
                    return {label: item.custinfo, value: item.custid}
                }))
            }
        }
    }

    useEffect(() => {
        setCustomerPOOptions([])
        if (dropdownData && dropdownData.polist && dropdownData.polist.length > 0) {
            const poOptions = dropdownData?.polist?.filter((item) => ((!item.hasOwnProperty('entityid') || !item.hasOwnProperty('custid')) || item.entityid === entityActiveSelectId && item.custid === watchCustcodeID))

            if (poOptions && poOptions.length > 0) {
                const poOptionsMap = poOptions?.map(item => {
                    return { label: item.polnumber, value: item.polid }
                })
                setCustomerPOOptions(poOptionsMap)
            }
        }
    }, [getValues("wocustomercodeid"), entityActiveSelect])

    const getEntityListOptions = () => {
        var entityData = []
        entityData = dropdownData?.entitylist?.map(item => (
            <MenuItem key={item.entityid} value={item.entityname}>
                {item.entityname}
            </MenuItem>
        ))
        return entityData
    }

    return (
        <Dialog open={isOpen} maxWidth="xl" className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <LoadingBackdrop open={isLoading} />
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>

                    <div className={styles.multipleInputContainer}>
                        <FormControl fullWidth>
                            <InputLabel>Entity*</InputLabel>
                            <Select
                                name="woentityid"
                                label="Entity"
                                value={entityActiveSelect}
                                size={'small'}
                                required={true}
                                variant="outlined"
                                onChange={handleEntityChange}
                            >
                                {getEntityListOptions()}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <AutocompleteSelect name='wocustomercodeid' label='Customer' options={customerOptions} required disabled={entityActiveSelect?.length <= 0}/>
                        </FormControl>
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <FormControl fullWidth>
                            <AutocompleteSelect name='wopolid' label='Customer PO' options={customerPOOptions} required disabled={entityActiveSelect?.length <= 0}/>
                        </FormControl>
                        <FormControl fullWidth>
                            <AutocompleteSelect name='divisioncontractid' label='Division Contract' options={contractOptions} required/>
                        </FormControl>
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <FormControl fullWidth>
                            <AutocompleteSelect name='divisioncustomercodeid' label='Division Customer' options={customerDivisionOptions} required/>
                        </FormControl>
                        <FormControl fullWidth>
                            <AutocompleteSelect name='divisionjpid' label='Division Job' options={jobOptions} required/>
                        </FormControl>
                    </div>

                    <div className={styles.multipleInputContainer}>
                        <div className={styles.multipleInputContainer}>
                            <TextInput
                                name="wocustomerworkorder"
                                label="Customer Work Order"
                                rules={{
                                    required: 'Customer Work Order is required',
                                    minLength: {
                                        value: 1,
                                        message: 'Must be at least 1 characters'
                                    },
                                    maxLength: {
                                        value: 200,
                                        message: 'Must be 200 or less characters'
                                    }
                                }}
                                required
                            />
                            <TextInput
                                name="wocustomerrelease"
                                label="Customer Release"
                            />
                        </div>
                        <div className={styles.multipleInputContainer}>
                            <TextInput
                                name="wocity"
                                label="City"
                            />
                            <Autocomplete
                                value={woState}
                                options={statesArray}
                                size={'small'}
                                getOptionLabel={(option) => option.value}
                                onChange={(event, newValue) => {
                                    //@ts-ignore
                                    setWoState(newValue)
                                }}
                                renderInput = {params => (
                                    <TextField
                                        {...params}
                                        label="State"
                                        name="wostate"
                                    />
                                )}
                            />
                        </div>

                    </div>
                    <div className={styles.multipleInputContainer}>
                        <TextInput
                            name="wolatlong"
                            label="Latitude, Longitude"
                        />
                        <TextInput
                            name="woaddress"
                            label="Location"
                        />
                    </div>

                    <div className={styles.multipleInputContainer}>
                        <DateInput name="schedulestartdate" label="Schedule Start Date" required={true} />
                        {/*<TimeInput name="schedulestarttime" label="Schedule Start Time" defaultValue={null} onChange={onChange} value={value} />*/}
                        <TextField
                            label={"Schedule Start Time"}
                            type="time"
                            size={'small'}
                            defaultValue=""
                            value={timeValue}
                            onChange={(event) => {setTimeValue(event.target.value)}}
                            fullWidth={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
