import {
  combineReducers,
  configureStore,
  getDefaultMiddleware
} from '@reduxjs/toolkit'

import auth from '../slices/authSlice'

const rootReducer = combineReducers({
  auth
})

export default configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
  // eslint-disable-next-line no-undef
  devTools: process.env.NODE_ENV === 'development'
})

export type AppState = ReturnType<typeof rootReducer>
