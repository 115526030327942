import React, {useEffect, useMemo, useState} from "react";
import styles from './WOListings.module.css'
import {IconButton, Box, AppBar} from '@material-ui/core'
import {Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core'
import { Delete, Edit, SettingsBackupRestore } from '@material-ui/icons'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Add, Save, TextSnippet} from '@mui/icons-material'
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {
    DataGridPro,
    GridColDef,
    GridCellParams,
    GridRowParams,
    GridCallbackDetails,
    MuiEvent,
    useGridApiRef,
    GridSortModel,
    GridFilterModel,
    useGridApiContext,
    GridToolbarContainer,
    GridCsvExportOptions, GridCsvGetRowsToExportParams, gridSortedRowIdsSelector
} from "@mui/x-data-grid-pro";
import {WorkOrderData, WorkOrderDataDefaults, useDeleteWorkOrder, useFetchWorkOrder} from "../../../queries/useWorkOrder";

import {WOListingForm} from "./WOListingForm";
import {
    Alert,
    Badge, BadgeProps,
    FormGroup,
    Grid,
    MenuItem, Select, SelectChangeEvent,
    styled
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {useFetchGridState, useUpdateGridState} from "../../../queries/useGridState";
import {GridSelectionModel, GridSortItem, GridFilterItem, GridValueFormatterParams} from "@material-ui/x-grid";
import {NotesIcon} from "../../../components/common/NotesLayout/NotesLayout";
import {useQueryClient} from "react-query";
import {ButtonProps} from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {QuickAddForm} from "../QuickAdd/QuickAddForm";


const WOListingTable = (params) => {
    let _sortModelState: any = null
    let _filterModelState: any = null
    const queryClient = useQueryClient()
    const [gridShowing, setGridShowing] = useState(false)
    const [isNotesUpdated, setIsNotesUpdated] = useState(false)
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [isClone, setIsClone] = useState(false)
    const [gridParams, setGridParams] = useState(WorkOrderDataDefaults)
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [newWOID, setNewWOID] = useState<number>(0)
    const [dialogObjDel, setDialogObjDel] = React.useState({ msg: "", id: 0 })
    const [dialogObj, setDialogObj] = React.useState({ msg: "", gridState: "", okButtonText: "Save" })
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const [dataRows, setDataRows] = React.useState([]);
    const woActiveSelection = localStorage.getItem("woActiveSelection")
    const [activeSelect, setActiveSelect] = React.useState<string>( woActiveSelection !== null ? woActiveSelection : 'all');
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([1]);
    const [showQuickAdd, setShowQuickAdd] = useState(false)

    const dataGridPageKey = "wolistingkey"
    const [page, setPage] = React.useState(Number(localStorage.getItem(dataGridPageKey)));
    const setPageNumber = (pageNumber) => {
        localStorage.setItem(dataGridPageKey, pageNumber)
        setPage(Number(pageNumber))
    }

    const gridAPIRef = useGridApiRef()
    const updateGridState = useUpdateGridState()
    const deleteWorkOrder = useDeleteWorkOrder()

    const gridSortItem: GridSortItem = {
        field: 'wocustomercode',
        sort: 'asc'
    }
    const defaultGridSortModel: GridSortModel = [gridSortItem]
    const [gridSortModel, setGridSortModel] = React.useState<GridSortModel>(defaultGridSortModel)

    const gridFilterItem: GridFilterItem = {
       columnField: 'Test',
       id: 0,
       operatorValue: '',
       value: ''
    }

    const { data } = useFetchWorkOrder()
    useEffect(() => {
        setGridSortModel(getColumnSortModel())
        if (data?.workorderlist && data?.workorderlist?.length > 0) {
            filterRowsToSelection(activeSelect)

            const woList = data?.workorderlist?.filter((workOrder) => { return workOrder.woid === newWOID })
            if (woList && woList.length > 0) {
                params.setWorkOrderObj(woList[0])
            }
        }
    }, [data])


    useEffect(() => {
        if (newWOID > 0) {
            queryClient.invalidateQueries(['workorderdata'])
            setTimeout(() => {
                gridAPIRef.current?.selectRows([newWOID], true, true)
            })
        }
    }, [newWOID])

    React.useEffect(() => {
        // gridAPIRef.current.applySorting()

        if (params.workOrderObj) {
            setTimeout(() => {
                gridAPIRef.current?.selectRows([params.workOrderObj.woid])
            })
        }
    }, [params.workOrderObj]);

    const getMappedRecords = () => {
        return data?.workorderlist.map((workOrder: WorkOrderData, i) => {
            return {
                id: workOrder.woid,
                woid: workOrder.woid,
                canedit: workOrder.canedit,
                candelete: workOrder.candelete,
                woentityid: workOrder.woentityid,
                woentity: workOrder.woentity,
                wodirectbill: workOrder.wodirectbill,
                wocustomercodeid: workOrder.wocustomercodeid,
                wocustomercode: workOrder.wocustomercode,
                wocustomerinfo: workOrder.wocustomerinfo,
                wocustomerworkorder: workOrder.wocustomerworkorder,
                wocustomerrelease: workOrder.wocustomerrelease,
                wopolid: workOrder.wopolid,
                wocustomerpo: workOrder.wocustomerpo,
                wofoldername: workOrder.wofoldername,
                wocustomerdivisionid: workOrder.wocustomerdivisionid,
                wocustomerdivision: workOrder.wocustomerdivision,
                woaddress: workOrder.woaddress,
                woaddress2: workOrder.woaddress2,
                wocity: workOrder.wocity,
                wostate: workOrder.wostate,
                wozip: workOrder.wozip,
                wocustomerworktype: workOrder.wocustomerworktype,
                wodivisionids: [],
                wodivision: workOrder.wodivision,
                wostatusid: workOrder.wostatusid,
                wostatus: workOrder.wostatus,
                wodatepackagereceived: workOrder.wodatepackagereceived,
                wodateworeceived: workOrder.wodateworeceived,
                wodatecommitted: workOrder.wodatecommitted,
                wodatejobready: workOrder.wodatejobready,
                woprojectmanagerid: workOrder.woprojectmanagerid,
                woprojectmanager: workOrder.woprojectmanager,
                wopricingmethodid: workOrder.wopricingmethodid,
                wopricingmethod: workOrder.wopricingmethod,
                woaddeduser: workOrder.woaddeduser,
                woaddeduserkey: workOrder.woaddeduserkey,
                woaddeddate: workOrder.woaddeddate,
                woupdateduser: workOrder.woupdateduser,
                woupdateduserkey: workOrder.woupdateduserkey,
                woupdateddate: workOrder.woupdateddate,
                woactive: workOrder.woactive,
                notestypeid: workOrder.notestypeid,
                notestitle: workOrder.notestitle,
                noteslinkid: workOrder.noteslinkid,
                notescount: workOrder.notescount
            }
        })
    }

    const handleSelectionChange = (event: SelectChangeEvent) => {
        setActiveSelect(event.target.value as string);
        localStorage.setItem("woActiveSelection", event.target.value)
        filterRowsToSelection(event.target.value)
    }

    const filterRowsToSelection = (value: string) => {
        const rowsToFIlter = getMappedRecords()
        if (value !== 'all') {
            setDataRows(rowsToFIlter.filter(rows => {
                return (rows.woactive === (value === 'active'))
            }))
        }
        else {
            setDataRows(rowsToFIlter)
        }
    }

    React.useEffect(() => {
        const colSortModel = getColumnSortModel()
        setGridSortModel(colSortModel)

        if (dataRows && dataRows.length > 0 && params.workOrderObj === null) {
            const woList = data.workorderlist.filter((workOrder) => { return workOrder.woid === gridAPIRef.current.getSortedRows()[0].id })
            if (woList && woList.length > 0) {
                params.setWorkOrderObj(woList[0])
            }
        }
        else if (dataRows && dataRows.length > 0 && params.workOrderObj !== null) {
            const woList = data.workorderlist.filter((workOrder) => { return workOrder.woid === params.workOrderObj.woid })
            if (woList && woList.length > 0) {
                params.setWorkOrderObj(woList[0])
            }
        }
    }, [dataRows]);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    }

    const basicProps = {
        disableColumnMenu: true,
        sortable: false,
        disableExport: true,
        disableReorder: true,
        editable: false,
        filterable: false,
        groupable: false,
        hideable: false,
        pinnable: false
    }

    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
        setIsClone(false)
    }

    const handleEdit = (params: GridCellParams) => {
        const woData = data.workorderlist.filter((item) => item.woid === Number(params.id))[0]
        console.log(woData)
        setReloadForm(reloadForm+1)
        setIsClone(false)
        setGridParams({
            woid: woData.woid,
            canedit: woData.canedit,
            candelete: woData.candelete,
            woentityid: woData.woentityid,
            woentity: woData.woentity,
            wodirectbill: woData.wodirectbill,
            wocustomercodeid: woData.wocustomercodeid,
            wocustomercode: woData.wocustomercode,
            wocustomerinfo: woData.wocustomerinfo,
            wocustomerworkorder: woData.wocustomerworkorder,
            wocustomerrelease: woData.wocustomerrelease,
            wopolid: woData.wopolid,
            wocustomerpo: woData.wocustomerpo,
            wofoldername: woData.wofoldername,
            wocustomerdivisionid: woData.wocustomerdivisionid,
            wocustomerdivision: woData.wocustomerdivision,
            woaddress: woData.woaddress,
            woaddress2: woData.woaddress2,
            wocity: woData.wocity,
            wostate: woData.wostate,
            wozip: woData.wozip,
            wocustomerworktype: woData.wocustomerworktype,
            wodivisionids: woData.wodivisionids,
            wodivision: woData.wodivision,
            wostatusid: woData.wostatusid,
            wostatus: woData.wostatus,
            wodatepackagereceived: woData.wodatepackagereceived,
            wodateworeceived: woData.wodateworeceived,
            wodatecommitted: woData.wodatecommitted,
            wodatejobready: woData.wodatejobready,
            woprojectmanagerid: woData.woprojectmanagerid,
            woprojectmanager: woData.woprojectmanager,
            wopricingmethodid: woData.wopricingmethodid,
            wopricingmethod: woData.wopricingmethod,
            woaddeduser: woData.woaddeduser,
            woaddeduserkey: woData.woaddeduserkey,
            woaddeddate: woData.woaddeddate,
            woupdateduser: woData.woupdateduser,
            woupdateduserkey: woData.woupdateduserkey,
            woupdateddate: woData.woupdateddate,
            woactive: woData.woactive,
            notestypeid: woData.notestypeid,
            notestitle: woData.notestitle,
            noteslinkid: woData.noteslinkid,
            notescount: woData.notescount,
            wofirezoneid: woData.wofirezoneid,
            woinsurancecategoryid: woData.woinsurancecategoryid,
        })
        setIsFormShowing(true)
    }

    const handleClone = (params: GridCellParams) => {
        const woData = data.workorderlist.filter((item) => item.woid === Number(params.id))[0]
        console.log(woData)
        setReloadForm(reloadForm+1)
        setIsClone(true)
        setGridParams({
            woid: woData.woid,
            canedit: woData.canedit,
            candelete: woData.candelete,
            woentityid: woData.woentityid,
            woentity: woData.woentity,
            wodirectbill: woData.wodirectbill,
            wocustomercodeid: woData.wocustomercodeid,
            wocustomercode: woData.wocustomercode,
            wocustomerinfo: woData.wocustomerinfo,
            wocustomerworkorder: woData.wocustomerworkorder,
            wocustomerrelease: woData.wocustomerrelease,
            wopolid: woData.wopolid,
            wocustomerpo: woData.wocustomerpo,
            wofoldername: woData.wofoldername,
            wocustomerdivisionid: woData.wocustomerdivisionid,
            wocustomerdivision: woData.wocustomerdivision,
            woaddress: woData.woaddress,
            woaddress2: woData.woaddress2,
            wocity: woData.wocity,
            wostate: woData.wostate,
            wozip: woData.wozip,
            wocustomerworktype: woData.wocustomerworktype,
            wodivisionids: woData.wodivisionids,
            wodivision: woData.wodivision,
            wostatusid: woData.wostatusid,
            wostatus: woData.wostatus,
            wodatepackagereceived: woData.wodatepackagereceived,
            wodateworeceived: woData.wodateworeceived,
            wodatecommitted: woData.wodatecommitted,
            wodatejobready: woData.wodatejobready,
            woprojectmanagerid: woData.woprojectmanagerid,
            woprojectmanager: woData.woprojectmanager,
            wopricingmethodid: woData.wopricingmethodid,
            wopricingmethod: woData.wopricingmethod,
            woaddeduser: woData.woaddeduser,
            woaddeduserkey: woData.woaddeduserkey,
            woaddeddate: woData.woaddeddate,
            woupdateduser: woData.woupdateduser,
            woupdateduserkey: woData.woupdateduserkey,
            woupdateddate: woData.woupdateddate,
            woactive: woData.woactive,
            notestypeid: woData.notestypeid,
            notestitle: woData.notestitle,
            noteslinkid: woData.noteslinkid,
            notescount: woData.notescount,
            wofirezoneid: woData.wofirezoneid,
            woinsurancecategoryid: woData.woinsurancecategoryid,
        })
        setIsFormShowing(true)
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObjDel({
            msg: 'Are you sure you want to delete work order # \"' + params.getValue(params.id, 'wocustomerworkorder').toString() + "\"?",
            id: Number(params.id)
        })
    }

    const canEdit = (params: GridCellParams) => {
        return params.row.canedit
    }

    const canDelete = (params: GridCellParams) => {
        return params.row.candelete
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteWorkOrder.mutateAsync({ woid: dialogObjDel.id })
        const retResponse = JSON.parse(response.jsonString)
        if (retResponse.status === 'failure') {
            closeDialog()
            setDialogObj({
                msg: retResponse.message,
                gridState: "",
                okButtonText: ''
            })
        }
        else {
            closeDialog()
        }
    }

    const buildColumnDefs = () => {
        const gridObj = parseGridObject()
        const parsedGridColumns = gridObj.gridDefs
        var gridColumns: GridColDef[] = [
            gridEditSaveButtonDef,
            gridIdDef,
            ...parsedGridColumns,
            gridButtonsDef
        ]
        return gridColumns
    }

    const getColumnSortModel = () => {
        const gridObj = parseGridObject()
        return gridObj.sortModel
    }

    const formatActive = (params: GridValueFormatterParams) => {
            return (params.value ? "Active" : "Inactive")
    }

    const parseGridObject = () => {
        var sortModel: GridSortModel = [gridSortItem]
        var gridStateString = JSON.stringify({gridType: "workorder", gridState: JSON.stringify(gridDefaults)})
        if (params.gridStateData && params.gridStateData.length > 0) {
            gridStateString = params.gridStateData[0].gridState
            const gridStateData = JSON.parse(gridStateString)
            const sendObj = {
                gridType: 'workorder',
                gridState: JSON.stringify(gridStateData.columnDefs)
            }
            gridStateString = JSON.stringify(sendObj)

           if (gridStateData && gridStateData.sortModel !== null && gridStateData.sortModel.length > 0) {
               // gridAPIRef?.current?.setSortModel(gridStateData.sortModel)
               const dataSort = gridStateData.sortModel
               sortModel = dataSort
           }
        }

        var gridDefs: GridColDef[] = [];
        if (gridStateString.length > 0) {
            const gridObject = JSON.parse(gridStateString)
            if (gridObject.gridType === 'workorder') {
                const gridState = JSON.parse(gridObject?.gridState)
                gridState?.forEach(state => {
                    var col: GridColDef = {...state}

                    if (col.field === 'woactive') {
                        col.valueFormatter = formatActive
                    }

                    gridDefs.push(col)
                })
            }
        }
        return { gridDefs: gridDefs, sortModel: sortModel }
    }

    const gridDefaults: GridColDef[] = [
        {
            "field":"woentity",
            "headerName":"Entity",
            "headerAlign":"left",
            "type":"string",
            "width":100,
            "align":"left"
        },
        {
            "field":"woid",
            "headerName":"Work Order Id",
            "headerAlign":"right",
            "type":"string",
            "width":125,
            "align":"right"
        },
        {
            "field":"wocustomerinfo",
            "headerName":"Customer",
            "headerAlign":"left",
            "type":"string",
            "width":250,
            "align":"left"
        },
        {
            "field": "wocustomerworkorder",
            "headerName": "Customer WO",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "field": "wocustomerworktype",
            "headerName": "Customer Work Type",
            "headerAlign": "left",
            "type": "string",
            "width": 200,
            "align": "left"
        },
        {
            "field": "wodivision",
            "headerName": "Division Assigned",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "hide": true,
            "field": "wodivisionids",
            "headerName": "Division Id",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "field": "wostatus",
            "headerName": "Status",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "hide": true,
            "field": "wostatusid",
            "headerName": "Status Id",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            field: 'woactive',
            type: 'string',
            headerName: 'Record Status',
            width: 120
        }
    ]

    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: -1,
            top: 3,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }));

    const gridButtonsDef: GridColDef = {
        field: 'Delete',
        headerName: '',
        width: 80,
        align: "center",
        headerAlign: "center",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
              <>
                  <NotesIcon
                    id={Number(params.id)}
                    notesid={params.getValue(params.id, 'notesid')}
                    notestitle={params.getValue(params.id, 'notestitle')}
                    notestypeid={params.getValue(params.id, 'notestypeid')}
                    noteslinkid={params.getValue(params.id, 'noteslinkid')}
                    notescount={params.getValue(params.id, 'notescount')}
                    queryType={['workorderdata']}
                  />
                {/*<Tooltip title="Clone">*/}
                {/*  <IconButton*/}
                {/*    className={styles.button}*/}
                {/*    onClick={() => {*/}
                {/*      handleClone(params)*/}
                {/*    }}>*/}
                {/*    <ContentCopyIcon />*/}
                {/*  </IconButton>*/}
                {/*</Tooltip>*/}

                {canDelete(params) && (
                  <Tooltip title="Delete">
                    <IconButton
                      className={styles.button}
                      onClick={() => {
                        handleDelete(params)
                      }}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
    }

    const gridEditSaveButtonDef: GridColDef = {
        field: 'Edit',
        renderHeader: () => {
            return (
                <>
                    {(data?.canadd) &&
                        <IconButton
                            className={styles.submitButton}
                            onClick={() => handleAdd()}
                            size="small">
                            <Add />
                        </IconButton>
                    }
                </>
            )
        },
        width: 50,
        align: "center",
        headerAlign: "center",
        pinnable: true,
        cellClassName: 'pinnedColumn',
        ...basicProps,
        renderCell: (params) => (
            <>
                {(canEdit(params)) &&
                <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
                    <Edit />
                </IconButton>
                }
            </>
        )
    }

    const gridIdDef: GridColDef = {
        field: 'id',
        hide: true,
        ...basicProps
    }

    const getColumnStringToSave = () => {
        var fieldObjects = []
        const gridState = gridAPIRef.current.getAllColumns()
        gridState.forEach(stateColumnDef => {
            if (stateColumnDef.sortable && stateColumnDef.pinnable && stateColumnDef.groupable) {
                fieldObjects.push({
                    field: stateColumnDef.field,
                    headerName: stateColumnDef.headerName,
                    headerAlign: stateColumnDef.headerAlign,
                    type: stateColumnDef.type,
                    width: stateColumnDef.width,
                    align: stateColumnDef.align,
                    description: stateColumnDef.description,
                    hide: stateColumnDef.hide,
                    valueFormatter: stateColumnDef.valueFormatter
                })
            }
        })

        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: fieldObjects, sortModel: sortModelDefs})
        return strObject
    }

    const handleRestore = () => {
        setGridShowing(false)
        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: gridDefaults, sortModel: sortModelDefs})
        setDialogObj({
            msg: 'Are you sure you want to reset the grid state to default?',
            gridState: strObject,
            okButtonText: 'RESET'
        })
    }

    const handleSave = () => {
        setGridShowing(false)
        const columnObjsting = getColumnStringToSave()
        setDialogObj({
            msg: 'Are you sure you want to save the current grid state?',
            gridState: columnObjsting,
            okButtonText: 'Save'
        })
    }

    const handleQuickAdd = () => {
        setShowQuickAdd(true)
    }

    const handleOkConfirm = async () => {
        var finalData = {
            gridType: params.gridStateVersion,
            gridState: dialogObj.gridState
        }
        const { data: response } = await updateGridState.mutateAsync(finalData)
        closeDialog()
        setGridShowing(true)
        setSnackbarOpen(true);
    }

    const closeDialog = () => {
        setGridShowing(true)
        setDialogObj({
            msg: '',
            gridState: '',
            okButtonText: ''
        })
        setDialogObjDel({
            msg: '',
            id: 0
        })
    }

    const handleRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        const woObjectList = data.workorderlist.filter((workOrder) => { return workOrder.woid === Number(param.id)})
        if (woObjectList && woObjectList.length > 0) {
            params.setWorkOrderObj(woObjectList[0])
            queryClient.invalidateQueries(['schedule', 'scheduleadddropdowns'])
        }
    }

    const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
        gridSortedRowIdsSelector(apiRef);

    function CustomGridToolbar() {
        const apiRef = useGridApiContext();

        const handleExport = (options: GridCsvExportOptions) =>
            apiRef.current.exportDataAsCsv(options);

        const buttonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <FileDownloadOutlinedIcon />,
        };

        const addButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <Add />,
        };

        const saveButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <Save />,
        };

        const restoreButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <SettingsBackupRestore />,
        };

        return (
            <GridToolbarContainer >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-begin"
                    alignItems="center"
                >
                    <Grid  item xs={2}>
                        <Select
                            onChange={handleSelectionChange}
                            style={{ width: '220px', height:'30px' }}
                            disabled={params.workOrderObj === null}
                            value={activeSelect}>
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="inactive">Inactive</MenuItem>
                        </Select>
                    </Grid>
                    <Grid  item xs={2}>
                        <Button
                            {...addButtonBaseProps}
                            onClick={() => handleQuickAdd()}
                        >
                            Quick Add UTC
                        </Button>
                    </Grid>
                    <Grid  item xs={8}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Button
                                {...saveButtonBaseProps}
                                onClick={() => handleSave()}
                            >
                                SAVE GRID SETTINGS
                            </Button>
                            <Button
                                {...restoreButtonBaseProps}
                                onClick={() => handleRestore()}
                            >
                                RESTORE GRID SETTINGS
                            </Button>
                            <Button
                                {...buttonBaseProps}
                                onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
                            >
                                EXPORT
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </GridToolbarContainer>
        )
    }

    return (
        <div className={styles.root}>
            <>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={4000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert severity="success">
                        Work order settings have been saved!
                    </Alert>
                </Snackbar>
            </>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        {dialogObj.okButtonText.length > 0 &&
                            <Button onClick={handleOkConfirm}>{dialogObj.okButtonText}</Button>
                        }
                    </DialogActions>
                </Dialog>
            </>

            <>
                <Dialog
                    open={dialogObjDel.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObjDel.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>
            <Grid style={{ height: '72vh', width: '100%' }} container spacing={1}>
                <Grid  item xs={12}>

                    {/*{gridShowing ?*/}
                        <DataGridPro
                            sx={{
                                "& .MuiDataGrid-pinnedColumns": {
                                    boxShadow: "none",
                                    backgroundColor: "transparent"
                                },
                                "& .MuiDataGrid-pinnedColumnHeaders": {
                                    boxShadow: "none",
                                    backgroundColor: "transparent"
                                }
                            }}
                            apiRef={gridAPIRef}
                            rows={dataRows}
                            page={page}
                            onPageChange={(newPage) => setPageNumber(newPage)}
                            columns={buildColumnDefs()}
                            //autoHeight={true}
                            density={'compact'}
                            pageSize={100}
                            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            // rowsPerPageOptions={[5, 10, 20]}
                            pagination
                            // headerHeight={gridShowing ? 75 : 0}
                            loading={!data}
                            sortModel={gridSortModel}
                            disableMultipleSelection={true}
                            onSortModelChange={(model: GridSortModel) => setGridSortModel(model)}
                            // onFilterModelChange={}
                            onStateChange={(state) => {
                                // console.log(state.sorting.sortModel)
                                //
                                // setGridSortModel(state.sorting.sortModel)
                                _sortModelState = state.sorting.sortModel
                                _filterModelState = state.filter.filterModel

                                return state
                            }}
                            initialState={ {
                                pinnedColumns: { left: ['Edit'], right: ['Notes', 'Delete'] },
                                sorting: {
                                    sortModel: gridSortModel
                                }
                            }}
                            onRowClick={handleRowClick}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            selectionModel={selectionModel}
                            components={{
                                Toolbar: CustomGridToolbar,
                            }}
                        />
                    <WOListingForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} woDropDownData={params.woDropDownData} canaddentity={data?.canaddentity} isClone={isClone} setNewWOID={setNewWOID}/>
                    <QuickAddForm isOpen={showQuickAdd} setIsOpen={setShowQuickAdd} gridParams={gridParams} reloadForm={reloadForm} woDropDownData={params.woDropDownData} setNewWOID={setNewWOID}></QuickAddForm>
                </Grid>
            </Grid>
        </div>
    );
}

export default WOListingTable
