import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {CWADropdownDefaults, CWADropdowns} from "./useCWADropdowns";

export interface customerList {
    custid: number
    custcode: string
    custinfo: string
}

export interface statusList {
    statusid: number
    statuscode: string
}

export interface worktypeList {
    worktypeid: number
    worktypecode: string
}

export interface pricingmethodList {
    pricingmethodid: number
    pricingmethodcode: string
}

export interface contactList {
    contactid: number
    contactname: string
}

export interface contractList {
    contractid: number
    contractname: string
}

export interface jobphaseList {
    jpid: number
    jpname: string
}

export interface  DivisionDropdowns {
    custlist: customerList[]
    worktypelist: worktypeList[]
    statuslist: statusList[]
    pricingmethodlist: pricingmethodList[]
    constactlist: contactList[]
    contractlist: contractList[]
    jobphaselist: jobphaseList[]
}

export const useFetchDivisionDropdowns =  (divisionid: number) => {
    console.log(divisionid)
    return useQuery(
        ['divisionsdropdowns'],
        () => globalFetcher(divisionid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const globalFetcher = async (id: number) => {
    const { data } = await axios.post('/divisiondropdowns', {
        divisionid: id
    })
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    return parsedData as DivisionDropdowns
}
