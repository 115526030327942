import React, {useEffect, useMemo, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle, Grid
} from '@material-ui/core'


import HookForm from '../../components/common/HookForm'
import styles from './FileViewer.module.css'
import TextInput from "../../components/common/inputs/TextInput";
import DateInput from "../../components/common/inputs/DateInput";
import {
    DialogContent,
    DialogContentText,
    FormControl,
    InputLabel, Menu,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import axios from "axios";
import {
    useCopyDocuments,
    useDeleteDocuments,
    useDownloadDocuments,
    useFetchDocuments, useMoveDocuments,
    useUploadDocument
} from "../../queries/useDocuments";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Dropzone, {DropzoneRef} from "react-dropzone";
import TreeItem from "@mui/lab/TreeItem";
import ArticleIcon from "@mui/icons-material/Article";
import PdfViewerComponent from "../../components/PdfViewerComponent";

export const FileViewerForm = ({ isOpen, setIsOpen }) => {
    const [woid, setWoId] = React.useState<number>(null)
    const [treeStructure, setTreeStructure] = React.useState([])
    const [folderData, setFolderData] = React.useState({contents: [], status: ''})
    const [dataLoaded, setDataLoaded] = React.useState(false)
    const [openViewer, setOpenViewer] = React.useState(false)
    const [watchLogoData, setWatchLogoData] = React.useState(null)
    const [watchLogoDataURL, setWatchLogoDataURL] = React.useState(null)
    const uploadDocument = useUploadDocument(isOpen.woid)
    const deleteDocument = useDeleteDocuments(isOpen.woid)
    const downloadDocument = useDownloadDocuments(isOpen.woid)
    const copyDocument = useCopyDocuments(isOpen.woid)
    const moveDocument = useMoveDocuments(isOpen.woid)
    const dropzoneRef = React.createRef<DropzoneRef>()
    const [expanded, setExpanded] = React.useState<string[]>([]);
    const [copyTree, setCopyTree] = React.useState(null)
    const [moveTree, setMoveTree] = React.useState(false)

    const { data } = useFetchDocuments(isOpen.woid)
    useEffect(() => {
        console.log('reloading')
        if (isOpen.woid > 0) {
            setFolderData(data)
            setWoId(isOpen.woid)
            // axios.post('/gdwodocuments', {
            //     woid: isOpen.woid
            // }).then((response) => {
            //     const dataJson = JSON.parse(response.data.jsonString)
            //     setFolderData(dataJson)
            // })
            //
        }
    }, [data])

    useEffect(() => {
        // console.log(woid)
        if (folderData && folderData.contents && folderData.contents.length > 0) {
            const topTreeItem = createTreeItems(folderData.contents[0], "")
            // console.log(folderData)
            // console.log(topTreeItem)
        }

        if (folderData && folderData.contents && folderData.contents.length > 0 && folderData.contents[0].length > 0) {
            const topFolder = folderData.contents[0][0].Name

            setTreeStructure(createTreeItems(folderData.contents[0], topFolder))
            setDataLoaded(true)
            setExpanded(getContentIdsForSchedule(folderData.contents[0]))
        }
        else if (folderData && folderData.status === 'success') {
            setDataLoaded(true)
        }
        else {
            setDataLoaded(false)
        }
    }, [folderData])

    const createTreeItems = (structure, topLevelName) => {
        if (structure && structure.length > 0) {
            return structure.map(tree => {
                if (tree.IsFolder) {
                    return (
                        <Dropzone key={tree.Name} onDrop={(files, rejects, e) => onSave(e, files, tree, topLevelName)} noClick={true}>
                            {({ getRootProps,
                                  getInputProps,
                                  isDragActive,
                                  isDragAccept,
                                  isDragReject,
                                  acceptedFiles,
                                  open,
                                  fileRejections,
                              }) => (
                                <div {...getRootProps()} className={isDragActive ? styles.dropzoneselect : styles.dropzone}>
                                    <TreeItem
                                        style={{color: (isDragActive ? 'blue' : 'black'), border:
                                                isDragActive && tree.type === 'folder' ? `1px dashed gray` : "none"}}
                                        key={tree.Id}
                                        id={tree.Id}
                                        nodeId={tree.Id}
                                        label={getTreeCount(tree)}
                                        onContextMenu={(event) => { handleContextMenu(event, tree) }}
                                    >
                                        <input {...getInputProps()} />
                                        {tree.SubItems && tree.SubItems.length > 0 &&
                                            createTreeItems(tree.SubItems, topLevelName)
                                        }
                                    </TreeItem>
                                </div>
                            )}
                        </Dropzone>
                    )
                }
                // console.log(tree)
                return (
                    <TreeItem icon={<ArticleIcon />} key={tree.Id} nodeId={tree.Id} label={tree.Name}
                              draggable={true}
                              onDoubleClick={(event) => { handleDoubleClick(event, tree) }}
                              onContextMenu={(event) => { handleContextMenu(event, tree) }} >
                        {tree.IsFolder && <TreeItem nodeId={""} label={""} />}
                    </TreeItem>
                )
            })
        }
    }

    const getTreeCount = (tree) => {
        if (tree && tree.SubItems && tree.SubItems.length > 0 && tree.IsFolder) {
            return tree.Name + ' (' + getCountofContent(tree.SubItems) + ')'
        }
        else {
            return tree.Name
        }
    }

    const getCountofContent = (contents) => {
        var files = 0
        contents.forEach((item) => {
            if (item.IsFolder && item.SubItems && item.SubItems.length > 0) {
                files += getCountofContent(item.SubItems)
            }
            else if (!item.IsFolder) {
                files += 1
            }
        })
        return files
    }

    const getContentIds = (contents) => {
        var ids = []
        contents.forEach((item) => {
            if (item.IsFolder) {
                ids.push(item.Id)
                const idsToAdd = getContentIds(item.SubItems)
                ids = [...ids, ...idsToAdd]
            }
        })
        console.log(ids)
        return ids
    }

    const getContentIdsForSchedule = (contents) => {
        var ids = []
        contents.forEach((item) => {
            // console.log('checking ' + Number(item.Name) + ':' + isOpen.scheduleid)
            // if (Number(item.Name)) {
            //     console.log('checking ' + Number(item.Name) + ':' + isOpen.scheduleid)
            //     if (Number(item.Name) == isOpen.scheduleid) {
            //         ids.push(item.Id)
            //         const idsToAdd = getContentIdsForSchedule(item.SubItems)
            //         ids = [...ids, ...idsToAdd]
            //     }
            // }
            if (item.IsFolder) {
                ids.push(item.Id)
                const idsToAdd = getContentIdsForSchedule(item.SubItems)
                ids = [...ids, ...idsToAdd]
            }
        })
        console.log(ids)
        return ids
    }

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
        tree: any;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent, tree) => {
        console.log('handleContextMenu')
        event.stopPropagation()
        event.preventDefault()
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                    tree: tree
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        )
    }

    const handleDoubleClick = (event: React.MouseEvent, tree) => {
        event.stopPropagation()
        event.preventDefault()

        window.open('/dispatch/open/' + encodeURIComponent(tree.Id), '_blank', 'noopener,noreferrer')

        // const body = {
        //     id: tree.Id,
        //     filename: tree.Name
        // }
        // downloadDocument.mutateAsync(body)
        // axios.post(`gdwodocumentsfile`, body).then((response) => {
        //     const dataJson = JSON.parse(response.data.jsonString)
        //     var contentType = 'image/png';
        //     if (dataJson.fileext === 'png') {
        //         contentType = 'image/png'
        //     }
        //     else if (dataJson.fileext === 'pdf') {
        //         contentType = 'application/pdf'
        //     }
        //     else if (dataJson.fileext === 'jpeg' || dataJson.fileext === 'jpg' || dataJson.fileext === 'jfif') {
        //         contentType = 'image/jpeg'
        //     }
        //     else if (dataJson.fileext === 'docx') {
        //         contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        //     }
        //     else if (dataJson.fileext === 'xlsx') {
        //         contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        //     }
        //     else if (dataJson.fileext === 'xls') {
        //         contentType = 'application/vnd.ms-excel'
        //     }
        //     setWatchLogoData(dataJson.filedata)
        //
        //     const byteCharacters = atob(dataJson.filedata)
        //     const byteNumbers = new Array(byteCharacters.length);
        //     for (let i = 0; i < byteCharacters.length; i++) {
        //         byteNumbers[i] = byteCharacters.charCodeAt(i);
        //     }
        //     const byteArray = new Uint8Array(byteNumbers);
        //     const blob = new Blob([byteArray], {type: contentType});
        //     const url = window.URL.createObjectURL(blob);
        //     setWatchLogoDataURL(url);
        //     setOpenViewer(true)
        // })
    }

    const onSave = async (event: any, files: File[], tree: any, topFolderName: any) => {
        const fileReader = new FileReader()
        fileReader.onload = function (event) {
            const data = event.target.result as string
            // setValue('logo', data.split(';base64,')[1])
            const body = {
                parentFolderID: tree.Id, // Folder the file is dropped on
                // id: tree.Id,
                rootFolderName: topFolderName, // root folder name
                filename: files[0].name,
                filedata: data.split(';base64,')[1],
                woid: woid,
                mimetype:  files[0].type,
            }
            uploadDocument.mutateAsync(body)
        }

        fileReader.readAsDataURL(files[0])
        event.stopPropagation()
    }

    const handleClose = () => {
        setContextMenu(null);
    }

    const handleCopy = () => {
        setCopyTree(contextMenu.tree)
        setContextMenu(null);
    }

    const handleUpload = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
        setContextMenu(null);
    }

    const handleMove = () => {
        setCopyTree(contextMenu.tree)
        setMoveTree(true)
        setContextMenu(null)
    }

    const handleRename = () => {
        setCopyTree(contextMenu.tree)
        // setMoveTree(true)
        setContextMenu(null);
    }

    const handlePaste = () => {
        const copytree = copyTree

        const body = {
            id: copytree.Id,
            toid: contextMenu.tree.Id
        }

        if (moveTree) {
            moveDocument.mutateAsync(body)
        }
        else {
            copyDocument.mutateAsync(body)
        }
        setMoveTree(false)
        setCopyTree(null)
        setContextMenu(null)
    }

    const handleDownload = () => {
        const body = {
            id: contextMenu.tree.Id,
            filename: contextMenu.tree.Name
        }
        const fileName = contextMenu.tree.name
        axios.post(`gdwodocumentsfile`, body).then((response) => {
            const dataJson = JSON.parse(response.data.jsonString)
            setWatchLogoData(dataJson.filedata)
            var contentType = 'image/png';
            if (dataJson.fileext === '.png') {
                contentType = 'image/png'
            }
            else if (dataJson.fileext === '.pdf') {
                contentType = 'application/pdf'
            }
            else if (dataJson.fileext === '.jpg' || dataJson.fileext === '.jpg') {
                contentType = 'image/jpeg'
            }
            else if (dataJson.fileext === '.docx') {
                contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            }
            else if (dataJson.fileext === '.xlsx') {
                contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
            else if (dataJson.fileext === '.xls') {
                contentType = 'application/vnd.ms-excel'
            }
            const byteCharacters = atob(dataJson.filedata)
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: contentType});
            const url = window.URL.createObjectURL(blob);
            setWatchLogoDataURL(url);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        setContextMenu(null);
    }

    const handleDelete = () => {
        const body = {
            id: contextMenu.tree.Id,
            filename: contextMenu.tree.Name
        }
        console.log(body)
        deleteDocument.mutateAsync(body)
        setContextMenu(null)
    }

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    return (
        <Dialog open={isOpen.openDialog} fullWidth={true} maxWidth="xl" >
            <DialogTitle>{isOpen.scheduledescription}</DialogTitle>
            <DialogContent>
                {!dataLoaded &&
                    <Box
                        className={styles.box}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height={"100%"}
                        width={"100%"}
                    >
                        <CircularProgress/>
                    </Box>
                }
                {dataLoaded && folderData && folderData.contents &&
                    <React.Fragment>
                        <TreeView
                            draggable={true}
                            aria-label="file system navigator"
                            defaultCollapseIcon={<ExpandMoreIcon/>}
                            defaultExpandIcon={<ChevronRightIcon/>}
                            // onNodeSelect={handleChange}
                            // selected={treeNodeState.selected}
                            expanded={expanded}
                            sx={{margin: 2, flexGrow: 1}}
                            onNodeToggle={handleToggle}
                        >
                            {treeStructure}
                        </TreeView>
                    </React.Fragment>
                }
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem disabled={contextMenu?.tree?.IsFolder} onClick={handleCopy}>Copy</MenuItem>
                    <MenuItem disabled={contextMenu?.tree?.IsFolder} onClick={handleMove}>Move</MenuItem>
                    <MenuItem disabled={copyTree === null} onClick={handlePaste}>Paste</MenuItem>
                    <MenuItem disabled={contextMenu?.tree?.IsFolder} onClick={handleRename}>Rename</MenuItem>
                    {/*<MenuItem disabled={contextMenu?.tree?.type === 'file'} onClick={handleUpload}>Upload</MenuItem>*/}
                    <MenuItem disabled={contextMenu?.tree?.IsFolder} onClick={handleDownload}>Download</MenuItem>
                    <MenuItem disabled={contextMenu?.tree?.IsFolder} onClick={handleDelete}>Delete</MenuItem>
                </Menu>

                {watchLogoDataURL && (
                    <Grid item xs={6} className={styles.selectcontrol}>
                        <>
                            <Dialog
                                open={openViewer}
                                fullWidth={true}
                                maxWidth="xl"
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent>
                                    <div className="PDF-viewer">
                                        <PdfViewerComponent
                                            document={watchLogoDataURL}
                                        />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => {
                                        setOpenViewer(false)
                                        setWatchLogoDataURL(null)
                                    }}>Cancel</Button>
                                    {/*<Button>Save</Button>*/}
                                </DialogActions>
                            </Dialog>
                        </>
                    </Grid>
                )}

            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen({openDialog: false, scheduledescription: '', woid: 0, scheduleid: 0})}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
