import React, {useEffect, useState} from "react";
import styles from '../../CWAListingPage/CWALiastingPage.module.css'
import {IconButton, Box, AppBar} from '@material-ui/core'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { Add } from '@mui/icons-material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {GridSelectionModel, GridValueFormatterParams, XGrid} from '@material-ui/x-grid'
import {
    DataGridPro,
    GridColDef,
    GridCellParams,
    GridRowParams,
    GridCallbackDetails,
    MuiEvent, useGridApiRef
} from "@mui/x-data-grid-pro";
import {POListings, POListingsDefaults, useDeletePOListings, useFetchPOListings} from "../../../../queries/usePOListings";
import {Grid} from "@mui/material";
import {formatMoney} from "../../../../util/formatHelpers";
import {POListForm} from "./POListForm";

const POListingsTable = (params) => {
    const gridAPIRef = useGridApiRef()
    const deletePOListings = useDeletePOListings()
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState(POListingsDefaults)
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([0]);

    var amountRows = []
    const { data } = useFetchPOListings(params.cwalid)
    if (data && data.length > 0) {
        amountRows = data?.map((polisting: POListings, i) => {
            return {
                id: polisting.polid,
                polnumber: polisting.polnumber,
                poldescription: polisting.poldescription,
                polstatusid: polisting.polstatusid,
                polactive: polisting.polactive,
                polauthorizedamount: polisting.polauthorizedamount,
                polbilledamount: polisting.polbilledamount,
                polpendingamount: polisting.polpendingamount,
                polremainingamount: polisting.polremainingamount
            }
        })
    }

    React.useEffect(() => {
        if (data && data.length > 0) {
            var rows = []
            if (data && data.length > 0) {
                rows = data?.filter((polisting: POListings) =>
                    polisting.polid === Number(data[0].polid)
                )
            }

            var polid = 0
            if (rows?.length > 0) {
                params.setPOListingObject(rows[0])
                polid = rows[0].polid
            }

            if (params.poListingObject?.polid > 0) {
                rows = data?.filter((polisting: POListings) =>
                    polisting.polid === params.poListingObject.polid
                )
                if (rows?.length > 0) {
                    params.setPOListingObject(rows[0])
                    polid = rows[0].polid
                }
            }
            setTimeout(() => {
                gridAPIRef.current?.selectRows([polid])
            })
        }
    }, [data]);

    const getStatusCode = (statusid: number) => {
        const value = params.postatuslist.filter((e) => e.statusid === statusid)
        return value[0]?.statuscode
    }

    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const amountColumns: GridColDef[] = [
        {
            field: 'Edit',
            renderHeader: () => {
                return (
                    <IconButton
                        className={styles.submitButton}
                        onClick={() => handleAdd()}
                        size="small">
                        <Add />
                    </IconButton>
                )
            },
            width: 50,
            align: "center",
            headerAlign: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
                    <Edit />
                </IconButton>
            )
        },
        {
            field: 'id',
            hide: true
        },
        {
            field: 'polnumber',
            type: 'string',
            headerName: 'PO Number',
            width: 125,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'poldescription',
            type: 'string',
            headerName: 'Description',
            width: 200,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'polstatusid',
            type: 'string',
            headerName: 'Status',
            width: 200,
            valueGetter: (params) =>
                getStatusCode(Number(params.value))
        },
        {
            field: 'polactive',
            type: 'string',
            headerName: 'Status',
            width: 120,
            valueGetter: (params) => {
                return (params.value ? "Active" : "Inactive")
            },
        },
        {
            field: 'polpendingamount',
            type: 'string',
            headerName: 'Pending',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            field: 'polremainingamount',
            type: 'number',
            headerName: 'Remaining',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            field: 'polauthorizedamount',
            type: 'number',
            headerName: 'Authorized',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            field: 'action',
            headerName: '',
            width: 50,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                    <Delete />
                </IconButton>
            )
        }
    ]

    const handleEdit = (params: GridCellParams) => {
        const polisting = data.filter((item) => item.polid === Number(params.id))[0]
        setReloadForm(reloadForm+1)
        setGridParams({
            polid: polisting.polid,
            polnumber: polisting.polnumber,
            poldescription: polisting.poldescription,
            polamountlastupdated: '',
            polstatusid: polisting.polstatusid,
            poladdeddate: '',
            polactive: polisting.polactive,
            polauthorizedamount: 0,
            polbilledamount: 0,
            polpendingamount: 0,
            polremainingamount: 0
        })
        setIsFormShowing(true)
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObj({
            msg: 'Are you sure you want to delete this amount \"' + params.getValue(params.id, 'polnumber').toString() + "\"?",
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deletePOListings.mutateAsync({ polid: dialogObj.id })
        closeDialog()
    }

    const closeDialog = () => {
        setDialogObj({
            msg: '',
            id: 0
        })
    }

    const handleRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        var rows = []
        if (data && data.length > 0) {
            rows = data?.filter((polisting: POListings) =>
                polisting.polid === Number(param.id)
            )
        }
        if (rows?.length > 0) {
            params.setPOListingObject(rows[0])
        }
        console.log('polid:' + param.id)
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <POListForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} cwalid={params.cwalid} poStatus={params.postatuslist}/>
                    <DataGridPro
                        apiRef={gridAPIRef}
                        rows={amountRows}
                        columns={amountColumns}
                        autoHeight={true}
                        density={'compact'}
                        pageSize={10}
                        loading={!data}
                        disableMultipleSelection={true}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                        onRowClick={handleRowClick}
                    />
                </Grid>
            </Grid>


        </div>
    );
}

export default POListingsTable
