import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import styles from './Entity.module.css'
import TextInput from "../../../components/common/inputs/TextInput";
import SelectInput from "../../../components/common/inputs/SelectInput";
import {useUpdateEntity} from "../../../queries/useEntity";

interface FormData {
    entityid: number
    entityname: string
    entityaccountingjob: string
    entityaccountingphase: string
    entityspectrum: boolean
    entityspectrumcompanycode: string
    entityactive: boolean
}

export const EntityForm = ({ isOpen, setIsOpen, gridParams, reloadForm }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit Entity" : "Create Entity"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateEntity = useUpdateEntity()
    const [amount, setAmount] = useState<number>(0)

    useEffect(() => {
        reset({
            entityid: (gridParams ? gridParams.entityid : 0),
            entityname: (gridParams ? gridParams.entityname : ''),
            entityaccountingjob: (gridParams ? gridParams.entityaccountingjob : ''),
            entityaccountingphase: (gridParams ? gridParams.entityaccountingphase : ''),
            entityspectrum: (gridParams ? gridParams.entityspectrum : true),
            entityspectrumcompanycode: (gridParams ? gridParams.entityspectrumcompanycode : ''),
            entityactive: (gridParams ? gridParams.entityspectrum : true)
        })
    }, [reloadForm])

    const onSubmit = async (data: FormData) => {
        var finalData = {
            entityname: data.entityname,
            entityaccountingjob: data.entityaccountingjob,
            entityaccountingphase: data.entityaccountingphase,
            entityspectrum: data.entityspectrum,
            entityspectrumcompanycode: data.entityspectrumcompanycode,
            entityactive: data.entityspectrum
        }
        if (data.entityid > 0) {
            finalData['entityid'] = data.entityid
        }
        const { data: response } = await updateEntity.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    return (
        <Dialog open={isOpen}
                fullWidth={true}
                maxWidth="xl"
                className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>

                    <div className={styles.multipleInputContainer}>
                        <TextInput
                            name="entityname"
                            label="Name"
                            rules={{
                                required: 'Accounting job is required',
                                minLength: {
                                    value: 1,
                                    message: 'Must be at least 1 characters'
                                },
                                maxLength: {
                                    value: 200,
                                    message: 'Must be 200 or less characters'
                                }
                            }}
                            required
                        />
                        <TextInput
                            name="entityaccountingjob"
                            label="Accounting Job"
                            rules={{
                                required: 'Accounting job is required',
                                minLength: {
                                    value: 1,
                                    message: 'Must be at least 1 characters'
                                },
                                maxLength: {
                                    value: 100,
                                    message: 'Must be 100 or less characters'
                                }
                            }}
                            required
                        />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <TextInput
                            name="entityaccountingphase"
                            label="Accounting Phase"
                            rules={{
                                required: 'Accounting phase is required',
                                minLength: {
                                    value: 1,
                                    message: 'Must be at least 1 characters'
                                },
                                maxLength: {
                                    value: 100,
                                    message: 'Must be 100 or less characters'
                                }
                            }}
                            required
                        />
                        <SelectInput
                            name="entityspectrum"
                            label="Spectrum"
                            options={[
                                { label: 'Yes', value: true },
                                { label: 'No', value: false }
                            ]}
                            required
                        />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <TextInput
                            name="entityspectrumcompanycode"
                            label="Spectrum Company Code"
                            rules={{
                                required: 'Company code is required',
                                minLength: {
                                    value: 1,
                                    message: 'Must be at least 1 characters'
                                },
                                maxLength: {
                                    value: 3,
                                    message: 'Must be 3 or less characters'
                                }
                            }}
                            required
                        />
                        <SelectInput
                            name="entityactive"
                            label="Status"
                            options={[
                                { label: 'Active', value: true },
                                { label: 'Inactive', value: false }
                            ]}
                            required
                        />
                    </div>
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
