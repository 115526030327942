import React, {useEffect, useMemo, useState} from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'
import HookForm from '../../../components/common/HookForm'
import styles from './Divisions.module.css'
import SelectInput from "../../../components/common/inputs/SelectInput";
import AutocompleteSelect  from "../../../components/common/inputs/AutoCompleteSelectInput/AutocompleteSelect";
import ChipsList from "../../../components/common/inputs/ChipsList";
import {useUpdateDivisions} from "../../../queries/useDivisions";
import DateInput from "../../../components/common/inputs/DateInput";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';


interface FormData {
    canedit: boolean
    divisionid: number
    divisionwoid: number
    divisionstatusid: number
    divisionentityid: number
    divisionentity: string
    divisionstatus: string
    divisionjpid: number
    divisionworktypeids: []
    divisionpricingmethodid: number
    divisionpricingmethod: string
    divisionaddeddate: string
    divisionactive: boolean
    notestypeid: number
    noteslinkid: number
    notestitle: string
    divisioncustomercodeid: number
    divisioncustomerinfo: string,
    divisioncustomercode: string,
    divisioncontractid: number
    divisionprojectmanagername: string
    divisionprojectmanagercntid: number
    divisioncustomerfeid: number
    divisioncustomerpmid: number
    divisioncustomerceid: number
    divisionworkcompletedate: string
    divisionqaqccompletedate: string
    divisionapproveddate: string
    divisionasbuilduploaddate: string
    divisionsubmittedcustomerdate: string
    divisionpaperworkuploaddate: string
    divisioncustomercomment1: string
    divisioncustomercomment2: string
}

export const DivisionsForm = ({ isOpen, setIsOpen, gridParams, reloadForm }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit Division - " + gridParams?.notestitle : "Create Division"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateDivisions = useUpdateDivisions()
    const [workTypes, setWorkTypes] = useState<{ value: any; label: string }[]>([])
    const [commentText1, setCommentText1] = React.useState("")
    const [commentText2, setCommentText2] = React.useState("")
    const divisionsDropDownData = gridParams.divisionsDropDownData
    useEffect(() => {
        var selectedWT = []
        gridParams?.divisionworktypeids?.forEach(wtId => {
            const toAdd = divisionsDropDownData?.worktypelist?.filter((wtObject) =>  wtObject.worktypeid === wtId )
            if (toAdd && toAdd.length > 0) {
                selectedWT.push({value: toAdd[0].worktypeid, label: toAdd[0].worktypecode})
            }
        })
        setWorkTypes(selectedWT)  
        reset({
            canedit: (gridParams ? gridParams.canedit : true),
            divisionid: (gridParams ? gridParams.divisionid : null),
            divisionwoid: (gridParams ? gridParams.divisionwoid : null),
            divisionentityid: (gridParams ? gridParams.divisionentityid : null),
            divisionentity: (gridParams ? gridParams.divisionentity : ""),
            divisionstatus: (gridParams ? gridParams.divisionstatus : ""),
            divisionstatusid: (gridParams ? gridParams.divisionstatusid : null),
            divisionjpid: (gridParams ? gridParams.divisionjpid : ""),
            divisionworktypeids: (gridParams ? gridParams.divisionworktypeids : []),
            divisionpricingmethodid: (gridParams ? gridParams.divisionpricingmethodid : null),
            divisionpricingmethod: (gridParams ? gridParams.divisionpricingmethod : ""),
            divisionaddeddate: (gridParams ? checkDate(gridParams.divisionaddeddate) : ""),
            divisionactive: (gridParams ? gridParams.divisionactive : true),
            notestypeid: (gridParams ? gridParams.notestypeid : 0),
            noteslinkid: (gridParams ? gridParams.noteslinkid : 0),
            notestitle: (gridParams ? gridParams.notestitle : ""),
            divisioncustomercodeid: (gridParams ? gridParams.divisioncustomercodeid : null),
            divisioncustomerinfo: (gridParams ? gridParams.divisioncustomerinfo : ""),
            divisioncustomercode: (gridParams ? gridParams.divisioncustomercode : ""),
            divisioncontractid: (gridParams ? gridParams.divisioncontractid : null),
            divisionprojectmanagername: (gridParams ? gridParams.divisionprojectmanagername : ""),
            divisionprojectmanagercntid: (gridParams ? gridParams.divisionprojectmanagercntid : null),
            divisioncustomerfeid: (gridParams ? gridParams.divisioncustomerfeid : null),
            divisioncustomerpmid: (gridParams ? gridParams.divisioncustomerpmid : null),
            divisioncustomerceid: (gridParams ? gridParams.divisioncustomerceid : null),
            divisionworkcompletedate: (gridParams ? checkDate(gridParams.divisionworkcompletedate) : null),
            divisionqaqccompletedate: (gridParams ? checkDate(gridParams.divisionqaqccompletedate) : null),
            divisionapproveddate: (gridParams ? checkDate(gridParams.divisionapproveddate) : null),
            divisionasbuilduploaddate: (gridParams ? checkDate(gridParams.divisionasbuilduploaddate) : null),
            divisionsubmittedcustomerdate: (gridParams ? checkDate(gridParams.divisionsubmittedcustomerdate) : null),
            divisionpaperworkuploaddate: (gridParams ? checkDate(gridParams.divisionpaperworkuploaddate) : null),
            divisioncustomercomment1: (gridParams ? gridParams.divisioncustomercomment1 : null),
            divisioncustomercomment2: (gridParams ? gridParams.divisioncustomercomment2 : null),
        })
        setCommentText1(gridParams.divisioncustomercomment1)
        setCommentText2(gridParams.divisioncustomercomment2)
    }, [reloadForm])

    const checkDate = (dateString: string) => {
       return (dateString && dateString.length > 0 ? dateString : null)
    }

    const onSubmit = async (data: FormData) => {
        const workTypesToSave = workTypes.map(wt => {
            return wt.value
        })
        const statusString = divisionsDropDownData?.statuslist?.filter((obj) =>  obj.statusid === data.divisionstatusid )

        console.log(data)
        var finalData = {
            divisionid: data.divisionid,
            divisionwoid: data.divisionwoid,
            divisionentityid: (data.divisionentityid > 0 ? data.divisionentityid : null),
            divisionentity: data.divisionentity,
            divisionstatusid: (data.divisionstatusid > 0 ? data.divisionstatusid : null),
            divisionstatus: (statusString ? statusString[0].statuscode : ''),
            divisionjpid: (data.divisionjpid > 0 ? data.divisionjpid : null),
            divisionworktypeids: workTypesToSave,
            divisionpricingmethodid: (data.divisionpricingmethodid > 0 ? data.divisionpricingmethodid : null),
            divisionpricingmethod: data.divisionpricingmethod,
            divisionaddeddate: data.divisionaddeddate,
            divisionactive: data.divisionactive,
            divisioncustomercodeid: (data.divisioncustomercodeid > 0 ? data.divisioncustomercodeid : null),
            divisioncontractid: (data.divisioncontractid > 0 ? data.divisioncontractid : null),
            divisionprojectmanagername: (data.divisionprojectmanagername ? data.divisionprojectmanagername : ""),
            divisionprojectmanagercntid: (data.divisionprojectmanagercntid > 0 ? data.divisionprojectmanagercntid : null),
            divisioncustomerfeid: (data.divisioncustomerfeid > 0 ? data.divisioncustomerfeid : null),
            divisioncustomerpmid: (data.divisioncustomerpmid > 0 ? data.divisioncustomerpmid : null),
            divisioncustomerceid: (data.divisioncustomerceid > 0 ? data.divisioncustomerceid : null),
            divisionworkcompletedate: (data.divisionworkcompletedate ? data.divisionworkcompletedate : null),
            divisionqaqccompletedate: (data.divisionqaqccompletedate ? data.divisionqaqccompletedate : null),
            divisionapproveddate: (data.divisionapproveddate ? data.divisionapproveddate : null),
            divisionasbuilduploaddate: (data.divisionasbuilduploaddate ? data.divisionasbuilduploaddate : null),
            divisionsubmittedcustomerdate: (data.divisionsubmittedcustomerdate ? data.divisionsubmittedcustomerdate : null),
            divisionpaperworkuploaddate: (data.divisionpaperworkuploaddate ? data.divisionpaperworkuploaddate : null),
            divisioncustomercomment1: commentText1,
            divisioncustomercomment2: commentText2,
        }
        const { data: response } = await updateDivisions.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }

    const closeDialog = () => {
        setDialogMsg("")
    }


    const GetDivisionCustomerCodeOptions = () => {
        return divisionsDropDownData?.custlist?.filter(filterItem => filterItem.custactive === true).map(item => {
            return { label: item.custinfo, value: item.custid }
        })
    }

    const GetDivisionContactListOptions = () => {
        if (divisionsDropDownData && divisionsDropDownData?.contactlist) {
            return divisionsDropDownData?.contactlist?.map(item => {
                return { label: item.contactname, value: item.contactid }
            })
        }
        else {
            return [{ label: "", value: null }]
        }
    }

    const getstatusListOptions = () => {
        return divisionsDropDownData?.statuslist?.map(item => {
            return { label: item.statuscode, value: item.statusid }
        })
    }

    const getWorkTypesOptions = useMemo(
        () =>
            divisionsDropDownData?.worktypelist?.map((item) => {
                return { label: item.worktypecode, value: item.worktypeid }
            }),
        [divisionsDropDownData?.worktypelist]
    )

    const getPricingMethodListOptions = () => {
        return divisionsDropDownData?.pricingmethodlist?.map(item => {
            return { label: item.pricingmethodcode, value: item.pricingmethodid }
        })
    }

    const getContractListOptions = () => {
        return divisionsDropDownData?.contractlist?.map(item => {
            return { label: item.contractname, value: item.contractid }
        })
    }

    const getJobPhaseListOptions = useMemo(
        () =>
            divisionsDropDownData?.jobphaselist?.map((item) => {
                // return { label: item.jpname, value: item.jpid }
                return { label: item.jpname, value: item.jpid }
            }),
        [divisionsDropDownData?.jobphaseList]
    )
    const getJobPhaseListOptionsNonMemo = () => {
      
          return divisionsDropDownData?.jobphaselist?.map((item) => {
              return { label: item.jpname, value: item.jpid }
          }
          )
        }
    // const getJobPhaseListOptions = () => {
    //     return divisionsDropDownData?.jobphaseList?.map(item => {
    //         return { label: item.jpid, value: item.jpname }
    //     })
    // }

    const getWorkType = (worktypeid) => {
        var strType = ''
        worktypeid.forEach((typeID) => {
            const value = divisionsDropDownData?.worktypelist?.filter((e) => e.worktypeid === typeID)

            if (value && value.length > 0) {
                if (strType.length > 0) {
                    strType = strType + ', '
                }
                strType = strType + value[0].worktypecode
            }
        })

        return strType
    }

    const onCommentChange1 = (e) => {
        setCommentText1(e.target.value)
    }

    const onCommentChange2 = (e) => {
        setCommentText2(e.target.value)
    }
    const getOpObj = option => {
      if (!option.value) option = getJobPhaseListOptions.find(op => op.value === option);
      return option;
    };
    return (
      <Dialog open={isOpen} maxWidth="xl" className={styles.dialog}>
        <DialogTitle>{title}</DialogTitle>
        {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
        <>
          <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Ok</Button>
            </DialogActions>
          </Dialog>
        </>
        <DialogContent>
          <HookForm
            methods={formMethods}
            onSubmit={onSubmit}
            className={styles.itemsForm}
            formProps={{ id: 'items-form' }}>
            <div className={styles.multipleInputContainer}>
              <SelectInput
                name="divisionstatusid"
                label="Status"
                options={getstatusListOptions()}
                required
              />
              <AutocompleteSelect
                name="divisioncustomercodeid"
                label="Customer Code"
                options={GetDivisionCustomerCodeOptions()}
                required
              />
            </div>
            <div className={styles.multipleInputContainer}>
            <AutocompleteSelect 
            name='divisionjpid' 
            label='Job Phase' 
            options={getJobPhaseListOptionsNonMemo()} 
            required
            />
            {/* <Controller
            control={formMethods.control}
            name="divisionjpid"
            rules={{ required: true }}
            render={({ field }) => (
                <Autocomplete
                {...field}
                options={getJobPhaseListOptions}
                getOptionLabel={option => getOpObj(option).label}
                onChange={(_, obj) => field.onChange(getOpObj(obj).value)}
                renderInput={(params) => (
                  <TextField {...params} label={'Job Phase'} 
                  required/>
                )}
              />
            )}
          /> */}
              
              {/* <SelectInput
                name="divisionjpid"
                label="Job Phase"
                options={getJobPhaseListOptions}
                required
              /> */}
              <ChipsList
                list={workTypes}
                setList={setWorkTypes}
                options={getWorkTypesOptions}
                label="Enter a Work Type"
              />
            </div>
            <div className={styles.multipleInputContainer}>
              <AutocompleteSelect
                name="divisioncustomerfeid"
                label="Customer FE"
                options={GetDivisionContactListOptions()}
              />
              <div className={styles.multipleInputContainer}>
                <AutocompleteSelect
                  name="divisioncustomerpmid"
                  label="Customer PM"
                  options={GetDivisionContactListOptions()}
                />
                <AutocompleteSelect
                  name="divisioncustomerceid"
                  label="Customer CE"
                  options={GetDivisionContactListOptions()}
                />
              </div>
            </div>
            <div className={styles.multipleInputContainer}>
                <DateInput
                  name="divisionworkcompletedate"
                  label="Work Completed"
                  required={false}
                />
                <AutocompleteSelect
                    name="divisioncontractid"
                    label="Contract"
                    options={getContractListOptions()}
                    required
                />
            </div>
            {/*<div className={styles.multipleInputContainer}>*/}
              {/*<div className={styles.multipleInputContainer}>*/}


                {/*<DateInput*/}
                {/*  name="divisionasbuilduploaddate"*/}
                {/*  label="Built Upload"*/}
                {/*  required={false}*/}
                {/*/>*/}
              {/*</div>*/}
              {/*<div className={styles.multipleInputContainer}>*/}
              {/*  <DateInput*/}
              {/*    name="divisionsubmittedcustomerdate"*/}
              {/*    label="Submitted Customer"*/}
              {/*    required={false}*/}
              {/*  />*/}
              {/*  <DateInput*/}
              {/*    name="divisionqaqccompletedate"*/}
              {/*    label="QAQC Completed"*/}
              {/*    required={false}*/}
              {/*  />*/}
              {/*</div>*/}
            {/*</div>*/}
            {/*<div className={styles.multipleInputContainer}>*/}
              {/*<div className={styles.multipleInputContainer}>*/}
              {/*  <DateInput*/}
              {/*    name="divisionpaperworkuploaddate"*/}
              {/*    label="Paper work upload"*/}
              {/*    required={false}*/}
              {/*  />*/}
              {/*  <SelectInput*/}
              {/*    name="divisionpricingmethodid"*/}
              {/*    label="Pricing Method"*/}
              {/*    options={getPricingMethodListOptions()}*/}
              {/*  />*/}
              {/*</div>*/}


              {/*<SelectInput*/}
              {/*    name="divisionactive"*/}
              {/*    label="Division Status"*/}
              {/*    options={[*/}
              {/*        { label: 'Active', value: true },*/}
              {/*        { label: 'Inactive', value: false }*/}
              {/*    ]}*/}
              {/*    required*/}
              {/*/>*/}
              {/*</div>*/}
            {/*</div>*/}
            <div className={styles.multipleInputContainer}>
              <TextField
                id="outlined-multiline-static"
                label="Customer Comment 1"
                multiline
                rows={4}
                defaultValue={commentText1}
                fullWidth={true}
                disabled={false}
                onChange={onCommentChange1}
                style={{ paddingBottom: '20px' }}
              />
              <TextField
                id="outlined-multiline-static"
                label="Customer Comment 2"
                multiline
                rows={4}
                defaultValue={commentText2}
                fullWidth={true}
                disabled={false}
                onChange={onCommentChange2}
                style={{ paddingBottom: '20px' }}
              />
            </div>
          </HookForm>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={styles.submitButton}
            type="submit"
            form="items-form">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
}
