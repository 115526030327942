import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import TextInput from '../../../components/common/inputs/TextInput'
import MaskedInput from '../../../components/common/inputs/MaskedInput'
import SelectInput from '../../../components/common/inputs/SelectInput'
import styles from './WALiastingPage.module.css'
import {useUpdateWAListing} from '../../../queries/useWAListing'

interface FormData {
    walid: number
    walcustomercodeid: string
    walnumber: string
    waldescription: string
    walamountlastupdated: string
    waladdeddate: string
    walactive: boolean
    walamount: number
}

export const WAListingForm = ({ isOpen, setIsOpen, gridParams, reloadForm, customerCodes }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit Listings" : "Create Listing"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateListing = useUpdateWAListing()
    const [amount, setAmount] = useState<number>(0)

    useEffect(() => {
        reset({
            walid: (gridParams ? gridParams.walid : 0),
            walcustomercodeid: (gridParams ? gridParams.walcustomercodeid : ''),
            walnumber: (gridParams ? gridParams.walnumber : 0),
            waldescription: (gridParams ? gridParams.waldescription : ''),
            walamountlastupdated: (gridParams ? gridParams.walamountlastupdated : ''),
            waladdeddate: (gridParams ? gridParams.waladdeddate : ''),
            walactive: (gridParams ? gridParams.walactive : true),
            walamount: (gridParams ? gridParams.walamount : 0)
        })
    }, [reloadForm])

    const handleAmount = (event) => {
        setAmount(event.floatValue)
    }

    const onSubmit = async (data: FormData) => {
        var finalData = {
            walcustomercodeid: data.walcustomercodeid,
            walnumber: data.walnumber,
            waldescription: data.waldescription,
            walamountlastupdated: data.walamountlastupdated,
            waladdeddate: data.waladdeddate,
            walactive: data.walactive
        }
        if (data.walid > 0) {
            finalData['walid'] = data.walid
        }
        if (!gridParams) {
            finalData['walamount'] = amount
        }
        const { data: response } = await updateListing.mutateAsync( finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        console.log(parsedResponse)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }

    const getcustomerCodeOptions = () => {
        return customerCodes.map(item => {
                return { label: item.custcode, value: item.custid }
            })

    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const currencyFormat = (num) => {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return (
        <Dialog open={isOpen}
                fullWidth={true}
                maxWidth="xl"
                className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>
                    <div className={styles.multipleInputContainer}>
                        <SelectInput
                            name="walcustomercodeid"
                            label="Customer Code"
                            options={getcustomerCodeOptions()}
                            required
                        />
                        <TextInput name="walnumber" label="WA No." required />
                    </div>
                    <TextInput
                        name="waldescription"
                        label="Description"
                        rules={{
                            required: 'Password field is required',
                            minLength: {
                                value: 1,
                                message: 'Must be at least 1 characters long'
                            },
                            maxLength: {
                                value: 500,
                                message: 'Must be less than 500 characters long'
                            }
                        }}
                        required
                    />
                    { !gridParams && (
                        <MaskedInput
                        name="walamount"
                        label="Amount"
                        defaultValue="0"
                        onValueChange={handleAmount}
                        />
                    )}

                    <SelectInput
                        name="walactive"
                        label="Status"
                        options={[
                            { label: 'Active', value: true },
                            { label: 'Inactive', value: false }
                        ]}
                        required
                    />
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
