export const getConfig = () => {
  console.log(process.env)
  console.log(window.location.hostname)

  if (window.location.hostname === 'localhost' || process.env.REACT_APP_ENV === 'local') {
    console.log('Setting up for Local')
    return {
      apiUrl: 'https://testapi.alvahgroup.com',
      version: 'Dispatch 1.0.2',
    }
  }
  else if (window.location.hostname === 'betapwr.alvahgroup.com') {
    console.log('Setting up for betapwr')
    return {
      apiUrl: 'https://testapi.alvahgroup.com',
      version: 'Dispatch 1.0.3',
      PSPDFKitLicenseKey: "JDHobwLuRmzoZVJ8AgS6t6oHPVU-_hYlsn9X3PyBsCjsfUzdguT96S_ILbgTy9OYt94a8guzT0Ar7pHK4GV9DK3qAU9m3CyiCK3QKQzKi0oDPqj5t2sNbvOlNc7QwbBQKn3vr0EUNBz9s1p3z_woi_pe1ZyiPfdN537BsgRG1ukLmmBC05Bx1Urxcen7g9awexQSvLcBgV0S5jOzIsLB_d1RpLwn98fBHW4GAgBFJJWEb3GMPEHsZKVtBFSuNwWOd-pPdn5kDHcJ_are9jhslavGo1-fEAlhH34bv3xYe0MtLnm_C03ncrPG3AjAHkTCxLD8ftfj9-zcYFpn8FXkwLANETQ96NIZnK4bFPvJN1DeAvXbzctGtIyxXYypMlcADnn9j14qeXKCcXtBHI0JydiNAbyFEh5dWi5V6BZDoNfGpszJLSwNc75PDqwCkf4pElbrEEZgj2c6iSXG2ev-VljzKxLq3vnxE99JbgGXEhLvQ1C7GYKtOgxX3XIJ2F7LqcPkM-RgR__T9JczbnU_1AEXA2qLbZQ-j3pXV5lT4MQ4pAfSqNMoY0VdaIJuOGtuXAZ2Xvf1NPAY6Adpf12FQg==\"},{\"bundle_id\":\"wo.alvahgroup.com\",\"license_key\":\"WbSg7azu0k_1dv8Dc1tK9bn9OHZt5ozisw1fFqg2uHCe5lQ0S1HFl4HV_OKbJVsrPQJr6GpTTClrZ563dchZTyWf4HBpmhGOUduptzPe9eIh30qtQoJ_faYM1sW6NndffeqsXW3U62j1m0HDuFUN5OuQzZXqkSD7b05FSMmo6pRBUWoT1At5XGNG2l_ii9jPPcsDQMaKuqdUO7HM1rBb1yAQzf6Hoic8SWaX8XwQHVUBzfXM4wtN4UKZiE-_Bi--LnknaAAhbz_eQJs2YwEY8OekatH2k2IjHW1tP6JXvzMNnj9W4BQr6r__aGlaxF6V0-vtu3WzMuYAFzLwzBC2nTqFZORY3-dbfy1YS5rl8PixgB7DXArmo8OWZhFfIioNh_obdwpt7Z8UOXmwyZPkplhMYY2Mp01aGm1nqSkGb5tQRq_NNuuDmDha3-8DvorFkfSyUdn2LPaP8uBFOFUxoGDkrR0zQJV5lHCUzb9LKIN2jM0y2Y_B29eJZLUTmLbDXTcqY1HO8qCgx1HN4fQJMwNEGzalp97oQVr0OgkPsZo=",
    }
  }
  else if (window.location.hostname === 'testwo.alvahgroup.com' || process.env.REACT_APP_ENV === 'dev') {
    console.log('Setting up for Dev')
    return {
      apiUrl: 'https://testapi.alvahgroup.com',
      version: 'Dispatch 1.0.2',
      // PSPDFKitLicenseKey: "JDHobwLuRmzoZVJ8AgS6t6oHPVU-_hYlsn9X3PyBsCjsfUzdguT96S_ILbgTy9OYt94a8guzT0Ar7pHK4GV9DK3qAU9m3CyiCK3QKQzKi0oDPqj5t2sNbvOlNc7QwbBQKn3vr0EUNBz9s1p3z_woi_pe1ZyiPfdN537BsgRG1ukLmmBC05Bx1Urxcen7g9awexQSvLcBgV0S5jOzIsLB_d1RpLwn98fBHW4GAgBFJJWEb3GMPEHsZKVtBFSuNwWOd-pPdn5kDHcJ_are9jhslavGo1-fEAlhH34bv3xYe0MtLnm_C03ncrPG3AjAHkTCxLD8ftfj9-zcYFpn8FXkwLANETQ96NIZnK4bFPvJN1DeAvXbzctGtIyxXYypMlcADnn9j14qeXKCcXtBHI0JydiNAbyFEh5dWi5V6BZDoNfGpszJLSwNc75PDqwCkf4pElbrEEZgj2c6iSXG2ev-VljzKxLq3vnxE99JbgGXEhLvQ1C7GYKtOgxX3XIJ2F7LqcPkM-RgR__T9JczbnU_1AEXA2qLbZQ-j3pXV5lT4MQ4pAfSqNMoY0VdaIJuOGtuXAZ2Xvf1NPAY6Adpf12FQg==\"},{\"bundle_id\":\"wo.alvahgroup.com\",\"license_key\":\"WbSg7azu0k_1dv8Dc1tK9bn9OHZt5ozisw1fFqg2uHCe5lQ0S1HFl4HV_OKbJVsrPQJr6GpTTClrZ563dchZTyWf4HBpmhGOUduptzPe9eIh30qtQoJ_faYM1sW6NndffeqsXW3U62j1m0HDuFUN5OuQzZXqkSD7b05FSMmo6pRBUWoT1At5XGNG2l_ii9jPPcsDQMaKuqdUO7HM1rBb1yAQzf6Hoic8SWaX8XwQHVUBzfXM4wtN4UKZiE-_Bi--LnknaAAhbz_eQJs2YwEY8OekatH2k2IjHW1tP6JXvzMNnj9W4BQr6r__aGlaxF6V0-vtu3WzMuYAFzLwzBC2nTqFZORY3-dbfy1YS5rl8PixgB7DXArmo8OWZhFfIioNh_obdwpt7Z8UOXmwyZPkplhMYY2Mp01aGm1nqSkGb5tQRq_NNuuDmDha3-8DvorFkfSyUdn2LPaP8uBFOFUxoGDkrR0zQJV5lHCUzb9LKIN2jM0y2Y_B29eJZLUTmLbDXTcqY1HO8qCgx1HN4fQJMwNEGzalp97oQVr0OgkPsZo=",
    }
  }
  else if (window.location.hostname === 'wo.alvahgroup.com' || process.env.REACT_APP_ENV === 'live') {
    console.log('Setting up for Live')
    return {
      apiUrl: 'https://api.alvahgroup.com',
      version: 'Dispatch 1.0.2',
      PSPDFKitLicenseKey: "w8lZ_agbgeqPnJWFPcl89njW3QWYD7yyfWiPomfWopRrSnkUd1RRw10e9W5GucbvDtu3w3h0RduCVOG6DvrrhOUQP9Ji4_IK9jqF68VjfyzEqklh3o-vdrprF2ROGezG_iPdRwXq5N7DDe5UkrrsFmXUSifbinDMKzedRFlKdiyIlrpZcwCh0j3ZxYph8cVGF30ZnmigvygztNSR-RiCgT7N9vqr1enHFdanxuhpb7Lw5LSO2_-NVfC0tnT5Mtg62kk9hPIdHzsqi1l_kkdljXNWmKgIQ5bR_LJDXk9gG0LXyiHyJujYydsp4bDYyjOxl4rQCZk5a_sEcUCfMNSH4WLOMOUcM2cI8Il9_HKju4jcusL9JFsiVdyHnkKxLEVv4njHwl3Lx5AwBGW-cuS_GB5fFdo0KR75N4c0nwJSO7FeiIviOAN9dfwiBeP2n-6qF5lBAHZQyTUUqo2vPUwUWlS5RDJsHNq6kHZzDyc8DNfAsC59Mc9alEJTP0cl0m5nyF7pCty8sYEP61QkFQ6lB9jaCx7cRe9Vz9RElBf2w_4APmpaBE570F8VVYv28ieq",
    }
  }
  else {
    console.log('Setting up for testing')
    return {
      apiUrl: 'https://testapi.alvahgroup.com',
      version: 'Dispatch 1.0.3',
      PSPDFKitLicenseKey: "JDHobwLuRmzoZVJ8AgS6t6oHPVU-_hYlsn9X3PyBsCjsfUzdguT96S_ILbgTy9OYt94a8guzT0Ar7pHK4GV9DK3qAU9m3CyiCK3QKQzKi0oDPqj5t2sNbvOlNc7QwbBQKn3vr0EUNBz9s1p3z_woi_pe1ZyiPfdN537BsgRG1ukLmmBC05Bx1Urxcen7g9awexQSvLcBgV0S5jOzIsLB_d1RpLwn98fBHW4GAgBFJJWEb3GMPEHsZKVtBFSuNwWOd-pPdn5kDHcJ_are9jhslavGo1-fEAlhH34bv3xYe0MtLnm_C03ncrPG3AjAHkTCxLD8ftfj9-zcYFpn8FXkwLANETQ96NIZnK4bFPvJN1DeAvXbzctGtIyxXYypMlcADnn9j14qeXKCcXtBHI0JydiNAbyFEh5dWi5V6BZDoNfGpszJLSwNc75PDqwCkf4pElbrEEZgj2c6iSXG2ev-VljzKxLq3vnxE99JbgGXEhLvQ1C7GYKtOgxX3XIJ2F7LqcPkM-RgR__T9JczbnU_1AEXA2qLbZQ-j3pXV5lT4MQ4pAfSqNMoY0VdaIJuOGtuXAZ2Xvf1NPAY6Adpf12FQg==\"},{\"bundle_id\":\"wo.alvahgroup.com\",\"license_key\":\"WbSg7azu0k_1dv8Dc1tK9bn9OHZt5ozisw1fFqg2uHCe5lQ0S1HFl4HV_OKbJVsrPQJr6GpTTClrZ563dchZTyWf4HBpmhGOUduptzPe9eIh30qtQoJ_faYM1sW6NndffeqsXW3U62j1m0HDuFUN5OuQzZXqkSD7b05FSMmo6pRBUWoT1At5XGNG2l_ii9jPPcsDQMaKuqdUO7HM1rBb1yAQzf6Hoic8SWaX8XwQHVUBzfXM4wtN4UKZiE-_Bi--LnknaAAhbz_eQJs2YwEY8OekatH2k2IjHW1tP6JXvzMNnj9W4BQr6r__aGlaxF6V0-vtu3WzMuYAFzLwzBC2nTqFZORY3-dbfy1YS5rl8PixgB7DXArmo8OWZhFfIioNh_obdwpt7Z8UOXmwyZPkplhMYY2Mp01aGm1nqSkGb5tQRq_NNuuDmDha3-8DvorFkfSyUdn2LPaP8uBFOFUxoGDkrR0zQJV5lHCUzb9LKIN2jM0y2Y_B29eJZLUTmLbDXTcqY1HO8qCgx1HN4fQJMwNEGzalp97oQVr0OgkPsZo=",
    }
  }
}
