import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  ProcessDataObject {
    status: string
    message: string
    canadd: boolean
    canaddschedule: 0
    dispatchlist: DispatchData[]
}

export interface  DispatchData {
    readonly: boolean
    canedit: boolean
    candelete: boolean
    dispatchid: number
    dispatchwoid: number
    dispatchdivision: string
    dispatchschedulenumber: number
    dispatchcustomerinfo: string
    dispatchcontractinfo: string
    dispatchjobinfo: string
    dispatchsentdate: string
    dispatchSentTo: string
    dispatchsentuser: string
    dispatchstatus: string
    notestypeid: number
    notestitle: string
    noteslinkid: number
    notescount: number
    lastwipposted: string
    lasttimeposted: string
    dispatchsubmitteddate: string
    dispatchsubmittedemployee: string
}

export const DispatchDataDefaults = {
    readonly: true,
    canedit: true,
    candelete: true,
    dispatchid: 0,
    dispatchwoid: 0,
    dispatchdivision: "",
    dispatchschedulenumber: 0,
    dispatchcustomerinfo: "",
    dispatchcontractinfo: "",
    dispatchjobinfo: "",
    dispatchsentdate: "",
    dispatchSentTo: "",
    dispatchsentuser: "",
    dispatchstatus: "",
    notestypeid: 0,
    notestitle: "",
    noteslinkid: 0,
    notescount: 0,
    lastwipposted: '',
    lasttimeposted: '',
    dispatchsubmitteddate: '',
    dispatchsubmittedemployee: ''
}

export const useFetchEntityProcess = (entityID: number) => {
    return useQuery<ProcessDataObject>(
        ['entityprocesslist', entityID],
        () => globalProcessListFetcher(entityID),
        {
            staleTime: 12000,
            keepPreviousData: false
        }
    )
}

export const useUpdateProcess = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`entityprocesslist`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['entityprocesslist'])
        }
    })
}

export const useDeleteProcess = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`entityprocesslist`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['entityprocesslist'])
        }
    })
}

export const globalProcessListFetcher = async (entityID: number) => {
    const { data } = await axios.post('/entityprocesslist', {
        entityid: entityID
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as ProcessDataObject
}