import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import styles from './Users.module.css'
import TextInput from "../../../components/common/inputs/TextInput";
import SelectInput from "../../../components/common/inputs/SelectInput";
import {useUpdateUsers} from "../../../queries/useUsersMaint";
import {POListings} from "../../../queries/usePOListings";
import { emailRegex } from '../../../util/formValidation'

interface FormData {
    usersid: number
    usersemail: string
    userspassword: string
    usersactive: boolean
}

export const UsersForm = ({ isOpen, setIsOpen, gridParams, reloadForm }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit User" : "Create User"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateUsers = useUpdateUsers()


    useEffect(() => {
        reset({
            usersid: (gridParams ? gridParams.usersid : 0),
            usersemail: (gridParams ? gridParams.usersemail : ''),
            userspassword: (gridParams ? '' : ''),
            usersactive: (gridParams ? gridParams.usersactive : true)
        })
    }, [reloadForm])

    const onSubmit = async (data: FormData) => {
        var finalData = {
            usersemail: data.usersemail,
            usersactive: data.usersactive
        }
        if (gridParams === null) {
            finalData['userspassword'] = data.userspassword
        }
        if (data.usersid > 0) {
            finalData['usersid'] = data.usersid
        }
        const { data: response } = await updateUsers.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    return (
        <Dialog open={isOpen} maxWidth="xl" className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>
                    <TextInput
                        name="usersemail"
                        label="Email"
                        rules={{
                            required: 'Email is required',
                            minLength: {
                                value: 1,
                                message: 'Must be at least 1 characters'
                            },
                            maxLength: {
                                value: 200,
                                message: 'Must be 200 or less characters'
                            },
                                pattern: {
                                  value: emailRegex,
                                  message: 'Enter a valid email'
                                }
                             
                        }}
                        required
                    />
                    {gridParams === null &&
                        <TextInput
                            name="userspassword"
                            label="Password"
                            rules={{
                                required: 'Password is required',
                                minLength: {
                                    value: 1,
                                    message: 'Must be at least 1 characters'
                                },
                                maxLength: {
                                    value: 200,
                                    message: 'Must be 200 or less characters'
                                }
                            }}
                            textFieldProps={{ type: 'password' }}
                            required
                        />
                    }

                    <SelectInput
                        name="usersactive"
                        label="Status"
                        options={[
                            { label: 'Active', value: true },
                            { label: 'Inactive', value: false }
                        ]}
                        required
                    />
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
