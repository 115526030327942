import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  DependenciesDataObject {
    status: string
    message: string
    canadd: boolean
    canaddentity: 0
    canadddirectonly: false
    dependencylist: DependenciesData[]
}

export interface  DependenciesData {
    readonly: boolean
    canedit: boolean
    candelete: boolean
    dependencyid: number
    woid: number
    dependencydivisionid: number
    dependencydivision: string
    dependencyscheduleid: number
    dependencytypeid: number
    dependencytype: number
    dependencycategoryid: number
    dependencycategory: string
    dependencyaction: string
    dependencydateactiontaken: string
    dependencydatecleartowork: string
    dependencydateexpiration: string
    dependencyreferenceinformation: string
    dependencypermanentlycleared: boolean
    dependencyaddeduser: string
    dependencyaddeduserkey: string
    dependencyaddeddate: string
    dependencyupdateduser: string
    dependencyupdateduserkey: string
    dependencyupdateddate: string
    dependencyactive: boolean
    notestypeid: number
    notestitle: string
    noteslinkid: number
    notescount: number
}

export const DependenciesDataDefaults = {
    readonly: true,
    canedit: true,
    candelete: true,
    dependencyid: 0,
    woid: 0,
    dependencydivisionid: 0,
    dependencydivision: "",
    dependencyscheduleid: 0,
    dependencytypeid: 0,
    dependencytype: 0,
    dependencycategoryid: 0,
    dependencycategory: "",
    dependencyaction: "",
    dependencydateactiontaken: "",
    dependencydatecleartowork: "",
    dependencydateexpiration: "",
    dependencyreferenceinformation: "",
    dependencypermanentlycleared: true,
    dependencyaddeduser: "",
    dependencyaddeduserkey: "",
    dependencyaddeddate: "",
    dependencyupdateduser: "",
    dependencyupdateduserkey: "",
    dependencyupdateddate: "",
    dependencyactive: true,
    notestypeid: 0,
    notestitle: "",
    noteslinkid: 0,
    notescount: 0
}

export const useFetchDependencies = (workOrderId: number) => {
    return useQuery<DependenciesDataObject>(
        ['dependenciesdata'],
        () => globalFetcher(workOrderId),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdateDependencies = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`dependency`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['dependenciesdata'])
        }
    })
}

export const useDeleteDependencies = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`dependency`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['dependenciesdata'])
        }
    })
}

export const globalFetcher = async (workOrderId: number) => {
    const { data } = await axios.post('/dependency', {
        woid: workOrderId
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as DependenciesDataObject
}
