import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  NotesData {
    notesid?: number
    notesnote?: string
    notessubject?: string
    notestypeid: number
    notestype?: string
    noteslinkid: number
    notesaddeduser?: string
    notesaddeddate?: string
    notesupdateduser?: string
    notesupdatedate?: string
    notesactive?: boolean
    notesaddeduserid?: number
    notesupdateduserid?: number
    notestitle?: string
}


export const NotesDataDefaults = {
    "notesid": 0,
    "notesnote": "",
    "notessubject": "",
    "notestypeid": 0,
    "notestype": "",
    "noteslinkid": 0,
    "notesaddeduser": "",
    "notesaddeddate": "",
    "notesupdateduser": "",
    "notesupdatedate": "",
    "notesactive": true,
    "notesaddeduserid": 0,
    "notesupdateduserid": 0,
    "notestitle" : "",
}


export const useFetchNotes = (notestypeid: number, noteslinkid: number) => {
    return useQuery<NotesData[]>(
        ['notesdata', notestypeid, noteslinkid],
        () => globalFetcher(notestypeid, noteslinkid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}
export const useFetchAllNotesForWO = (woid: number) => {
    return useQuery<NotesData[]>(
        ['allnotes', woid],
        () => globalFetcher2(woid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}
export const useUpdateNotes = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`notes`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['notesdata'])
        }
    })
}

export const useDeleteNotes = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`notes`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['notesdata'])
        }
    })
}

export const globalFetcher = async (notestypeid: number, noteslinkid: number) => {
    const { data } = await axios.post('/notes', {
        notestypeid: notestypeid,
        noteslinkid: noteslinkid
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.noteslist as NotesData[]
}
export const globalFetcher2 = async (woid: number) => {
    const { data } = await axios.post('/notesbywo', {
        woid: woid
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.noteslist as NotesData[]
}