import React, {useEffect, useMemo, useState} from "react";
import styles from './Schedules.module.css'
import {
    useFetchEntitySchedules,
    useDeleteSchedules,
    SchedulesData,
} from "../../../queries/useSchedules";
import {GridSelectionModel, GridSortItem, GridValueFormatterParams} from "@material-ui/x-grid";
import {useQueryClient} from 'react-query'
import Button, { ButtonProps } from '@mui/material/Button';
import {
    DataGridPro,
    GridCallbackDetails,
    GridCellParams,
    GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
    GridRowParams, gridSortedRowIdsSelector,
    GridSortModel, GridToolbarContainer, GridToolbarExport,
    MuiEvent,
    useGridApiContext,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import {useUpdateGridState} from "../../../queries/useGridState";
import {dispatchsend} from "../../../queries/useSendDispatch";
import {
    Alert,
    Grid,
} from "@mui/material";
import {NotesIcon} from "../../../components/common/NotesLayout/NotesLayout";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Typography
} from "@material-ui/core";
import {Delete, PhoneIphone, Edit, SettingsBackupRestore} from "@material-ui/icons";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {Add, Save} from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import dayjs from "dayjs";
import {SchedulesForm} from "../../WorkOrderPage/Schedules/Schedules.Form";
import {globalSchedulesFetcher} from "../../../queries/useScheduleDropdowns";
import {useDeleteDependencies} from "../../../queries/useDependencies";


const SchedulesPage = (params) => {
    let _sortModelState: any = null
    const [gridShowing, setGridShowing] = useState(false)
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObjDel, setDialogObjDel] = React.useState({msg: "", id: 0})
    const [dialogObj, setDialogObj] = React.useState({msg: "", gridState: "", okButtonText: "Save"})
    const [dialogMsg, setDialogMsg] = React.useState("")
    const [snackbarOpen, setSnackbarOpen] = React.useState({ open: false, message: '' })
    const [dataRows, setDataRows] = React.useState([]);
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([1]);
    const gridAPIRef = useGridApiRef()
    const updateGridState = useUpdateGridState()
    const [scheduleDropdowns, setScheduleDropdowns] = React.useState({});
    const deleteSchedules = useDeleteSchedules()
    const [gridParams, setGridParams] = useState({
        id: null,
        readonly: null,
        canedit: null,
        scheduleid: null,
        schedulenumber: null,
        scheduledivisionid: null,
        scheduledivision: null,
        scheduletemplateid: null,
        scheduletemplate: null,
        scheduledivisionwoid: null,
        schedulestatusid: null,
        schedulestatus: null,
        schedulestartdate: null,
        scheduleenddate: null,
        scheduleworklocation: null,
        schedulegeneralforemanid: null,
        schedulegeneralforeman: null,
        scheduleforemanid: null,
        scheduleforeman: null,
        scheduleaddeddate: null,
        scheduleactive: null,
        notestypeid: null,
        notestitle: null,
        noteslinkid: null,
        notescount: null,
        scheduledivisioncustomerinfo: null,
        scheduledivisionwocustomerworkorder: null,
        schedulelastdatedispatchsent: null,
        schedulecandispatch: null
    })

    const gridSortItem: GridSortItem = {
        field: 'schedulestartdate',
        sort: 'asc'
    }

    const queryClient = useQueryClient()
    React.useEffect(() => {
        queryClient.invalidateQueries(['schedule'])
    }, [params.entityId])

    const defaultGridSortModel: GridSortModel = [gridSortItem]
    const [gridSortModel, setGridSortModel] = React.useState<GridSortModel>(defaultGridSortModel)


    const {data} = useFetchEntitySchedules('schedule', params.entityId, null)
    useEffect(() => {
        setGridSortModel(getColumnSortModel())
        if (data?.schedulelist && data?.schedulelist?.length > 0) {
            const dataRows = getMappedRecords()
            setDataRows(dataRows)
        }
        else {
            setDataRows([])
        }
    }, [data])

    const canDispatch = (params: GridCellParams) => {
        return params.row.schedulecandispatch
    }
    const getMappedRecords = () => {
        return data?.schedulelist.map((schedulesData: SchedulesData, i) => {
            return {
                id: schedulesData.scheduleid,
                readonly: schedulesData.readonly,
                canedit: schedulesData.canedit,
                candelete: schedulesData.candelete,
                scheduleid: schedulesData.scheduleid,
                schedulenumber: schedulesData.schedulenumber,
                scheduledivisionid: schedulesData.scheduledivisionid,
                scheduledivision: schedulesData.scheduledivision,
                scheduletemplateid: schedulesData.scheduletemplateid,
                scheduletemplate: schedulesData.scheduletemplate,
                scheduledivisionwoid: schedulesData.scheduledivisionwoid,
                schedulestatusid: schedulesData.schedulestatusid,
                schedulestatus: schedulesData.schedulestatus,
                schedulestartdate: schedulesData.schedulestartdate,
                scheduleenddate: schedulesData.scheduleenddate,
                scheduleworklocation: schedulesData.scheduleworklocation,
                schedulegeneralforemanid: schedulesData.schedulegeneralforemanid,
                schedulegeneralforeman: schedulesData.schedulegeneralforeman,
                scheduleforemanid: schedulesData.scheduleforemanid,
                scheduleforeman: schedulesData.scheduleforeman,
                scheduleaddeddate: schedulesData.scheduleaddeddate,
                scheduleactive: schedulesData.scheduleactive,
                notestypeid: schedulesData.notestypeid,
                notestitle: schedulesData.notestitle,
                noteslinkid: schedulesData.noteslinkid,
                notescount: schedulesData.notescount,
                scheduledivisioncustomerinfo:schedulesData.scheduledivisioncustomerinfo,
                scheduledivisionwocustomerworkorder:schedulesData.scheduledivisionwocustomerworkorder,
                schedulelastdatedispatchsent: schedulesData.schedulelastdatedispatchsent,
                schedulecandispatch: schedulesData.schedulecandispatch,
                scheduledispatchsent : schedulesData.scheduledispatchsent
            }
        }).filter((schedData) =>
            schedData.scheduleactive === true
        )
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen({ open: false, message: ""  });
    }

    const basicProps = {
        disableColumnMenu: true,
        sortable: false,
        disableExport: true,
        disableReorder: true,
        editable: false,
        filterable: false,
        groupable: false,
        hideable: false,
        pinnable: false
    }

    const HandleEdit = async (params: GridCellParams) => {

        const scheduleData = data?.schedulelist.filter((item) => item.scheduleid === Number(params.id))[0]
        const scheduleDropdowns = await globalSchedulesFetcher(scheduleData?.scheduledivisionwoid)
        if (scheduleDropdowns) {
            setScheduleDropdowns(scheduleDropdowns)
        }

        // console.log(scheduleData)
        setGridParams({
            id: scheduleData.scheduleid,
            readonly: scheduleData.readonly,
            canedit: scheduleData.canedit,
            scheduleid: scheduleData.scheduleid,
            schedulenumber: scheduleData.schedulenumber,
            scheduledivisionid: scheduleData.scheduledivisionid,
            scheduledivision: scheduleData.scheduledivision,
            scheduletemplateid: scheduleData.scheduletemplateid,
            scheduletemplate: scheduleData.scheduletemplate,
            scheduledivisionwoid: scheduleData.scheduledivisionwoid,
            schedulestatusid: scheduleData.schedulestatusid,
            schedulestatus: scheduleData.schedulestatus,
            schedulestartdate: scheduleData.schedulestartdate,
            scheduleenddate: scheduleData.scheduleenddate,
            scheduleworklocation: scheduleData.scheduleworklocation,
            schedulegeneralforemanid: scheduleData.schedulegeneralforemanid,
            schedulegeneralforeman: scheduleData.schedulegeneralforeman,
            scheduleforemanid: scheduleData.scheduleforemanid,
            scheduleforeman: scheduleData.scheduleforeman,
            scheduleaddeddate: scheduleData.scheduleaddeddate,
            scheduleactive: scheduleData.scheduleactive,
            notestypeid: scheduleData.notestypeid,
            notestitle: scheduleData.notestitle,
            noteslinkid: scheduleData.noteslinkid,
            notescount: scheduleData.notescount,
            scheduledivisioncustomerinfo: scheduleData.scheduledivisioncustomerinfo,
            scheduledivisionwocustomerworkorder: scheduleData.scheduledivisionwocustomerworkorder,
            schedulelastdatedispatchsent: scheduleData.schedulelastdatedispatchsent,
            schedulecandispatch: scheduleData.schedulecandispatch
        })
        setReloadForm(reloadForm + 1)
        setIsFormShowing(true)
    }

    const HandleDispatchSend = (params: GridCellParams) => {
       sendDispatch(params.id)
      
    }

    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const canEdit = (params: GridCellParams) => {
        return params.row.canedit
    }

    const buildColumnDefs = () => {
        const gridObj = parseGridObject()
        const parsedGridColumns = gridObj.gridDefs
        var gridColumns: GridColDef[] = [
            gridEditSaveButtonDef,
            gridIdDef,
            gridSendDispatchButtonDef,
            ...parsedGridColumns,
            gridButtonsDef
        ]
        return gridColumns
    }

    const gridButtonsDef: GridColDef = {
        field: 'Delete',
        headerName: '',
        width: 80,
        align: "center",
        headerAlign: "center",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <>
                    <NotesIcon
                        id={Number(params.id)}
                        notesid={params.row.notesid}
                        notestitle={params.row.notestitle}
                        notestypeid={params.row.notestypeid}
                        noteslinkid={params.row.noteslinkid}
                        notescount={params.row.notescount}
                        queryType={['schedeulePage']}
                    />
                    <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                        <Delete />
                    </IconButton>
                </>
            )
        }
    }

    const handleDelete = (params: GridCellParams) => {
        console.log(params.row)
        const title = 'Are you sure you want to delete \"' + params.row.notestitle + "\"?"
        setDialogObjDel({
            msg: title,
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        console.log(dialogObjDel.id)
        const { data: response } = await deleteSchedules.mutateAsync({ scheduleid: dialogObjDel.id })
        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        closeDialog()
    }

    const getColumnSortModel = () => {
        const gridObj = parseGridObject()
        return gridObj.sortModel
    }

    const formatDate = (params: GridValueFormatterParams) => {
        return (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DD").format('MM/DD/YYYY') : '')
    }

    const parseGridObject = () => {
        var sortModel: GridSortModel = [gridSortItem]
        var gridStateString = JSON.stringify({gridType: "schedule", gridState: JSON.stringify(gridDefaults)})
        if (params.gridStateData && params.gridStateData.length > 0) {
            gridStateString = params.gridStateData[0].gridState
            const gridStateData = JSON.parse(gridStateString)
            const sendObj = {
                gridType: 'schedule',
                gridState: JSON.stringify(gridStateData.columnDefs)
            }
            gridStateString = JSON.stringify(sendObj)

            if (gridStateData && gridStateData.sortModel !== null && gridStateData.sortModel.length > 0) {
                // gridAPIRef?.current?.setSortModel(gridStateData.sortModel)
                const dataSort = gridStateData.sortModel
                sortModel = dataSort
            }
        }

        var gridDefs: GridColDef[] = [];
        if (gridStateString.length > 0) {
            const gridObject = JSON.parse(gridStateString)
            if (gridObject.gridType === 'schedule') {
                const gridState = JSON.parse(gridObject?.gridState)
                gridState?.forEach(state => {
                    var col: GridColDef = {...state}

                    if (col.field === 'schedulestartdate' || col.field === 'scheduleenddate' ||
                        col.field === 'scheduleaddeddate' || col.field === 'schedulelastdatedispatchsent') {
                        col.valueFormatter = formatDate
                    }

                    gridDefs.push(col)
                })
            }
        }
        return {gridDefs: gridDefs, sortModel: sortModel}
    }

    const gridDefaults: GridColDef[] = [
        {
            "field": "scheduledivisionwoid",
            "headerName": "WO#",
            "headerAlign": "left",
            "type": "string",
            "width": 100,
            "align": "left"
        },
        {
            "field": "schedulenumber",
            "headerName": "Sched #",
            "headerAlign": "left",
            "type": "string",
            "width": 100,
            "align": "left"
        },
        {
            "field": "scheduledivision",
            "headerName": "Division",
            "headerAlign": "left",
            "type": "string",
            "width": 100,
            "align": "left"
        },
        {
            "field": "scheduledivisioncustomerinfo",
            "headerName": "Cust#/Name",
            "headerAlign": "left",
            "type": "string",
            "width": 200,
            "align": "left"
        },
        {
            "field": "scheduledivisionwocustomerworkorder",
            "headerName": "Customer WO#",
            "headerAlign": "left",
            "type": "string",
            "width": 120,
            "align": "left"
        },
        {
            "field": "scheduleworklocation",
            "headerName": "Work Location",
            "headerAlign": "left",
            "type": "string",
            "width": 200,
            "align": "left"
        },
        {
            "field": "schedulelastdatedispatchsent",
            "headerName": "Dispatch Last Sent",
            "headerAlign": "left",
            "type": "string",
            "width": 120,
            "align": "left"
        },
        {
            "field": "schedulestartdate",
            "headerName": "Schedule Date",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "field": "scheduletemplate",
            "headerName": "Schedule Type",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "field": "schedulestatus",
            "headerName": "Status",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "field": "schedulegeneralforeman",
            "headerName": "General Foreman",
            "headerAlign": "left",
            "type": "string",
            "width": 200,
            "align": "left"
        },
        {
            "field": "scheduleforeman",
            "headerName": "Foreman",
            "headerAlign": "left",
            "type": "string",
            "width": 200,
            "align": "left"
        },        
        {
            "field": "scheduledispatchsent",
            "headerName": "Dispatch Sent",
            "headerAlign": "left",
            "type": "string",
            "width": 100,
            "align": "left"
        },
    ]

    const gridEditSaveButtonDef: GridColDef = {
        field: '',
        renderHeader: () => {
            return (
                <>
                    {/*<IconButton*/}
                    {/*    className={styles.submitButton}*/}
                    {/*    onClick={() => handleAdd()}*/}
                    {/*    size="small">*/}
                    {/*    <Add />*/}
                    {/*</IconButton>*/}
                </>
            )
        },
        width: 50,
        align: "center",
        headerAlign: "center",
        pinnable: true,
        cellClassName: 'pinnedColumn',
        ...basicProps,
        renderCell: (params) => (
            <>
                {(canEdit(params)) &&
                <IconButton className={styles.button} onClick={() => {
                    HandleEdit(params)
                }}>
                    <Edit/>
                </IconButton>
                }
            </>
        )
    }
    const gridSendDispatchButtonDef: GridColDef = {
        field: '.',
        width: 50,
        align: "center",
        headerAlign: "center",
        pinnable: true,
        cellClassName: 'sendDispatch',
        ...basicProps,
        renderCell: (params) => (
            <>
             {(canDispatch(params)) &&
                     <IconButton className={styles.button} onClick={() => {
                        HandleDispatchSend(params)
                    }}>
                        <PhoneIphone/>
                    </IconButton>
                    }
               
              
            </>
        )
    }

    const gridIdDef: GridColDef = {
        field: 'id',
        hide: true,
        ...basicProps
    }

    const getColumnStringToSave = () => {
        var fieldObjects = []
        const gridState = gridAPIRef.current.getAllColumns()
        gridState.forEach(stateColumnDef => {
            if (stateColumnDef.sortable && stateColumnDef.pinnable && stateColumnDef.groupable) {
                fieldObjects.push({
                    field: stateColumnDef.field,
                    headerName: stateColumnDef.headerName,
                    headerAlign: stateColumnDef.headerAlign,
                    type: stateColumnDef.type,
                    width: stateColumnDef.width,
                    align: stateColumnDef.align,
                    description: stateColumnDef.description,
                    hide: stateColumnDef.hide,
                    valueFormatter: stateColumnDef.valueFormatter
                })
            }
        })

        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: fieldObjects, sortModel: sortModelDefs})
        return strObject
    }

    const handleRestore = () => {
        setGridShowing(false)
        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: gridDefaults, sortModel: sortModelDefs})
        setDialogObj({
            msg: 'Are you sure you want to reset the grid state to default?',
            gridState: strObject,
            okButtonText: 'RESET'
        })
    }

    const sendDispatch = async  (scheduleID) => {
        const response = await dispatchsend(scheduleID)
        setSnackbarOpen( { open: true, message: response.message })
    }

    const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
        gridSortedRowIdsSelector(apiRef);

    function CustomGridToolbar() {
        const apiRef = useGridApiContext();

        const handleExport = (options: GridCsvExportOptions) =>
            apiRef.current.exportDataAsCsv(options);

        const buttonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <FileDownloadOutlinedIcon />,
        };

        const saveButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <Save />,
        };

        const restoreButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <SettingsBackupRestore />,
        };

        return (
            <GridToolbarContainer >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        {...saveButtonBaseProps}
                        onClick={() => handleSave()}
                    >
                        SAVE GRID SETTINGS
                    </Button>
                    <Button
                        {...restoreButtonBaseProps}
                        onClick={() => handleRestore()}
                    >
                        RESTORE GRID SETTINGS
                    </Button>
                    <Button
                        {...buttonBaseProps}
                        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
                    >
                        EXPORT
                    </Button>
                    {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                </Grid>
            </GridToolbarContainer>
        )
    }

    const handleSave = () => {
        setGridShowing(false)
        const columnObjsting = getColumnStringToSave()
        setDialogObj({
            msg: 'Are you sure you want to save the current grid state?',
            gridState: columnObjsting,
            okButtonText: 'Save'
        })
    }

    const handleOkConfirm = async () => {
        var finalData = {
            gridType: params.gridStateVersion,
            gridState: dialogObj.gridState
        }
        const {data: response} = await updateGridState.mutateAsync(finalData)
        closeDialog()
        setGridShowing(true)
        setSnackbarOpen({ open: true, message: "Schedule settings have been saved" })
    }

    const closeDialog = () => {
        setGridShowing(true)
        setDialogObj({
            msg: '',
            gridState: '',
            okButtonText: ''
        })
        setDialogObjDel({
            msg: '',
            id: 0
        })
        setDialogMsg("")
    }

    const handleRowClick = (param: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        // const woObjectList = data.workorderlist.filter((workOrder) => { return workOrder.woid === Number(param.id)})
        // if (woObjectList && woObjectList.length > 0) {
        //     params.setWorkOrderObj(woObjectList[0])
        // }
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>

            {data && data.status === 'failure' ?
                <Typography
                    variant="h6"
                    color="primary"
                    align="center"
                    component="div">
                    {data.message}
                </Typography>
                :
                <>

                    <>
                        <SchedulesForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} scheduleDropdowns={scheduleDropdowns} reloadForm={reloadForm} isClone={false} woid={gridParams?.scheduledivisionwoid}/>
                    </>
                    <>
                        <Snackbar
                            open={snackbarOpen.open}
                            autoHideDuration={4000}
                            onClose={handleClose}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        >
                            <Alert severity="success">
                                {snackbarOpen.message}
                            </Alert>
                        </Snackbar>
                    </>
                    <>
                        <Dialog
                            open={dialogObj.msg.length > 0}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {dialogObj.msg}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDialog}>Cancel</Button>
                                <Button onClick={handleOkConfirm}>{dialogObj.okButtonText}</Button>
                            </DialogActions>
                        </Dialog>
                    </>

                    <>
                        <Dialog
                            open={dialogObjDel.msg.length > 0}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth={true}
                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {dialogObjDel.msg}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDialog}>Cancel</Button>
                                <Button onClick={handleDeleteConfirm}>DELETE</Button>
                            </DialogActions>
                        </Dialog>
                    </>

                    <Grid container spacing={1}>
                        {/*<Grid item xs={12} style={{marginLeft: '15px', marginTop: '15px'}}>*/}
                        {/*    <Grid container spacing={1} >*/}
                        {/*        <Box style={{ marginLeft: '10px' }} sx={{ flexGrow: 1 }}>*/}
                        {/*            <FormGroup>*/}
                        {/*            </FormGroup>*/}
                        {/*        </Box>*/}
                        {/*        <Box sx={{ flexGrow: 20 }}>*/}
                        {/*            <FormGroup>*/}
                        {/*            </FormGroup>*/}
                        {/*        </Box>*/}

                        {/*        <Box style={{marginRight: '10px'}}>*/}                {/*<Grid item xs={12} style={{marginLeft: '15px', marginTop: '15px'}}>*/}
                        {/*    <Grid container spacing={1} >*/}
                        {/*        <Box style={{ marginLeft: '10px' }} sx={{ flexGrow: 1 }}>*/}
                        {/*            <FormGroup>*/}
                        {/*            </FormGroup>*/}
                        {/*        </Box>*/}
                        {/*        <Box sx={{ flexGrow: 20 }}>*/}
                        {/*            <FormGroup>*/}
                        {/*            </FormGroup>*/}
                        {/*        </Box>*/}

                        {/*        <Box style={{marginRight: '10px'}}>*/}
                        {/*            <IconButton*/}
                        {/*                className={styles.saveButton}*/}
                        {/*                onClick={() => handleSave()}*/}
                        {/*                style={{marginRight: '10px'}}*/}
                        {/*                size="small">*/}
                        {/*                <Save/>*/}
                        {/*            </IconButton>*/}
                        {/*            <IconButton*/}
                        {/*                className={styles.saveButton}*/}
                        {/*                onClick={() => handleRestore()}*/}
                        {/*                style={{marginRight: '10px'}}*/}
                        {/*                size="small">*/}
                        {/*                <SettingsBackupRestore/>*/}
                        {/*            </IconButton>*/}
                        {/*        </Box>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>
                        {/*            <IconButton*/}
                        {/*                className={styles.saveButton}*/}
                        {/*                onClick={() => handleSave()}*/}
                        {/*                style={{marginRight: '10px'}}*/}
                        {/*                size="small">*/}
                        {/*                <Save/>*/}
                        {/*            </IconButton>*/}
                        {/*            <IconButton*/}
                        {/*                className={styles.saveButton}*/}
                        {/*                onClick={() => handleRestore()}*/}
                        {/*                style={{marginRight: '10px'}}*/}
                        {/*                size="small">*/}
                        {/*                <SettingsBackupRestore/>*/}
                        {/*            </IconButton>*/}
                        {/*        </Box>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}

                        <Grid item xs={12}>
                            <DataGridPro
                                sx={{
                                    "& .MuiDataGrid-pinnedColumns": {
                                        boxShadow: "none",
                                        backgroundColor: "transparent"
                                    },
                                    "& .MuiDataGrid-pinnedColumnHeaders": {
                                        boxShadow: "none",
                                        backgroundColor: "transparent"
                                    }
                                }}
                                apiRef={gridAPIRef}
                                rows={dataRows}
                                columns={buildColumnDefs()}
                                autoHeight={true}
                                density={'compact'}
                                pageSize={10}
                                sortModel={gridSortModel}
                                disableMultipleSelection={true}
                                onSortModelChange={(model: GridSortModel) => setGridSortModel(model)}
                                onStateChange={(state) => {
                                    _sortModelState = state.sorting.sortModel

                                    return state
                                }}
                                initialState={{
                                    pinnedColumns: {left: ['Edit'], right: ['Notes', 'Delete']},
                                    sorting: {
                                        sortModel: gridSortModel
                                    }
                                }}
                                onRowClick={handleRowClick}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                selectionModel={selectionModel}
                                components={{
                                    Toolbar: CustomGridToolbar,
                                }}
                            />
                        </Grid>
                    </Grid>
                </>
            }
        </div>
    );
}
export default SchedulesPage
