import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  WAListing {
    walid: number
    walwoid: number
    walnumber: string
    waldescription: string
    walamountlastupdated: string
    waladdeddate: string
    walactive: boolean
    walcurrentapproved: number
    walcurrentpending: number
}

export const WAListingDefaults = {
    walid: 0,
    walwoid: 0,
    walnumber: '',
    waldescription: '',
    walamountlastupdated: '',
    waladdeddate: '',
    walactive: true,
    walcurrentapproved: 0,
    walcurrentpending: 0
}

export const useFetchWAListing = () => {
    return useQuery<WAListing[]>(
        ['walisting'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            placeholderData: [WAListingDefaults],
            keepPreviousData: false
        }
    )
}

export const useUpdateWAListing = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`walisting`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['walisting'])
        }
    })
}

export const useDeleteWAListing = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`walisting`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['walisting'])
        }
    })
}

export const globalFetcher = async () => {
    const { data } = await axios.get('/walisting')
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.walist as WAListing[]
}
