import React, {useEffect, useMemo, useRef, useState} from "react";
import styles from './Calendar2.module.css'
import {
    useFetchEntitySchedules,
    useFetchCalendarSchedules,
    SchedulesData
} from "../../../queries/useSchedules";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import './Calendar2-override.css'
import moment from 'moment'
import {
    useFetchEntityScheduleDropdowns
} from "../../../queries/useEntityScheduleDropdowns";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton, Typography, withStyles
} from "@material-ui/core";
import axios from 'axios'

import {Grid, Popover} from "@mui/material";
import {
    Alert,
    FormControl,
    FormGroup,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import {SchedulesForm} from "../../WorkOrderPage/Schedules/Schedules.Form";
import {globalSchedulesFetcher} from "../../../queries/useScheduleDropdowns";
import dayjs from "dayjs";
import ReactDOM from "react-dom";
const localizer = momentLocalizer(moment)

const Calendar2Page = (params) => {
    const [gridShowing, setGridShowing] = useState(false)
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState({
        id: null,
        readonly: null,
        canedit: null,
        scheduleid: null,
        schedulenumber: null,
        scheduledivisionid: null,
        scheduledivision: null,
        scheduletemplateid: null,
        scheduletemplate: null,
        scheduledivisionwoid: null,
        schedulestatusid: null,
        schedulestatus: null,
        schedulestartdate: null,
        scheduleenddate: null,
        scheduleworklocation: null,
        schedulegeneralforemanid: null,
        schedulegeneralforeman: null,
        scheduleforemanid: null,
        scheduleforeman: null,
        scheduleaddeddate: null,
        scheduleactive: null,
        notestypeid: null,
        notestitle: null,
        noteslinkid: null,
        notescount: null,
        scheduledivisioncustomerinfo: null,
        scheduledivisionwocustomerworkorder: null,
        schedulelastdatedispatchsent: null,
        schedulecandispatch: null,
        schedulecalendarcolor: null,
    })
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [scheduleDropdowns, setScheduleDropdowns] = React.useState({});

    const [staffList, setStaffList] = useState([])
    const [custList, setCustList] = useState([])
    const [statusList, setStatusList] = useState([])

    const [activeStatus, setActiveStatus] = React.useState<string>( '');
    const [activeStatusId, setActiveStatusId] = React.useState<number>( 0);
    const [activeCust, setActiveCust] = React.useState<string>( '');
    const [activeCustId, setActiveCustId] = React.useState<number>( 0);
    const [selectedDate, setSelectedDate] = React.useState<Date>( new Date());
    const [showemergencybutton, setshowemergencybutton] = React.useState<boolean>(false);
    const [staffSelect, setStaffSelect] = React.useState<string[]>([]);
    const [staffSelectId, setStaffSelectId] = React.useState<number[]>([]);
    const [mobile, setMobile] = useState(window.innerWidth <= 500);
    const [dialogMsg, setDialogMsg] = React.useState("")

    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= 500);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    const {data: entityScheduleDropdowns} = useFetchEntityScheduleDropdowns(params.entityId)
    useEffect(() => {
        var statusid = 0
        var custid = 0
        var staffid
        if (entityScheduleDropdowns?.statuslist && entityScheduleDropdowns?.statuslist?.length > 0) {
            statusid = -1
            // setActiveStatusId(entityScheduleDropdowns.statuslist[0].schedulestatusid)
            // setActiveStatus(entityScheduleDropdowns.statuslist[0].schedulestatus)
            setActiveStatusId(-1)
            setActiveStatus('All Status')
            setStatusList(entityScheduleDropdowns?.statuslist)
        }
        else {
            setActiveStatusId(null)
            setActiveStatus(null)
            setStatusList([])
        }
        if (entityScheduleDropdowns?.stafflist && entityScheduleDropdowns?.stafflist?.length > 0) {
            // staffid = [entityScheduleDropdowns?.stafflist[0].staffid]
            staffid = []
            // setStaffSelect([entityScheduleDropdowns?.stafflist[0].staffinfo])
            // setStaffSelectId([entityScheduleDropdowns?.stafflist[0].staffid])
            setStaffSelect([])
            setStaffSelectId([])
            setStaffList(entityScheduleDropdowns?.stafflist)
        }
        else {
            setStaffSelect([])
            setStaffSelectId([])
            setStaffList([])
        }
        if (entityScheduleDropdowns?.custlist && entityScheduleDropdowns?.custlist?.length > 0) {
            custid = -1
            // setActiveCustId(entityScheduleDropdowns.custlist[0].custid)
            // setActiveCust(entityScheduleDropdowns.custlist[0].custinfo)
            setActiveCustId(-1)
            setActiveCust("All Customers")
            setCustList(entityScheduleDropdowns?.custlist)
        }
        else {
            setActiveCustId(null)
            setActiveCust(null)
            setCustList([])
        }
        filterRowsToSelection(statusid, custid, staffid)
    }, [entityScheduleDropdowns])


    const [eventData, setEventData] = React.useState([]);
    const [legendList, setLegendList] = React.useState([]);
    const [eventFilteredData, setEventFilteredData] = React.useState([]);

    var queryKey = 'schedule'
    console.log(params)
    if (params.calendarGuid) {
        queryKey = 'calendar'
    }

    const {data} = useFetchEntitySchedules(queryKey, params.entityId, params.calendarGuid)
    useEffect(() => {
        console.log(data?.schedulelist)
        if (data?.legendlist && data?.legendlist?.length > 0) {
            setLegendList(data.legendlist)
        }
        if(data && data?.schedulelist && data?.schedulelist.length > 0) {
            if(data?.schedulelist[0].showemergencybutton && params.calendarGuid){
                setshowemergencybutton(true)
            }
            else {
                setshowemergencybutton(false)
            }
            if (data?.employee) {
                params.setTitle(data?.employee)
            }

            setEventRecord()
        }
        else {
            setEventData([])
            setEventFilteredData([])
            if (params.calendarGuid) {
                setshowemergencybutton(true)
            }
            else {
                setshowemergencybutton(false)
            }
        }
    }, [data])

    const closeDialog = () => {
        setDialogMsg("")
    }

    const setEventRecord = () => {

        const edata = data?.schedulelist.map((schedulesData: SchedulesData, i) => {
            // if (schedulesData.schedulestartdate && schedulesData.schedulestartdate.length > 0) {
            console.log(schedulesData.schedulehover)
                return {
                    scheduleid: schedulesData.scheduleid,
                    start: moment(schedulesData.schedulestartdate).startOf('day').toDate(),
                    end: moment(schedulesData.schedulestartdate).startOf('day').toDate(),
                    schedulestatusid: schedulesData.schedulestatusid,
                    schedulestatus: schedulesData.schedulestatus,
                    schedulestaffid: schedulesData.schedulestaffid,
                    schedulestaff: schedulesData.schedulestaff,
                    schedulestartdate: schedulesData.schedulestartdate,
                    scheduleenddate: schedulesData.scheduleenddate,
                    scheduledispatchsubmitted: schedulesData.scheduledispatchsubmitted,
                    scheduledivisioncustomercodeid: schedulesData.scheduledivisioncustomercodeid,
                    scheduledivisioncustomerinfo: schedulesData.scheduledivisioncustomerinfo,
                    title: schedulesData.scheduledescription,
                    scheduledescription: schedulesData.scheduledescription,
                    schedulehover: schedulesData.schedulehover,
                    urllink: schedulesData.urllink,
                    readonly: schedulesData.readonly,
                    schedulecalendarcolor: schedulesData.schedulecalendarcolor
                }
            // }
        })
        setEventData(edata)
        setEventFilteredData(edata)
    }

    const getStatusOptions = () => {
        if (statusList && statusList.length > 0) {
            const statusL = statusList.map(options => {
                return (
                    <MenuItem key={options.schedulestatusid} value={options.schedulestatus}>
                        {options.schedulestatus}
                    </MenuItem>
                )
            })
            return [(
                <MenuItem key={'All Status'} value={'All Status'}>
                    {'All Status'}
                </MenuItem>
            ), ...statusL]
        }
    }

    const getCustOptions = () => {
        if (custList && custList.length > 0) {
            const custL = custList.map(options => {
                return (
                    <MenuItem key={options.custid} value={options.custinfo}>
                        {options.custinfo}
                    </MenuItem>
                )
            })
            return [(
                <MenuItem key={'All Customers'} value={'All Customers'}>
                    {'All Customers'}
                </MenuItem>
            ), ...custL]
        }
    }

    const handleStatusSelectionChange = (event: SelectChangeEvent) => {
        var statusid = -1
        if (event.target.value === 'All Status') {
            setActiveStatusId(statusid)
            setActiveStatus(event.target.value as string);
        }
        else {
            const status = statusList.filter((statusData) => statusData.schedulestatus === event.target.value as string)
            statusid = (status && status.length > 0 ? status[0].schedulestatusid : 0)
            setActiveStatusId(statusid)
            setActiveStatus(event.target.value as string);
        }

        filterRowsToSelection(statusid, activeCustId, staffSelectId)
    }

    const handleCustSelectionChange = (event: SelectChangeEvent) => {
        var custid = -1
        if (event.target.value === 'All Customers') {
            setActiveCustId(custid)
            setActiveCust(event.target.value as string);
        }
        else {
            const cust = custList.filter((custData) => custData.custinfo === event.target.value as string)
            custid = (cust && cust.length > 0 ? cust[0].custid : 0)
            setActiveCustId(custid)
            setActiveCust(event.target.value as string);
        }

        filterRowsToSelection(activeStatusId, custid, staffSelectId)
    }

    const handleStaffChange = (event: SelectChangeEvent<typeof staffSelect>) => {
        const {
            target: { value },
        } = event;

        const lookupStrings = (typeof value === 'string' ? value.split(',') : value)
        const staffids = lookupStrings.map(item => {
            if (statusList && statusList.length > 0) {
                const staffVals = staffList.filter(staffOptions => (staffOptions.staffinfo === item))
                return staffVals[0].staffid
            }
        })

        setStaffSelect(lookupStrings)
        setStaffSelectId(staffids)
        filterRowsToSelection(activeStatusId, activeCustId, staffids)
    }

    const filterRowsToSelection = (statusId: number, custId: number, staffId: number[]) => {
        const filteredData = eventData.filter((data) => (data.schedulestatusid === statusId || statusId === -1) && (data.scheduledivisioncustomercodeid === custId || custId === -1))
        if (staffId && staffId.length > 0) {
            const result = filteredData.filter(filteredFirst => {
                const result2 = filteredFirst.schedulestaffid.filter(ids1 => {
                    let arr = staffId.filter(ids2 => ids1 === ids2)
                    return !(arr.length === 0)
                })

                return result2.length > 0
            })
            setEventFilteredData(result)
        }
        else {
            setEventFilteredData(filteredData)
        }
    }

    const HandleEventClick = async (scheduleId: number) => {

        const scheduleData = data?.schedulelist.filter((item) => item.scheduleid === scheduleId)[0]
        if (scheduleData.readonly) {
            window.open(scheduleData.urllink)
        }
        else {
            const scheduleDropdowns = await globalSchedulesFetcher(scheduleData?.scheduledivisionwoid)
            if (scheduleDropdowns) {
                setScheduleDropdowns(scheduleDropdowns)
            }

            setGridParams({
                id: scheduleData.scheduleid,
                readonly: scheduleData.readonly,
                canedit: scheduleData.canedit,
                scheduleid: scheduleData.scheduleid,
                schedulenumber: scheduleData.schedulenumber,
                scheduledivisionid: scheduleData.scheduledivisionid,
                scheduledivision: scheduleData.scheduledivision,
                scheduletemplateid: scheduleData.scheduletemplateid,
                scheduletemplate: scheduleData.scheduletemplate,
                scheduledivisionwoid: scheduleData.scheduledivisionwoid,
                schedulestatusid: scheduleData.schedulestatusid,
                schedulestatus: scheduleData.schedulestatus,
                schedulestartdate: scheduleData.schedulestartdate,
                scheduleenddate: scheduleData.scheduleenddate,
                scheduleworklocation: scheduleData.scheduleworklocation,
                schedulegeneralforemanid: scheduleData.schedulegeneralforemanid,
                schedulegeneralforeman: scheduleData.schedulegeneralforeman,
                scheduleforemanid: scheduleData.scheduleforemanid,
                scheduleforeman: scheduleData.scheduleforeman,
                scheduleaddeddate: scheduleData.scheduleaddeddate,
                scheduleactive: scheduleData.scheduleactive,
                notestypeid: scheduleData.notestypeid,
                notestitle: scheduleData.notestitle,
                noteslinkid: scheduleData.noteslinkid,
                notescount: scheduleData.notescount,
                scheduledivisioncustomerinfo: scheduleData.scheduledivisioncustomerinfo,
                scheduledivisionwocustomerworkorder: scheduleData.scheduledivisionwocustomerworkorder,
                schedulelastdatedispatchsent: scheduleData.schedulelastdatedispatchsent,
                schedulecandispatch: scheduleData.schedulecandispatch,
                schedulecalendarcolor: scheduleData.schedulecalendarcolor
            })
            setReloadForm(reloadForm + 1)
            setIsFormShowing(true)
        }
    }

    const getCalendarBackgroundColor = (event) => {
        console.log(event)
        if (
            event.schedulestatus.includes('Complete') || event.schedulestatus === 'Canceled'
        )
        {
            return '#808080'
        }
        else if (event.schedulestatus === 'Unscheduled') {
            return 'red'
        }
        else if (
            event.schedulestatus === 'Scheduled' &&
            event.scheduledispatchsubmitted === false &&
            dayjs(event.schedulestartdate).isBefore(dayjs(), 'day')
        )
        {
            return 'yellow'
        }
        else if (
            event.schedulestatus === 'Scheduled' &&
            event.scheduledispatchsubmitted === true &&
            dayjs(event.schedulestartdate).isBefore(dayjs(), 'day')
        )
        {
            return '#fed8b1'
        }
        else if (event.schedulestatus === 'Scheduled' && (dayjs(event.schedulestartdate).isAfter(dayjs(), 'day') || dayjs(event.schedulestartdate).isSame(dayjs(), 'day'))) {
            return 'green'
        }
        else if (event.schedulestatus === 'Scheduled' && event.schedulestaffid?.length === 0 && (dayjs(event.schedulestartdate).isAfter(dayjs(), 'day') || dayjs(event.schedulestartdate).isSame(dayjs(), 'day'))) {
            // Lite Green
            return '#cefad0'
        }
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        var style = {
            background: event.schedulecalendarcolor,
            color: 'black',
            fontWeight: 'bold',
            fontSize: '12px'
        }
        return {
            style: style
        }
    }

    const handleReset = () => {
        setActiveStatusId(-1)
        setActiveStatus('All Status')
        setActiveCustId(-1)
        setActiveCust("All Customers")
        setStaffSelect([])
        setStaffSelectId([])
        filterRowsToSelection(-1, -1, [])
    }

    const sendEmergency = () =>{
        axios.post('/emergency', { guid: params.calendarGuid})
        closeDialog()
    }

    const showEmergencyDlg = () =>{
        setDialogMsg('Warnning - this will create a brand new job in the system. Do you really want to do this?')
    }
    
    const buildLegend = () => {
        const emergencyButtonData = showemergencybutton ? (
            <Grid item>
                <div>
                    <button onClick={showEmergencyDlg} style={{marginBottom: '10px'}}>Emergency/Additional Job</button>
                </div>
            </Grid>
        ) : (<></>)

        var finalData = (
            <></>
        )

        const legendData = legendList?.map((legend) => {
            return (
                <Grid item>
                    <div style={{backgroundColor: legend.color, color: 'black', fontSize: '12px'}}>
                        <Typography
                            variant="body2"
                            align="center"
                            component="div">
                            {legend.description}
                        </Typography>
                    </div>
                </Grid>
            )
        })

        finalData = (
            <>
                <Grid container direction="row"
                      alignItems="center"
                      justifyContent="center" columnSpacing={{ xs: .5, sm: .5, md: .5 }}>
                    {emergencyButtonData}
                </Grid>
                {params?.showLegend &&
                    <Grid container direction="row"
                          alignItems="center"
                          justifyContent="center" columnSpacing={{ xs: .5, sm: .5, md: .5 }}>
                        {legendData}
                    </Grid>
                }
            </>
        )


        return finalData
    }


    function Event(event) {
        const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
        const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handlePopoverClose = () => {
            setAnchorEl(null);
        }
        const open = Boolean(anchorEl)

        return (
            <div>
                <span aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >{event.title}</span>
                    <Popover
                        id="mouse-over-popover"
                        sx={{
                            pointerEvents: 'none',
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <Typography ><div dangerouslySetInnerHTML={{__html:event.event.schedulehover}} /></Typography>
                    </Popover>
            </div>
        );
    }
    
    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>No</Button>
                        <Button onClick={sendEmergency}>Yes</Button>
                    </DialogActions>
                </Dialog>
            </>
            {data && data.status === 'failure' ?
                <Typography
                    variant="h6"
                    color="primary"
                    align="center"
                    component="div">
                    {data.message}
                </Typography>
                :
                <>

                    <>
                        <SchedulesForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams}
                                       scheduleDropdowns={scheduleDropdowns} reloadForm={reloadForm} isClone={false} woid={gridParams?.scheduledivisionwoid}/>
                    </>
                    <Grid container spacing={1}>
                        {params.calendarGuid === null &&
                            <Grid item xs={12} style={{marginLeft: '15px', marginTop: '15px'}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Select
                                        onChange={handleStatusSelectionChange}
                                        style={{width: '320px', height: '30px', marginLeft: '10px'}}
                                        disabled={false}
                                        value={activeStatus}>
                                        {getStatusOptions()}
                                    </Select>
                                    <Select
                                        onChange={handleCustSelectionChange}
                                        style={{width: '320px', height: '30px', marginLeft: '10px'}}
                                        disabled={false}
                                        value={activeCust}>
                                        {getCustOptions()}
                                    </Select>
                                    <FormControl sx={{height: 30, width: 300}}>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            style={{width: '320px', height: '30px', marginLeft: '10px'}}
                                            multiple
                                            value={staffSelect}
                                            onChange={handleStaffChange}
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {staffList?.map((staff) => (
                                                <MenuItem key={staff.staffid} value={staff.staffinfo}>
                                                    <Checkbox checked={staffSelect.indexOf(staff.staffinfo) > -1}/>
                                                    <ListItemText primary={staff.staffinfo}/>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <Button onClick={handleReset}
                                            style={{width: '180px', height: '30px', marginLeft: '40px'}}
                                            variant="contained">Reset Filters</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        }

                        <Grid item xs={12}>
                            {buildLegend()}
                            <Calendar
                                components={{event: Event}}
                                localizer={localizer}
                                events={eventFilteredData}
                                startAccessor="start"
                                endAccessor="end"
                                // defaultDate={new Date()}
                                defaultView="week"
                                views={['month', 'week', 'day']}
                                style={{height: 500}}
                                // onSelectEvent={event => {
                                //     HandleEventClick(event.scheduleid)
                                // }}
                                onDoubleClickEvent={event => {
                                    HandleEventClick(event.scheduleid)
                                }}
                                date={selectedDate}
                                onNavigate={date => {
                                    console.log(date)
                                    setSelectedDate(date);
                                }}
                                eventPropGetter={(eventStyleGetter)}
                            />
                        </Grid>
                    </Grid>
                </>
            }

    </div>
    );
}
export default Calendar2Page
