import React, {useEffect, useState} from "react";
import styles from './Users.module.css'
import {IconButton, Box, AppBar, Typography} from '@material-ui/core'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { Add, Save } from '@mui/icons-material'
import {GridSelectionModel, GridValueFormatterParams, XGrid} from '@material-ui/x-grid'
import {
    DataGridPro,
    GridColDef,
    GridCellParams,
    GridRowParams,
    GridCallbackDetails,
    MuiEvent, useGridApiRef
} from "@mui/x-data-grid-pro";

import {UsersDefault, Users, useDeleteUsers, useFetchUsers} from "../../../queries/useUsersMaint";
import {UsersForm} from "./UsersForm";
import {Grid} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LoadingBackdrop from "../../../components/common/LoadingBackdrop";

const UsersPage = (params) => {
    const gridAPIRef = useGridApiRef()
    const deleteUsers = useDeleteUsers()
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState(UsersDefault)
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const [listingIDSelected, setListingIDSelected] = React.useState<number>(null);
    const [tabSel, setTabSel] = React.useState<number>(0);

    useEffect(() => {
        console.log(gridAPIRef.current.getAllColumns())
    }, [gridAPIRef])

    var userRows = []
    const { data } = useFetchUsers()
    if (data && data.length > 0) {
        userRows = data?.map((user: Users, i) => {
            return {
                id: user.usersid,
                usersemail: user.usersemail,
                userspassword: user.userspassword,
                usersactive: user.usersactive
            }
        })
    }

    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const amountColumns: GridColDef[] = [
        {
            field: 'Edit',
            pinnable: true,
            renderHeader: () => {
                return (
                    <IconButton
                        className={styles.submitButton}
                        onClick={() => handleAdd()}
                        size="small">
                        <Add />
                    </IconButton>
                )
            },
            width: 50,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
                    <Edit />
                </IconButton>
            )
        },
        {
            field: 'id',
            hide: true
        },
        {
            field: 'usersemail',
            type: 'string',
            headerName: 'Email',
            width: 300,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'usersactive',
            type: 'string',
            headerName: 'Status',
            width: 125,
            valueGetter: (params) => {
                return (params.value ? "Active" : "Inactive")
            },
        },
        {
            field: 'action',
            headerName: '',
            width: 50,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                    <Delete />
                </IconButton>
            )
        }
    ]

    const handleEdit = (params: GridCellParams) => {
        const user = data.filter((item) => item.usersid === Number(params.id))[0]
        setReloadForm(reloadForm+1)
        setGridParams({
            usersid: user.usersid,
            usersemail: user.usersemail,
            userspassword: user.userspassword,
            usersactive: user.usersactive
        })
        setIsFormShowing(true)
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObj({
            msg: 'Are you sure you want to delete this user \"' + params.getValue(params.id, 'usersemail').toString() + "\"?",
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteUsers.mutateAsync({ usersid: dialogObj.id })
        closeDialog()
    }

    const closeDialog = () => {
        setDialogObj({
            msg: '',
            id: 0
        })
    }

    const handleListingRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        setListingIDSelected(Number(param.id))
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <Grid container spacing={1}>
                <AppBar position="static" color="default">
                    <Typography
                        variant="h6"
                        color="primary"
                        align="center"
                        component="div">
                        Users
                    </Typography>
                    <Tabs
                        value={tabSel}
                        // onChange={handleListingTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab value={0} label={'Users'}></Tab>
                        <Tab disabled={true} value={1} label={'Permissions'}></Tab>
                    </Tabs>
                    <UsersForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} />
                    {tabSel === 0 && (
                        <DataGridPro
                            apiRef={gridAPIRef}
                            rows={userRows}
                            columns={amountColumns}
                            autoHeight={true}
                            density={'compact'}
                            pageSize={10}
                            loading={!data}
                            disableMultipleSelection={true}
                            pinnedColumns={ { left: ['Edit'] } }
                            // onRowClick={handleRowClick}
                        />
                    )}
                    {tabSel === 1 && data && (
                        <div>Test</div>
                    )}
                </AppBar>
            </Grid>
        </div>
    );
}

export default UsersPage
