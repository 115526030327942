import React, {useEffect, useState} from "react";
import styles from '../Lookup.module.css'
import {
    DataGridPro,
    GridColDef,
    GridCellParams,
    GridRowParams,
    GridCallbackDetails,
    MuiEvent, useGridApiRef
} from "@mui/x-data-grid-pro";
import {useFetchLookupCategory} from "../../../../queries/useLookupCategory";
import Tabs from "@mui/material/Tabs";
import {AppBar} from "@material-ui/core";
import {LookupForm} from "../LookupForm";

const LookupCategoryTable = (params) => {
    var lookupRows = []
    const { data } = useFetchLookupCategory()
    const gridAPIRef = useGridApiRef()

    if (data && data.lookupcategorylist?.length > 0) {
        lookupRows = data.lookupcategorylist?.map((lookupCat, i) => {
            return {
                id: lookupCat.lookupcategoryid,
                lookupcategoryid: lookupCat.lookupcategoryid,
                lookupcategory: lookupCat.lookupcategory,
                lookupcategoryrequireentity: lookupCat.lookupcategoryrequireentity,
                lookupcategoryrequirecustomer: lookupCat.lookupcategoryrequirecustomer,
                lookupcategoryshowentity: lookupCat.lookupcategoryshowentity,
                lookupcategoryshowcustomer: lookupCat.lookupcategoryshowcustomer
            }
        })
    }

    React.useEffect(() => {
        if (params.lookupCategoryObj) {
            setTimeout(() => {
                gridAPIRef.current?.selectRows([params.lookupCategoryObj.lookupcategoryid])
            })
        }
    }, [params.lookupCategoryObj]);

    React.useEffect(() => {
        if (data && data?.lookupcategorylist?.length > 0 && !params.lookupCategoryObj) {
            const catLook = data.lookupcategorylist.filter((listcat) => { return listcat.lookupcategoryid === data.lookupcategorylist[0].lookupcategoryid })
            if (catLook && catLook.length > 0) {
                params.setLookupCategory(catLook[0])
                params.setLookupEntity(data.entitylist)
                params.setLookupCustomer(data.custlist)
            }
        }
    }, [lookupRows]);

    const lookupCategoryColumnDefs: GridColDef[] = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'lookupcategoryid',
            type: 'number',
            headerName: 'Category ID',
            width: 125,
            align: "right",
            headerAlign: "right"
        },
        {
            field: 'lookupcategory',
            type: 'string',
            headerName: 'Category',
            width: 400,
            align: "left",
            headerAlign: "left"
        }
    ]

    const handleRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        const lookupCatObj = data.lookupcategorylist.filter((lookupCategory) => { return lookupCategory.lookupcategoryid === Number(param.id)})

        if (lookupCatObj && lookupCatObj.length > 0) {
            console.log(lookupCatObj[0])
            params.setLookupCategory(lookupCatObj[0])
        }
        // params.setLookupEntity(data.entitylist)
        // params.setLookupCustomer(data.custlist)
    }

    return (
        <div className={styles.root}>
            <DataGridPro
                apiRef={gridAPIRef}
                rows={lookupRows}
                columns={lookupCategoryColumnDefs}
                autoHeight={true}
                density={'compact'}
                pageSize={10}
                loading={!lookupRows}
                disableMultipleSelection={true}
                onRowClick={handleRowClick}
            />
        </div>
    );
}

export default LookupCategoryTable

