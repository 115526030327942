import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {EmployeeData, VehicleData} from "./useDispatch";


export interface  WIPDataObject {
    status: string
    message: string
    canadd: boolean
    canedit: boolean
    candelete: boolean
    itemmessage: string
    wiplist: WIPListData[]
    itemlist: TEItemListData[]
    defaultworkdate: string
}

export interface  TEItemListData {
    unitd: number
    iteminfo: string
    itemcode: string
    itemdescription: string
    itemmeasure: string
    itemreate: number
}

export interface  WIPListData {
    wipid: number,
    entityid: number,
    dispatchid: number,
    woid: string,
    workdate:string,
    unitid: number,
    quantity: number,
    rate: number,
    contractinfo: string,
    iteminfo: string,
    itemcode: string,
    itemdescription: string,
    itemmeasure: string,
    extended: number,
    transferred: string,
    candelete: boolean,
    canedit: boolean,
}

export const WIPDataDefaults = {
    wipid: 0,
    entityid: 0,
    dispatchid: 0,
    woid: "",
    workdate: "",
    unitid: 0,
    quantity: 0,
    rate: 0,
    contractinfo: "",
    iteminfo: "",
    itemcode: "",
    itemdescription: "",
    itemmeasure: "",
    extended: 0,
    transferred: "",
    candelete: true,
    canedit: true,
}

export const useFetchDispatchWIP = (dispatchId: number) => {
    return useQuery<WIPDataObject>(
        ['dispatchwip', dispatchId],
        () => globaDispatchWIP(dispatchId),
        {
            staleTime: 12000,
            keepPreviousData: false
        }
    )
}

export const useUpdateDispatchWIP = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`dispatchwip`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['dispatchwip'])
        }
    })
}

export const useDeleteDispatchWIP = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`dispatchwip`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['dispatchwip'])
        }
    })
}

export const globaDispatchWIP = async (dispatchId: number) => {
    const { data } = await axios.post('/dispatchwip', {
        dispatchid: dispatchId
    })
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    return parsedData as WIPDataObject
}