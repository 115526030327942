import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  Contact {
    contactid: number
    contactname: string
    contactphone: string
    contactemail: string
    contactlocation: string
    contactcompany: string
    contactactive: boolean
}

export const ContactDefaults = {
    contactid: 0,
    contactname: '',
    contactphone: '',
    contactemail: '',
    contactlocation: '',
    contactcompany: '',
    contactactive: true
}

export const useFetchContact =  () => {
    return useQuery<Contact[]>(
        ['contact'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdateContact = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`contact`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['contact'])
        }
    })
}

export const useDeleteContact = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`contact`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['contact'])
        }
    })
}

export const globalFetcher = async () => {
    const { data } = await axios.post('/contact', {})
    const parsedData = JSON.parse(data.jsonString)

    return parsedData.contactlist as Contact[]
}
