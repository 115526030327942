import React, {createRef, useEffect, useMemo, useState} from 'react'
import styles from './DocumentsPage.module.css'
import { Dropbox } from 'dropbox'
import TreeView  from '@mui/lab/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FolderIcon from '@mui/icons-material/Folder';
import ArticleIcon from '@mui/icons-material/Article';
import TreeItem from '@mui/lab/TreeItem'
import {getConfig} from "../../../AppConfig";
import CircularProgress from '@mui/material/CircularProgress';
import PdfViewerComponent from '../../../components/PdfViewerComponent';
import Box from '@mui/material/Box';

import {
    useCopyDocuments,
    useDeleteDocuments,
    useDownloadDocuments,
    useFetchDocuments, useMoveDocuments,
    useUploadDocument
} from "../../../queries/useDocuments";
import Dropzone, {DropzoneRef, useDropzone} from "react-dropzone";
import Alerts from "../../../components/common/Alerts";
import {Grid, Menu, MenuItem} from "@mui/material";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography} from "@material-ui/core";
import axios from "axios";


export const DocumentsPage = ({ workOrderObj, woDropDownData, reloadTab }) => {
    const [treeStructure, setTreeStructure] = React.useState([])
    const [copyTree, setCopyTree] = React.useState(null)
    const [watchLogoData, setWatchLogoData] = React.useState(null)
    const [watchLogoDataURL, setWatchLogoDataURL] = React.useState(null)
    const [moveTree, setMoveTree] = React.useState(false)
    const [dataLoaded, setDataLoaded] = React.useState(false)
    const [openViewer, setOpenViewer] = React.useState(false)
    const [currentSelection, setCurrentSelection] = React.useState<number>(0)
    // const {getRootProps, getInputProps, open} = useDropzone({noClick: true})
    const uploadDocument = useUploadDocument(workOrderObj.woid)
    const deleteDocument = useDeleteDocuments(workOrderObj.woid)
    const downloadDocument = useDownloadDocuments(workOrderObj.woid)
    const copyDocument = useCopyDocuments(workOrderObj.woid)
    const moveDocument = useMoveDocuments(workOrderObj.woid)
    const dropzoneRef = React.createRef<DropzoneRef>()
    const [expanded, setExpanded] = React.useState<string[]>([]);


    const { data } = useFetchDocuments(workOrderObj.woid)
    useEffect(() => {
        if (data) {
            const topTreeItem = createTreeItems(data.contents[0], "")
            console.log(data)
            console.log(topTreeItem)
        }

        if (data && data.contents && data.contents.length > 0 && data.contents[0].length > 0) {
            const topFolder = data.contents[0][0].Name

            setTreeStructure(createTreeItems(data.contents[0], topFolder))
            setDataLoaded(true)
            setExpanded(getContentIds(data.contents[0]))
        }
        else if (data && data.status === 'success') {
            setDataLoaded(true)
        }
        else {
            setDataLoaded(false)
        }
    }, [data, reloadTab])


    const handlebuildTreeClick = async () => {
        const {data} = await axios.put('/gdwodocuments', {
            woid: workOrderObj.woid
        })
        const parsedData = JSON.parse(data.jsonString)

        if (parsedData && parsedData.contents && parsedData.contents.length > 0) {
            setTreeStructure(createTreeItems(parsedData.contents, ''))
            setDataLoaded(true)
        }
        else if (parsedData && parsedData.status === 'success') {
            setDataLoaded(true)
        }
        else {
            setDataLoaded(false)
        }
    
    }
    const onSave = async (event: any, files: File[], tree: any, topFolderName: any) => {
        const fileReader = new FileReader()
        fileReader.onload = function (event) {
            const data = event.target.result as string
            // setValue('logo', data.split(';base64,')[1])
            const body = {
                parentFolderID: tree.Id, // Folder the file is dropped on
                // id: tree.Id,
                rootFolderName: topFolderName, // root folder name
                filename: files[0].name,
                filedata: data.split(';base64,')[1],
                woid: workOrderObj.woid,
                mimetype:  files[0].type,
            }
            uploadDocument.mutateAsync(body)
        }

        fileReader.readAsDataURL(files[0])
        event.stopPropagation()
    }

    const createTreeItems = (structure, topLevelName) => {
        if (structure && structure.length > 0) {
            return structure.map(tree => {
                if (tree.IsFolder) {
                    return (
                        <Dropzone key={tree.Name} onDrop={(files, rejects, e) => onSave(e, files, tree, topLevelName)} noClick={true}>
                            {({ getRootProps,
                                  getInputProps,
                                  isDragActive,
                                  isDragAccept,
                                  isDragReject,
                                  acceptedFiles,
                                  open,
                                  fileRejections,
                              }) => (
                                <div {...getRootProps()} className={isDragActive ? styles.dropzoneselect : styles.dropzone}>
                                    <TreeItem
                                        style={{color: (isDragActive ? 'blue' : 'black'), border:
                                                isDragActive && tree.type === 'folder' ? `1px dashed gray` : "none"}}
                                        key={tree.Id}
                                        id={tree.Id}
                                        nodeId={tree.Id}
                                        label={getTreeCount(tree)}
                                        onContextMenu={(event) => { handleContextMenu(event, tree) }}
                                    >
                                        <input {...getInputProps()} />
                                        {tree.SubItems && tree.SubItems.length > 0 &&
                                            createTreeItems(tree.SubItems, topLevelName)
                                        }
                                    </TreeItem>
                                </div>
                            )}
                        </Dropzone>
                    )
                }
                // console.log(tree)
                return (
                    <TreeItem icon={<ArticleIcon />} key={tree.Id} nodeId={tree.Id} label={tree.Name+ ' (' + HumanReadableDate(tree.CreatedTime) + ')'}
                              draggable={true}
                              onDoubleClick={(event) => { handleDoubleClick(event, tree) }}
                              onContextMenu={(event) => { handleContextMenu(event, tree) }} >
                        {tree.IsFolder && <TreeItem nodeId={""} label={""} />}
                    </TreeItem>
                )
            })
        }
    }
    
    function HumanReadableDate(isoDateString) {
        console.log(isoDateString)
        const date = new Date(isoDateString);
       // Check if the date is valid
  if (isNaN(date.getTime())) {
    // Return a default message or handle the error as needed
    return '';
  }
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric', // Correct type
            month: '2-digit', // Correct type
            day: '2-digit', // Correct type
            hour: '2-digit', // Correct type
            minute: '2-digit', // Correct type
            timeZoneName: 'short', // Correct type
            hour12: false // Correctly expecting a boolean
          };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
      
        return formattedDate;
      }
    const getTreeCount = (tree) => {
        if (tree && tree.SubItems && tree.SubItems.length > 0 && tree.IsFolder) {
            return tree.Name + ' (' + getCountofContent(tree.SubItems) + ')'
        }
        else {
            return tree.Name
        }
    }

    const getCountofContent = (contents) => {
        var files = 0
        contents.forEach((item) => {
            if (item.IsFolder && item.SubItems && item.SubItems.length > 0) {
                files += getCountofContent(item.SubItems)
            }
            else if (!item.IsFolder) {
                files += 1
            }
        })
        return files
    }

    const getContentIds = (contents) => {
        var ids = []
        console.log(contents)
        contents.forEach((item) => {
            if (item.IsFolder) {
                ids.push(item.Id)
                const idsToAdd = getContentIds(item.SubItems)
                ids = [...ids, ...idsToAdd]
            }
        })
        console.log(ids)
        return ids
    }

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
        tree: any;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent, tree) => {
        event.stopPropagation()
        event.preventDefault()
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                    tree: tree
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        )
    }

    const handleDoubleClick = (event: React.MouseEvent, tree) => {
        event.stopPropagation()
        event.preventDefault()

        const body = {
            id: tree.Id,
            filename: tree.Name
        }
        // downloadDocument.mutateAsync(body)
        axios.post(`gdwodocumentsfile`, body).then((response) => {
            const dataJson = JSON.parse(response.data.jsonString)
            var contentType = 'image/png';
            if (dataJson.fileext === 'png') {
                contentType = 'image/png'
            }
            else if (dataJson.fileext === 'pdf') {
                contentType = 'application/pdf'
            }
            else if (dataJson.fileext === 'jpeg' || dataJson.fileext === 'jpg' || dataJson.fileext === 'jfif') {
                contentType = 'image/jpeg'
            }
            else if (dataJson.fileext === 'docx') {
                contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            }
            else if (dataJson.fileext === 'xlsx') {
                contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
            else if (dataJson.fileext === 'xls') {
                contentType = 'application/vnd.ms-excel'
            }
            setWatchLogoData(dataJson.filedata)

            const byteCharacters = atob(dataJson.filedata)
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: contentType});
            const url = window.URL.createObjectURL(blob);
            setWatchLogoDataURL(url);
            setOpenViewer(true)
        })
    }

    const handleClose = () => {
        setContextMenu(null);
    }

    const handleCopy = () => {
        setCopyTree(contextMenu.tree)
        setContextMenu(null);
    }

    const handleUpload = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
        setContextMenu(null);
    }

    const handleMove = () => {
        setCopyTree(contextMenu.tree)
        setMoveTree(true)
        setContextMenu(null);
    }

    const handleRename = () => {
        setCopyTree(contextMenu.tree)
        // setMoveTree(true)
        setContextMenu(null);
    }

    const handlePaste = () => {
        const copytree = copyTree

        const body = {
            id: copytree.Id,
            toid: contextMenu.tree.Id
        }

        if (moveTree) {
            moveDocument.mutateAsync(body)
        }
        else {
            copyDocument.mutateAsync(body)
        }
        setMoveTree(false)
        setCopyTree(null)
        setContextMenu(null)
    }

    const handleDownload = () => {
        const body = {
            id: contextMenu.tree.Id,
            filename: contextMenu.tree.Name
        }
        const fileName = contextMenu.tree.name
        axios.post(`gdwodocumentsfile`, body).then((response) => {
            const dataJson = JSON.parse(response.data.jsonString)
            setWatchLogoData(dataJson.filedata)
                var contentType = 'image/png';
                if (dataJson.fileext === '.png') {
                    contentType = 'image/png'
                }
                else if (dataJson.fileext === '.pdf') {
                    contentType = 'application/pdf'
                }
                else if (dataJson.fileext === '.jpg' || dataJson.fileext === '.jpg') {
                    contentType = 'image/jpeg'
                }
                else if (dataJson.fileext === '.docx') {
                    contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                }
                else if (dataJson.fileext === '.xlsx') {
                    contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
                else if (dataJson.fileext === '.xls') {
                    contentType = 'application/vnd.ms-excel'
                }
                const byteCharacters = atob(dataJson.filedata)
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {type: contentType});
                const url = window.URL.createObjectURL(blob);
                setWatchLogoDataURL(url);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
        })
        setContextMenu(null);
    }

    const handleDelete = () => {
        const body = {
            id: contextMenu.tree.Id,
            filename: contextMenu.tree.Name
        }
        console.log(body)
        deleteDocument.mutateAsync(body)
        setContextMenu(null);
    }

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };
  
    return (
        <div className={styles.root}>
            {dataLoaded && data && !data.contents && !data.allowstructurerecreate &&
                <Box
                    className={styles.box}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={"100%"}
                    width={"100%"}
                >
                    <Typography
                        variant="h6"
                        color="primary"
                        component="div">
                        No documents for this work order.
                    </Typography>
                </Box>
            }
            {dataLoaded && data && data.allowstructurerecreate && !treeStructure &&
                <Box
                    className={styles.box}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={"100%"}
                    width={"100%"}
                >
                     <Button onClick={handlebuildTreeClick}>Build Tree</Button>
                </Box>
            }
            {!dataLoaded &&
                <Box
                    className={styles.box}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={"100%"}
                    width={"100%"}
                >
                    <CircularProgress/>
                </Box>
            }
            {dataLoaded && data && data.contents &&
                <React.Fragment>
                    <TreeView
                        draggable={true}
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMoreIcon/>}
                        defaultExpandIcon={<ChevronRightIcon/>}
                        // onNodeSelect={handleChange}
                        // selected={treeNodeState.selected}
                        expanded={expanded}
                        sx={{margin: 2, flexGrow: 1}}
                        onNodeToggle={handleToggle}
                    >
                        {treeStructure}
                    </TreeView>
                </React.Fragment>
            }
            <Alerts errorMsg={(uploadDocument.error as any)?.message} />

            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem disabled={contextMenu?.tree?.IsFolder} onClick={handleCopy}>Copy</MenuItem>
                <MenuItem disabled={contextMenu?.tree?.IsFolder} onClick={handleMove}>Move</MenuItem>
                <MenuItem disabled={copyTree === null} onClick={handlePaste}>Paste</MenuItem>
                <MenuItem disabled={contextMenu?.tree?.IsFolder} onClick={handleRename}>Rename</MenuItem>
                {/*<MenuItem disabled={contextMenu?.tree?.type === 'file'} onClick={handleUpload}>Upload</MenuItem>*/}
                <MenuItem disabled={contextMenu?.tree?.IsFolder} onClick={handleDownload}>Download</MenuItem>
                <MenuItem disabled={contextMenu?.tree?.IsFolder} onClick={handleDelete}>Delete</MenuItem>
            </Menu>

                {watchLogoDataURL && (
                    <Grid item xs={6} className={styles.selectcontrol}>
                    <>
                        <Dialog
                            open={openViewer}
                            fullWidth={true}
                            maxWidth="xl"
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent>
                                    <div className="PDF-viewer">
                                        <PdfViewerComponent
                                            document={watchLogoDataURL}
                                        />
                                    </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {
                                    setOpenViewer(false)
                                    setWatchLogoDataURL(null)
                                }}>Cancel</Button>
                                {/*<Button>Save</Button>*/}
                            </DialogActions>
                        </Dialog>
                    </>
                    </Grid>
                )}

        </div>
    )
}

export default DocumentsPage
