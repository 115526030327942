import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  CWAListing {
    cwalid: number
    cwalentityid: number
    cwalcustomercodeid: string
    cwalcustomerinfo: string
    cwalnumber: string
    cwaldescription: string
    cwalamountlastupdated: string
    cwaladdeddate: string
    cwalactive: boolean
    cwalexpirationdate: string
    cwalcurrentapproved: number
    cwalcurrentpending: number
    notestypeid: number
    notestitle: string
    noteslinkid: number
    notescount: number
}

export const CWAListingDefaults = {
    cwalid: 0,
    cwalentityid: 0,
    cwalcustomercodeid: '',
    cwalcustomerinfo: '',
    cwalnumber: '',
    cwaldescription: '',
    cwalamountlastupdated: '',
    cwaladdeddate: '',
    cwalactive: true,
    cwalexpirationdate: '',
    cwalcurrentapproved: 0,
    cwalcurrentpending: 0,
    notestypeid: 0,
    notestitle: "",
    noteslinkid: 0,
    notescount: 0
}

export const useFetchCWAListing = () => {
    return useQuery<CWAListing[]>(
        ['cwalisting'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdateCWAListing = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`cwalisting`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['cwalisting'])
        }
    })
}

export const useDeleteCWAListing = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`cwalisting`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['cwalisting'])
        }
    })
}

export const globalFetcher = async () => {
    const { data } = await axios.get('/cwalisting')
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.cwalist as CWAListing[]
}

export const sendCWRExport = async () => {
    const json = JSON.stringify({ })
    const { data } = await axios.post('/cwaexport', json, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData
}
