import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {CWADropdownDefaults, CWADropdowns} from "./useCWADropdowns";

export interface customerList {
    custid: number
    custinfo: string
}

export interface staffList {
    staffid: number
    staffinfo: string
}

export interface statusList {
    schedulestatusid: number
    schedulestatus: string
}

export interface  EnityScheduleDropdowns {
    custlist: customerList[]
    statuslist: statusList[]
    stafflist: staffList[]
}

export const useFetchEntityScheduleDropdowns =  (entityid: number) => {
    return useQuery(
        ['entityscheduledropdowns', entityid],
        () => globalEntityScheduleFetcher(entityid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const globalEntityScheduleFetcher = async (id: number) => {
    const { data } = await axios.post('/entityscheduledropdowns', {
        entityid: id
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as EnityScheduleDropdowns
}
