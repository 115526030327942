import { useController, useFormContext } from 'react-hook-form'

import { Switch as MuiSwitch, SwitchProps } from '@material-ui/core'

import styles from './Switch.module.css'

type Props = {
  name: string
  rules?: Object
  switchProps?: SwitchProps
  defaultValue?: boolean
  disabled?: boolean
}

const Switch = ({
  rules = {},
  name,
  switchProps = {},
  defaultValue = false,
  disabled = false
}: Props) => {
  const { control } = useFormContext()
  const {
    field: { ref, ...inputProps }
  } = useController({
    name: name as any,
    control,
    rules,
    defaultValue
  })

  return (
    <MuiSwitch
      color="primary"
      onChange={inputProps.onChange}
      disabled={disabled}
      checked={inputProps.value}
      classes={{
        switchBase: styles.switchBase,
        checked: styles.checked,
        track: styles.track
      }}
      {...switchProps}
    />
  )
}

export default Switch
