import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import styles from './Lookup.module.css'
import TextInput from "../../../components/common/inputs/TextInput";
import SelectInput from "../../../components/common/inputs/SelectInput";
import {useUpdateLookup} from "../../../queries/useUDLookup";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {ensureEntitiesArray} from "@reduxjs/toolkit/dist/entities/utils";

interface FormData {
    lookupid: number
    lookupcategoryid: number
    lookupvalue: string
    lookupcustid: number
    lookupentid: number
    lookupdefault: boolean
    lookupactive: boolean
}

export const LookupForm = ({ isOpen, setIsOpen, gridParams, reloadForm, lookupCategoryObj, lookupEntityList, lookupCustomerList }) => {

    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit Lookup" : "Create Lookup"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateLookup = useUpdateLookup()
    const [customerOptions, setCustomerOptions] = React.useState()
    const [entityActiveSelect, setEntityActiveSelect] = React.useState<string>("")
    const [entityIdActiveSelect, setEntityIdActiveSelect] = React.useState<number>()
    const [customerActiveSelect, setCustomerActiveSelect] = React.useState<string>("")
    const [customerIdActiveSelect, setCustomerIdActiveSelect] = React.useState<number>()
    const arrayOfNoDefault = [7,9,10,16,17,18]
    const shouldUseDefault = arrayOfNoDefault.indexOf(lookupCategoryObj.lookupcategoryid) === -1

    useEffect(() => {
        const entitySelection = lookupEntityList?.filter((item) => item.entityid === (gridParams ? gridParams.lookupentid : 0))
        if (entitySelection && entitySelection?.length > 0) {
            setEntityActiveSelect(entitySelection[0].entityname)
            setEntityIdActiveSelect(entitySelection[0].entityid)

            if (entitySelection && entitySelection?.length > 0) {
                const customerSelection = lookupCustomerList?.filter((item) => item.entityid === entitySelection[0].entityid)
                console.log(customerSelection)
                if (customerSelection && customerSelection.length > 0) {
                    setCustomerOptions(customerSelection.map(item => (
                        <MenuItem key={item.custid} value={item.custinfo}>
                            {item.custinfo}
                        </MenuItem>
                    )))

                    setCustomerActiveSelect(gridParams.lookupcustinfo)
                    setCustomerIdActiveSelect(gridParams.lookupcustid)
                }
            }
        }
        else {
            setEntityActiveSelect("")
            setEntityIdActiveSelect(null)
        }

        reset({
            lookupid: (gridParams ? gridParams.lookupid : 0),
            lookupcategoryid: (gridParams ? gridParams.lookupcategoryid : 0),
            lookupvalue: (gridParams ? gridParams.lookupvalue : ''),
            lookupcustid: (gridParams ? gridParams.lookupcustid : 0),
            lookupentid: (gridParams ? gridParams.lookupentid : 0),
            lookupdefault: (gridParams ? gridParams.lookupdefault : true),
            lookupactive: (gridParams ? gridParams.lookupactive : true),
        })
    }, [gridParams])

    const onSubmit = async (data: FormData) => {
        console.log(data.lookupdefault)
        var finalData = {
            lookupcategoryid: lookupCategoryObj.lookupcategoryid,
            lookupvalue: data.lookupvalue,
            lookupcustid: customerIdActiveSelect,
            lookupentid: entityIdActiveSelect,
            lookupdefault: shouldUseDefault? data.lookupdefault : false,
            lookupactive: data.lookupactive
        }
        if (data.lookupid > 0) {
            finalData['lookupid'] = data.lookupid
        }
        const { data: response } = await updateLookup.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            reset()
            setIsOpen(false)
        }
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const getEntityOptions = () => {
        return lookupEntityList?.map(item => (
            <MenuItem key={item.entityid} value={item.entityname}>
                {item.entityname}
            </MenuItem>
        ))
    }

    const getCustomerOptions = (entityId: number) => {
        setCustomerOptions(null)
        const entitySelection = lookupEntityList?.filter((item) => item.entityid === entityId)
        if (entitySelection && entitySelection?.length > 0) {
            const customerSelection = lookupCustomerList?.filter((item) => item.entityid === entitySelection[0].entityid)
            console.log(customerSelection)
            if (customerSelection && customerSelection.length > 0) {
                setCustomerOptions(customerSelection.map(item => (
                    <MenuItem key={item.custid} value={item.custinfo}>
                        {item.custinfo}
                    </MenuItem>
                )))
            }
            else {
                setCustomerOptions(null)
            }
        }
        else {
            setCustomerOptions(null)
        }
    }

    const handleEntityChange = (event) => {
        setEntityActiveSelect(event.target.value as string)
        const entitySelection = lookupEntityList?.filter((item) => item.entityname === event.target.value as string)
        setEntityIdActiveSelect(entitySelection[0].entityid)
        getCustomerOptions(entitySelection[0].entityid)
    }

    const handleCustomerChange = (event) => {
        setCustomerActiveSelect(event.target.value as string)
        const custSelection = lookupCustomerList?.filter((item) => item.custinfo === event.target.value as string)
        console.log(custSelection)
        if (custSelection && custSelection.length > 0) {
            setCustomerIdActiveSelect(custSelection[0].custid)
        }
    }

    return (
        <Dialog open={isOpen} maxWidth="xl" className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>

                        {lookupCategoryObj.lookupcategoryshowentity &&
                            <FormControl fullWidth>
                                <InputLabel>Entity*</InputLabel>
                                <Select
                                    name="lookupentid"
                                    label="Entity"
                                    value={entityActiveSelect}
                                    size={'small'}
                                    required={lookupCategoryObj.lookupcategoryrequireentity}
                                    variant="outlined"
                                    disabled={false}
                                    onChange={handleEntityChange}
                                >
                                    {getEntityOptions()}
                                </Select>
                            </FormControl>
                        }
                        {lookupCategoryObj.lookupcategoryshowcustomer &&
                            <FormControl fullWidth>
                                <InputLabel>Customer*</InputLabel>
                                <Select
                                    name="lookupcustid"
                                    label="Customer"
                                    value={customerActiveSelect}
                                    size={'small'}
                                    required={lookupCategoryObj.lookupcategoryrequirecustomer}
                                    variant="outlined"
                                    disabled={entityActiveSelect?.length <= 0}
                                    onChange={handleCustomerChange}
                                >
                                    {customerOptions}
                                </Select>
                            </FormControl>
                        }
                        <TextInput
                            name="lookupvalue"
                            label="Value"
                            rules={{
                                required: 'Value is required',
                                minLength: {
                                    value: 1,
                                    message: 'Must be at least 1 characters'
                                },
                                maxLength: {
                                    value: 200,
                                    message: 'Must be 200 or less characters'
                                }
                            }}
                            // disabled={entityActiveSelect?.length <= 0 && customerActiveSelect?.length <= 0}
                            required
                        />
                        <>
                            <SelectInput
                                name="lookupactive"
                                label="Active"
                                options={[
                                    { label: 'True', value: true },
                                    { label: 'False', value: false }
                                ]}
                                required
                            />
                        </>

                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

