export const DBConfig = {
    name: 'workorderDB',
    version: 1,
    objectStoresMeta: [
        {
            store: 'dispatch',
            storeConfig: { keyPath: 'uuid', autoIncrement: false },
            storeSchema: [
                { name: 'uuid', keypath: 'uuid', options: { unique: true } },
                { name: 'json', keypath: 'json', options: { unique: false } }
            ]
        }
    ]
};