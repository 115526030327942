import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {WorkOrderData} from "./useWorkOrder";

export interface  WorkAuthorizationsData {
    waid: number
    waworkorder: string
    wawoid: number
    wanumber: string
    wadescription: string
    waamount: number
    waamountchange: number
    wastatus: string
    wastatusid: number
    wasubmitteddate: string
    waapproveddate: string
    warejecteddate: string
    wacomments: string
    warequestdocument: string
    waamountlastupdated: string
    waaddeduser: string
    waaddeddate: string
    waupdateduser: string
    waupdatedate: string
    waaddeduserid: number
    waupdateduserid: number
    waisactive: boolean
    notestypeid: number
    noteslinkid: number
    notestitle: string
    noteslinkidhas: boolean
    notescount: number
}

export const WorkAuthorizationsDataDefaults = {
    waid: 0,
    waworkorder: "",
    wawoid: 0,
    wanumber: "",
    wadescription: "",
    waamount: 0,
    waamountchange: 0,
    wastatus: "",
    wastatusid: 0,
    wasubmitteddate: "",
    waapproveddate: "",
    warejecteddate: "",
    wacomments: "",
    warequestdocument: "",
    waamountlastupdated: "",
    waaddeduser: "",
    waaddeddate: "",
    waupdateduser: "",
    waupdatedate: "",
    waaddeduserid: 0,
    waupdateduserid: 0,
    waisactive: true,
    notestypeid: 0,
    noteslinkid: 0,
    notestitle: "",
    noteslinkidhas: true,
    notescount: 0
}


// notescount: 0
// noteslinkid: 1
// noteslinkidhas: false
// notestitle: "12 [32423423]"
// notestypeid: 3
// waactive: true
// waaddeddate: "2022-03-23T06:33:00.527"
// waaddeduser: "sleathers74@gmail.com"
// waaddeduserid: 6
// waamount: 200
// waamountchange: 200
// waamountlastupdated: "2022-03-23T06:33:00.527"
// wacomments: ""
// wadescription: "desc"
// waid: 1
// waisactive: "Yes"
// wanumber: "12"
// warequestdocument: ""
// wastatus: ""
// wastatusid: 1
// wawoid: 2
// waworkorder: "32423423"

export const useFetchWorkAuthorizations = (wawoid: number) => {
    return useQuery<WorkAuthorizationsData[]>(
        ['workauthorizationsdata'],
        () => globalFetcher(wawoid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdateWorkOrder = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`workauthorization`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['workauthorizationsdata'])
        }
    })
}

export const useDeleteWorkAuthorization = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`/workauthorization`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['workauthorizationsdata'])
        }
    })
}

export const globalFetcher = async (id: number) => {
    const { data } = await axios.post('/workauthorization', {
        wawoid: id
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.walist as WorkAuthorizationsData[]
}


