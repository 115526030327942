import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AuthToken, loginUser } from '../../../slices/authSlice'

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const useLoginMutation = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutation(
    (body: any) => {
      return axios.post(`/login`, body)
    },
    {
      onSuccess: (response: any) => {
        console.log(response)
        if (response.status === 'failure') {
          throw new Error(response.message)
        }
        else {
          // update local storage
          localStorage.setItem('token', response.token)
          const parsedToken: AuthToken = parseJwt(response.token)
          // update redux
          dispatch(loginUser(parsedToken))

          // Invalidate all queries
          queryClient.invalidateQueries()

          // redirect
          history.push('/home')
        }
      }
    }
  )
}
