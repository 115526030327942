import React, { useEffect, useState } from 'react'

import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

type Props = {
  errorMsg?: string
  successMsg?: string
}

const Alerts = ({ errorMsg, successMsg }: Props) => {
  const [isErrorOpen, setIsErrorOpen] = useState(false)
  const [isSuccessOpen, setIsSuccessOpen] = useState(false)

  useEffect(() => {
    if (errorMsg) {
      setIsErrorOpen(true)
      setIsSuccessOpen(false)
    } else if (successMsg && successMsg.length > 0) {
      setIsSuccessOpen(true)
      setIsErrorOpen(false)
    } else {
      setIsSuccessOpen(false)
      setIsSuccessOpen(false)
    }
  }, [errorMsg, successMsg])

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isErrorOpen}
        onClose={() => setIsErrorOpen(false)}
        autoHideDuration={6000}>
        <Alert onClose={() => setIsErrorOpen(false)} severity={'error'}>
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSuccessOpen}
        autoHideDuration={3000}
        onClose={() => setIsSuccessOpen(false)}>
        <Alert onClose={() => setIsSuccessOpen(false)} severity={'success'} color={'success'}>
          {successMsg}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Alerts
