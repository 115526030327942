import React, {useState} from "react";
import styles from './WAMainPage.module.css'
import Tab from '@mui/material/Tab';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import {AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton} from "@material-ui/core";
import {
    DataGridPro,
    GridCallbackDetails,
    GridCellParams,
    GridColDef,
    GridRowParams,
    MuiEvent,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import {WAListingForm} from "./WAListingPage/WAListForm";
import {WAListing, WAListingDefaults, useDeleteWAListing, useFetchWAListing} from "../../queries/useWAListing";
import {Add} from "@mui/icons-material";
import {Delete, Edit} from "@material-ui/icons";
import {GridSelectionModel, GridValueFormatterParams} from "@material-ui/x-grid";
import {formatMoney} from "../../util/formatHelpers";
import {useFetchPODropdowns} from "../../queries/usePODropdowns";
import {useFetchWADropdowns} from "../../queries/useWADropdowns";
import WAAmountsTable from "./WAAmounts/WAAmountsTable";
import {Grid} from "@mui/material";

const WAMainPage = () => {
    const gridAPIRef = useGridApiRef()
    const { data: listingData, isLoading: isListingLoading } = useFetchWAListing()
    const [tabSel, setTabSel] = React.useState<number>(0);
    const deleteWAListing = useDeleteWAListing()
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState(WAListingDefaults)
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [listingString, setListingString] = useState<string>('')
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const [listingIDSelected, setListingIDSelected] = React.useState<number>(null);
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([19]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabSel(newValue)
    }

    const colors = {
        50: '#F0F7FF',
        100: '#dedede',
        200: '#b5b5b5',
        300: '#9f9f9f',
        400: '#757575',
        500: '#7e7e7e',
        600: '#6b6b6b',
        700: '#6e6e6e',
        800: '#353535',
        900: '#303030',
    };

    const Tab = styled(TabUnstyled)`
        font-family: IBM Plex Sans, sans-serif;
        color: white;
        cursor: pointer;
        font-size: 0.575rem;
        font-weight: bold;
        background-color: transparent;
        width: 80%;
        padding: 12px 16px;
        margin: 6px 6px;
        border: none;
        border-radius: 5px;
        display: flex;
        justify-content: center;
    
    &:hover {
        background-color: ${colors[400]};
    }
    
    &:focus {
        color: #fff;
        border-radius: 3px;
        outline: 2px solid ${colors[200]};
        outline-offset: 2px;
    }
    
    &.${tabUnstyledClasses.selected} {
        background-color: ${colors[50]};
        color: ${colors[600]};
    }
    
    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }`

    const TabPanel = styled(TabPanelUnstyled) `width: 100%;`

    const TabsList = styled(TabsListUnstyled)`
      min-width: 320px;
      background-color: ${colors[500]};
      border-radius: 8px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: space-between;
    `

    const columns: GridColDef[] = [
        {
            field: 'Edit',
            renderHeader: () => {
                return (
                    <IconButton
                        className={styles.submitButton}
                        onClick={() => handleAdd()}
                        size="small">
                        <Add />
                    </IconButton>
                )
            },
            sortable: false,
            width: 50,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
                    <Edit />
                </IconButton>
            )
        },
        {
            field: 'id',
            hide: true
        },
        {
            field: 'walwoid',
            type: 'string',
            headerName: 'WO Code',
            width: 200,
            valueFormatter: (params) =>
                getWOCodeDescription(Number(params.value))
        },
        {
            field: 'walnumber',
            type: 'string',
            headerName: 'WA No.',
            width: 200
        },
        {
            field: 'waladdeddate',
            type: 'string',
            headerName: 'Added',
            width: 200,
            valueFormatter: (params) =>
                params.value ? new Date(params.value as string).toLocaleString() : 'N/A'
        },
        {
            field: 'walcurrentapproved',
            type: 'number',
            headerName: 'Approved',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            field: 'walcurrentpending',
            type: 'string',
            headerName: 'Pending',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            field: 'action',
            headerName: '',
            width: 50,
            align: "center",
            headerAlign: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                    <Delete />
                </IconButton>
            )
        }
    ]

    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const handleEdit = (params: GridCellParams) => {
        const waListing = listingData.filter((item) => item.walid === Number(params.id))[0]
        setReloadForm(reloadForm+1)
        setGridParams({
            walid: waListing.walid,
            walwoid: waListing.walwoid,
            walnumber: waListing.walnumber,
            waldescription: waListing.waldescription,
            walamountlastupdated: waListing.walamountlastupdated,
            waladdeddate: waListing.waladdeddate,
            walactive: waListing.walactive,
            walcurrentapproved: waListing.walcurrentapproved,
            walcurrentpending: waListing.walcurrentpending
        })
        setIsFormShowing(true)
    }

    const { data: dropDownData, isLoading: dropdownLoading } = useFetchWADropdowns()
    const getWOCodeDescription = (woId: number) => {
        const value = dropDownData.wolist.filter((e) => e.woid === woId)
        return value[0]?.wocode
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObj({
            msg: 'Are you sure you want to delete WA # \"' + params.getValue(params.id, 'walnumber').toString() + "\"?",
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteWAListing.mutateAsync({ walid: dialogObj.id })
        setListingString('')
        setListingIDSelected(null)
        closeDialog()
    }

    const closeDialog = () => {
        setDialogObj({
            msg: '',
            id: 0
        })
    }

    const handleListingRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        console.log('walid:' + param.id)
        setListingIDSelected(Number(param.id))
        const listingsSeletedData: WAListing[] = listingData.filter((listing) => { return listing.walid === param.id})
        setListingString(listingsSeletedData[0].walnumber)
    }

    var listingRows = []
    listingRows = listingData.map((listing: WAListing, i) => {
        return {
            id: listing.walid,
            walwoid: listing.walwoid,
            wano: listing.walnumber,
            waladdeddate: listing.waladdeddate,
            walcurrentapproved: listing.walcurrentapproved,
            walcurrentpending: listing.walcurrentpending,
            walnumber: listing.walnumber
        }
    })

    React.useEffect(() => {
        if (listingData && listingData.length > 0) {
            // console.log(listingData.length)
            const id = listingData[0].walid
            const numTest = listingData[0].walnumber
            setListingIDSelected(id)
            setListingString(numTest)

            // if (gridAPIRef.current.getRowsCount() > 0) {
            setTimeout(() => {
                gridAPIRef.current?.selectRows([id])
            })
            // }
        }
    }, [listingData]);

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <TabsUnstyled
                defaultValue={0}
                onChange={handleTabChange}
               >
                <TabsList>
                    <Tab>WA</Tab>
                    <Tab >WA Amounts</Tab>
                </TabsList>

                <WAListingForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} customerCodes={dropDownData.wolist}/>
                {tabSel === 0 && (
                    <DataGridPro
                        apiRef={gridAPIRef}
                        rows={listingRows}
                        columns={columns}
                        autoHeight={true}
                        density={'compact'}
                        pageSize={10}
                        loading={false}
                        disableMultipleSelection={true}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                        onRowClick={handleListingRowClick}
                    />
                    // <WAListingPage></WAListingPage>
                )}
                {tabSel === 1 && (
                    <WAAmountsTable id={listingIDSelected} listingDisplay={listingString} waamountsstatuslist={dropDownData.waamountsstatuslist}/>
                )}
                {tabSel === 2 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginLeft: '15px', marginTop: '15px'}}>
                            <div style={{ width: '100%' }}>
                                WA No. {listingString}
                            </div>
                        </Grid>
                       
                    </Grid>
                    // id={listingIDSelected} listingDisplay={listingString} waamountsstatuslist={dropDownData.waamountsstatuslist}/>
                )}

            </TabsUnstyled>
        </div>
      )
}


export default WAMainPage
