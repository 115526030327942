import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface divisionList {
    divisionid: number
    divisionname: string
}

export interface scheduleTemplateList {
    scheduletemplateid: number
    scheduletemplate: string
    divisionid: number
    divisionname: string
}

export interface  ScheduleDropdowns {
    divisionlist: divisionList[]
    scheduletemplatelist: scheduleTemplateList[]
}

export const useFetchScheduleDropdowns =  (woid: number) => {
    return useQuery(
        ['scheduleadddropdowns'],
        () => globalSchedulesFetcher(woid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const globalSchedulesFetcher = async (id: number) => {
    const { data } = await axios.post('/scheduleadddropdowns', {
        woid: id
    })
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    return parsedData as ScheduleDropdowns
}
