import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  CWAAmounts {
    cwaaid: number
    cwaaamount: number
    cwaastatusid: string
    cwaadaterequested: string
    cwaadateapproved: string
    cwaacomment: string
    cwaaamountlastupdated: string
    cwaaaddeddate: string
    cwaadateexpire: string
}

export const CWAAmountsDefaults = {
    cwaaid: 0,
    cwaaamount: 0,
    cwaastatusid: '',
    cwaadaterequested: '',
    cwaadateapproved: '',
    cwaacomment: '',
    cwaaamountlastupdated: '',
    cwaaaddeddate: '',
    cwaadateexpire: null
}

export const useFetchCWAAmounts =  (cwalid: number) => {
    return useQuery<CWAAmounts[]>(
        ['cwaamounts', cwalid],
        () => globalFetcher(cwalid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdateCWAAmounts = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`cwaamounts`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['cwaamounts'])
        }
    })
}

export const useDeleteCWAAmounts = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`cwaamounts`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['cwaamounts'])
        }
    })
}

export const globalFetcher = async (id: number) => {
    const { data } = await axios.post('/cwaamounts', {
        cwalid: id
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.cwaamountlist as CWAAmounts[]
}
