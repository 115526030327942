import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../../components/common/HookForm'
import TextInput from '../../../../components/common/inputs/TextInput'
import MaskedInput from '../../../../components/common/inputs/MaskedInput'
import SelectInput from '../../../../components/common/inputs/SelectInput'
import styles from '../../CWAListingPage/CWALiastingPage.module.css'
import {useUpdatePOListings} from "../../../../queries/usePOListings";


interface FormData {
    polid: number
    polnumber: string,
    poldescription: string,
    polstatusid: number,
    polactive: boolean
}

export const POListForm = ({ isOpen, setIsOpen, gridParams, reloadForm, cwalid, poStatus }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit PO Listings" : "Create PO"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateListing = useUpdatePOListings()
    const [amount, setAmount] = useState<number>(0)

    useEffect(() => {
        reset({
            polid: (gridParams ? gridParams.polid : 0),
            polnumber: (gridParams ? gridParams.polnumber : ''),
            poldescription: (gridParams ? gridParams.poldescription : ''),
            polstatusid: (gridParams ? gridParams.polstatusid : 3),
            polactive: (gridParams ? gridParams.polactive : true)
        })
    }, [reloadForm])

    const handleAmount = (event) => {
        setAmount(event.floatValue)
    }

    const onSubmit = async (data: FormData) => {
        var finalData = {
            cwalid: cwalid,
            polnumber: data.polnumber,
            poldescription: data.poldescription,
            polactive: data.polactive,
            polstatusid: data.polstatusid
        }
        if (data.polid > 0) {
            finalData['polid'] = data.polid
        }
        const { data: response } = await updateListing.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }

    const getPOStatusOptions = () => {
        return poStatus?.map(item => {
                return { label: item.statuscode, value: item.statusid }
            })

    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const currencyFormat = (num) => {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return (
        <Dialog open={isOpen} maxWidth="xl" className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>
                    <div className={styles.multipleInputContainer}>
                        <TextInput name="polnumber" label="PO No." required />
                        <SelectInput
                            name="polstatusid"
                            label="PO Status"
                            options={getPOStatusOptions()}
                            required
                        />
                    </div>
                    <TextInput
                        name="poldescription"
                        label="Description"
                        rules={{
                            required: 'Password field is required',
                            minLength: {
                                value: 1,
                                message: 'Must be at least 1 characters long'
                            },
                            maxLength: {
                                value: 500,
                                message: 'Must be less than 500 characters long'
                            }
                        }}
                        required
                    />
                    <SelectInput
                        name="polactive"
                        label="Status"
                        options={[
                            { label: 'Active', value: true },
                            { label: 'Inactive', value: false }
                        ]}
                        required
                    />
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
