import React, {useEffect, useState} from "react";
import styles from './Lookup.module.css'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {IconButton, Box, AppBar, Typography} from '@material-ui/core'
import {EntityList, CustomerList, LookupCategory, useFetchLookupCategory} from "../../../queries/useUDLookupCategory";
import {Grid} from "@mui/material";
import LookupCategoryTable from "./LookupCategory/LookupCategoryTable";
import LookupValuesTable from "./LookupValues/LookupValuesTable";
import {useQueryClient} from "react-query";

const LookupPage = (params) => {
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [tabSel, setTabSel] = React.useState<number>(0);
    const [lookupCategoryObj, setLookupCategory] = React.useState(null);
    const [lookupEntityList, setLookupEntity] = React.useState<EntityList[]>(null);
    const [lookupCustomerList, setLookupCustomer] = React.useState<CustomerList[]>(null);
    const queryClient = useQueryClient()

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabSel(newValue)
    }

    React.useEffect(() => {
        console.log(lookupCategoryObj)
        queryClient.invalidateQueries(['lookup'])
    }, [lookupCategoryObj])

    return (
        <div className={styles.root}>
            <Grid container spacing={1}>
                <AppBar position="static" color="default">
                    <Typography
                        variant="h6"
                        color="primary"
                        align="center"
                        component="div">
                        User Defined Lookups
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                        component="div">
                        {lookupCategoryObj?.lookupcategoryid} - {lookupCategoryObj?.lookupcategory}
                    </Typography>
                    <Tabs
                        value={tabSel}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab value={0} label={'Listing'}></Tab>
                        <Tab value={1} label={'Value'}></Tab>
                    </Tabs>

                    {tabSel === 0 && (
                        <LookupCategoryTable
                            lookupCategoryObj={lookupCategoryObj}
                            setLookupCategory={setLookupCategory}
                            setLookupEntity={setLookupEntity}
                            setLookupCustomer={setLookupCustomer}
                        />
                    )}
                    {tabSel === 1 && (
                        <LookupValuesTable
                            setLookupCategory={setLookupCategory}
                            lookupCategoryObj={lookupCategoryObj}
                            lookupEntityList={lookupEntityList}
                            lookupCustomerList={lookupCustomerList}
                        />
                    )}


                </AppBar>
            </Grid>
        </div>
    );
}

export default LookupPage
