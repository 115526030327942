import React, {useEffect, useMemo, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle
} from '@material-ui/core'

import HookForm from '../../../../components/common/HookForm'
import TextInput from '../../../../components/common/inputs/TextInput'
import MaskedInput from '../../../../components/common/inputs/MaskedInput'
import styles from '../../CWAListingPage/CWALiastingPage.module.css'
import {useUpdatePOAmounts} from "../../../../queries/usePOAmounts";
import ChipsList from '../../../../components/common/inputs/ChipsList'
import DateTimeInput from "../../../../components/common/inputs/DateInput";


interface FormData {
    poaid: number
    poaline: number,
    poadescription: string,
    poaworkcategoryids: [],
    poaamount: number,
    poacomment: number,
    poaexpirationdate: string
}

export const POAmountsForm = ({ isOpen, setIsOpen, gridParams, reloadForm, polid, poWorkTypes }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit PO Amount" : "Create PO Amount"
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateAmounts = useUpdatePOAmounts()
    const [amount, setAmount] = useState<number>(0)
    const [workTypes, setWorkTypes] = useState<{ value: any; label: string }[]>([])

    useEffect(() => {
        let selectedWO = []
        gridParams?.poaworkcategoryids?.forEach(wtId => {
            const woToAdd = poWorkTypes?.filter((wtObject) =>  wtObject.worktypeid === wtId )
            if (woToAdd && woToAdd.length > 0) {
                selectedWO.push({value: woToAdd[0].worktypeid, label: woToAdd[0].worktypecode})
            }
        })
        setWorkTypes(selectedWO)
        setAmount((gridParams ? gridParams.poaamount : 0))
        reset({
            poaid: (gridParams ? gridParams.poaid : 0),
            poaline: (gridParams ? gridParams.poaline : ''),
            poaworkcategoryids: (gridParams ? gridParams.poaworkcategoryids : []),
            poadescription: (gridParams ? gridParams.poadescription : ''),
            poaamount: (gridParams ? gridParams.poaamount : 0),
            poacomment: (gridParams ? gridParams.poacomment : ''),
            poaexpirationdate: (gridParams ? gridParams.poaexpirationdate : null),
        })
    }, [reloadForm])

    const handleAmount = (event) => {
        setAmount(event.floatValue)
    }

    const onSubmit = async (data: FormData) => {
        const workTypesToSave = workTypes.map(wt => {
            return wt.value
        })
        var finalData = {
            polid: polid,
            poaline: data.poaline,
            poaworkcategoryids: workTypesToSave,
            poadescription: data.poadescription,
            poaamount: amount,
            poacomment: data.poacomment,
            poaexpirationdate: data.poaexpirationdate
        }
        if (data.poaid > 0) {
            finalData['poaid'] = data.poaid
        }
        const { data: response } = await updateAmounts.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }
    const getWorkTypesOptions = useMemo(
        () =>
            poWorkTypes?.map((item) => {
                return { label: item.worktypecode, value: item.worktypeid }
            }),
        [poWorkTypes]
    )

    const closeDialog = () => {
        setDialogMsg("")
    }

    const currencyFormat = (num) => {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return (
        <Dialog open={isOpen}
                fullWidth={true}
                maxWidth="xl"
                className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>
                    <div className={styles.multipleInputContainer}>
                        <MaskedInput
                            name="poaline"
                            label="Line Number"
                            thousandSeparator={false}
                            thousandsGroupStyle={""}
                            prefix=""
                            rules={{
                                validate: (val) => (val > 0 && val < 999999) || 'Please enter a valid line number between 1 and 999999.',
                            }}
                            required />
                        <MaskedInput
                            name="poaamount"
                            label="Amount"
                            defaultValue="0"
                            onValueChange={handleAmount}
                        />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <TextInput
                            name="poadescription"
                            label="Description"
                            rules={{
                                required: 'Password field is required',
                                minLength: {
                                    value: 1,
                                    message: 'Must be at least 1 characters long'
                                },
                                maxLength: {
                                    value: 500,
                                    message: 'Must be less than 500 characters long'
                                }
                            }}
                            required
                        />
                        <TextInput
                            name="poacomment"
                            label="Comment"
                            rules={{
                                required: 'Password field is required',
                                minLength: {
                                    value: 1,
                                    message: 'Must be at least 1 characters long'
                                },
                                maxLength: {
                                    value: 500,
                                    message: 'Must be less than 500 characters long'
                                }
                            }}
                            required
                        />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <ChipsList
                            list={workTypes}
                            setList={setWorkTypes}
                            options={getWorkTypesOptions}
                            label="Enter a Tag"
                        />
                        <DateTimeInput name="poaexpirationdate" label="Expiration Date" defaultValue={null} required={false} />
                    </div>
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
