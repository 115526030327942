import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  Users {
    usersid: number
    usersemail: string
    userspassword: string
    usersactive: boolean
}

export const UsersDefault = {
    usersid: 0,
    usersemail: '',
    userspassword: '',
    usersactive: true
}

export const useFetchUsers =  () => {
    return useQuery<Users[]>(
        ['usersMaint'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            // placeholderData: [{}],
            keepPreviousData: false
        }
    )
}

export const useUpdateUsers = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`users`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['usersMaint'])
        }
    })
}

export const useDeleteUsers = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`users`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['usersMaint'])
        }
    })
}

export const globalFetcher = async () => {
    const { data } = await axios.post('/users', {})
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)

    return parsedData.userslist as Users[]
}
