import React, {useEffect} from "react";
import styles from './DivisionProcessingPage.module.css'
import {AppBar, Button, Typography} from "@material-ui/core";
import {Grid} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useFetchGridState} from "../../queries/useGridState";
import {useFetchWorkOrderDropdowns} from "../../queries/useWorkOrderDropdowns";
import {WorkOrderData} from "../../queries/useWorkOrder";
import ListingPage from "./Listing/ListingPage";
import {useParams} from "react-router";
import ProcessingPage from "./Processing/ProcessingPage";
import axios from "axios";

interface Params {
    entityId: string
}

const DivisionProcessingPage = () => {
    const { entityId } = useParams<Params>()
    const gridProcessingStateVersion = 'processing-grid-v0'
    const { data: gridProcessingStateData } = useFetchGridState(gridProcessingStateVersion)
    const gridListingStateVersion = 'listing-grid-v1'
    const { data: gridListingStateData } = useFetchGridState(gridListingStateVersion)

    const [tabSel, setTabSel] = React.useState<number>(0);
    const { data: woDropDownData } = useFetchWorkOrderDropdowns()
    const [dispatchList, setDispatchList] = React.useState(null)
    const [dispatchListRow, setDispatchListRow] = React.useState<number>(0);
    const [reloadData, setReloadData] = React.useState(1)
    const [reloadListingData, setReloadListingData] = React.useState(1)
    const [data, setData] = React.useState({
        dispatchlist: [],
        status: '',
        message: ''
    })

    const [timeData, setTimeData] = React.useState({
        status: '',
        message: '',
        canadd: false,
        canedit: false,
        candelete: false,
        canaddtime: true,
        canaddwip: true,
        canposttime: true,
        canpostwip: true,
        itemmessage: '',
        wiplist: [],
        itemlist: [],
        vehiclelist: [],
        defaultworkdate: '',
        totalrevenue: '',
        totalcost: '',
        marginpercentage: '',
        grossprofit: '',
        timerecap: [],
        timelist: []
    })

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabSel(newValue)
    }

    const getData = async (entityId, dispatchid) => {
        var body = {entityid: entityId}
        if (dispatchid) {
            body['dispatchid'] = dispatchid
        }

        const { data } = await axios.post('/entityprocesslist', body)
        const parsedData = JSON.parse(data.jsonString)
        console.log(parsedData)
        if (dispatchid) {
            setTimeData(parsedData)
        }
        else {
            setData(parsedData)
        }
    }

    useEffect(() => {
        setDispatchListRow(dispatchList?.dispatchid)
    }, [dispatchList])

    useEffect(() => {
        if (entityId) {
            getData(entityId, dispatchListRow)
        }
    }, [entityId, dispatchListRow, dispatchList?.dispatchid, reloadData])

    useEffect(() => {
        if (entityId) {
            getData(entityId, null)
        }
    }, [reloadListingData])

    return (
        <div className={styles.root}>
            <Grid container spacing={1} border={0}>
                <AppBar position="static" color="default">
                    <Typography
                        variant="h6"
                        color="primary"
                        align="center"
                        component="div">
                        Dispatch Processing
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                        component="div">
                        {dispatchList?.dispatchtitle}
                    </Typography>
                    <Tabs
                        value={tabSel}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab value={0} label={'Listing'}></Tab>
                        <Tab value={1} disabled={!dispatchList} label={'Processing'}></Tab>
                        
                    </Tabs>

                    {tabSel === 0 && (
                        <ListingPage
                            gridStateData={gridListingStateData}
                            gridStateVersion={gridListingStateVersion}
                            woDropDownData={woDropDownData}
                            entityId={entityId}
                            dispatchList={dispatchList}
                            setDispatchList={setDispatchList}
                            data={data}
                            reloadListingData={reloadListingData}
                            setReloadListingData={setReloadListingData}
                            dispatchListRow = {dispatchListRow}
                            setDispatchListRow = {setDispatchListRow}

                        />
                    )}
                    {tabSel === 1 && (
                        <ProcessingPage
                            gridStateData={gridProcessingStateData}
                            gridStateVersion={gridProcessingStateVersion}
                            woDropDownData={woDropDownData}
                            entityId={entityId}
                            dispatchList={dispatchList}
                            timeData={timeData}
                            reloadData={reloadData}
                            setReloadData={setReloadData}
                        />
                    )}
                </AppBar>
            </Grid>
        </div>
      )
}

export default DivisionProcessingPage
