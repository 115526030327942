import React from 'react'
import { useController, useFormContext } from 'react-hook-form'


import { TextField, TextFieldProps, InputAdornment } from '@material-ui/core'


import styles from './TextInput.module.css'

type Props = {
  name: string
  label: string
  required?: boolean
  rules?: Object
  textFieldProps?: TextFieldProps
  defaultValue?: string
  startAdornment?: string
  endAdornment?: string
  disabled?: boolean
}

export const StyledMUIInput = ({ ...props }) => {
  return (
    <TextField
      classes={{ root: styles.root }}
      FormHelperTextProps={{
        classes: {
          error: styles.error
        }
      }}
      InputProps={{
        classes: {
          root: styles.input,
          error: styles.error,
          focused: styles.focus
        },
        startAdornment: <InputAdornment position="start">{props.startAdornment}</InputAdornment>,
        endAdornment: <InputAdornment position="end">{props.endAdornment}</InputAdornment>
      }}
      InputLabelProps={{
        classes: {
          root: styles.label,
          error: styles.error,
          focused: styles.focus
        },
        shrink: true
      }}
      size="small"
      variant="outlined"
      {...props}
    />
  )
}

const TextInput = ({
  name,
  label,
  rules = {},
  required = false,
  defaultValue = "",
  textFieldProps,
  startAdornment = "",
  endAdornment = "",
  disabled = false
}: Props) => {
  const { control, formState } = useFormContext()
  const {
    field: { ref, ...inputProps }
  } = useController({
    name: name as any,
    control,
    rules: { ...rules, required: required ? 'Field is required' : false },
    defaultValue: defaultValue
  })

  return (
    <StyledMUIInput
      label={label}
      error={formState.errors[name] ? true : false}
      helperText={formState.errors[name]?.message}
      required={required}
      disabled={disabled}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      {...textFieldProps}
      {...inputProps}
    />
  )
}

export default TextInput
