import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {WorkOrderData} from "./useWorkOrder";

export interface  DivisionsDataObject {
    status: string
    message: string
    canadd: boolean
    canaddentity: 0
    canadddirectonly: false
    divisionlist: DivisionsData[]
}

export interface  DivisionsData {
    canedit: boolean
    divisionid: number
    divisionwoid: number
    divisionentityid: number
    divisionentity: string
    divisionstatusid: number
    divisionstatus: string
    divisionjpid: number
    divisionjp: string
    divisionworktype: string
    divisionworktypeids: []
    divisionpricingmethodid: number
    divisionpricingmethod: string
    divisionaddeddate: string
    divisioncustomercodeid: number
    divisioncustomerinfo: string
    divisioncustomercode: string
    divisioncontractid: number
    divisionprojectmanagername: string
    divisionprojectmanagerid: number
    divisioncustomerfeid: number
    divisioncustomerpmid: number
    divisioncustomerpmname: string
    divisioncustomerceid: number
    divisionworkcompletedate: string
    divisionqaqccompletedate: string
    divisionapproveddate: string
    divisionasbuilduploaddate: string
    divisionsubmittedcustomerdate: string
    divisionpaperworkuploaddate: string
    notescount: number
    notestypeid: number
    noteslinkid: number
    notestitle: string
    divisioncustomercomment1: string
    divisioncustomercomment2: string
}

export const DivisionsDataDefaults = {
    canedit: true,
    divisionid: 0,
    divisionwoid: 0,
    divisionentityid: 0,
    divisionentity: "",
    divisionstatus: "",
    divisionstatusid: 0,
    divisionjpid: 0,
    divisionjp: "",
    divisionworktype: "",
    divisionworktypeids: [],
    divisionpricingmethodid: 0,
    divisionpricingmethod: "",
    divisionaddeddate: "",
    divisioncustomercodeid: 0,
    divisioncustomerinfo: "",
    divisioncustomercode: "",
    divisioncontractid: 0,
    divisionprojectmanagername: "",
    divisionprojectmanagerid: 0,
    divisioncustomerfeid: 0,
    divisioncustomerpmid: 0,
    divisioncustomerpmname: "",
    divisioncustomerceid: null,
    divisionworkcompletedate: null,
    divisionqaqccompletedate: null,
    divisionapproveddate: null,
    divisionasbuilduploaddate: null,
    divisionsubmittedcustomerdate: null,
    divisionpaperworkuploaddate: null,
    notestypeid: 0,
    notestitle: "",
    noteslinkid: 0,
    notescount: 0,
    divisioncustomercomment1: "",
    divisioncustomercomment2: ""
}

export const useFetchDivisions = (divisionwoid: number) => {
    return useQuery<DivisionsDataObject>(
        ['divisiondata'],
        () => globalFetcher(divisionwoid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdateDivisions = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`division`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['divisiondata'])
        }
    })
}

export const useDeleteDivisions = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`division`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['divisionsdata'])
        }
    })
}

export const globalFetcher = async (id: number) => {
    const { data } = await axios.post('/division', {
        divisionwoid: id
    })
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    return parsedData as DivisionsDataObject
}
