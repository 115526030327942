import React, {useEffect, useRef, useState} from "react";
import styles from "./VacationSchedule.module.css";
import { Typography, Grid } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import {
  DataGridPro,
  GridColDef,
  GridCsvExportOptions, GridRowHeightParams,
  GridToolbarContainer,
  useGridApiContext
} from "@mui/x-data-grid-pro";
import axios from "axios";
import { useFetchVacationScheduleList } from "../../queries/useVacationScheduleList";
import { Tab, Tabs, Box } from "@material-ui/core";
import {WorkOrderData} from "../../queries/useWorkOrder";
import {useParams} from "react-router";
import Button, {ButtonProps} from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Save} from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {SettingsBackupRestore} from "@material-ui/icons";
import {MenuItem, Select} from "@mui/material";
import dayjs from "dayjs";
import {QueryClient} from "react-query";
import TextField from "@mui/material/TextField";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface Params {
  entityId: string
}


const VacationSchedulePage = () => {
  const { entityId } = useParams<Params>()
  // var currentDay = dayjs().format('YYYY-MM-DD')
  const [currentday, setcurrentday] = useState(null);
  // var { data } = useFetchVacationScheduleList(Number(entityId), currentday);
  // setRowData(data)
  const [employeeVacationData, setEmployeeVacationData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [previousweekdate, setpreviousweekdate] = useState(null);
  const [nextweekdate, setnextweekdate] = useState(null);
  const queryClient = new QueryClient()

  useEffect(() => {
    setcurrentday(dayjs().format('YYYY-MM-DD'))
  }, [])

  useEffect(() => {
    const getData = async (currentweekdate) => {
      const {data} = await axios.post(
          "/vacationcalendar",
          {entityid: entityId, currentweekdate: currentweekdate}
      );
      const parsedData = JSON.parse(data.jsonString);
      if (parsedData && parsedData.employee && parsedData.employee.length > 0) {
        const mappedData = getMappedRecordsNew(parsedData)
        setcurrentday(parsedData.currentweekdate)

        setEmployeeVacationData(mappedData)
      }
      else {
        setEmployeeVacationData([])
      }

      setnextweekdate(parsedData.nextweekdate)
      setpreviousweekdate(parsedData.previousweekdate)
    }

    if (currentday) {
      getData(currentday)
    }

  }, [currentday]);

  function CustomGridToolbar() {
    const apiRef = useGridApiContext();

    const handleExport = (options: GridCsvExportOptions) =>
        apiRef.current.exportDataAsCsv(options);

    const nextbuttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      endIcon: <ArrowForwardIcon />,
    };

    const backButtonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <ArrowBackIcon />,
    };

    const restoreButtonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <SettingsBackupRestore />,
    };

    return (
        <GridToolbarContainer >
          <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
          >
            <Button
                {...backButtonBaseProps}
                variant="contained"
                onClick={() => setcurrentday(previousweekdate)}
            >
              Previous Week
            </Button>
            <Button
                // onClick={() => handleRestore()}
            >
              {currentday}
            </Button>
            <Button
                {...nextbuttonBaseProps}
                variant="contained"
                onClick={() => setcurrentday(nextweekdate)}
            >
              Next Week
            </Button>
            {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
          </Grid>
        </GridToolbarContainer>
    )
  }
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 520,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const buildDayComp = (dayArray) => {
    return dayArray?.map((day) => {
      var hoverDAnger = React.createElement("div", { dangerouslySetInnerHTML: { __html: day.hover } });
       return ( <div>
        <HtmlTooltip
        title={ <React.Fragment>
          {hoverDAnger}
        </React.Fragment>
      }
    >
              <div style={{'backgroundColor': day.color}}>{day.description} </div>

    </HtmlTooltip></div>
    )
      //  return ( <div title={day.hover} style={{'backgroundColor': day.color}}>{day.description} </div> )
    })
  }

  const buildColumnDefs = () => {
    var gridDefs: GridColDef[] = [];

    var employeeInfoCol : GridColDef = { field: "employeeinfo", headerName: "Employee", width: 350 }
    gridDefs.push(employeeInfoCol)

    var col: GridColDef = {
      "field": "Mon",
      "headerName": "Monday",
      "type": "string",
      "width": 150,
    }
    col.renderCell = (params: any) =>  {
      return (
        <>
          <div style={{float: 'left', clear: 'none'}}>
            {buildDayComp(params.row.Monobject)}
          </div>
        </>
    ) }
    gridDefs.push(col)

    var col: GridColDef = {
      "field": "Tue",
      "headerName": "Tuesday",
      "type": "string",
      "width": 150,
    }
    col.renderCell = (params: any) =>  {
      return (
        <>
          <div style={{float: 'left', clear: 'none'}}>
            {buildDayComp(params.row.Tueobject)}
          </div>
        </>
    ) }
    gridDefs.push(col)

    var col: GridColDef = {
      "field": "Wed",
      "headerName": "Wednesday",
      "type": "string",
      "width": 150,
    }
    col.renderCell = (params: any) =>  {
      return (
        <>
          <div style={{float: 'left', clear: 'none'}}>
            {buildDayComp(params.row.Wedobject)}
          </div>
        </>
    ) }
    gridDefs.push(col)

    var col: GridColDef = {
      "field": "Thu",
      "headerName": "Thursday",
      "type": "string",
      "width": 150,
    }
    col.renderCell = (params: any) =>  {
      return (
          <>
            <div style={{float: 'left', clear: 'none'}}>
              {buildDayComp(params.row.Thuobject)}
            </div>
          </>
      ) }
    gridDefs.push(col)

    var col: GridColDef = {
      "field": "Fri",
      "headerName": "Friday",
      "type": "string",
      "width": 150,
    }
    col.renderCell = (params: any) =>  {
      return (
          <>
            <div style={{float: 'left', clear: 'none'}}>
              {buildDayComp(params.row.Friobject)}
            </div>
          </>
      ) }
    gridDefs.push(col)

    var col: GridColDef = {
      "field": "Sat",
      "headerName": "Saturday",
      "type": "string",
      "width": 150,
    }
    col.renderCell = (params: any) =>  {
      return (
          <>
            <div style={{float: 'left', clear: 'none'}}>
              {buildDayComp(params.row.Satobject)}
            </div>
          </>
      ) }
    gridDefs.push(col)

    var col: GridColDef = {
      "field": "Sun",
      "headerName": "Sunday",
      "type": "string",
      "width": 150,
    }
    col.renderCell = (params: any) =>  {
      return (
          <>
            <div style={{float: 'left', clear: 'none'}}>
              {buildDayComp(params.row.Sunobject)}
            </div>
          </>
      ) }
    gridDefs.push(col)

    return gridDefs
  }

  const getMappedRecordsNew = (dataNew) => {
    return dataNew?.employee.map((employeeData, i) => {
      const dateList = getDateList(employeeData.datelist)

      return {
        id: employeeData.employeeid,
        employeeid: employeeData.employeeid,
        employeeinfo: employeeData.employeeinfo,
        ...dateList
      }
    })
  }

  const getDateList = (dateList) => {
    var newData = {}
    dateList?.map((dataData, i) => {
      const dayOfWeek = dayjs(dataData.date).format('ddd')

      if (newData[dayOfWeek + 'object']) {
        newData[dayOfWeek + 'object'].push({...dataData})
      }
      else {
        newData[dayOfWeek + 'object'] = []
        newData[dayOfWeek + 'object'].push({...dataData})
      }
    })
    return newData
  }


  return (
    <div className={styles.root}>
      <Grid container direction="column">
        <Grid item>

        </Grid>
          <Grid item>
            <Box sx={{ height: '83vh', width: '100%' }}>
            <DataGridPro
              rows={employeeVacationData}
              columns={buildColumnDefs()}
              getRowHeight={() => 'auto'}
              //autoHeight={true}
              // density={"compact"}
              // pageSize={10}
              onRowClick={(params) => {
                setSelectedEmployee(params.row);
                setActiveTab(1);
              }}
              components={{
                Toolbar: CustomGridToolbar,
              }}
              // getRowHeight={({ id, densityFactor }: GridRowHeightParams) => {
              //     return 600 * densityFactor;
              // }}
            />
            </Box>
        </Grid>
      </Grid>
    </div>
  );
  
  
};

export default VacationSchedulePage;
