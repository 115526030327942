import React, {useEffect, useMemo, useState} from "react";
import styles from './Divisions.module.css'
import {
    useDeleteDivisions,
    useFetchDivisions,
    DivisionsData,
    DivisionsDataObject,
    DivisionsDataDefaults
} from "../../../queries/useDivisions";
import {GridSelectionModel, GridSortItem, GridValueFormatterParams} from "@material-ui/x-grid";
import {useQueryClient} from 'react-query'
import {
    DataGridPro,
    GridCallbackDetails,
    GridCellParams,
    GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
    GridRowParams, gridSortedRowIdsSelector,
    GridSortModel, GridToolbarContainer,
    MuiEvent, useGridApiContext,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import {useUpdateGridState} from "../../../queries/useGridState";
import {Alert, Badge, BadgeProps, FormGroup, Grid, MenuItem, Select, SelectChangeEvent, styled} from "@mui/material";
import {NotesIcon} from "../../../components/common/NotesLayout/NotesLayout";
import Button from '@mui/material/Button';
import {Box, Dialog, DialogActions, DialogContent, DialogContentText, IconButton} from "@material-ui/core";
import {Delete, Edit, SettingsBackupRestore} from "@material-ui/icons";
import {Add, Save} from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import {DivisionsForm} from "./DivisionsForm";
import {globalFetcher, DivisionDropdowns} from "../../../queries/useDivisionDropdowns";
import axios from "axios";
import dayjs from "dayjs";
import HookForm from "../../../components/common/HookForm";
import {ButtonProps} from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const DivisionsPage = (params) => {

    console.log(params.workOrderObj)
    let _sortModelState: any = null
    const [gridShowing, setGridShowing] = useState(false)
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState({ ...DivisionsDataDefaults, divisionsDropDownData: {} })
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObjDel, setDialogObjDel] = React.useState({ msg: "", id: 0 })
    const [dialogObj, setDialogObj] = React.useState({ msg: "", gridState: "", okButtonText: "Save" })
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const [dataRows, setDataRows] = React.useState([]);
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([1]);
    const gridAPIRef = useGridApiRef()
    const updateGridState = useUpdateGridState()
    const deleteDisisions = useDeleteDivisions()

    const gridSortItem: GridSortItem = {
        field: 'wocustomercode',
        sort: 'asc'
    }
    const defaultGridSortModel: GridSortModel = [gridSortItem]
    const [gridSortModel, setGridSortModel] = React.useState<GridSortModel>(defaultGridSortModel)

    // Query for the divisions relalted to the work order
    const queryClient = useQueryClient()
    React.useEffect(() => {
        queryClient.invalidateQueries(['divisiondata'])
    }, [params.workOrderObj])

    const { data } = useFetchDivisions(params?.workOrderObj?.woid)
    useEffect(() => {
        setGridSortModel(getColumnSortModel())
        if (data?.divisionlist && data?.divisionlist?.length > 0) {
            const dataRows = getMappedRecords()
            setDataRows(dataRows)
        }
    }, [data])

    const getMappedRecords = () => {
        return data?.divisionlist.map((divisionData: DivisionsData, i) => {
            return {
                id: divisionData.divisionid,
                canedit: divisionData.canedit,
                divisionid: divisionData.divisionid,
                divisionwoid: divisionData.divisionwoid,
                divisionentityid: divisionData.divisionentityid,
                divisionentity: divisionData.divisionentity,
                divisionstatus: divisionData.divisionstatus,
                divisionstatusid: divisionData.divisionstatusid,
                divisionjpid: divisionData.divisionjpid,
                divisionjp: divisionData.divisionjp,
                divisionworktype: divisionData.divisionworktype,
                divisionworktypeids: divisionData.divisionworktypeids,
                divisionpricingmethodid: divisionData.divisionpricingmethodid,
                divisionpricingmethod: divisionData.divisionpricingmethod,
                divisionaddeddate: divisionData.divisionaddeddate,
                divisioncustomercodeid: divisionData.divisioncustomercodeid,
                divisioncustomerinfo: divisionData.divisioncustomerinfo,
                divisioncustomercode: divisionData.divisioncustomercode,
                divisioncontractid: divisionData.divisioncontractid,
                divisionprojectmanagername: divisionData.divisionprojectmanagername,
                divisionprojectmanagerid: divisionData.divisionprojectmanagerid,
                divisioncustomerfeid: divisionData.divisioncustomerfeid,
                divisioncustomerpmid: divisionData.divisioncustomerpmid,
                divisioncustomerpmname: divisionData.divisioncustomerpmname,
                divisioncustomerceid: divisionData.divisioncustomerceid,
                divisionworkcompletedate: divisionData.divisionworkcompletedate,
                divisionqaqccompletedate: divisionData.divisionqaqccompletedate,
                divisionapproveddate: divisionData.divisionapproveddate,
                notescount: divisionData.notescount,
                notestypeid: divisionData.notestypeid,
                noteslinkid: divisionData.noteslinkid,
                notestitle: divisionData.notestitle,
                divisionasbuilduploaddate: divisionData.divisionasbuilduploaddate,
                divisionsubmittedcustomerdate: divisionData.divisionsubmittedcustomerdate,
                divisionpaperworkuploaddate: divisionData.divisionpaperworkuploaddate,
                divisioncustomercomment1: divisionData.divisioncustomercomment1,
                divisioncustomercomment2: divisionData.divisioncustomercomment2
            }
        })
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    }

    const basicProps = {
        disableColumnMenu: true,
        sortable: false,
        disableExport: true,
        disableReorder: true,
        editable: false,
        filterable: false,
        groupable: false,
        hideable: false,
        pinnable: false
    }

    const HandleEdit = async (params: GridCellParams) => {
        const divisionData = data.divisionlist.filter((item) => item.divisionid === Number(params.id))[0]
        console.log(divisionData)
        const divisionDropdownData = await globalFetcher(divisionData.divisionid)
        setGridParams({
            canedit: divisionData.canedit,
            divisionid: divisionData.divisionid,
            divisionwoid: divisionData.divisionwoid,
            divisionentityid: divisionData.divisionentityid,
            divisionentity: divisionData.divisionentity,
            divisionstatus: divisionData.divisionstatus,
            divisionstatusid: divisionData.divisionstatusid,
            divisionjpid: divisionData.divisionjpid,
            divisionjp: divisionData.divisionjp,
            divisionworktype: divisionData.divisionworktype,
            divisionworktypeids: divisionData.divisionworktypeids,
            divisionpricingmethodid: divisionData.divisionpricingmethodid,
            divisionpricingmethod: divisionData.divisionpricingmethod,
            divisionaddeddate: divisionData.divisionaddeddate,
            divisioncustomercodeid: divisionData.divisioncustomercodeid,
            divisioncustomerinfo: divisionData.divisioncustomerinfo,
            divisioncustomercode: divisionData.divisioncustomercode,
            divisioncontractid: divisionData.divisioncontractid,
            divisionprojectmanagername: divisionData.divisionprojectmanagername,
            divisionprojectmanagerid: divisionData.divisionprojectmanagerid,
            divisioncustomerfeid: divisionData.divisioncustomerfeid,
            divisioncustomerpmid: divisionData.divisioncustomerpmid,
            divisioncustomerpmname: divisionData.divisioncustomerpmname,
            divisioncustomerceid: divisionData.divisioncustomerceid,
            divisionworkcompletedate: divisionData.divisionworkcompletedate,
            divisionqaqccompletedate: divisionData.divisionqaqccompletedate,
            divisionapproveddate: divisionData.divisionapproveddate,
            divisionsDropDownData: divisionDropdownData,
            notescount: divisionData.notescount,
            notestypeid: divisionData.notestypeid,
            noteslinkid: divisionData.noteslinkid,
            notestitle: divisionData.notestitle,
            divisionasbuilduploaddate: divisionData.divisionasbuilduploaddate,
            divisionsubmittedcustomerdate: divisionData.divisionsubmittedcustomerdate,
            divisionpaperworkuploaddate: divisionData.divisionpaperworkuploaddate,
            divisioncustomercomment1: divisionData.divisioncustomercomment1,
            divisioncustomercomment2: divisionData.divisioncustomercomment2,
        })
        setReloadForm(reloadForm+1)
        setIsFormShowing(true)
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObjDel({
            msg: 'Are you sure you want to delete work order # \"' + params.row.wocustomerworkorder.toString() + "\"?",
            id: Number(params.id)
        })
    }

    const canEdit = (params: GridCellParams) => {
        return params.row.canedit
    }

    const canDelete = (params: GridCellParams) => {
        return params.row.candelete
    }

    const handleDeleteConfirm = async () => {
        // const { data: response } = await deleteWorkOrder.mutateAsync({ woid: dialogObjDel.id })
        // closeDialog()
    }

    const buildColumnDefs = () => {
        const gridObj = parseGridObject()
        const parsedGridColumns = gridObj.gridDefs
        var gridColumns: GridColDef[] = [
            gridEditSaveButtonDef,
            // gridIdDef,
            ...parsedGridColumns,
            gridButtonsDef
        ]
        return gridColumns
    }

    const getColumnSortModel = () => {
        const gridObj = parseGridObject()
        return gridObj.sortModel
    }

    const formatDate = (params: GridValueFormatterParams) => {
        return (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DD").format('MM/DD/YYYY') : '')
    }

    const parseGridObject = () => {
        var sortModel: GridSortModel = [gridSortItem]
        var gridStateString = JSON.stringify({gridType: "division", gridState: JSON.stringify(gridDefaults)})
        if (params.gridStateData && params.gridStateData.length > 0) {
            gridStateString = params.gridStateData[0].gridState
            const gridStateData = JSON.parse(gridStateString)
            const sendObj = {
                gridType: 'division',
                gridState: JSON.stringify(gridStateData.columnDefs)
            }
            gridStateString = JSON.stringify(sendObj)

            if (gridStateData && gridStateData.sortModel !== null && gridStateData.sortModel.length > 0) {
                // gridAPIRef?.current?.setSortModel(gridStateData.sortModel)
                const dataSort = gridStateData.sortModel
                sortModel = dataSort
            }
        }

        var gridDefs: GridColDef[] = [];
        if (gridStateString.length > 0) {
            const gridObject = JSON.parse(gridStateString)
            if (gridObject.gridType === 'division') {
                const gridState = JSON.parse(gridObject?.gridState)
                gridState?.forEach(state => {
                    var col: GridColDef = {...state}

                    if (col.field === 'divisionworkcompletedate') {
                        col.valueFormatter = formatDate
                    }

                    gridDefs.push(col)
                })
            }
        }
        return { gridDefs: gridDefs, sortModel: sortModel }
    }

    const gridDefaults: GridColDef[] = [
        {
            "field":"divisionentity",
            "headerName":"Entity",
            "headerAlign":"left",
            "type":"string",
            "width":100,
            "align":"left"
        },
        {
            "field": "divisioncustomerpmname",
            "headerName": "Project Manager",
            "headerAlign": "left",
            "type": "string",
            "width": 250,
            "align": "left"
        },
        {
            "field": "divisionjp",
            "headerName": "Job Phase",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "field": "divisionworktype",
            "headerName": "Work Type",
            "headerAlign": "left",
            "type": "string",
            "width": 150,
            "align": "left"
        },
        {
            "field": "divisionstatus",
            "headerName": "Status",
            "headerAlign": "right",
            "type": "string",
            "width": 125,
            "align": "right"
        },
        {
            field: 'divisionworkcompletedate',
            type: 'string',
            headerName: 'Work Completed',
            width: 200,
            valueFormatter: (params) =>
                params.value ? new Date(params.value as string).toLocaleString() : 'N/A'
        },
        {
            "field": "divisioncustomercomment1",
            "headerName": "Customer Comment 1",
            "headerAlign": "right",
            "type": "string",
            "width": 225,
            "align": "right"
        },
        {
            "field": "divisioncustomercomment2",
            "headerName": "Customer Comment 2",
            "headerAlign": "right",
            "type": "string",
            "width": 225,
            "align": "right"
        },
    ]

    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: -1,
            top: 3,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }));

    const gridButtonsDef: GridColDef = {
        field: 'Delete',
        headerName: '',
        width: 80,
        align: "center",
        headerAlign: "center",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <>
                    <NotesIcon
                        id={Number(params.id)}
                        notesid={params.row.notesid}
                        notestitle={params.row.notestitle}
                        notestypeid={params.row.notestypeid}
                        noteslinkid={params.row.noteslinkid}
                        notescount={params.row.notescount}
                        queryType={['divisiondata']}
                    />
                    {(canDelete(params)) &&
                    <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                        <Delete />
                    </IconButton>
                    }

                </>
            )}
    }

    const gridEditSaveButtonDef: GridColDef = {
        field: '',
        // renderHeader: () => {
        //     return (
        //         <>
        //             <IconButton
        //                 className={styles.submitButton}
        //                 onClick={() => handleAdd()}
        //                 size="small">
        //                 <Add />
        //             </IconButton>
        //         </>
        //     )
        // },
        width: 50,
        align: "center",
        headerAlign: "center",
        pinnable: true,
        cellClassName: 'pinnedColumn',
        ...basicProps,
        renderCell: (params) => (
            <>
                {(canEdit(params)) &&
                <IconButton className={styles.button} onClick={() => { HandleEdit(params) }}>
                    <Edit />
                </IconButton>
                }
            </>
        )
    }

    const gridIdDef: GridColDef = {
        field: 'id',
        headerName: 'ID',
        hide: false,
        disableColumnMenu: true,
        sortable: false,
        disableExport: true,
        disableReorder: true,
        editable: false,
        filterable: false,
        groupable: false,
        hideable: true,
        pinnable: false
    }

    const getColumnStringToSave = () => {
        var fieldObjects = []
        const gridState = gridAPIRef.current.getAllColumns()
        gridState.forEach(stateColumnDef => {
            if (stateColumnDef.sortable && stateColumnDef.pinnable && stateColumnDef.groupable) {
                fieldObjects.push({
                    field: stateColumnDef.field,
                    headerName: stateColumnDef.headerName,
                    headerAlign: stateColumnDef.headerAlign,
                    type: stateColumnDef.type,
                    width: stateColumnDef.width,
                    align: stateColumnDef.align,
                    description: stateColumnDef.description,
                    hide: stateColumnDef.hide,
                    valueFormatter: stateColumnDef.valueFormatter
                })
            }
        })

        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: fieldObjects, sortModel: sortModelDefs})
        return strObject
    }

    const handleRestore = () => {
        setGridShowing(false)
        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: gridDefaults, sortModel: sortModelDefs})
        setDialogObj({
            msg: 'Are you sure you want to reset the grid state to default?',
            gridState: strObject,
            okButtonText: 'RESET'
        })
    }

    const handleSave = () => {
        setGridShowing(false)
        const columnObjsting = getColumnStringToSave()
        setDialogObj({
            msg: 'Are you sure you want to save the current grid state?',
            gridState: columnObjsting,
            okButtonText: 'Save'
        })
    }

    const handleOkConfirm = async () => {
        var finalData = {
            gridType: params.gridStateVersion,
            gridState: dialogObj.gridState
        }
        const { data: response } = await updateGridState.mutateAsync(finalData)
        closeDialog()
        setGridShowing(true)
        setSnackbarOpen(true);
    }

    const closeDialog = () => {
        setGridShowing(true)
        setDialogObj({
            msg: '',
            gridState: '',
            okButtonText: ''
        })
        setDialogObjDel({
            msg: '',
            id: 0
        })
    }

    const handleRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        // const woObjectList = data.workorderlist.filter((workOrder) => { return workOrder.woid === Number(param.id)})
        // if (woObjectList && woObjectList.length > 0) {
        //     params.setWorkOrderObj(woObjectList[0])
        // }
    }

    const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
        gridSortedRowIdsSelector(apiRef);

    function CustomGridToolbar() {
        const apiRef = useGridApiContext();

        const handleExport = (options: GridCsvExportOptions) =>
            apiRef.current.exportDataAsCsv(options);

        const buttonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <FileDownloadOutlinedIcon />,
        };

        const saveButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <Save />,
        };

        const restoreButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <SettingsBackupRestore />,
        };

        return (
            <GridToolbarContainer >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        {...saveButtonBaseProps}
                        onClick={() => handleSave()}
                    >
                        SAVE GRID SETTINGS
                    </Button>
                    <Button
                        {...restoreButtonBaseProps}
                        onClick={() => handleRestore()}
                    >
                        RESTORE GRID SETTINGS
                    </Button>
                    <Button
                        {...buttonBaseProps}
                        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
                    >
                        EXPORT
                    </Button>
                    {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                </Grid>
            </GridToolbarContainer>
        )
    }


    return (
        <div className={styles.root}>
            <>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={4000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert severity="success">
                        Work order settings have been saved!
                    </Alert>
                </Snackbar>
            </>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleOkConfirm}>{dialogObj.okButtonText}</Button>
                    </DialogActions>
                </Dialog>
            </>

            <>
                <Dialog
                    open={dialogObjDel.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObjDel.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {/*{gridShowing ?*/}
                    <DataGridPro
                        sx={{
                            "& .MuiDataGrid-pinnedColumns": {
                                boxShadow: "none",
                                backgroundColor: "transparent"
                            },
                            "& .MuiDataGrid-pinnedColumnHeaders": {
                                boxShadow: "none",
                                backgroundColor: "transparent"
                            }
                        }}
                        apiRef={gridAPIRef}
                        rows={dataRows}
                        columns={buildColumnDefs()}
                        autoHeight={true}
                        density={'compact'}
                        pageSize={10}
                        // headerHeight={gridShowing ? 75 : 0}
                        // loading={!data}
                        sortModel={gridSortModel}
                        disableMultipleSelection={true}
                        onSortModelChange={(model: GridSortModel) => setGridSortModel(model)}
                        onStateChange={(state) => {
                            // setGridSortModel(state.sorting.sortModel)
                            _sortModelState = state.sorting.sortModel

                            return state
                        }}
                        initialState={ {
                            pinnedColumns: { left: ['Edit'], right: ['Notes', 'Delete'] },
                            sorting: {
                                sortModel: gridSortModel
                            }
                        }}
                        onRowClick={handleRowClick}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                        components={{
                            Toolbar: CustomGridToolbar,
                        }}
                    />
                    <DivisionsForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} />
                </Grid>
            </Grid>
        </div>
    );
}

export default DivisionsPage
