import React, {useEffect, useMemo, useState} from "react";
import styles from './BulkDispatchPage.module.css'
import {usePutBulkDispatch, BulkDispatch} from "../../../queries/useBulkForm";

import {GridSelectionModel, GridSortItem, GridValueFormatterParams} from "@material-ui/x-grid";
import {useQueryClient} from 'react-query'
import {
    DataGridPro,
    GridCallbackDetails,
    GridCellParams,
    GridColDef,
    GridRowParams,
    GridSortModel,
    MuiEvent,
    useGridApiRef
} from "@mui/x-data-grid-pro";

import dayjs from "dayjs";

import {Alert, Badge, BadgeProps, FormGroup, Grid, MenuItem, Select, SelectChangeEvent, styled} from "@mui/material";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Typography
} from "@material-ui/core";
import BulkDispatchPage from "./BulkDispatchPage";



const BulkDispatchSelectGridForm = (params) => {
    const gridAPIRef = useGridApiRef()
    const putDispatch = usePutBulkDispatch()

    var amountRows = []
    if (params.ddata && params.ddata.length > 0) {
        amountRows = params.ddata?.map((bd: BulkDispatch, i) => {

            return {
                id: i,
                checked: bd.checked,
                customerinfo: bd.customerinfo,
                entity: bd.entity,
                scheduleid:bd.scheduleid,
                schedulenumber:bd.schedulenumber,
                senddate: bd.senddate,
                staffid: bd.staffid,
                staffinfo: bd.staffinfo,
                startdate:bd.startdate,
                woid: bd.woid
            }
            
        })
    }
    const handleSubmitSelection = () => {
        console.log(gridAPIRef.current?.getSelectedRows())
        var selectedRows = []
        selectedRows =Array.from(gridAPIRef.current.getSelectedRows()).map((bd) => {
            var curVal = amountRows[bd[0]]
            return {
                scheduleid: curVal.scheduleid,
                staffid: curVal.staffid
            }
        })
        // selectedRows = gridAPIRef.current?.getSelectedRows.map((bd: BulkDispatch) => {
        //     return {
        //         scheduleid: bd.scheduleid,
        //         staffid: bd.staffid
        //     }
        // })
        console.log(selectedRows)
        putDispatch.mutateAsync({dispatchlist:selectedRows}).then((response) => { 
            params.setDialogMessage('All of selected Schedule Items have been marked as dispatched and will be available on users calendar');
            params.setOpen(true);

          })
          .catch((error) => {
            // Handle error case
            console.error("Error during dispatch:", error);
            params.setDialogMessage('Failed to dispatch data.'); // Adjust based on your error handling needs
            params.setOpen(true);
          });
        
    }
    React.useEffect(() => {
        if (amountRows && amountRows.length > 0) {
            amountRows.forEach(element => {
                if(element.checked){
                    gridAPIRef.current?.selectRows([element.id])
                }});
        }
    }, [amountRows]);
    const amountColumns: GridColDef[] = [
        {
            field: 'id',
            hide: true
        },{
            field: 'scheduleid',
            hide: true
        },
        {
            field: 'staffid',
            hide: true
        },
        {
            field: 'woid',
            type: 'string',
            headerName: 'WO',
            width: 75
        },
        {
            field: 'startdate',
            type: 'date',
            headerName: 'Date',
            width: 125,
            valueFormatter: (params) =>
                (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DD").format('MM/DD/YYYY') : 'N?A')
        },
        {
            field: 'customerinfo',
            type: 'string',
            headerName: 'Customer',
            width: 175
        },
        {
            field: 'staffinfo',
            type: 'string',
            headerName: 'Staff',
            width: 175
        },
        {
            field: 'senddate',
            type: 'date',
            headerName: 'Previous Send',
            width: 125,
            valueFormatter: (params) =>
                (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DD").format('MM/DD/YYYY') : '')
        }
    ]


    return (
        <div className={styles.root}>
            
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <DataGridPro
                        apiRef={gridAPIRef}

                        rows={amountRows}
                        columns={amountColumns}
                        //getRowId={(row: any) =>  row.staffid + row.scheduleid}
                        autoHeight={true}
                        density={'compact'}
                        pageSize={10}
                        checkboxSelection
                       
                    />
                </Grid>
                <Grid item xs={12}>
            <Button 
                onClick={handleSubmitSelection}
                style={{width: '180px', height: '30px', marginLeft: '5px'}}
                variant="contained"
            >
                Dispatch
            </Button>
        </Grid>
            </Grid>
            
        </div>
    );
}


export default BulkDispatchSelectGridForm
