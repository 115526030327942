import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  BulkDispatch {
    staffid: number
    checked: boolean
    scheduleid: number
    woid: number
    entity: string
    schedulenumber: number
    customerinfo: string
    startdate: string
    staffinfo: string
    senddate: string
}

export const BulkDispatchDefaults = {
   staffid: 0,
   checked : true,
   scheduleid: 0,
   woid: 0,
   entity: "" ,
   schedulenumber: 0,
   customerinfo:"",
   startdate:"",
   staffinfo:"",
   senddate:""
}


export const usePutBulkDispatch = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put('bulkdispatch', body), {
        onSuccess: (data) => {
            console.log(data)
            //queryClient.invalidateQueries(['bulkdispatch'])
        }
    })
}

