import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../components/common/HookForm'
import styles from './DispatchPage.module.css'
import TextInput from "../../components/common/inputs/TextInput";
import SelectInput from "../../components/common/inputs/SelectInput";
import {useUpdateLookup} from "../../queries/useLookup";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {ensureEntitiesArray} from "@reduxjs/toolkit/dist/entities/utils";
import MaskedInput from "../../components/common/inputs/MaskedInput";
import AutocompleteSelect from "../../components/common/inputs/AutoCompleteSelectInput/AutocompleteSelect"

interface FormData {
    equipmentid: number
    equipment: string
    quantity: number
}

export const EquipmentForm = ({ isOpen, setIsOpen, reloadForm, equipmentlist, equipmentData, setEquipmentData }) => {
    const formMethods = useForm<FormData>()
    const title = "Create Equipment"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateLookup = useUpdateLookup()
    // const [equipmentActiveSelect, setEquipmentActiveSelect] = React.useState<string>("")
    // const [equipmentActiveSelectId, setEquipmentActiveSelectId] = React.useState<number>()

    useEffect(() => {
        reset({
            equipment: "",
            equipmentid: null,
            quantity: 0
        })
        // setEquipmentActiveSelect(null)
        // setEquipmentActiveSelectId(null)
    }, [reloadForm])

    const onSubmit = async (data: FormData) => {
        const selection = equipmentlist?.filter((item) => item.equipmentid === data.equipmentid)
        if (selection && selection.length > 0) {
            const finalData = [...equipmentData, {
                equipment: selection[0].equipment,
                id: data.equipmentid,
                equipmentid: data.equipmentid,
                quantity: data.quantity
            }]
            setEquipmentData(finalData)
        }

        setIsOpen(false)
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const getEquipmentOptions = () => {
        return equipmentlist?.map(item =>{
            return {value:item.equipmentid, label:item.equipment}
           
    })
    }

    // const handleEquipmentChange = (event) => {
    //     setEquipmentActiveSelect(event.target.value as string)
    //     const selection = equipmentlist?.filter((item) => item.equipment === event.target.value as string)
    //     if (selection && selection.length > 0) {
    //         setEquipmentActiveSelectId(selection[0].equipmentid)
    //     }
    // }

    return (
        <Dialog open={isOpen} fullWidth={false} maxWidth="xl" className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent style={{maxWidth: "280px"}}>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>

                    {/*<div className={styles.multipleInputContainer}>*/}
                        <FormControl fullWidth style={{maxWidth: "280px"}}>
                            <AutocompleteSelect 
                                name="equipmentid"
                                label="Equipment"
                                options={getEquipmentOptions()}
                                required
                                />
                            {/* <InputLabel>Equipment*</InputLabel>
                            <Select
                                name="equipment"
                                label="Equipment"
                                value={equipmentActiveSelect}
                                size={'small'}
                                required={true}
                                variant="outlined"
                                disabled={false}
                                onChange={handleEquipmentChange}
                            >
                                {getEquipmentOptions()}
                            </Select> */}
                        </FormControl>
                    <FormControl fullWidth style={{maxWidth: "280px"}}>
                        <MaskedInput
                            name="quantity"
                            label="Quantity"
                            thousandSeparator={false}
                            thousandsGroupStyle={""}
                            prefix=""
                            rules={{
                                validate: (val) => (val > 0 && val < 999999) || 'Please enter a valid quantity between 1 and 999999.',
                            }}
                            required />
                    </FormControl>
                    {/*</div>*/}
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
