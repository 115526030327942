import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {WorkOrderData} from "./useWorkOrder";


export const useFetchDocuments = (woid: number) => {
    return useQuery(
        ['gdwodocuments', woid],
        () => globalFetcher(woid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUploadDocument = (woid) => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`gdwodocumentsfile`, body), {
        onSuccess: (response) => {
            console.log(response)
            if (response.status === 200) {
                const parsedData = JSON.parse(response.data.jsonString)
                if (parsedData.status === 'failed') {
                    throw new Error(parsedData.message)
                }
                else {
                    queryClient.invalidateQueries(['gdwodocuments', woid])
                }
            }
        }
    })
}

export const useUploadSchedeuleDocument = () => {
    const queryClient = useQueryClient()
    return useMutation((body: any) =>
        axios.put(`gdwodocumentsfileschedule`, body), {
        onSuccess: (response) => {
            if (response.status === 200) {
                const parsedData = JSON.parse(response.data.jsonString)
                if (parsedData.status === 'failed') {
                    throw new Error(parsedData.message)
                }
                else {
                    queryClient.invalidateQueries(['gdwodocuments'])
                }
            }
        }
    })
}

export const useDeleteDocuments = (woid: number) => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`gdwodocumentsfile`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['gdwodocuments', woid])
        }
    })
}

export const useDownloadDocuments = (woid: number) => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.post(`gdwodocumentsfile`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['gdwodocuments', woid])
        }
    })
}

export const useCopyDocuments = (woid: number) => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.post(`gdwodocumentsfilecopy`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['gdwodocuments', woid])
        }
    })
}

export const useMoveDocuments = (woid: number) => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.post(`gdwodocumentsfilemove`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['gdwodocuments', woid])
        }
    })
}

export const globalFetcher = async (id: number) => {
    const { data } = await axios.post('/gdwodocuments', {
        woid: id
    })
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    return parsedData
}
