import cn from 'classnames'
import {
  bindMenu,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import {
  AppBar,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core'
import {
  ChevronRight,
  ExitToApp as ExitIcon,
  Menu as MenuIcon,
  HomeRounded as HomeIcon,
} from '@material-ui/icons'

import { Logo } from '../../../../icons/Logo'
import { logout } from '../../../../slices/authSlice'
import styles from './Header.module.css'

const Header = ({ showMenu, isMenuOpen, setIsMenuOpen }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
  const history = useHistory()
  const dispatch = useDispatch()

  const redirectToLogin = () => {
    dispatch(logout())
    history.push('/auth/login')
  }
  const redirectToHome = () => {
    history.push('/home')
  }
  return (
    <AppBar
      position="absolute"
      className={cn(styles.appBar, isMenuOpen && styles.shift)}>
      <Toolbar className={styles.toolbar}>
        <IconButton
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            edge="start"
            aria-label="Menu">
          <MenuIcon/>
        </IconButton>
        <Typography
            variant="h6"
            color="primary"
            component="div">
          Work Order
        </Typography>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          {...bindTrigger(popupState)}>
          {/* <Typography>{user?.firstname}</Typography> */}
          <Avatar className={styles.avatar} />
          <IconButton color="inherit">
            <ChevronRight className={styles.arrow} />
          </IconButton>
        </div>
        <Menu {...bindMenu(popupState)} style={{ top: 40 }}>
          <MenuItem onClick={() => redirectToLogin()}>
            <ExitIcon style={{ marginRight: 10 }} />
            Sign Out
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default Header
