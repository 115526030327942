import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Box, Container, IconButton } from '@material-ui/core'
import Header from './Header'
import MainMenu from './MainMenu'

// eslint-disable-next-line no-unused-vars

type Props = {
  children: any
  showHome?: boolean
  showArrow?: boolean
  showMenu?: boolean
  showBackArrow?: boolean
  showOrgTitle?: boolean
}

const Layout: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
) => {
  const [isMenuOpen, setMenuIsOpen] = useState(false)

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Header showMenu={props.showMenu} isMenuOpen={isMenuOpen} setIsMenuOpen={setMenuIsOpen} />
        {isMenuOpen &&
            <MainMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setMenuIsOpen} />
        }
        <Container maxWidth={"xl"}
          style={{
            padding: '84px 20px 20px 20px',
            width: '100%',
            height: '100%',
            justifySelf: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}>
            {props.children}
        </Container>
    </Box>
  )
}

export default Layout
