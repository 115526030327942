import React from "react";
import styles from './SubcontractPage.module.css'
import {AppBar, Typography} from "@material-ui/core";

const SubcontractPage = () => {

    return (
        <div className={styles.root}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left'
                }}>
                <Typography
                    variant="h6"
                    color="primary"
                    align="center"
                    component="div">
                    Subcontract
                </Typography>
            </div>
        </div>
      )
}

export default SubcontractPage
