import React, {useEffect, useState} from "react";
import styles from './Entity.module.css'
import {IconButton, Box, AppBar, Typography} from '@material-ui/core'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { Add } from '@mui/icons-material'
import {GridSelectionModel, GridValueFormatterParams, XGrid} from '@material-ui/x-grid'
import {
    DataGridPro,
    GridColDef,
    GridCellParams,
    GridRowParams,
    GridCallbackDetails,
    MuiEvent, useGridApiRef
} from "@mui/x-data-grid-pro";

import {EntityDefaults, Entity, useDeleteEntity, useFetchEntity} from "../../../queries/useEntity";
import {EntityForm} from "./EntityForm";
import {Grid} from "@mui/material";

const EntityPage = (params) => {
    const deleteEntity = useDeleteEntity()
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState(EntityDefaults)
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const [listingIDSelected, setListingIDSelected] = React.useState<number>(null);

    var entityRows = []
    const { data } = useFetchEntity()
    if (data && data.length > 0) {
        entityRows = data?.map((entity: Entity, i) => {
            return {
                id: entity.entityid,
                entityname: entity.entityname,
                entityaccountingjob: entity.entityaccountingjob,
                entityaccountingphase: entity.entityaccountingphase,
                entityspectrum: entity.entityspectrum,
                entityspectrumcompanycode: entity.entityspectrumcompanycode,
                entityactive: entity.entityactive
            }
        })
    }


    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const amountColumns: GridColDef[] = [
        {
            field: 'Edit',
            renderHeader: () => {
                return (
                    <IconButton
                        className={styles.submitButton}
                        onClick={() => handleAdd()}
                        size="small">
                        <Add />
                    </IconButton>
                )
            },
            width: 50,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
                    <Edit />
                </IconButton>
            )
        },
        {
            field: 'id',
            hide: true
        },
        {
            field: 'entityname',
            type: 'string',
            headerName: 'Name',
            width: 125,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'entityaccountingjob',
            type: 'string',
            headerName: 'Accounting Job',
            width: 125,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'entityaccountingphase',
            type: 'string',
            headerName: 'Accounting Phase',
            width: 200,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'entityspectrum',
            type: 'string',
            headerName: 'Spectrum',
            width: 125,
            valueFormatter: (params: GridValueFormatterParams) => {
                return (params.value ? "Yes" : "No")
            },
        },
        {
            field: 'entityspectrumcompanycode',
            type: 'string',
            headerName: 'Spectrum Company Code',
            width: 200
        },
        {
            field: 'entityactive',
            type: 'string',
            headerName: 'Status',
            width: 125,
            valueGetter: (params) => {
                return (params.value ? "Active" : "Inactive")
            },
        },
        {
            field: 'action',
            headerName: '',
            width: 50,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                    <Delete />
                </IconButton>
            )
        }
    ]

    const handleEdit = (params: GridCellParams) => {
        const entity = data.filter((item) => item.entityid === Number(params.id))[0]
        setReloadForm(reloadForm+1)
        setGridParams({
            entityid: entity.entityid,
            entityname: entity.entityname,
            entityaccountingjob: entity.entityaccountingjob,
            entityaccountingphase: entity.entityaccountingphase,
            entityspectrum: entity.entityspectrum,
            entityspectrumcompanycode: entity.entityspectrumcompanycode,
            entityactive: entity.entityactive
        })
        setIsFormShowing(true)
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObj({
            msg: 'Are you sure you want to delete this entity \"' + params.getValue(params.id, 'entityname').toString() + "\"?",
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteEntity.mutateAsync({ entityid: dialogObj.id })
        closeDialog()
    }

    const closeDialog = () => {
        setDialogObj({
            msg: '',
            id: 0
        })
    }

    const handleListingRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        setListingIDSelected(Number(param.id))
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <Grid container spacing={1}>
                <AppBar position="static" color="default">
                    <Typography
                        variant="h6"
                        color="primary"
                        align="center"
                        component="div">
                        Entity
                    </Typography>
                    <EntityForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} />
                    <DataGridPro
                        // apiRef={gridAmountAPIRef}
                        rows={entityRows}
                        columns={amountColumns}
                        autoHeight={true}
                        density={'compact'}
                        pageSize={10}
                        loading={!data}
                        disableMultipleSelection={true}
                        // onRowClick={handleRowClick}
                    />
                </AppBar>
            </Grid>
        </div>
    );
}

export default EntityPage
