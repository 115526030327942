import React, { useEffect, useMemo, useState } from 'react'
import {Controller ,useController, useFormContext } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

type selectOption = {
  label: string
  value: number
}

type Props = {
  name: string
  label: string
  options: selectOption[] 
  required?: boolean
  disabled?: boolean
}

const AutocompleteSelect = ({
  name='',
  label='',
  options=[],
  required = false,
  disabled = false
}: Props) => {
  const { control, formState } = useFormContext()
  
  const {
    field: { ref, ...inputProps }
  } = useController({
    name: name,
    control
  })
  const getOpObj = (option) => {
    if(!option)
      return {}
    if (!option.value)
      option = options.find((op) => op.value === option)
     
    return option
  }
  const getOptions = () => {
    if (options && options.length > 0) {
      return options
    }
    else {
      return []
    }
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required }}
      defaultValue={{}}
      render={({ field }) => (
        <Autocomplete
          {...field}
          size={'small'}
          disabled = {disabled}
          options={getOptions()}
          getOptionLabel={(option) => getOpObj(option)?.label || ""}
          isOptionEqualToValue={(option, value) => getOpObj(option)?.value === value}
          onChange={(_, obj) => field.onChange(getOpObj(obj).value)}
          renderInput={(params) => (
            <TextField {...params} label={label} required={required}
            />
          )}
        />
      )}
    />
  )
}

export default AutocompleteSelect
