import axios from 'axios'
import { useQuery } from 'react-query'

export interface ValidToken {
    type: string
    status: string
    message: string
}

export const validTokenFetcher = async () => {
    const response = await axios.get(`/validatetoken`, {
        params: {}
    })
    const parsedResponse = JSON.parse(response?.data?.jsonString)
    return parsedResponse
}

export const useValidToken = ({
                                  onSuccess = () => null,
                                  onError = () => null
}: {
    onSuccess?: any
    onError?: any
} = {}) => {
    return useQuery<ValidToken>(
        ['validatetoken'],
        () => validTokenFetcher(),
        {
            staleTime: 120,
            onSuccess,
            onError,
            retry: false
        }
    )
}
