import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import styles from './WorkAuthorizationsPage.module.css'
import {useUpdateWorkOrder} from "../../../queries/useWorkAuthorizations";
import TextInput from "../../../components/common/inputs/TextInput";
import SelectInput from "../../../components/common/inputs/SelectInput";
import MaskedInput from "../../../components/common/inputs/MaskedInput";
import DateTimeInput from "../../../components/common/inputs/DateTimeInput";
import DateInput from "../../../components/common/inputs/DateInput";

interface FormData {
    waid: number
    waworkorder: string
    wawoid: number
    wanumber: string
    wadescription: string
    waamount: number
    waamountchange: number
    wastatus: string
    wastatusid: number
    wasubmitteddate: string
    waapproveddate: string
    warejecteddate: string
    wacomments: string
    warequestdocument: string
    waamountlastupdated: string
    waaddeduser: string
    waaddeddate: string
    waupdateduser: string
    waupdatedate: string
    waaddeduserid: number
    waupdateduserid: number
    waisactive: boolean
    notestypeid: number
    noteslinkid: number
    notestitle: string
    noteslinkidhas: boolean
    notescount: number
}

export const WorkAuthorizationsForm = ({ isOpen, setIsOpen, gridParams, reloadForm, workOrderObj, workAuthorizationDropdownData }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit Work Authorization" : "Create Work Authorization"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateWorkAuthorization = useUpdateWorkOrder()
    const [amount, setAmount] = useState<number>(0)
    const [amountChange, setAmountChange] = useState<number>(0)

    useEffect(() => {
        reset({
            waid: (gridParams ? gridParams.waid : 0),
            waworkorder: (gridParams ? gridParams.waworkorder : ""),
            wawoid: workOrderObj.woid,
            wanumber: (gridParams ? gridParams.wanumber : ""),
            wadescription: (gridParams ? gridParams.wadescription : ""),
            waamount: (gridParams ? gridParams.waamount : 0),
            waamountchange: (gridParams ? gridParams.waamountchange : 0),
            wastatus: (gridParams ? gridParams.wastatus : ""),
            wastatusid: (gridParams ? gridParams.wastatusid : 0),
            wasubmitteddate: (gridParams ? gridParams.wasubmitteddate : ""),
            waapproveddate: (gridParams ? gridParams.waapproveddate : ""),
            warejecteddate: (gridParams ? gridParams.warejecteddate : ""),
            wacomments: (gridParams ? gridParams.wacomments : ""),
            warequestdocument: (gridParams ? gridParams.warequestdocument : ""),
            waamountlastupdated: (gridParams ? gridParams.waamountlastupdated : ""),
            waaddeduser: (gridParams ? gridParams.waaddeduser : ""),
            waaddeddate: (gridParams ? gridParams.waaddeddate : ""),
            waupdateduser: (gridParams ? gridParams.waupdateduser : ""),
            waupdatedate: (gridParams ? gridParams.waupdatedate : ""),
            waaddeduserid: (gridParams ? gridParams.waaddeduserid : 0),
            waupdateduserid: (gridParams ? gridParams.waupdateduserid : 0),
            waisactive: (gridParams ? gridParams.waisactive : true),
            notestypeid: (gridParams ? gridParams.notestypeid : 0),
            noteslinkid: (gridParams ? gridParams.noteslinkid : 0),
            notestitle: (gridParams ? gridParams.notestitle : ""),
            noteslinkidhas: (gridParams ? gridParams.noteslinkidhas : true),
            notescount: (gridParams ? gridParams.notescount : 0)
        })
    }, [reloadForm])

    const onSubmit = async (data: FormData) => {
        var finalData = {
            waworkorder: data.waworkorder,
            wawoid: workOrderObj.woid,
            wanumber: data.wanumber,
            wadescription: data.wadescription,
            waamount: amount,
            waamountchange: amountChange,
            wastatus: data.wastatus,
            wastatusid: data.wastatusid,
            wasubmitteddate: data.wasubmitteddate,
            waapproveddate: data.waapproveddate,
            warejecteddate: data.warejecteddate,
            wacomments: data.wacomments,
            warequestdocument: data.warequestdocument,
            waamountlastupdated: data.waamountlastupdated,
            waaddeduser: data.waaddeduser,
            waaddeddate: data.waaddeddate,
            waupdateduser: data.waupdateduser,
            waupdatedate: data.waupdatedate,
            waaddeduserid: data.waaddeduserid,
            waupdateduserid: data.waupdateduserid,
            waisactive: data.waisactive
        }
        if (data.waid > 0) {
            finalData['waid'] = data.waid
        }
        const { data: response } = await updateWorkAuthorization.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const handleAmount = (event) => {
        setAmount(event.floatValue)
    }

    const handleAmountChange = (event) => {
        setAmountChange(event.floatValue)
    }

    const getstatusListOptions = () => {
        return workAuthorizationDropdownData?.statuslist?.map(item => {
            return { label: item.statuscode, value: item.statusid }
        })
    }

    return (
        <>
            <Dialog open={isOpen}
                    fullWidth={true}
                    maxWidth="xl"
                    className={styles.dialog}>
                <DialogTitle>{title}</DialogTitle>
                {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
                <>
                    <Dialog
                        open={dialogMsg.length > 0}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {dialogMsg}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog}>Ok</Button>
                        </DialogActions>
                    </Dialog>
                </>
                <DialogContent>
                    <HookForm
                        methods={formMethods}
                        onSubmit={onSubmit}
                        className={styles.itemsForm}
                        formProps={{ id: 'items-form' }}>
                        <div className={styles.multipleInputContainer}>
                            <div className={styles.multipleInputContainer}>
                                <TextInput
                                    name="wanumber"
                                    label="Number"
                                    required={true}
                                />
                                <TextInput
                                    name="wadescription"
                                    label="Description"
                                />
                            </div>
                            <div className={styles.multipleInputContainer}>
                                <MaskedInput
                                    name="waamount"
                                    label="Amount"
                                    defaultValue="0"
                                    onValueChange={handleAmount}
                                    required={true}
                                />
                                <MaskedInput
                                    name="waamountchange"
                                    label="Amount Change"
                                    defaultValue="0"
                                    onValueChange={handleAmountChange}
                                />
                            </div>
                        </div>
                        <div className={styles.multipleInputContainer}>
                            <SelectInput
                                name="wastatusid"
                                label="Status"
                                options={getstatusListOptions()}
                                required
                            />
                            <DateInput name="warejecteddate" label="Rejectede Date" defaultValue={new Date().toISOString()} required={true} />
                        </div>
                        <div className={styles.multipleInputContainer}>
                            <DateInput name="wasubmitteddate" label="Submitted Date" defaultValue={new Date().toISOString()} required={true} />
                            <DateInput name="waapproveddate" label="Approved Date" defaultValue={new Date().toISOString()} required={true} />
                        </div>
                        <div className={styles.multipleInputContainer}>
                            <SelectInput
                                name="cwalactive"
                                label="Record Status"
                                options={[
                                    { label: 'Active', value: true },
                                    { label: 'Inactive', value: false }
                                ]}
                                required
                            />
                            <TextInput
                                name="warequestdocument"
                                label="Request Document"
                            />
                        </div>
                        <TextInput
                            name="waacomment"
                            label="Comment"
                        />
                    </HookForm>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className={styles.submitButton}
                        type="submit"
                        form="items-form">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
