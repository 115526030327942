import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  Employee {
    id: number
    entityid: number
    code : string
    title : string
    isforeman : boolean
    isgeneralforeman : boolean
    first : string
    last : string
    address1 : string
    address2 : string
    address3 : string
    city : string
    state : string
    zip : string
    phone : string
    email : string
    active : boolean
}

export const EmployeeDefaults = {
    id: 0,
    entityid: 0,
    code : "",
    title : "",
    isforeman : false,
    isgeneralforeman : false,
    first : "",
    last : "",
    address1 : "",
    address2 : "",
    address3 : "",
    city : "",
    state : "",
    zip : "",
    phone : "",
    email : "",
    active : true
}

export const useFetchEmployee =  () => {
    return useQuery<Employee[]>(
        ['Employee'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdateEmployee = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`Employee`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['Employee'])
        }
    })
}

export const useDeleteEmployee = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`Employee`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['Employee'])
        }
    })
}

export const globalFetcher = async () => {
    const { data } = await axios.post('/Employee', {})
    const parsedData = JSON.parse(data.jsonString)

    return parsedData.employeelist as Employee[]
}
