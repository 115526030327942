import React, { useEffect, useRef, useState } from 'react'
import styles from './DispatchPage.module.css'
import {
    AppBar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton
} from '@material-ui/core'
import SignatureCanvas from 'react-signature-canvas'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import {
    Alert,
    CardHeader,
    FormControl,
    Grid,
    ImageList,
    ImageListItem,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material'
import { useParams } from 'react-router'
import { useFetchDispatch, useUpdateDispatch, useUpdateTempDispatch } from '../../queries/useDispatch'
import HookForm from '../../components/common/HookForm'
import { useForm } from 'react-hook-form'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import WorkAuthorizationTable from '../WorkOrderPage/WorkAuthorizations/WorkAuthorizationTable'
import cn from 'classnames'
import { Wrapper, Status } from '@googlemaps/react-wrapper'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro'
import { Add } from '@mui/icons-material'
import { Delete, Edit } from '@material-ui/icons'
import { EquipmentForm } from './EquipmentForm'
import { TimeEntryForm } from './TimeEntryForm'
import Dropzone from "react-dropzone";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import axios from "axios";
import PdfViewerComponent from "../../components/PdfViewerComponent";
import dayjs from "dayjs";
import LoadingBackdrop from "../../components/common/LoadingBackdrop";
import Snackbar from "@mui/material/Snackbar";
import { DBConfig } from './DBConfig';
import {initDB, useIndexedDB} from 'react-indexed-db';
import {setNetworkState, useNetworkOnline} from "../../slices/authSlice";

initDB(DBConfig);

interface Params {
    userGuid: string
}

const DispatchPage = () => {
    const [disableSubmit, setDisableSubmit] = useState(false)
    const formMethods = useForm<FormData>()
    const { setValue, watch, getValues } = formMethods
    const [equipmentData, setEquipmentData] = React.useState([])
    const [equipmentFormData, setEquipmentFormData] = React.useState([])
    const { userGuid } = useParams<Params>()
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [isTEFormShowing, setIsTEFormShowing] = useState(false)
    const [reloadTEForm, setReloadTEForm] = useState<number>(0)
    const [gridParams, setGridParams] = useState({})
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObj, setDialogObj] = React.useState({ msg: '', id: 0 })
    const [dialogTimeEntryObj, setDialogTimeEntryObj] = React.useState({ msg: '', starttravel: new Date(), employeeid: 0 })
    const [dialogObjPhoto, setDialogObjPhoto] = React.useState({ msg: '', name: '' })
    const [dialogMsg, setDialogMsg] = React.useState("")
    const [photos, setPhotos] = React.useState([])
    const [documents, setDocuments] = React.useState([])
    const [selectedPath, setSelectedPath] = React.useState('');
    const [dispatchData, setDispatchData] = React.useState(null);
    const [vehicleList, setVehicleList] = React.useState(null);
    const [employeeList, setEmployeeList] = React.useState(null);
    const [watchLogoData, setWatchLogoData] = React.useState(null)
    const [viewDataBody, setViewDataBody] = React.useState({id: ''})
    const [watchLogoDataURL, setWatchLogoDataURL] = React.useState(null)
    const [openViewer, setOpenViewer] = React.useState(false)
    const [commentText, setCommentText] = React.useState('')
    const [titleMessage, setTitleMessage] = React.useState('Loading Schedule')
    const [timeEntries, setTimeEntries] = React.useState([])
    const [teEdit, setTEEdit] = React.useState(false)
    const [loadingBackdrop, setLoadingBackdrop] = React.useState(false)
    const signCanvasCustomerRep = React.useRef() as React.MutableRefObject<any>;
    const signCanvasCompanyRep = React.useRef() as React.MutableRefObject<any>;
    const [companyRepActiveSelect, setCompanyRepActiveSelect] = React.useState<string>("")
    const [companyRepActiveSelectId, setCompanyRepActiveSelectId] = React.useState<number>()
    const [customerRepActiveSelect, setCustomerRepActiveSelect] = React.useState<string>("")
    const [isDirty, setIsDirty] = useState(false)

    // Time Entry
    const [employeeActiveSelect, setEmployeeActiveSelect] = React.useState<string>("")
    const [employeeActiveSelectId, setEmployeeActiveSelectId] = React.useState<number>()
    const [vehicleActiveSelect, setVehicleActiveSelect] = React.useState<string>("")
    const [vehicleActiveSelectId, setVehicleActiveSelectId] = React.useState<number>()

    const [customerSigned, setCustomerSigned] = React.useState()
    const [companySigned, setCompanySigned] = React.useState()

    const [snackbarOpen, setSnackbarOpen] = React.useState({ open: false, message: '' })
    const { getByIndex, getAll, add, update, clear, deleteRecord } = useIndexedDB('dispatch');
    const networkOnline = useNetworkOnline()


    // const testdownload = async () => {
    //     const testBody = {
    //         "dispatchsendid":222, "filename":"/utc/schedule/0000000001/field documents/35099571 - road closure  (1).jpg",  "id": "3314865345"
    //     }
    //     const response = await axios.post(`dispatchfiledownload`, testBody)
    //     // console.log(response)
    //     return response
    // }
    // const test = await testdownload()
    // console.log(test)
    // console.log(response)



    const getDBRecord = async (id: string) => {
        return await getByIndex('uuid', id)
    }

    const addDBRecord = async (id: string, jsonString: string) => {
        const record = await getDBRecord(id)
        let returnResult = null
        if (record) {
            returnResult = await update({ uuid: id, json: jsonString })
        }
        else {
            returnResult = await add({ uuid: id, json: jsonString })
        }
        return returnResult
    }

    const loadDatabaseRecord = async (userGuidToPro) => {
        const record = await getDBRecord(userGuidToPro)
        if (record) {
            return JSON.parse(record.json)
        }
        else {
            return undefined
        }
    }

    const loadPhotosDatabaseRecord = async (userGuidToPro) => {
        const record = await getDBRecord(userGuidToPro + '-photos')
        if (record) {
            return JSON.parse(record.json)
        }
        else {
            return undefined
        }
    }

    const getPhotoSectionData = () => {
        return {
            "sectionname": "*PHOTOS*",
            "data": photos.map((photo) => { return { photo: photo.imageData, filename: photo.name, filesize: photo.size, filetype: photo.type }}),
        }
    }

    const loadDocumentsDatabaseRecord = async (userGuidToPro) => {
        const record = await getDBRecord(userGuidToPro + '-documents')

        if (record) {
            console.log('LOADING DB Record')
            console.log(record.json)
            return record.json
        }
        else {
            return undefined
        }
    }

    const writeDatabaseRecordForDocuments = (documentsToSave: any) => {
        if (documentsToSave) {
            const documenntGuid = userGuid + '-documents'
            console.log("WRITING DB RECORD")
            console.log(documentsToSave)
            const documentsJSONData = JSON.stringify(documentsToSave)
            console.log(documentsJSONData)
            addDBRecord(documenntGuid, documentsToSave).then(
                id => {
                    console.log('ID Generated: ', id);
                },
                error => {
                    console.log(error);
                }
            )
        }
    }

    const getDocumentsSectionData = () => {
        return {
            "sectionname": "*DOCUMENTS*",
            "data": dispatchData?.filelist.map((photo) => { return { photo: photo.imageData, filename: photo.name, filesize: photo.size, filetype: photo.type }}),
        }
    }

    const getSectionsData = (includePhotos: Boolean) => {
        return dispatchData.sections.map((section) => {
            if (section.sectionname === "*TIMEENTRY*") {
                return {
                    "sectionname": "*TIMEENTRY*",
                    "data": timeEntries,
                    "employeelist": section.employeelist,
                    "vehiclelist": section.vehiclelist,
                    "sectiondata": section.sectiondata
                }
            }
            else if (section.sectionname === "*EQUIPMENT*") {
                return {
                    "sectionname": "*EQUIPMENT*",
                    "data": equipmentData,
                    "equipmentlist": section.equipmentlist,
                    "sectiondata": section.sectiondata
                }
            }
            else if (includePhotos && section.sectionname === "*PHOTOS*") {
                return {
                    "sectionname": "*PHOTOS*",
                    "data": photos.map((photo) => { return { photo: photo.imageData, filename: photo.name, filesize: photo.size, filetype: photo.type }}),
                    "sectiondata": section.sectiondata
                }
            }
            else if (section.sectionname === "*CUSTOMERSIGNOFF*") {
                return {
                    "sectionname": "*CUSTOMERSIGNOFF*",
                    "data": [{
                        "companyrep": companyRepActiveSelect,
                        "companyrepid": companyRepActiveSelectId,
                        "customerrep": customerRepActiveSelect,
                        "comment": commentText,
                        "customerSignedImage": signCanvasCustomerRep?.current?.getTrimmedCanvas().toDataURL('image/png').split(';base64,')[1],
                        "companySignedImage": signCanvasCompanyRep?.current?.getTrimmedCanvas().toDataURL('image/png').split(';base64,')[1],
                    }],
                    "sectiondata": section.sectiondata
                }
            }
            else {
                return section
            }
        })
    }

    const getSubmitData = (includePhotos: Boolean) => {
        let submitData = null;
        if (dispatchData) {
            submitData = {
                "dispatchsendid": dispatchData.dispatchsendid,
                "sectiondata": dispatchData.sectiondata,
                "isreadonly": dispatchData.isreadonly,
                "title": dispatchData.title,
                "sections": getSectionsData(includePhotos)
            }
        }

        return submitData
    }

    const sendTempSave = () => {
        const submitData = getSubmitData(false)
        return (submitData ? updateTempDispatch.mutateAsync(submitData) : null)
    }

    const sendSave = () => {
        const submitData = getSubmitData(true)
        return (submitData ? updateDistpatch.mutateAsync(submitData) : null)
    }

    const {data: dispatchDataHold} = useFetchDispatch(userGuid)

    useEffect(() => {
        console.log(networkOnline)
        if (networkOnline) {
            checkDBRec(userGuid)
        }
        else {
            writeDatabaseRecord()
        }
    }, [networkOnline])

    useEffect(() => {
        if (!dispatchData || !dispatchData?.isreadonly) {
            if (networkOnline) {
                sendTempSave()
            } else {
                writeDatabaseRecord()
            }
        }
    }, [timeEntries, equipmentData, companyRepActiveSelectId, customerRepActiveSelect, commentText])

    useEffect(() => {
        // Save photos to local DB
        if (dispatchData && !dispatchData.isreadonly) {
            writeDatabaseRecordForPhotos()
        }
    }, [photos])

    useEffect(() => {
        // Save photos to local DB
        if (dispatchData && !dispatchData.isreadonly && documents && documents.length > 0) {
            writeDatabaseRecordForDocuments(documents)
        }
    }, [documents])

    const [startValue, setStartValue] = React.useState<Date | null>(
        new Date('2014-08-18T21:11:54'),
    );

    const handleStartChange = (newValue: Date | null) => {
        setStartValue(newValue);
    };

    const updateDistpatch = useUpdateDispatch()
    const updateTempDispatch = useUpdateTempDispatch()

    const checkDBRec = async (userGuidToPro) => {
        const record = await getDBRecord(userGuidToPro)
        if (record) {
            submitTempDatabaseData(JSON.parse(record.json)).then((resp) => {
                deleteRecord(record.uuid)
            })
        }
    }

    const submitTempDatabaseData = async (dataObject) => {
        return await updateTempDispatch.mutateAsync(dataObject)
    }

    const writeDatabaseRecordForPhotos = () => {
        if (dispatchData) {
            const photoGuid = userGuid + '-photos'
            addDBRecord(photoGuid, JSON.stringify(getPhotoSectionData())).then(
                id => {
                    console.log('ID Generated: ', id);
                },
                error => {
                    console.log(error);
                }
            )
        }
    }

    const writeDatabaseRecord = () => {
        if (dispatchData) {
            addDBRecord(userGuid, JSON.stringify(getSubmitData(true))).then(
                id => {
                    console.log('ID Generated: ', id);
                },
                error => {
                    console.log(error);
                }
            )
        }
    }

    useEffect(() => {
        console.log(dispatchDataHold)
        if (dispatchDataHold === undefined) {
            loadDatabaseRecord(userGuid).then((data) => {
                console.log(data)
                setDispatchData(data)
            })
        }
        else {
            setDispatchData(dispatchDataHold)
        }
    }, [dispatchDataHold])

    useEffect(() => {
        getAppBarMessage()

        dispatchData?.sections?.map((dispatch, i) => {
            if (dispatch && dispatch.equipmentlist && dispatch.equipmentlist.length > 0) {
                setEquipmentFormData(dispatch.equipmentlist)
            }

            if (dispatch && dispatch.sectionname === '*EQUIPMENT*' && dispatch.data?.length > 0) {
                setEquipmentData(dispatch.data)
            }
            else if (dispatch && dispatch.sectionname === '*TIMEENTRY*' && dispatch.data?.length > 0) {
                setTimeEntries(dispatch.data)
            }
            else if (dispatch && dispatch.sectionname === '*PHOTOS*') {
                if (dispatchData.isreadonly && dispatch.data?.length > 0) {
                    getDBRecord(userGuid + '-photos').then((record) => {
                        if (record) {
                            deleteRecord(record.uuid)
                        }
                    })

                    const newData = dispatch.data?.map((p: any, i) => {
                        return { imageData: p.photo, name: p.filename, size: p.filesize, type: p.filetype }
                    })
                    setPhotos(newData)
                }
                else {
                    loadPhotosDatabaseRecord(userGuid).then((photosData) => {
                        if (photosData && photosData.data) {
                            const newData = photosData.data?.map((p: any, i) => {
                                return { imageData: p.photo, name: p.filename, size: p.filesize, type: p.filetype }
                            })
                            setPhotos(newData)
                        }
                    })
                }
            }
            else if (dispatch && dispatch.sectionname === '*DOCUMENTS*') {
                // scotty
                loadDocumentsDatabaseRecord(userGuid).then((documentsData) => {
                    console.log('LOADED DATABASE RECORDS')
                    console.log(documentsData)
                    // if (documentsData === undefined) {
                    //     console.log('NO DB Document data exists')
                    //     if (dispatch?.filelist) {
                    //         getDocumentsData(dispatch?.filelist).then((fileDataArray) =>
                    //             setDocuments(fileDataArray)
                    //         )
                    //     }
                    // }
                    // else {
                    //     console.log('Loading Documents From DB')
                    //     let newFiles = []
                    //     if (dispatch && dispatch?.filelist) {
                    //         dispatch.filelist.map(async (file) => {
                    //             const storedFileDataFilter = documentsData.filter(item => item.id === file.id)
                    //             if (storedFileDataFilter && storedFileDataFilter.length > 0) {
                    //                 console.log('checking')
                    //                 console.log(storedFileDataFilter)
                    //                 console.log(file)
                    //                 if (storedFileDataFilter[0].serverModified !== file.serverModified) {
                    //                     console.log('Reloading document file because its stale')
                    //                     const body = {
                    //                         id: file.id,
                    //                         filename: file.filename,
                    //                         dispatchsendid: dispatchData.dispatchsendid
                    //                     }
                    //                     const documentObject = await getDocumentObj(body).then((docObj) => {
                    //                         return {
                    //                             id: file.id,
                    //                             filename: file.filename,
                    //                             serverModified: docObj.serverModified,
                    //                             filedata: docObj.filedata,
                    //                             fileext: docObj.fileext
                    //                         }
                    //                     })
                    //                     console.log('getting initial load')
                    //                     newFiles.push(documentObject)
                    //                 }
                    //                 else {
                    //                     console.log('found matching file - Using it')
                    //                     newFiles.push(storedFileDataFilter[0])
                    //                 }
                    //             }
                    //             else {
                    //                 newFiles.push(file)
                    //             }
                    //         })
                    //     }
                    //     console.log(newFiles)
                    //     setDocuments(newFiles)
                    // }
                })

                if (dispatchData.isreadonly && dispatch.data?.length > 0) {
                    // getDBRecord(userGuid + '-photos').then((record) => {
                    //     if (record) {
                    //         deleteRecord(record.uuid)
                    //     }
                    // })
                    //
                    // const newData = dispatch.data?.map((p: any, i) => {
                    //     return { imageData: p.photo, name: p.filename, size: p.filesize, type: p.filetype }
                    // })
                    // setPhotos(newData)
                }
                else {
                    // loadDocumentsDatabaseRecord(userGuid).then((photosData) => {
                    //     if (photosData && photosData.data) {
                    //         const newData = photosData.data?.map((p: any, i) => {
                    //             return { imageData: p.photo, name: p.filename, size: p.filesize, type: p.filetype }
                    //         })
                    //         setPhotos(newData)
                    //     }
                    // })
                }
            }
            else if (dispatch && dispatch.sectionname === '*CUSTOMERSIGNOFF*' && dispatch.data?.length > 0) {
                const newData = dispatch.data?.map((d: any, i) => {
                    return d
                })
                console.log(newData)
                const signOffData = newData[0]
                setCommentText(signOffData?.comment)
                setCustomerRepActiveSelect(signOffData?.customerrep)
                setCompanyRepActiveSelect(signOffData?.companyrep)
                setCustomerSigned(signOffData?.customerSignedImage)
                setCompanySigned(signOffData?.companySignedImage)
            }
        })
    }, [dispatchData])

    const getFileDownload = async (file: any) => {
        const body = {
            id: file.id,
            filename: file.filename,
            dispatchsendid: file.dispatchsendid
        }

        const documentObject = await getDocumentObj(body)
        return documentObject
    }

    const getDocumentsData = async (filelist: any) => {
        console.log(filelist)
        const fileDataArray = filelist.map( async (item) => {
            const body = {
                id: item.id,
                filename: item.path,
                dispatchsendid: dispatchData.dispatchsendid
            }

            const fileData = await getFileDownload(body)

            return {
                "id": body.id,
                "filename": body.filename,
                "serverModified": item.serverModified,
                "filedata": fileData.filedata,
                "fileext": fileData.fileext
            }
        })

        console.log(await Promise.all(fileDataArray))
        return await Promise.all(fileDataArray)
    }

    const getMainLabels = () => {
        if (
            dispatchData &&
            dispatchData.sectiondata &&
            dispatchData.sectiondata.length > 0
        ) {
            return (dispatchData?.sectiondata).map((dispatch, i) => {
                return (
                    <>
                        <Grid item border={0} xs={8}>
                            <Typography variant="body2" gutterBottom>
                                {dispatch.label}
                            </Typography>
                        </Grid>
                        <Grid item border={0} xs={4}>
                            <Typography variant="body2" gutterBottom>
                                {dispatch.value}
                            </Typography>
                        </Grid>
                    </>
                )
            })
        }
    }

    const getSectionsAccordion = () => {
        if (
            dispatchData &&
            dispatchData.sections &&
            dispatchData.sections.length > 0
        ) {
            return (dispatchData?.sections).map((dispatch, i) => {
                if (dispatch.sectionname === '*EQUIPMENT*') {
                    return (
                        <Accordion disableGutters={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="h6">Equipment</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Grid
                                        container
                                        border={0}
                                        direction="row"
                                        alignItems="center"
                                        height={'100%'}
                                        width={'100%'}
                                    >
                                        {!dispatchData.isreadonly &&
                                            <div style={{width:'25px', height:'25px', paddingBottom: '25px'}}>
                                                <IconButton
                                                    className={styles.submitButton}
                                                    onClick={() => handleEquipmentAdd()}
                                                    size="small">
                                                    <Add />
                                                </IconButton>
                                            </div>
                                        }
                                        {getEquiptmentCardDetails()}
                                    </Grid>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )
                }
                else if (dispatch.sectionname === '*PHOTOS*') {
                    return (
                        <Accordion disableGutters={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="h6">Photos</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Grid
                                        container
                                        border={0}
                                        direction="row"
                                        alignItems="center"
                                        height={'100%'}
                                        width={'100%'}
                                    >
                                        {getPhotoAccordionDetails()}
                                    </Grid>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )
                }
                else if (dispatch.sectionname === '*DOCUMENTS*') {
                    console.log(dispatch)
                    return (
                        <Accordion disableGutters={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="h6">Documents</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Grid
                                        container
                                        border={0}
                                        direction="row"
                                        alignItems="center"
                                        height={'100%'}
                                        width={'100%'}
                                    >
                                        {getDocumentAccordionDetails(dispatch?.filelist)}
                                    </Grid>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )
                }
                else if (dispatch.sectionname === '*TIMEENTRY*') {
                    return (
                        <>
                            <TimeEntryForm
                                isOpen={isTEFormShowing}
                                setIsOpen={setIsTEFormShowing}
                                reloadForm={reloadTEForm}
                                employeeList={dispatch?.employeelist}
                                vehicleList={dispatch?.vehiclelist}
                                timeEntries={timeEntries}
                                setTimeEntries={setTimeEntries}
                                gridParams={gridParams}
                                isEdit={teEdit}
                                dispatchid={null}
                                timeid={null}
                                shouldSubmit={false}
                            />
                            <Accordion disableGutters={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant="h6">Time Entry</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box>
                                        <Grid
                                            container
                                            border={0}
                                            direction="row"
                                            alignItems="center"
                                            height={'100%'}
                                            width={'100%'}
                                        >
                                            {!dispatchData.isreadonly &&
                                                <div style={{width: '25px', height: '25px', paddingBottom: '25px'}}>
                                                    <IconButton
                                                        className={styles.submitButton}
                                                        onClick={() => handleTimeEntryAdd()}
                                                        size="small">
                                                        <Add/>
                                                    </IconButton>
                                                </div>
                                            }
                                            {getTimeEntryCardDetails()}
                                        </Grid>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )
                }
                else if (dispatch.sectionname === '*CUSTOMERSIGNOFF*') {
                    return (
                        <Accordion disableGutters={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="h6">Customer Sign Off</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Grid
                                        container
                                        border={0}
                                        direction="row"
                                        alignItems="center"
                                        height={'100%'}
                                        width={'100%'}
                                    >
                                        {getSignOffAccordionDetails(dispatch?.companyreplist)}
                                    </Grid>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )
                }
                else {
                    return (
                        <Accordion disableGutters={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant={'h6'}>{dispatch.sectionname}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Grid
                                        container
                                        border={0}
                                        direction="row"
                                        alignItems="center"
                                        height={'100%'}
                                        width={'100%'}
                                    >
                                        {getAccordionDetails(
                                            dispatch.sectionname,
                                            dispatch.sectiondata
                                        )}
                                    </Grid>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )
                }
            })
        }
    }

    const handlePhotoDelete = (e, item) => {
        setDialogObjPhoto({
            msg: 'Are you sure you want to delete "' + item.name + '"?',
            name: item.name
        })
    }

    const handlePhotoDeleteConfirm = async () => {
        const finalData = [...photos]
        const itsIt = finalData.filter((item) => item.name !== dialogObjPhoto.name)
        console.log(itsIt)
        setPhotos(itsIt)
        closeDialog()
    }

    const handleEquipmentAdd = () => {
        setReloadForm(reloadForm + 1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const handleEquipmentDelete = (equipt) => {
        setDialogObj({
            msg: 'Are you sure you want to delete "' + equipt.equipment + '"?',
            id: Number(equipt.id)
        })
    }

    const handleEquipmentDeleteConfirm = async () => {
        const finalData = [...equipmentData]
        const itsIt = finalData.filter((item) => item.id !== dialogObj.id)
        setEquipmentData(itsIt)
        closeDialog()
    }

    const closeDialog = () => {
        setDialogObjPhoto({
            msg: '',
            name: ''
        })
        setDialogObj({
            msg: '',
            id: 0
        })
        setDialogTimeEntryObj({
            msg: '',
            starttravel: new Date(),
            employeeid: 0
        })
        setDialogMsg("")
    }

    const onLogoDrop = (files: File[]) => {
        var newPhotos = [...photos]
        files.forEach((file) => {
            console.log(file)
            const fileReader = new FileReader()

            fileReader.onloadend = function (event) {
                console.log(newPhotos)
                setPhotos(newPhotos)
            }
            fileReader.onload = function (event) {
                const data = event.target.result as string

                // path: "cubs (1).png"
                // lastModified: 1657308022556
                // lastModifiedDate: Fri Jul 08 2022 14:20:22 GMT-0500 (Central Daylight Time) {}
                // name: "cubs (1).png"
                // size: 5324
                // type: "image/png"

                newPhotos.push({
                    imageData: data.split(';base64,')[1],
                    name: file.name,
                    size: file.size,
                    type: file.type
                })


                // setPhotos([...photos, {
                //     imageData: data.split(';base64,')[1],
                //     name: file.name,
                //     size: file.size,
                //     type: file.type
                // }])
            }
            fileReader.readAsDataURL(file)
        })
    }

    const getPhotoAccordionDetails = () => {
        return (
            <>
                <Grid container spacing={1}>
                    {!dispatchData.isreadonly &&
                        <Grid item xs={12}>
                            <Dropzone onDrop={onLogoDrop}>
                                {({
                                      getRootProps,
                                      getInputProps,
                                      isDragActive,
                                      isDragAccept,
                                      isDragReject,
                                      acceptedFiles,
                                      fileRejections,
                                  }) => (
                                    <div style={{width: '25px', height: '25px'}} {...getRootProps()}
                                         className={styles.dropzone}>
                                        <input {...getInputProps()} />
                                        <AddAPhotoIcon style={{width: '100%', height: '100%'}}/>
                                    </div>
                                    
                                )}
                            </Dropzone>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {photos &&
                            <ImageList sx={{ width: 300, height: 300, alignItems: 'center', overflow: 'auto', }}  cols={1} gap={8} rowHeight={200} style={{alignItems: 'center'}}>
                                {photos.map((item) => (
                                    <Card sx={{ width: 300, height: 300, alignItems: 'center', }}>
                                        {!dispatchData.isreadonly &&
                                        <CardHeader
                                            action={
                                                <IconButton onClick={(e) => {handlePhotoDelete(e, item)}} aria-label="settings">
                                                <ClearIcon  />
                                                </IconButton>
                                            }
                                            // title={item.name}
                                            subheader={item.name}
                                        />}
                                        {dispatchData.isreadonly &&
                                            <CardHeader
                                                // title={item.name}
                                                subheader={item.name}
                                            />}
                                        <CardMedia
                                            style={{
                                                width: "auto",
                                                maxHeight: "250px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: 'center'
                                            }}
                                            component="img"
                                            src={`data:image/png;base64,${item.imageData}`}
                                            alt=""
                                        />
                                    </Card>
                                ))}
                            </ImageList>
                        }
                    </Grid>
                </Grid>
            </>

        )
    }

    const getDocumentBlob = async (body: any) => {
        const response = await axios.post(`dispatchfiledownload`, body)

        const dataJson = JSON.parse(response.data.jsonString)
        var contentType = 'image/png';
        if (dataJson.fileext === 'png') {
            contentType = 'image/png'
        } else if (dataJson.fileext === 'pdf') {
            contentType = 'application/pdf'
        } else if (dataJson.fileext === 'jpeg' || dataJson.fileext === 'jpg' || dataJson.fileext === 'jfif') {
            contentType = 'image/jpeg'
        } else if (dataJson.fileext === 'docx') {
            contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        } else if (dataJson.fileext === 'xlsx') {
            contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        } else if (dataJson.fileext === 'xls') {
            contentType = 'application/vnd.ms-excel'
        }
        // setWatchLogoData(dataJson.filedata)

        console.log(dataJson.filedata)
        const byteCharacters = atob(dataJson.filedata)
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: contentType});
        return blob
    }

    const getDocumentObj = async (body: any) => {
        try {
            console.log(body)
            const response = await axios.post(`gddispatchfiledownload`, body)// .then(responce => responce.data)
            console.log(response.data)
            console.log('before')

            const dataJson = JSON.parse(response.data.jsonString)
            console.log(dataJson.type)
            console.log('after')
            // var contentType = 'image/png';
            // if (dataJson.fileext === 'png') {
            //     contentType = 'image/png'
            // } else if (dataJson.fileext === 'pdf') {
            //     contentType = 'application/pdf'
            // } else if (dataJson.fileext === 'jpeg' || dataJson.fileext === 'jpg') {
            //     contentType = 'image/jpeg'
            // } else if (dataJson.fileext === 'docx') {
            //     contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            // } else if (dataJson.fileext === 'xlsx') {
            //     contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            // } else if (dataJson.fileext === 'xls') {
            //     contentType = 'application/vnd.ms-excel'
            // }
            // // setWatchLogoData(dataJson.filedata)
            //
            // console.log(dataJson.filedata)
            // const byteCharacters = atob(dataJson.filedata)
            // const byteNumbers = new Array(byteCharacters.length);
            // for (let i = 0; i < byteCharacters.length; i++) {
            //     byteNumbers[i] = byteCharacters.charCodeAt(i);
            // }
            // const byteArray = new Uint8Array(byteNumbers);
            // const blob = new Blob([byteArray], {type: contentType});
            return dataJson
        }
        catch(err) {
            console.log(err)
        }
    }


    const handleDocumentListItemClick = async (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        item,
    ) => {
        event.stopPropagation()
        event.preventDefault()

        // SCOTTY
        // loadDocumentsDatabaseRecord(userGuid).then((documentsData) => {
        //     console.log(documentsData)
        // })

        // setSelectedPath(item.path)

        const body = {
            id: item.Id,
            filename: item.Name,
            dispatchsendid: dispatchData.dispatchsendid
        }
        setViewDataBody({
            id: item.Id
        })
        // downloadDocument.mutateAsync(body)
        const blob = await getDocumentBlob(body)
        const url = window.URL.createObjectURL(blob);
        setWatchLogoDataURL(url);
        setOpenViewer(true)
    }

    const getDocumentAccordionDetails = (filelist) => {
        console.log(filelist)
        return (
            <Box sx={{ width: '100%', maxWidth: 300, bgcolor: 'background.paper' }}>
                {filelist && filelist.length > 0 && filelist.map((item) => (
                    <List component="nav" aria-label="secondary mailbox folder">
                        <ListItemButton
                            // selected={selectedPath === item.path}
                            onClick={(event) => handleDocumentListItemClick(event, item)}
                        >
                            <ListItemText primary={item.Name} />
                        </ListItemButton>
                    </List>
                ))}
            </Box>
        )
    }

    const clearSignatureCustomerRep = () => {
        signCanvasCustomerRep.current.clear()
    }

    const clearSignatureCompanyRep = () => {
        signCanvasCompanyRep.current.clear()
    }

    const getOptions = (companyRepList) => {
        return companyRepList?.map(item => (
            <MenuItem key={item.companyrepid} value={item.companyrep}>
                {item.companyrep}
            </MenuItem>
        ))
    }

    const getVehicleOptions = (vehiclelist) => {
        return vehiclelist?.map(item => (
            <MenuItem key={item.vehicleid} value={item.vehicle}>
                {item.vehicle}
            </MenuItem>
        ))
    }

    const handleCompanyRepChange = (event, repList) => {
        setCompanyRepActiveSelect(event.target.value as string)
        const selection = repList?.filter((item) => item.companyrep === event.target.value as string)
        if (selection && selection.length > 0) {
            setCompanyRepActiveSelectId(selection[0].companyrepid)
        }
    }

    const handleEmployeeChange = (event, repList) => {
        setEmployeeActiveSelect(event.target.value as string)
        const selection = repList?.filter((item) => item.companyrep === event.target.value as string)
        if (selection && selection.length > 0) {
            setEmployeeActiveSelectId(selection[0].companyrepid)
        }
    }

    const handleVehicleChange = (event, vehiclelist) => {
        setVehicleActiveSelect(event.target.value as string)
        const selection = vehiclelist?.filter((item) => item.vehicle === event.target.value as string)
        if (selection && selection.length > 0) {
            setVehicleActiveSelectId(selection[0].vehicleid)
        }
    }

    const onCommentChange = (e) => {
        setCommentText(e.target.value)
    }

    const onCustomerRepChange = (e) => {
        setCustomerRepActiveSelect(e.target.value)
    }

    const getSignOffAccordionDetails = (companyRepList) => {
        return (
            <Box sx={{height: '100%',width: '100%', bgcolor: 'background.paper' }}>
                <TextField
                    id="outlined-multiline-static"
                    label="Other Comments"
                    multiline
                    rows={4}
                    defaultValue={commentText}
                    fullWidth={true}
                    disabled={dispatchData.isreadonly}
                    onChange={onCommentChange}
                    style={{'paddingBottom': '20px'}}
                />
                <FormControl fullWidth style={{'paddingBottom': '20px'}}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Customer Rep"
                        defaultValue={customerRepActiveSelect}
                        value={customerRepActiveSelect}
                        fullWidth={true}
                        disabled={dispatchData.isreadonly}
                        onChange={onCustomerRepChange}
                        style={{'paddingBottom': '20px'}}
                    />
                </FormControl>

                {dispatchData.isreadonly ?
                    <>
                        <Typography variant="body2" gutterBottom>Customer Rep</Typography>
                        <img height={'100px'} src={`data:image/png;base64,${customerSigned}`}
                             alt="Signature" style={{'paddingBottom': '20px'}}/>
                    </>
                    :
                    <>
                        <SignatureCanvas onEnd={() => {setIsDirty(true)}} clearOnResize={false} canvasProps={{className: styles.sigPad}}
                                         ref={signCanvasCustomerRep}/>
                        <button className={styles.buttons} onClick={clearSignatureCustomerRep}>
                            Clear
                        </button>
                    </>
                }


                <FormControl fullWidth style={{'paddingBottom': '20px'}}>
                    {dispatchData.isreadonly ?
                        <TextField
                            id="outlined-multiline-static"
                            label="Company Rep"
                            defaultValue={companyRepActiveSelect}
                            value={companyRepActiveSelect}
                            fullWidth={true}
                            disabled={dispatchData.isreadonly}
                            onChange={onCustomerRepChange}
                            style={{'paddingBottom': '20px'}}
                        />
                        :
                        <>
                            <InputLabel>Company Rep*</InputLabel>
                            <Select
                                name="equipment"
                                label="Company Rep"
                                defaultValue={companyRepActiveSelect}
                                value={companyRepActiveSelect}
                                size={'small'}
                                required={true}
                                variant="outlined"
                                onChange={(e) => { handleCompanyRepChange(e, companyRepList) }}
                            >
                                {getOptions(companyRepList)}
                            </Select>
                        </>
                    }

                </FormControl>
                {dispatchData.isreadonly ?
                    <>
                        <Typography variant="body2" gutterBottom>Company Rep</Typography>
                        <img height={'100px'} src={`data:image/png;base64,${companySigned}`}
                             alt="Signature"/>
                    </>
                    :
                    <>
                        <SignatureCanvas onEnd={() => {setIsDirty(true)}} clearOnResize={false} canvasProps={{className: styles.sigPad}}
                                         ref={signCanvasCompanyRep}/>
                        <button className={styles.buttons} onClick={clearSignatureCompanyRep}>
                            Clear
                        </button>
                    </>
                }

            </Box>
        )
    }

    const handleTimeEntryAdd = () => {
        setReloadTEForm(reloadTEForm + 1)

        if (timeEntries && timeEntries.length) {
            setGridParams(timeEntries[timeEntries.length - 1])
        }
        else {
            setGridParams(null)
        }
        setIsTEFormShowing(true)
        setTEEdit(false)
    }

    const handleTimeEntryDelete = (e, entry) => {
        e.stopPropagation()
        e.preventDefault()

        console.log(entry)

        setDialogTimeEntryObj({
            msg: 'Are you sure you want to delete the time card for "' + entry.employee + '"?',
            starttravel: entry.starttravel,
            employeeid: entry.employeeid
        })
    }

    const handleTimeEntryDeleteConfirm = async () => {
        const finalData = [...timeEntries]
        const itsIt = finalData.filter((item) => item.employeeid !== dialogTimeEntryObj.employeeid)
        setTimeEntries(itsIt)
        closeDialog()
    }

    const handleTimeEntryEdit = (entry) => {
        if (!dispatchData.isreadonly) {
            setReloadTEForm(reloadTEForm + 1)
            setGridParams(entry)
            setIsTEFormShowing(true)
            setTEEdit(true)
        }
    }

    const getTimeEntryCardDetails = () => {
        return timeEntries.map((entry) => {
            return (
                <>
                    <Card sx={{ width: '100%', marginBottom: '15px'}} onClick={(e) => { handleTimeEntryEdit(entry) }}>
                        <CardContent>
                            <Grid
                                container
                                border={0}
                                direction="row"
                                alignItems="top"
                                height={'100%'}
                                width={'100%'}
                            >
                                <>
                                    <Grid item border={0} xs={10}>


                                        <Typography variant="body1" gutterBottom>
                                            <IconButton aria-label="settings" onClick={(e) => {
                                                handleTimeEntryEdit(entry)
                                            }}>
                                                <EditIcon/>
                                            </IconButton>
                                            {entry.employee}
                                        </Typography>
                                    </Grid>
                                    {!dispatchData.isreadonly &&
                                        <Grid item border={0} xs={2}>
                                            <IconButton aria-label="settings" onClick={(e) => {
                                                handleTimeEntryDelete(e, entry)
                                            }}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Grid>
                                    }
                                    {/*{!dispatchData.isreadonly &&*/}
                                    {/*    <Grid item border={0} xs={1}>*/}
                                    {/*        <IconButton aria-label="settings" onClick={(e) => {*/}
                                    {/*            handleTimeEntryEdit(entry)*/}
                                    {/*        }}>*/}
                                    {/*            <EditIcon/>*/}
                                    {/*        </IconButton>*/}
                                    {/*    </Grid>*/}
                                    {/*}*/}
                                </>
                                <>
                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            Travel Start:
                                        </Typography>
                                    </Grid>

                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            {dayjs(entry.starttravel).format('MM/DD/YYYY hh:mmA')}
                                        </Typography>
                                    </Grid>
                                </>
                                <>
                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            On Job:
                                        </Typography>
                                    </Grid>

                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            {dayjs(entry.onjob).format('MM/DD/YYYY hh:mmA')}
                                        </Typography>
                                    </Grid>
                                </>
                                <>
                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            Leave Job:
                                        </Typography>
                                    </Grid>

                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            {dayjs(entry.leavejob).format('MM/DD/YYYY hh:mmA')}
                                        </Typography>
                                    </Grid>
                                </>
                                <>
                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            Travel End:
                                        </Typography>
                                    </Grid>

                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            {dayjs(entry.endtravel).format('MM/DD/YYYY hh:mmA')}
                                        </Typography>
                                    </Grid>
                                </>
                                <>
                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            Vehicle:
                                        </Typography>
                                    </Grid>

                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            {entry.vehicle ? entry.vehicle : 'N/A'}
                                        </Typography>
                                    </Grid>
                                </>
                                <>
                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            Missing Lunch:
                                        </Typography>
                                    </Grid>

                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            {entry.lunch ? 'Yes' : 'No'}
                                        </Typography>
                                    </Grid>
                                </>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )
        })
    }

    const getEquiptmentCardDetails = () => {
        return equipmentData.map((equipt) => {
            return (
                <>
                    <Card sx={{ width: '100%', marginBottom: '15px'}}>
                        <CardContent>
                            <Grid
                                container
                                border={0}
                                direction="row"
                                alignItems="center"
                                height={'100%'}
                                width={'100%'}
                            >

                                <>
                                    <Grid item border={0} xs={10}>
                                        <Typography variant="body1">
                                            {equipt.equipment}
                                        </Typography>
                                    </Grid>
                                    {!dispatchData.isreadonly &&
                                        <Grid item border={0} xs={2}>
                                            <IconButton aria-label="settings" onClick={(e) => { handleEquipmentDelete(equipt) }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    }
                                </>
                                <>
                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            Quantity:
                                        </Typography>
                                    </Grid>

                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            {equipt.quantity}
                                        </Typography>
                                    </Grid>
                                </>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )
        })
    }

    const getAccordionDetails = (name: string, sectionData: any) => {
        if (sectionData && sectionData.length > 0) {
            return sectionData.map((section) => {
                if (section.label === '*MAP*') {
                    return getMap(section.value)
                } else if (section.label && section.value) {
                    return (
                        <>
                            <Grid item border={0} xs={8}>
                                <Typography variant="body2" gutterBottom>
                                    {section.label}
                                </Typography>
                            </Grid>

                            <Grid item border={0} xs={4}>
                                <Typography variant="body2" gutterBottom>
                                    {section.value}
                                </Typography>
                            </Grid>
                        </>
                    )
                } else if (section.label) {
                    return (
                        <>
                            <Grid item border={0} xs={12}>
                                <Typography variant="body2" gutterBottom>
                                    {section.label}
                                </Typography>
                            </Grid>
                        </>
                    )
                } else if (section.value) {
                    return (
                        <>
                            <Grid item border={0} xs={12}>
                                <Typography variant="body2" gutterBottom>
                                    {section.value}
                                </Typography>
                            </Grid>
                        </>
                    )
                }
            })
        }
    }

    const MapComponent: React.FC<{
        center: { lat: number; lng: number }
        zoom: number
    }> = ({ center, zoom }) => {
        const mapRef = useRef()

        useEffect(() => {
            // @ts-ignore
            const map = new window.google.maps.Map(mapRef.current, {
                center,
                zoom
            })

            // @ts-ignore
            new window.google.maps.Marker({
                position: center,
                map: map
            })
        })

        return (
            <div
                ref={mapRef}
                style={{ backgroundColor: 'lightblue', width: '100%', height: '300px' }}
            ></div>
        )
    }

    const getAppBarMessage = () => {
        if (dispatchData && dispatchData.status === 'failure') {
            setTitleMessage(dispatchData.message)
        }
        else if (dispatchData && dispatchData.title) {
            setTitleMessage(dispatchData.title)
        }
        else {
            setTitleMessage('Loading Schedule')
        }
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen({ open: false, message: ""  });
    }

    const submitTempForm = () => {
        if (!networkOnline) {
            setSnackbarOpen( { open: true, message: "Data connection error - Work order is currently working in offline mode." })
        }
        else {
            setLoadingBackdrop(true)
            const sendTempSavePromise = sendTempSave()
            if (sendTempSavePromise !== null) {
                sendTempSavePromise.then(
                    (resp) => {
                        setLoadingBackdrop(false)
                        if (resp && resp.data) {
                            const parsedData = JSON.parse(resp.data.jsonString)
                            if (parsedData.status === 'success') {
                                setDisableSubmit(true)
                            }
                            // const [isFormShowing, setIsFormShowing] = useState(false)
                            setSnackbarOpen( { open: true, message: parsedData.message })
                        }
                        else {
                            setSnackbarOpen( { open: true, message: "Unexpected Error - Please try again." })
                        }
                    }
                )
            }
        }
    }

    const submitForm = () => {
        if (signCanvasCompanyRep.current.isEmpty()) {
            setLoadingBackdrop(false)
            setDialogMsg("Missing a valid company signature. Please sign by a company employee.")
        }
        else if (!networkOnline) {
            setLoadingBackdrop(false)
            setSnackbarOpen( { open: true, message: "Data connection error - Work order is currently working in offline mode." })
        }
        else {
            setLoadingBackdrop(true)
            sendSave().then(
                (resp) => {
                    setLoadingBackdrop(false)
                    if (resp && resp.data) {
                        const parsedData = JSON.parse(resp.data.jsonString)
                        if (parsedData.status === 'success') {
                            setDisableSubmit(true)
                        }
                        setSnackbarOpen( { open: true, message: parsedData.message })
                    }
                    else {
                        setSnackbarOpen( { open: true, message: "Unexpected Error - Please try again." })
                    }
                }
            )
        }
    }

    const getMap = (mapValue: string) => {
        const center = mapValue.split(',').reduce(
            (acc: any, value, i) => {
                if (i === 0) {
                    acc.lat = Number(value)
                } else {
                    acc.lng = Number(value)
                }
                return acc
            },
            { lat: 0, lng: 0 }
        )


        return  (center && center.lat > 0 ? (
                    <Grid item height={'100%'} width={'100%'}>
                        <Wrapper apiKey={'AIzaSyC4INLRUkBZng0Xum4YtYWoIsSr20HcH7w'}>
                            <MapComponent center={center} zoom={10} />
                        </Wrapper>
                    </Grid>
                )
            :
            ( <></> )
        )
    }

    return (
        <>
            <AppBar position="absolute" className={cn(styles.appBar, styles.shift)}>
                <Typography variant="h6" color="primary" component="div" align="center">
                    {titleMessage}
                </Typography>
            </AppBar>
            <>
                {watchLogoDataURL && (
                    <Grid item xs={6} className={styles.selectcontrol}>
                        <>
                            <Dialog
                                open={openViewer}
                                fullWidth={true}
                                maxWidth="xl"
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent>
                                    <div className="PDF-viewer">
                                        <PdfViewerComponent
                                            document={watchLogoDataURL}
                                        />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => {
                                        setOpenViewer(false)
                                        setWatchLogoDataURL(null)
                                    }}>Cancel</Button>
                                    <Button onClick={() => {
                                        setOpenViewer(false)
                                        window.open('/dispatch/open/' + encodeURIComponent(viewDataBody.id), '_blank', 'noopener,noreferrer')
                                    }}>Open Window</Button>
                                    {/*<Button>Save</Button>*/}
                                </DialogActions>
                            </Dialog>
                        </>
                    </Grid>
                )}
            </>
            <>
                <Snackbar
                    open={snackbarOpen.open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                >
                    <Alert severity="success">
                        {snackbarOpen.message}
                    </Alert>
                </Snackbar>
            </>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleEquipmentDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>
            <>
                <Dialog
                    open={dialogTimeEntryObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogTimeEntryObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleTimeEntryDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>
            <>
                <Dialog
                    open={dialogObjPhoto.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObjPhoto.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handlePhotoDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <EquipmentForm
                isOpen={isFormShowing}
                setIsOpen={setIsFormShowing}
                reloadForm={reloadForm}
                equipmentlist={equipmentFormData}
                equipmentData={equipmentData}
                setEquipmentData={setEquipmentData}
            />
            <div className={styles.root}>
                <Container>
                    <Box>
                        <Grid
                            container
                            border={0}
                            direction="row"
                            alignItems="center"
                            height={'100%'}
                            width={'100%'}
                        >
                            {getMainLabels()}
                        </Grid>
                    </Box>
                    <Box>{getSectionsAccordion()}</Box>
                    <LoadingBackdrop open={loadingBackdrop} />
                    {!disableSubmit && dispatchData && !dispatchData.isreadonly && dispatchData.sectiondata && dispatchData.sectiondata.length > 0 &&
                        <Button type="submit" onClick={submitForm} className={styles.buttons} style={{'paddingTop': '20px'}}>
                            Submit
                        </Button>
                    }
                    {(!dispatchData || dispatchData === undefined) &&
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress />
                        </div>
                    }
                </Container>

            </div>
        </>
    )
}

export default DispatchPage