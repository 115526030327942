import React, {useEffect, useMemo, useState} from "react";
import styles from './DispatchWIP.module.css'
import {
    useFetchEntitySchedules,
    SchedulesData,

    SchedeulesDataObject,
    SchedulesDataDefaults
} from "../../../queries/useSchedules";
import {GridSelectionModel, GridSortItem, GridValueFormatterParams} from "@material-ui/x-grid";
import {useQueryClient} from 'react-query'
import {
    DataGridPro,
    GridCallbackDetails,
    GridCellParams,
    GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
    GridRowParams, gridSortedRowIdsSelector,
    GridSortModel, GridToolbarContainer,
    MuiEvent, useGridApiContext,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import {useFetchGridState, useUpdateGridState} from "../../../queries/useGridState";
import {dispatchsend, dispatchtransferwip} from "../../../queries/useSendDispatch";
import {Alert, Badge, BadgeProps, FormGroup, Grid, MenuItem, Select, SelectChangeEvent, styled} from "@mui/material";
import {NotesIcon} from "../../../components/common/NotesLayout/NotesLayout";
import {Box, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Typography} from "@material-ui/core";
import {Delete, PhoneIphone, Edit, SettingsBackupRestore} from "@material-ui/icons";
import {Add, Save} from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import dayjs from "dayjs";
import {formatMoney} from "../../../util/formatHelpers";
import {TimeEntryForm} from "../../DispatchPage/TimeEntryForm";
import {ButtonProps} from "@mui/material/Button";
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {WIPListData, useFetchDispatchWIP, useDeleteDispatchWIP} from "../../../queries/useDispatchWip";
import {DispatchWIPForm} from "./DispatchWIPForm";

const DispatchWIPPage = (params) => {
    const gridTEStateVersion = 'dispatch-wip-dispatch-grid-v0'
    const { data: gridTEStateData } = useFetchGridState(gridTEStateVersion)
    const deleteWIP = useDeleteDispatchWIP()
    let _sortModelState: any = null
    const [gridShowing, setGridShowing] = useState(false)
    const [gridParams, setGridParams] = useState({})
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [extendedValue, setExtendedValue] = useState<string>("")
    const [dialogObjDel, setDialogObjDel] = React.useState({msg: "", dispatchid: 0, wipid: 0})
    const [dialogObj, setDialogObj] = React.useState({msg: "", gridState: "", okButtonText: "Save"})
    const [snackbarOpen, setSnackbarOpen] = React.useState({ open: false, message: '' })
    const [dataRows, setDataRows] = React.useState([]);
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([1]);
    const gridAPIRef = useGridApiRef()
    const updateGridState = useUpdateGridState()
    const [itemList, setItemList] = useState([])
    const [permissions, setPermissions] = React.useState({ canedit: true, canadd: true, candelete: true, itemmessage: "" })

    const [isFormShowing, setIsFormShowing] = useState(false)
    const [isTEFormShowing, setIsTEFormShowing] = useState(false)
    const [reloadTEForm, setReloadTEForm] = useState<number>(0)
    const [teEdit, setTEEdit] = React.useState(false)
    const [timeObject, setTimeObject] =  React.useState<WIPListData>()
    const [defaultWorkDate, setDefaultWorkdate] = React.useState("")

    const gridSortItem: GridSortItem = {
        field: 'schedulestartdate',
        sort: 'asc'
    }
    const defaultGridSortModel: GridSortModel = [gridSortItem]
    const [gridSortModel, setGridSortModel] = React.useState<GridSortModel>(defaultGridSortModel)

    useEffect(() => {
        console.log(params.dispatchList.dispatchid)
    }, [params.dispatchList.dispatchid])

    useEffect(() => {
        params.setReloadData(params.reloadData + 1)
    }, [isTEFormShowing])

    useEffect(() => {
        console.log(params?.dispatchWIPData)
        setGridSortModel(getColumnSortModel())
        if (params?.dispatchWIPData?.itemlist && params?.dispatchWIPData?.itemlist?.length > 0) {
            setItemList(params?.dispatchWIPData.itemlist)
        }
        if (params?.dispatchWIPData) {
            setPermissions({
                canedit: params?.dispatchWIPData.canedit,
                candelete: params?.dispatchWIPData.candelete,
                canadd: params?.dispatchWIPData.canaddwip,
                itemmessage: params?.dispatchWIPData.itemmessage
            })
            setDefaultWorkdate(params?.dispatchWIPData.defaultworkdate)
        }
        if (params?.dispatchWIPData?.wiplist && params?.dispatchWIPData?.wiplist?.length > 0) {
            const dataRows = getMappedRecords()
            setDataRows(dataRows)
        }
        else {
            setDataRows([])
        }
    }, [params?.dispatchWIPData])
    const canDispatch = (params: GridCellParams) => {
        return params.row.schedulecandispatch
    }
    const getMappedRecords = () => {
        let extended = 0
        const dataReturn = params?.dispatchWIPData?.wiplist.map((wipList: WIPListData, i) => {
            extended += wipList.extended
            return {
                id: wipList.wipid,
                wipid: wipList.wipid,
                entityid: wipList.entityid,
                dispatchid: wipList.dispatchid,
                woid: wipList.woid,
                workdate: wipList.workdate,
                unitid: wipList.unitid,
                quantity: wipList.quantity,
                rate: wipList.rate,
                contractinfo: wipList.contractinfo,
                iteminfo: wipList.iteminfo,
                itemcode: wipList.itemcode,
                itemdescription: wipList.itemdescription,
                itemmeasure: wipList.itemmeasure,
                extended: wipList.extended,
                transferred: wipList.transferred,
                candelete: wipList.candelete,
                canedit: wipList.canedit,
            }
        })
        setExtendedValue(formatMoney(Number(extended)))
        return dataReturn
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen({ open: false, message: ""  });
    }

    const basicProps = {
        disableColumnMenu: true,
        sortable: false,
        disableExport: true,
        disableReorder: true,
        editable: false,
        filterable: false,
        groupable: false,
        hideable: false,
        pinnable: false
    }

    const HandleEdit = async (gridParams: GridCellParams) => {
        const wipObj = params?.dispatchWIPData?.wiplist.filter((item) => item.wipid === Number(gridParams.id))[0]
        setGridParams({
            id: wipObj.wipid,
            wipid: wipObj.wipid,
            entityid: wipObj.entityid,
            dispatchid: wipObj.dispatchid,
            woid: wipObj.woid,
            workdate: wipObj.workdate,
            unitid: wipObj.unitid,
            quantity: wipObj.quantity,
            rate: wipObj.rate,
            contractinfo: wipObj.contractinfo,
            iteminfo: wipObj.iteminfo,
            itemcode: wipObj.itemcode,
            itemdescription: wipObj.itemdescription,
            itemmeasure: wipObj.itemmeasure,
            extended: wipObj.extended
        })

        setTEEdit(true)
        setReloadTEForm(reloadTEForm + 1)
        setIsTEFormShowing(true)
    }

    const HandleDispatchSend = (params: GridCellParams) => {
       sendDispatch(params.id)
    }

    const handleAdd = () => {
        setTEEdit(false)
        setReloadTEForm(reloadTEForm + 1)
        setGridParams(null)
        setIsTEFormShowing(true)
    }

    const canEdit = (params: GridCellParams) => {
        return params.row.canedit
    }

    const buildColumnDefs = () => {
        const gridObj = parseGridObject()
        const parsedGridColumns = gridObj.gridDefs
        var gridColumns: GridColDef[] = [
            gridEditSaveButtonDef,
            ...parsedGridColumns,
            gridButtonsDef
        ]
        return gridColumns
    }

    const gridButtonsDef: GridColDef = {
        field: 'Delete',
        headerName: '',
        width: 40,
        align: "center",
        headerAlign: "center",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <>
                    {(params.row.candelete) &&
                        <IconButton className={styles.button} onClick={() => {
                            handleDelete(params)
                        }}>
                            <Delete/>
                        </IconButton>
                    }
                </>
            )
        }
    }
    const handleDelete = (params: GridCellParams) => {
        setDialogObjDel({
            //  msg: 'Are you sure you want to delete this WIP for employee \"' + params.row.employee.toString() + "\"?",
            msg: "Are you sure you want to delete this wip?",
            dispatchid: Number(params.row.dispatchid),
            wipid: Number(params.row.wipid)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteWIP.mutateAsync({ dispatchid: dialogObjDel.dispatchid, wipid: dialogObjDel.wipid })
        params.setReloadData(params.reloadData + 1)
        closeDialog()
    }

    const getColumnSortModel = () => {
        const gridObj = parseGridObject()
        return gridObj.sortModel
    }

    const formatDate = (params: GridValueFormatterParams) => {
        return (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DD").format('MM/DD') : '')
    }

    const formatDateTime = (params: GridValueFormatterParams) => {
        return (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DDTHH:mm:ss").format('MM/DD hh:mm A') : '')
    }

    const formatMoneyParam = (params: GridValueFormatterParams) => {
        return formatMoney(Number(params.value))
    }

    const parseGridObject = () => {
        var sortModel: GridSortModel = [gridSortItem]
        var gridStateString = JSON.stringify({gridType: "timeentrydispatch", gridState: JSON.stringify(gridDefaults)})
        if (params.gridStateData && params.gridStateData.length > 0) {
            gridStateString = params.gridStateData[0].gridState
            const gridStateData = JSON.parse(gridStateString)
            const sendObj = {
                gridType: 'timeentrydispatch',
                gridState: JSON.stringify(gridStateData.columnDefs)
            }
            gridStateString = JSON.stringify(sendObj)

            if (gridStateData && gridStateData.sortModel !== null && gridStateData.sortModel.length > 0) {
                // gridAPIRef?.current?.setSortModel(gridStateData.sortModel)
                const dataSort = gridStateData.sortModel
                sortModel = dataSort
            }
        }

        var gridDefs: GridColDef[] = [];
        if (gridStateString.length > 0) {
            const gridObject = JSON.parse(gridStateString)
            if (gridObject.gridType === 'timeentrydispatch') {
                const gridState = JSON.parse(gridObject?.gridState)
                gridState?.forEach(state => {
                    var col: GridColDef = {...state}

                    if (col.field === 'workdate') {
                        col.valueFormatter = formatDate
                    }
                    else if (col.field === 'rate' || col.field === 'extended') {
                        col.valueFormatter = formatMoneyParam
                    }

                    gridDefs.push(col)
                })
            }
        }
        return {gridDefs: gridDefs, sortModel: sortModel}
    }

    const gridDefaults: GridColDef[] = [
        {
            "field": "itemcode",
            "headerName": "Code",
            "headerAlign": "left",
            "type": "string",
            "width": 200,
            "align": "left"
        },
        {
            "field": "itemdescription",
            "headerName": "Description",
            "headerAlign": "left",
            "type": "string",
            "width": 300,
            "align": "left",
        },
        {
            "field": "itemmeasure",
            "headerName": "Measure",
            "headerAlign": "left",
            "type": "string",
            "width": 100,
            "align": "left"
        },
        {
            "field": "quantity",
            "headerName": "Quantity",
            "headerAlign": "left",
            "type": "number",
            "width": 100,
            "align": "right"
        },
        {
            field: 'rate',
            type: 'string',
            headerName: 'Rate',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            field: 'extended',
            type: 'string',
            headerName: 'Extended',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            field: 'workdate',
            type: 'string',
            headerName: 'Work date',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueFormatter: formatDate,
        },
        {
            field: "transferred",
            headerName: "Transferred",
            headerAlign: "right",
            type: "string",
            width: 125,
            align: "right",
        },
    ]

    const gridEditSaveButtonDef: GridColDef = {
        field: '',
        renderHeader: () => {
            return (
                <>
                    {permissions?.canadd &&
                        <IconButton
                            className={styles.submitButton}
                            onClick={() => handleAdd()}
                            size="small">
                            <Add />
                        </IconButton>
                    }
                </>
            )
        },
        width: 50,
        align: "center",
        headerAlign: "center",
        pinnable: true,
        cellClassName: 'pinnedColumn',
        ...basicProps,
        renderCell: (params) => (
            <>
                {(params.row.canedit) &&
                    <IconButton className={styles.button} onClick={() => {
                        HandleEdit(params)
                    }}>
                        <Edit/>
                    </IconButton>
                }
            </>
        )
    }

    const gridIdDef: GridColDef = {
        field: 'id',
        hide: true,
        ...basicProps
    }

    const getColumnStringToSave = () => {
        var fieldObjects = []
        const gridState = gridAPIRef.current.getAllColumns()
        gridState.forEach(stateColumnDef => {
            if (stateColumnDef.sortable && stateColumnDef.pinnable && stateColumnDef.groupable) {
                fieldObjects.push({
                    field: stateColumnDef.field,
                    headerName: stateColumnDef.headerName,
                    headerAlign: stateColumnDef.headerAlign,
                    type: stateColumnDef.type,
                    width: stateColumnDef.width,
                    align: stateColumnDef.align,
                    description: stateColumnDef.description,
                    hide: stateColumnDef.hide,
                    valueFormatter: stateColumnDef.valueFormatter
                })
            }
        })

        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: fieldObjects, sortModel: sortModelDefs})
        return strObject
    }

    const handleRestore = () => {
        setGridShowing(false)
        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: gridDefaults, sortModel: sortModelDefs})
        setDialogObj({
            msg: 'Are you sure you want to reset the grid state to default?',
            gridState: strObject,
            okButtonText: 'RESET'
        })
    }

    const sendDispatch = async  (scheduleID) => {
        const response = await dispatchsend(scheduleID)
        setSnackbarOpen( { open: true, message: response.message })
    }

    const handleSave = () => {
        setGridShowing(false)
        const columnObjsting = getColumnStringToSave()
        setDialogObj({
            msg: 'Are you sure you want to save the current grid state?',
            gridState: columnObjsting,
            okButtonText: 'Save'
        })
    }

    const handleOkConfirm = async () => {
        var finalData = {
            gridType: params.gridStateVersion,
            gridState: dialogObj.gridState
        }
        const {data: response} = await updateGridState.mutateAsync(finalData)
        closeDialog()
        setGridShowing(true)
        setSnackbarOpen({ open: true, message: "Time Entry settings have been saved" })
    }

    const closeDialog = () => {
        setGridShowing(true)
        setDialogObj({
            msg: '',
            gridState: '',
            okButtonText: ''
        })
        setDialogObjDel({
            msg: '',
            dispatchid: 0,
            wipid: 0
        })
    }

    const handleRowClick = (gridparam: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        const timeListData = params?.dispatchWIPData.wiplist.filter((dispatchList) => { return dispatchList.wipid === Number(gridparam.id)})

        if (timeListData && timeListData.length > 0) {
            setTimeObject(timeListData[0])
        }
    }

    const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
        gridSortedRowIdsSelector(apiRef);

    const sendWip = async () => {
        const response = await dispatchtransferwip(params.dispatchList.dispatchid)
        params.setReloadData(params.reloadData + 1)
        setSnackbarOpen({ open: true, message: response.message })
    }

    function CustomGridToolbar() {
        const apiRef = useGridApiContext();

        const handleExport = (options: GridCsvExportOptions) =>
            apiRef.current.exportDataAsCsv(options);

        const buttonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <FileDownloadOutlinedIcon />,
        };

        const saveButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <Save />,
        };

        const restoreButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <SettingsBackupRestore />,
        };

        return (
            <>
                {!itemList || itemList.length === 0 &&
                    <GridToolbarContainer >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography
                                variant="h6"
                                color="primary"
                                component="div">
                                {permissions?.itemmessage}
                            </Typography>
                        </Grid>
                    </GridToolbarContainer>
                }

                {itemList && itemList.length > 0 &&
                    <GridToolbarContainer>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Button
                                {...saveButtonBaseProps}
                                onClick={() => handleSave()}
                            >
                                SAVE GRID SETTINGS
                            </Button>
                            <Button
                                {...restoreButtonBaseProps}
                                onClick={() => handleRestore()}
                            >
                                RESTORE GRID SETTINGS
                            </Button>
                            <Button
                                {...buttonBaseProps}
                                onClick={() => handleExport({getRowsToExport: getUnfilteredRows})}
                            >
                                EXPORT
                            </Button>
                            {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                        </Grid>
                    </GridToolbarContainer>
                }
            </>
        )
    }

    return (
        <>
        <>
            <Snackbar
                open={snackbarOpen.open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert severity="success">
                    {snackbarOpen.message}
                </Alert>
            </Snackbar>
        </>
        <>
            <Dialog
                open={dialogObj.msg.length > 0}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogObj.msg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={handleOkConfirm}>{dialogObj.okButtonText}</Button>
                </DialogActions>
            </Dialog>
        </>

        <>
            <Dialog
                open={dialogObjDel.msg.length > 0}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogObjDel.msg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm}>DELETE</Button>
                </DialogActions>
            </Dialog>
        </>
        <DispatchWIPForm
            isOpen={isTEFormShowing}
            setIsOpen={setIsTEFormShowing}
            gridParams={gridParams}
            reloadForm={reloadTEForm}
            itemlist={itemList}
            dispatchid={params.dispatchList.dispatchid}
            defaultWorkDate={defaultWorkDate}
        />
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <DataGridPro
                    sx={{
                        "& .MuiDataGrid-pinnedColumns": {
                            boxShadow: "none",
                            backgroundColor: "transparent"
                        },
                        "& .MuiDataGrid-pinnedColumnHeaders": {
                            boxShadow: "none",
                            backgroundColor: "transparent"
                        }
                    }}
                    apiRef={gridAPIRef}
                    rows={dataRows}
                    columns={buildColumnDefs()}
                    autoHeight={true}
                    density={'compact'}
                    pageSize={10}
                    sortModel={gridSortModel}
                    disableMultipleSelection={true}
                    onSortModelChange={(model: GridSortModel) => setGridSortModel(model)}
                    onStateChange={(state) => {
                        _sortModelState = state.sorting.sortModel

                        return state
                    }}
                    initialState={{
                        pinnedColumns: {left: ['Edit'], right: ['Notes', 'Delete']},
                        sorting: {
                            sortModel: gridSortModel
                        }
                    }}
                    onRowClick={handleRowClick}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    components={{
                        Toolbar: CustomGridToolbar,
                    }}
                />
            </Grid>
            <Grid container direction="row" marginTop={'10px'} justifyContent="flex-end">
                <Typography
                    variant="h6"
                    color="primary"
                    align="center"
                    component="div"
                    style={{marginRight: '10px'}}
                >
                    Total Cost: {params?.dispatchWIPData?.totalcost}
                </Typography>
                <Typography
                    variant="h6"
                    color="primary"
                    align="center"
                    component="div"
                    style={{marginRight: '10px'}}
                >
                    Gross Profit: {params?.dispatchWIPData?.grossprofit}
                </Typography>
                <Typography
                    variant="h6"
                    color="primary"
                    align="center"
                    component="div"
                    style={{marginRight: '10px'}}
                >
                    Total Revenue: {params?.dispatchWIPData?.totalrevenue}
                </Typography>
                <Typography
                    variant="h6"
                    color="primary"
                    align="center"
                    component="div"
                    style={{marginRight: '10px'}}
                >
                    Margin Percentage: {params?.dispatchWIPData?.marginpercentage}
                </Typography>
            </Grid>

            <Grid container direction="row" justifyContent="flex-end">
                <Button
                    style={{width: '180px', height: '30px', marginLeft: '40px', marginTop: '20px', marginBottom: '20px'}}
                    variant="contained"
                    disabled={!params?.dispatchWIPData?.canpostwip}
                    onClick={() => { sendWip() }}
                >
                    Post WIP
                </Button>
            </Grid>
        </Grid>
            </>
    );
}
export default DispatchWIPPage
