import { QueryClient, QueryClientProvider } from 'react-query'
import {Provider} from 'react-redux'
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom'

import { LicenseInfo } from '@material-ui/x-grid'

import { AuthGuard } from '../components/common/AuthGuard'
import Layout from '../components/common/Layout'
import AuthPage from '../pages/AuthPage'
import HomePage from '../pages/HomePage'
import WorkOrderPage from '../pages/WorkOrderPage'
import SubcontractPage from '../pages/SubcontractPage'
import CWAMainPage from '../pages/CWAMainPage'
import WAMainPage from '../pages/WAMainPage'
import { useAutoAuth } from '../queries/useAutoAuth'
import HttpInterceptor from '../util/HttpInterceptor'
import styles from './App.module.css'
import store from './store'
import TestPage from "../pages/TestPage";
import DispatchPage from "../pages/DispatchPage/DispatchPage";
import EntityPage from "../pages/MaintenancePages/EntityPages/EntityPage";
import LookupPage from "../pages/MaintenancePages/LookupPages/LookupPage";
import UDLookupPage from "../pages/MaintenancePages/UDLookupPages/LookupPage";
import ContactPage from "../pages/MaintenancePages/ContactPages/ContactPage";
import EmployeePage from "../pages/MaintenancePages/EmployeesPage/EmployeePage";
import UsersPage from "../pages/MaintenancePages/UserPages/UsersPage";
import DivisionPage from "../pages/DivisionTabPage/DivisionTabPage";
import DivisionPage2 from "../pages/DivisionTabPage2/DivisionTabPage2";
import DynamicSchedulePage from "../pages/DynamicSchedules/DynamicSchedulePage";
import BulkDispatchPage from "../pages/DivisionTabPage/Bulk/BulkDispatchPage";
import DivisionProcessingPage from "../pages/DivisionProcessingPage/DivisionProcessingPage";
import React from "react";
import {Container} from "@material-ui/core";
import DispatchViewerPage from "../pages/DispatchPage/DispatchViewerPage";
import CalendarPhonePage from "../pages/CalendarPhonePage/CalendarPhonePage"
import CalendarDispatchPage from "../pages/CalendarDispatchPage/CalendarDispatchPage";
import {useNetworkStatus} from "../queries/useNetworkStatus";
import VacationEmployeeGridPage from '../pages/WorkOrderPage/Vacation/VacationPage'
import VacationSchedulePage from "../pages/VacationSchedule/VacationSchedulePage";

// LicenseInfo.setLicenseKey(
//   '5d197bb51445f4132825e19d5d148671T1JERVI6Mjc2MjEsRVhQSVJZPTE2NTkxMDU3NzIwMDAsS0VZVkVSU0lPTj0x'
// )
LicenseInfo.setLicenseKey(
    'f7086eca5ec0b9128713cd1ec7c772f3Tz03NzkxNSxFPTE3MzA1NjM4NjAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
)

export enum PagePathTypes {
  auth = '/auth',
  home = '/home',
  workorder = '/workorder',
  subcontract = '/subcontract',
  cwa = '/cwa',
  wa = '/wa',
  entity = '/entity',
  lookup = '/lookup',
  udlookup = '/udlookup',
  contact = '/contact',
  employee = '/employee',
  users = '/users',
  test = '/test',
  division = '/division/:entityId',
  division2 = '/division2/:entityId',
  dynamicschedules = '/dynamicschedules/:entityId',
  divisionprocessing = '/divisionprocessing/:entityId',
  dispatch = '/dispatch/:userGuid',
  dispatchOpenViewer = '/dispatch/open/:id',
  calendarPhone = '/calendarphone',
  calendar = '/calendar/:calendarGuid',
  bulk = '/bulk/:entityId',
  vacation = '/vacation/:entityId',
  vacationschedule = '/vacationschedule/:entityId'
}
const queryClient = new QueryClient()

const AutoAuth = ({ children }) => {
  useAutoAuth()
  return children
}

const NetworkStatus = ({ children }) => {
    useNetworkStatus()
    return children
}

export const App: React.FC =  () => {
  return (
    <QueryClientProvider client={queryClient}>
      <HttpInterceptor />
      <Provider store={store}>
        <div className={styles.app}>
          <Router>
              <Switch>
                {/* Removed calendar and calendar phone from the network children list as it ended up triggering auto auth 
                    Something to do with query i'm sure but given these pages are made to be used immediately we shouldnt need caching hopefully 
                */}
              <Route exact path={PagePathTypes.calendarPhone}>
                  <Container
                      style={{
                        padding: '84px 0 0 0px',
                        width: '100%',
                        height: '100%',
                        justifySelf: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                    <CalendarPhonePage />
                  </Container>
                </Route>
                <Route exact path={PagePathTypes.calendar}>
                  <Container
                      style={{
                        padding: '84px 0 0 0px',
                        width: '100%',
                        height: '100%',
                        justifySelf: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                    <CalendarDispatchPage />
                  </Container>
                </Route>
                <NetworkStatus>
                <Route path={PagePathTypes.test}>
                  <Layout>
                    <TestPage />
                  </Layout>
                </Route>
                <Route path={PagePathTypes.auth}>
                  <AuthPage />
                </Route>
               

                <Route exact path={PagePathTypes.dispatch}>
                    <Container
                        style={{
                          padding: '84px 0 0 0px',
                          width: '100%',
                          height: '100%',
                          justifySelf: 'center',
                          display: 'flex',
                          flexDirection: 'column'
                        }}>
                      <DispatchPage />
                    </Container>
                </Route>
                <Route exact path={PagePathTypes.dispatchOpenViewer}>
                  <Container
                      style={{
                        padding: '84px 0 0 0px',
                        width: '100%',
                        height: '100%',
                        justifySelf: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                    <DispatchViewerPage />
                  </Container>
                </Route>
                <AutoAuth>
                <AuthGuard>
                  <Route exact path="/">
                    <Layout showOrgTitle={true}>
                      <Redirect to={PagePathTypes.home} />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.home}>
                    <Layout showOrgTitle={true}>
                      <HomePage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.workorder}>
                    <Layout>
                      <WorkOrderPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.divisionprocessing}>
                    <Layout>
                      <DivisionProcessingPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.division}>
                    <Layout>
                      <DivisionPage />
                    </Layout>
                  </Route>
                    <Route exact path={PagePathTypes.division2}>
                        <Layout>
                            <DivisionPage2 />
                        </Layout>
                    </Route>
                    <Route exact path={PagePathTypes.dynamicschedules}>
                        <Layout>
                            <DynamicSchedulePage />
                        </Layout>
                    </Route>
                  <Route exact path={PagePathTypes.subcontract}>
                    <Layout>
                      <SubcontractPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.cwa}>
                    <Layout>
                      <CWAMainPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.wa}>
                    <Layout>
                      <WAMainPage />
                      </Layout>
                      </Route>
                  <Route exact path={PagePathTypes.entity}>
                    <Layout>
                      <EntityPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.vacation}>
                    <Layout>
                      <VacationEmployeeGridPage />
                    </Layout>
                  </Route>
                    <Route exact path={PagePathTypes.vacationschedule}>
                        <Layout>
                            <VacationSchedulePage />
                        </Layout>
                    </Route>
                  <Route exact path={PagePathTypes.contact}>
                    <Layout>
                      <ContactPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.employee}>
                    <Layout>
                      <EmployeePage />
                    </Layout>
                  </Route>                    
                  <Route exact path={PagePathTypes.lookup}>
                    <Layout>
                      <LookupPage />
                    </Layout>
                  </Route>
                <Route exact path={PagePathTypes.udlookup}>
                    <Layout>
                        <UDLookupPage />
                    </Layout>
                </Route>
                  <Route exact path={PagePathTypes.bulk}>
                    <Layout>
                      <BulkDispatchPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.users}>
                    <Layout>
                      <UsersPage />
                    </Layout>
                  </Route>
                </AuthGuard>
                </AutoAuth>
                </NetworkStatus>
              </Switch>
          </Router>
        </div>
      </Provider>
    </QueryClientProvider>
  )
}
