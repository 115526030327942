import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  SchedeuleFieldsDataObject {
    status: string
    message: string
    canadd: boolean
    canaddschedule: 0
    canadddirectonly: false
    schedulefieldslist: ScheduleFieldData[]
    schedulelookuplist: ScheduleLookupOptions[]
}

export interface  ScheduleFieldData {
    schedulefieldid: number
    schedulefieldheaderid: number
    schedulefieldcontrol: number
    schedulefieldrequired: boolean
    schedulefieldreadonly: boolean
    schedulefieldlabel: string
    schedulefieldhover: string
}

export interface  ScheduleLookupOptions {
    lookupheaderid: number
    lookupid: number
    lookup: string
    lookupactive: boolean
}

export const SchedulesDataDefaults = {
    schedulefieldid: 0,
    schedulefieldheaderid: 0,
    schedulefieldcontrol: 0,
    schedulefieldrequired: true,
    schedulefieldreadonly: true,
    schedulefieldlabel: "",
    schedulefieldhover: ""
}

export const useFetchScheduleFieldsBySched = (scheduleid: number) => {
    return useQuery<SchedeuleFieldsDataObject>(
        ['schedulefields'],
        () => globalFetcherBySchedule(scheduleid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useFetchScheduleFields = (divisionid: number, templateid: number) => {
    return useQuery<SchedeuleFieldsDataObject>(
        ['schedulefields'],
        () => globalFetcher(divisionid, templateid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdateScheduleFields = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`schedulefields`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['schedulefields'])
            queryClient.invalidateQueries(['schedule'])
        }
    })
}

export const useDeleteScheduleFields = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`schedulefields`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['schedulefields'])
            queryClient.invalidateQueries(['schedule'])
        }
    })
}

export const globalFetcherBySchedule = async (scheduleid: number) => {
    const { data } = await axios.post('/schedulefields', {
        scheduleid: scheduleid
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as SchedeuleFieldsDataObject
}

export const globalFetcher = async (divisionid: number, templateid: number) => {
    const { data } = await axios.post('/schedulefields', {
        templateid: templateid,
        divisionid: divisionid
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as SchedeuleFieldsDataObject
}
