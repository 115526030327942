import React, { useEffect, useRef, useState } from 'react'
import styles from './CalendarPhonePage.module.css'
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText,
} from '@material-ui/core'
import HookForm from '../../components/common/HookForm'
import MaskedInput from "../../components/common/inputs/MaskedInput";
import {emailRegex, phoneRegex} from "../../util/formValidation";
import {useForm} from "react-hook-form";
import axios from "axios";
import LoadingBackdrop from "../../components/common/LoadingBackdrop";

interface FormData {
    phone: string
}

const CalendarPhonePage = () => {
    const formMethods = useForm<FormData>()
    const [dialogMsg, setDialogMsg] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)

    const onSubmit = async (data: FormData) => {
        setIsLoading(true)
        const phoneNumber = data.phone.replace(/[-() ]/g, "")
        const body = {
            entityid: 4,
            phone: phoneNumber,
        }

        axios.post(`calendarphone`, body).then((response) => {
            const dataJson = JSON.parse(response.data.jsonString)
            setDialogMsg(dataJson.message)
            setIsLoading(false)
        })
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    return (

        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <LoadingBackdrop open={isLoading} />
            <div className={styles.backgroundContainer}>
                <div className={styles.header}>
                    <h1>Dispatch Calendar</h1>
                </div>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.form}>

                    <MaskedInput
                        name="phone"
                        label="Phone"
                        defaultValue=""
                        thousandSeparator={false}
                        thousandsGroupStyle={""}
                        prefix={""}
                        format={"(###) ###-####"}
                        mask={"_"}
                        rules={{
                            pattern: {
                                value: phoneRegex,
                                message: 'Please enter a valid phone number.'
                            }
                        }}
                    />
                    <Button type="submit" variant="contained" className={styles.button}>
                        Send Link
                    </Button>

                </HookForm>
            </div>
        </div>
    )
}

export default CalendarPhonePage