import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'



export const dispatchsend = async (scheduleid) => {
    const json = JSON.stringify({ scheduleid: scheduleid})
    const { data } = await axios.post('/dispatchsend', json, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData
}

export const dispatchfielddoc = async (dispatchid) => {
    const json = JSON.stringify({ dispatchid: dispatchid})
    const { data } = await axios.post('/dispatchfielddoc', json, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData
}

export const dispatchpwrpush = async (dispatchid) => {
    const json = JSON.stringify({ dispatchid: dispatchid})
    const { data } = await axios.post('/dispatchpwrpush', json, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData
}

export const dispatchtransfertime = async (dispatchid) => {
    const json = JSON.stringify({ dispatchid: dispatchid})
    const { data } = await axios.post('/dispatchtransfertime', json, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData
}

export const dispatchtransferwip = async (dispatchid) => {
    const json = JSON.stringify({ dispatchid: dispatchid})
    const { data } = await axios.post('/dispatchtransferwip', json, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData
}

