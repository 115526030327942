import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { Button } from '@material-ui/core'

import { PagePathTypes } from '../../../App'
import Alerts from '../../../components/common/Alerts'
import HookForm from '../../../components/common/HookForm'
import LoadingBackdrop from '../../../components/common/LoadingBackdrop'
import TextInput from '../../../components/common/inputs/TextInput'
import { emailRegex } from '../../../util/formValidation'
import { AuthPageSubPaths } from '../AuthPage'
import styles from './LoginForm.module.css'
import { useLoginMutation } from './useLoginMutation'

type FormData = {
  email: string
  password: string
}

const LoginForm = () => {
  const formMethods = useForm<FormData>({
    mode: 'onChange'
  })

  const loginMutation = useLoginMutation()

  const onSubmit = ({ email, password }: FormData) => {
    loginMutation.mutate({ password, email })
  }

  return (
    <div className={styles.root}>
      <h3>Login</h3>
      <HookForm
        methods={formMethods}
        onSubmit={onSubmit}
        className={styles.form}>
        <LoadingBackdrop open={loginMutation.isLoading} />

        <TextInput
          name="email"
          label="Email"
          required
          rules={{
            pattern: {
              value: emailRegex,
              message: 'Enter a valid email'
            }
          }}
        />
        <TextInput
          name="password"
          label="Password"
          rules={{
            required: 'Field is required'
          }}
          required
          textFieldProps={{ type: 'password' }}
        />
        <Button type="submit" variant="contained" className={styles.button}>
          Login
        </Button>

        <Alerts errorMsg={(loginMutation.error as any)?.message} />
      </HookForm>
      <Link to={`${PagePathTypes.auth}${AuthPageSubPaths.forgotPassword}`}>
        Forgot Password?
      </Link>
    </div>
  )
}

export default LoginForm
