import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface customerList {
    entityid: number
    custid: number
    custcode: string
    custinfo: string
}

export interface cwaamountsstatuslist {
    statusid: number
    statuscode: string
}

export interface  CWADropdowns {
    custlist: customerList[]
    cwaamountsstatuslist: cwaamountsstatuslist[]
    entitylist: EntityList[]
}

export const CWADropdownDefaults = {
    custlist: [],
    cwaamountsstatuslist: [],
    entitylist: []
}

export interface EntityList {
    entityid: number
    entityname: string
}

export const useFetchCWADropdowns =  () => {
    return useQuery<CWADropdowns>(
        ['cwadropdowns'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            placeholderData: CWADropdownDefaults,
            keepPreviousData: false
        }
    )
}

export const globalFetcher = async () => {
    const { data } = await axios.get('/cwadropdowns')
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as CWADropdowns
}
