import React from "react";
import { useForm } from 'react-hook-form'
import styles from './BulkDispatchPage.module.css'
import {Grid} from "@mui/material";
import { BulkDispatchForm } from "./BulkDispatchForm";
import {useParams} from "react-router";

interface Params {
  entityId: string
}

const BulkDispatchPage = () => {
  const { entityId } = useParams<Params>()

    return (
        <div className={styles.root}>
           <BulkDispatchForm entityid={entityId}/>
        </div>
      )
}

export default BulkDispatchPage