import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

// export interface  DynamicSchedeuleFieldsDataObject {
//     status: string
//     message: string
//     canadd: boolean
//     canaddschedule: 0
//     canadddirectonly: false
//     schedulefieldslist: ScheduleFieldData[]
//     schedulelookuplist: ScheduleLookupOptions[]
// }
//
// export interface  ScheduleFieldData {
//     schedulefieldid: number
//     schedulefieldheaderid: number
//     schedulefieldcontrol: number
//     schedulefieldrequired: boolean
//     schedulefieldreadonly: boolean
//     schedulefieldlabel: string
//     schedulefieldhover: string
// }
//
// export interface  ScheduleLookupOptions {
//     lookupheaderid: number
//     lookupid: number
//     lookup: string
//     lookupactive: boolean
// }
//
// export const SchedulesDataDefaults = {
//     schedulefieldid: 0,
//     schedulefieldheaderid: 0,
//     schedulefieldcontrol: 0,
//     schedulefieldrequired: true,
//     schedulefieldreadonly: true,
//     schedulefieldlabel: "",
//     schedulefieldhover: ""
// }

export const useUpdateDynamicScheduleFields = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`entitydynamicschedule`, body), {
        onSuccess: () => {}
    })
}

// export const globalFetcherBySchedule = async (scheduleid: number) => {
//     const { data } = await axios.post('/schedulefields', {
//         scheduleid: scheduleid
//     })
//     const parsedData = JSON.parse(data.jsonString)
//     return parsedData as SchedeuleFieldsDataObject
// }
//
// export const globalFetcher = async (divisionid: number, templateid: number) => {
//     const { data } = await axios.post('/entitydynamicschedule', {})
//     const parsedData = JSON.parse(data.jsonString)
//     return parsedData as SchedeuleFieldsDataObject
// }
