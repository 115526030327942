import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import SignatureCanvas from 'react-signature-canvas'

import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, FormControlLabel, Grid, useMediaQuery
} from '@material-ui/core'

import HookForm from '../../components/common/HookForm'
import styles from './DispatchPage.module.css'
import {useUpdateLookup} from "../../queries/useLookup";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import AdapterDateFns from "@date-io/date-fns";
import {MobileDateTimePicker} from "@mui/x-date-pickers/MobileDateTimePicker";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from 'dayjs';
import Typography from "@mui/material/Typography";
import {useUpdateDispatchSubmittedTIme} from "../../queries/useDispatchTimeEntry"
import MaskedInput from "../../components/common/inputs/MaskedInput";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import AutocompleteSelect from "../../components/common/inputs/AutoCompleteSelectInput/AutocompleteSelect"
import TextInput from "../../components/common/inputs/TextInput";
import {emailRegex} from "../../util/formValidation";
import axios from "axios";

interface FormData {
    employeeid: number
    employee: string
    vehicleid: number
    vehicle: string
    perdiem: number
    lunch: boolean
    shiftpay: boolean
    paylevel: string
    travel: number
    missedmeal: number
    additionalot: number
    additionaldt: number
    st: number
    ot: number
    dt: number
    meals: number
    overridecalc: boolean
    comments: string
}

export const AdminTimeEntryForm = ({ isOpen, setIsOpen, reloadForm, employeeList, vehicleList, timeEntries, setTimeEntries, gridParams, isEdit, dispatchid, timeid, entityid, reloadData, setReloadData}) => {
    const formMethods = useForm<FormData>()
    const title = (isEdit ? "Edit Time Entry" : "Create Time Entry")
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateLookup = useUpdateLookup()
   // const [employeeActiveSelect, setEmployeeActiveSelect] = React.useState<string>("")
   // const [employeeActiveSelectId, setEmployeeActiveSelectId] = React.useState<number>()
   // const [vehicleActiveSelect, setVehicleActiveSelect] = React.useState<string>("")
   // const [vehicleActiveSelectId, setVehicleActiveSelectId] = React.useState<number>()
    const signEmployee = React.useRef() as React.MutableRefObject<any>
    const [perdiem, setPerdiem] = useState<number>(0)

    const startDateNearest = new Date()
    startDateNearest.setMinutes(Math.round(startDateNearest.getMinutes() / 15) * 15)
    const dateNearest = new Date()
    startDateNearest.setMinutes(Math.round(startDateNearest.getMinutes() / 15) * 15)

    const [startTravelValue, setStartTravelValue] = React.useState<Dayjs>(dayjs())
    const [onJobValue, setOnJobValue] = React.useState<Date | null>(dayjs().toDate())
    const [endTravelValue, setEndTravelValue] = React.useState<Date | null>(dateNearest)
    const [leaveJobValue, setLeaveJobValue] = React.useState<Date | null>(dateNearest)
    const [employeeSigned, setEmployeeSigned] = React.useState()
    const [missingLunch, setMissingLunch] = React.useState<boolean>(false)
    const [overrideCalc, setOverrideCalc] = React.useState<boolean>(false)
    const [shiftPay, setShiftPay] = React.useState<boolean>(false)
    const [travelValue, setTravelValue] = React.useState(0)
    const [additionaldt, setAdditionaldt] = React.useState(0)
    const [additionalot, setAdditionalot] = React.useState(0)
    const [missedmeal, setmissedmeal] = React.useState(0)

    const [meals, setMeals] = React.useState<number>(0)
    const updateTimeentry = useUpdateDispatchSubmittedTIme()

    const handleStartTravelChange = (newValue: Dayjs | null) => {
        setStartTravelValue(dayjs(newValue));
    }

    const handleOnJobChance = (newValue: Date | null) => {
        setOnJobValue(newValue);
    }

    const handleOnJobValidation = (newValue: Date | null) => {
        const stV = dayjs(newValue)
        var diffInMinutes = stV.diff(startTravelValue, 'minutes')
        if (diffInMinutes < 0) {
            setOnJobValue(startTravelValue.toDate())
            setDialogMsg("The 'On Job' date must be after the travel start date. Please correct the 'On Job' date.")
        }
        else {
            setOnJobValue(newValue);
        }
    }

    const handleLeaveJobChange = (newValue: Date | null) => {
        setLeaveJobValue(newValue);
    }

    const handleLeaveJobValidation = (newValue: Date | null) => {
        const stV = dayjs(newValue)
        var diffInMinutes = stV.diff(onJobValue, 'minutes')
        if (diffInMinutes < 0) {
            setLeaveJobValue(onJobValue)
            setDialogMsg("The 'Leave Job' date must be after the on job date. Please correct the 'Leave Job' date.")
        }
        else {
            setLeaveJobValue(newValue);
        }
    }

    const handleEndTravelChange = (newValue: Date | null) => {
        setEndTravelValue(newValue);
    }

    const handleEndTravelValidation = (newValue: Date | null) => {
        const stV = dayjs(newValue)
        var diffInMinutes = stV.diff(leaveJobValue, 'minutes')
        if (diffInMinutes < 0) {
            setEndTravelValue(leaveJobValue)
            setDialogMsg("The 'Travel End' date must be after the leave job date. Please correct the 'Travel End' date.")
        }
        else {
            setEndTravelValue(newValue);
        }
    }

    const handlePerdiem = (event) => {
        setPerdiem(event.floatValue)
    }

    const handleMeals = (event) => {
        setMeals(event.floatValue)
    }

    useEffect(() => {
        reset({
            employeeid:(isEdit ? gridParams.employeeid : null),
            employee: "",
            vehicleid: (isEdit ? gridParams.vehicleid : null),
            vehicle: "",
        })
        if (gridParams) {
            if (isEdit) {
                console.log('isEdit')
                console.log(gridParams)
               // setEmployeeActiveSelect(gridParams.employee)
                //setEmployeeActiveSelectId(gridParams.employeeid)
                //setVehicleActiveSelect(gridParams.vehicle)
                //setVehicleActiveSelectId(gridParams.vehicleid)
                setEmployeeSigned(gridParams.employeesignature)
                setPerdiem(gridParams.perdiem)
                setMeals(gridParams.meals)
                setTravelValue(gridParams.travel)
                setMissingLunch(gridParams.lunch)
                setShiftPay(gridParams.shiftpay)
                setValue("perdiem", gridParams.perdiem)
                setValue("meals", gridParams.meals)

                setValue("st", gridParams.st)
                setValue("dt", gridParams.dt)
                setValue("ot", gridParams.ot)
            }
            else {
              //  setEmployeeActiveSelect(null)
                //setEmployeeActiveSelectId(null)
               // setVehicleActiveSelect(null)
               // setVehicleActiveSelectId(null)
                setEmployeeSigned(null)
                setPerdiem(0)
                setMeals(0)
                setValue("perdiem", 0)
                setValue("meals", 0)
            }


            gridParams.starttravel === undefined ?
                setStartTravelValue(dayjs())
                :
                setStartTravelValue(dayjs(gridParams.starttravel))

            gridParams.onjob === undefined ?
                setOnJobValue(dayjs().toDate())
                :
                setOnJobValue(gridParams.onjob)

            gridParams.endtravel === undefined ?
                setEndTravelValue(dateNearest)
                :
                setEndTravelValue(gridParams.endtravel)

            gridParams.leavejob === undefined ?
                setLeaveJobValue(dayjs().toDate())
                :
                setLeaveJobValue(gridParams.leavejob)
        }
        else {
            //setEmployeeActiveSelect(null)
            //setEmployeeActiveSelectId(null)
           // setVehicleActiveSelect(null)
            //setVehicleActiveSelectId(null)
            setPerdiem(0)
            setValue("perdiem", 0)
            setStartTravelValue(dayjs())
            setOnJobValue(dayjs().toDate())
            setLeaveJobValue(dayjs().toDate())
            setEndTravelValue(dayjs().toDate())
        }

    }, [reloadForm])

    const onCalc = async () => {
        const paylevel = getValues('paylevel')
        const body = {
            starttravel: dayjs(startTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
            onjob: dayjs(onJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
            endtravel: dayjs(endTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
            leavejob: dayjs(leaveJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
            lunch: missingLunch,
            shiftpay: shiftPay,
            paylevel: paylevel
        }

        axios.post(`dispatchsubmittedtimecalc`, body).then((response) => {
            const dataJson = JSON.parse(response.data.jsonString)
            console.log(dataJson[0])
            const newValues = dataJson[0]
            setValue('ot', newValues.ot)
            setValue('st', newValues.st)
            setValue('dt', newValues.dt)
            setValue('perdiem', newValues.perdiem)
            setValue('meals', newValues.meals)
            setTravelValue(newValues.travel)
            setAdditionaldt(newValues.additionaldt)
            setAdditionalot(newValues.additionalot)
            setmissedmeal(newValues.missedmeal)

            console.log(dataJson)
            // setDialogMsg(dataJson.message)
        })
    }

    const onSubmit = async (data: FormData) => {
        const stV = dayjs(onJobValue)
        const diffInMinutes = stV.diff(startTravelValue, 'minutes')

        const ljV = dayjs(leaveJobValue)
        const diffLJVInMinutes = ljV.diff(onJobValue, 'minutes')

        const teV = dayjs(endTravelValue)
        const diffTEVInMinutes = teV.diff(leaveJobValue, 'minutes')

        if (diffInMinutes < 0) {
            setDialogMsg("The 'On Job' date must be after the travel start date. Please correct the 'On Job' date.")
        }
        else if (diffLJVInMinutes < 0) {
            setDialogMsg("The 'Leave Job' date must be after the on job date. Please correct the 'Leave Job' date.")
        }
        else if (diffTEVInMinutes < 0) {
            setDialogMsg("The 'Travel End' date must be after the leave job date. Please correct the 'Travel End' date.")
        }
        else {
            onSubmitProcessing(data)
        }
    }

    const onSubmitProcessing = async (data: FormData) => {
        var finalData = {}

        const selection = employeeList?.filter((item) => item.employeeid === data.employeeid)
        let employee = ""
        if (selection && selection.length > 0) {
            employee = selection[0].employee
        }

        const vehicleSelection = vehicleList?.filter((item) => item.vehicleid === data.vehicleid)
        let vehicle = ""
        if (vehicleSelection && vehicleSelection.length > 0) {
            vehicle = vehicleSelection[0].vehicle
        }

        if (isEdit) {
            finalData = {
                dispatchid: dispatchid,
                timeid: timeid,
                employeeid: data.employeeid,
                employee: employee,
                vehicleid: data.vehicleid,
                vehicle: vehicle,
                paylevel: data.paylevel,
                lunch: missingLunch,
                shiftpay: shiftPay,
                starttravel: dayjs(startTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                onjob: dayjs(onJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                endtravel: dayjs(endTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                leavejob: dayjs(leaveJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                ot: getValues('ot'),
                st: getValues('st'),
                dt: getValues('dt'),
                perdiem: perdiem,
                meals: getValues('meals'),
                overridecalc: overrideCalc
            }
        }
        else {
            finalData = {
                dispatchid: dispatchid,
                employeeid: data.employeeid,
                employee: employee,
                vehicleid: data.vehicleid,
                vehicle: vehicle,
                paylevel: data.paylevel,
                lunch: missingLunch,
                shiftpay: shiftPay,
                starttravel: dayjs(startTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                onjob: dayjs(onJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                endtravel: dayjs(endTravelValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                leavejob: dayjs(leaveJobValue).format('YYYY-MM-DDTHH:mm:ss').toString(),
                ot: getValues('ot'),
                st: getValues('st'),
                dt: getValues('dt'),
                perdiem: perdiem,
                meals: getValues('meals'),
                overridecalc: overrideCalc
            }
        }

        console.log(finalData)

        const { data: response } = await updateTimeentry.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setReloadData(reloadData + 1)
            setIsOpen(false)
        }

    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const clearEmployee = () => {
        signEmployee.current.clear()
    }

    // const handleEmployeeChange = (event) => {
    //     setEmployeeActiveSelect(event.target.value as string)
    //     const selection = employeeList?.filter((item) => item.employee === event.target.value as string)
    //     if (selection && selection.length > 0) {
    //        // setEmployeeActiveSelectId(selection[0].employeeid)
    //     }
    // }

    // const handleVehicleChange = (event) => {
    //     setVehicleActiveSelect(event.target.value as string)
    //     const selection = vehicleList?.filter((item) => item.vehicle === event.target.value as string)
    //     if (selection && selection.length > 0) {
    //         setVehicleActiveSelectId(selection[0].vehicleid)
    //     }
    // }

    const getEmployeeOptions = (employeeList) => {
        return employeeList?.map(item => {
             return {value: item.employeeid, label: item.employee}
        })
    }
    // const getEmployeeOptions = (employeeList) => {
    //     return employeeList?.map(item => (
    //         <MenuItem key={item.employeeid} value={item.employee}>
    //             {item.employee}
    //         </MenuItem>
    //     ))
    // }
    const getVehicleOptions = () => {
               return vehicleList?.map(item => {
            return {value: item.vehicleid, label: item.vehicle}
        })
    }

    const handleMissingLunch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMissingLunch(event.target.checked);
    }

    const handleShiftPay = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShiftPay(event.target.checked);
    }

    const handleOverrideCalc = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverrideCalc(event.target.checked);
    }

    return (
        <Dialog open={isOpen} fullWidth maxWidth="xl" className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent >
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6} style={{ width: '100%' }}>
                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                {/* <InputLabel>Employee*</InputLabel> */}
                                <AutocompleteSelect
                                    name="employeeid"
                                    label="Employee"
                                    options={getEmployeeOptions(employeeList)}
                                    required
                                />
                                {/* <Select
                            name="employee"
                            label="Employee"
                            value={employeeActiveSelect}
                            size={'small'}
                            required={true}
                            variant="outlined"
                            disabled={false}
                            onChange={(e) => { handleEmployeeChange(e) }}
                        >
                            {getEmployeeOptions(employeeList)}
                        </Select> */}
                            </FormControl>

                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDateTimePicker
                                        label="Travel Start"
                                        value={startTravelValue}
                                        onChange={handleStartTravelChange}
                                        minutesStep={15}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDateTimePicker
                                        label="On Job"
                                        value={onJobValue}
                                        onAccept={handleOnJobValidation}
                                        onChange={handleOnJobChance}
                                        minutesStep={15}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDateTimePicker
                                        label="Leave Job"
                                        value={leaveJobValue}
                                        onChange={handleLeaveJobChange}
                                        onAccept={handleLeaveJobValidation}
                                        minutesStep={15}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDateTimePicker
                                        label="Travel End"
                                        value={endTravelValue}
                                        onChange={handleEndTravelChange}
                                        onAccept={handleEndTravelValidation}
                                        minutesStep={15}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>

                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <AutocompleteSelect
                                    name="vehicleid"
                                    label="Vehicle"
                                    options={getVehicleOptions()}
                                />
                                {/* <InputLabel>Vehicle</InputLabel>
                        <Select
                            name="vehicle"
                            label="Vehicle"
                            value={vehicleActiveSelect}
                            size={'small'}
                            required={false}
                            variant="outlined"
                            disabled={false}
                            onChange={(e) => { handleVehicleChange(e) }}
                        >
                            {getVehicleOptions()}
                        </Select> */}
                            </FormControl>
                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={'lunch'}
                                            defaultChecked={false}
                                            onChange={(e) => {handleMissingLunch(e)}}
                                            checked={missingLunch}
                                            color="primary"
                                        />
                                    }
                                    label="Missing Lunch"
                                />
                            </FormControl>
                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={'shiftPay'}
                                            defaultChecked={false}
                                            onChange={(e) => {handleShiftPay(e)}}
                                            checked={shiftPay}
                                            color="primary"
                                        />
                                    }
                                    label="Shift Pay"
                                />
                            </FormControl>



                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <>
                                    {employeeSigned
                                        ?
                                        <>
                                            <Typography variant="body2" gutterBottom>Current Signature</Typography>
                                            <img height={'100px'} src={`data:image/png;base64,${employeeSigned}`}
                                                 alt="Signature"/>
                                        </>
                                        :
                                        null
                                    }
                                </>
                            </FormControl>
                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <TextInput
                                    name="paylevel"
                                    label="Pay Level"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} style={{ width: '100%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        color="primary"
                                        align="left"
                                        component="div">
                                        Travel: {travelValue}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        color="primary"
                                        align="left"
                                        component="div">
                                        Additional OT: {additionalot}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        color="primary"
                                        align="left"
                                        component="div">
                                        Missed Meal: {missedmeal}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        color="primary"
                                        align="left"
                                        component="div">
                                        Additional DT: {additionaldt}
                                    </Typography>
                                </Grid>
                            </Grid>


                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <TextInput
                                    name="st"
                                    label="ST"
                                    defaultValue={'0'}
                                    textFieldProps={{ type: 'number' }}
                                />
                            </FormControl>
                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <TextInput
                                    name="ot"
                                    label="OT"
                                    defaultValue={'0'}
                                    textFieldProps={{ type: 'number' }}
                                />
                            </FormControl>
                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <TextInput
                                    name="dt"
                                    label="DT"
                                    defaultValue={'0'}
                                    textFieldProps={{ type: 'number' }}
                                />
                            </FormControl>
                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <MaskedInput
                                    name="perdiem"
                                    label="Per/Diem"
                                    defaultValue={perdiem ? perdiem.toString() : "0"}
                                    onValueChange={handlePerdiem}
                                    required={false}
                                />
                            </FormControl>
                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <MaskedInput
                                    name="meals"
                                    label="Meals"
                                    defaultValue={perdiem ? perdiem.toString() : "0"}
                                    onValueChange={handlePerdiem}
                                    required={false}
                                />
                            </FormControl>
                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={'overridecalc'}
                                            defaultChecked={false}
                                            onChange={(e) => {handleOverrideCalc(e)}}
                                            checked={overrideCalc}
                                            color="primary"
                                        />
                                    }
                                    label="Override Calc"
                                />
                            </FormControl>
                            <FormControl fullWidth style={{marginTop: '15px'}}>
                                <Button
                                    variant="contained"
                                    className={styles.submitButton}
                                    disabled={overrideCalc}
                                    form="items-form"
                                    onClick={() => {onCalc()}}
                                >
                                    Calculate
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>


                    {/*</div>*/}
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    className={styles.deleteBtn}
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
