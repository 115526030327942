import React, {useEffect, useMemo, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import styles from './Dependencies.module.css'

import TextInput from "../../../components/common/inputs/TextInput";
import SelectInput from "../../../components/common/inputs/SelectInput";
import DateTimeInput from "../../../components/common/inputs/DateTimeInput";
import {useUpdateDependencies} from "../../../queries/useDependencies";
import LoadingBackdrop from "../../../components/common/LoadingBackdrop";
import {Skeleton} from "@mui/lab";

interface FormData {
    readonly: boolean
    canedit: true
    candelete: true,
    dependencyid: number
    dependencydivisionid: number
    dependencydivision: string
    dependencyscheduleid: number
    dependencytypeid: number
    dependencytype: number
    dependencycategoryid: number
    dependencycategory: string
    dependencyaction: string
    dependencydateactiontaken: string
    dependencydatecleartowork: string
    dependencydateexpiration: string
    dependencyreferenceinformation: string
    dependencypermanentlycleared: boolean
    dependencyaddeduser: string
    dependencyaddeduserkey: string
    dependencyaddeddate: string
    dependencyupdateduser: string
    dependencyupdateduserkey: string
    dependencyupdateddate: string
    dependencyactive: boolean
}

export const DependenciesForm = ({ isOpen, setIsOpen, gridParams, dependencyDropdowns, workOrderObj, reloadForm }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit Dependency - " + gridParams?.dependencyid : "Create Dependency"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateDependencies = useUpdateDependencies()
    const [workTypes, setWorkTypes] = useState<{ value: any; label: string }[]>([])

    useEffect(() => {
        var selectedWT = []
        gridParams?.divisionworktypeids?.forEach(wtId => {
            const toAdd = dependencyDropdowns?.worktypelist?.filter((wtObject) =>  wtObject.worktypeid === wtId )
            if (toAdd && toAdd.length > 0) {
                selectedWT.push({value: toAdd[0].worktypeid, label: toAdd[0].worktypecode})
            }
        })
        setWorkTypes(selectedWT)

        reset({
            readonly: (gridParams ? gridParams.readonly : true),
            canedit: (gridParams ? gridParams.canedit : true),
            candelete: (gridParams ? gridParams.candelete : true),
            dependencyid: (gridParams ? gridParams.dependencyid : 0),
            dependencydivisionid: (gridParams ? gridParams.dependencydivisionid : 0),
            dependencydivision: (gridParams ? gridParams.dependencydivision : 0),
            dependencyscheduleid: (gridParams ? gridParams.dependencyscheduleid : 0),
            dependencytypeid: (gridParams ? gridParams.dependencytypeid : 0),
            dependencytype: (gridParams ? gridParams.dependencytype : 0),
            dependencycategoryid: (gridParams ? gridParams.dependencycategoryid : 0),
            dependencycategory: (gridParams ? gridParams.dependencycategory : ""),
            dependencyaction: (gridParams ? gridParams.dependencyaction : ""),
            dependencydateactiontaken: (gridParams ? gridParams.dependencydateactiontaken : ""),
            dependencydatecleartowork: (gridParams ? gridParams.dependencydatecleartowork : ""),
            dependencydateexpiration: (gridParams ? gridParams.dependencydateexpiration : ""),
            dependencyreferenceinformation: (gridParams ? gridParams.dependencyreferenceinformation : ""),
            dependencypermanentlycleared: (gridParams ? gridParams.dependencypermanentlycleared : true),
            dependencyaddeduser: (gridParams ? gridParams.dependencyaddeduser : ""),
            dependencyaddeduserkey: (gridParams ? gridParams.dependencyaddeduserkey : ""),
            dependencyaddeddate: (gridParams ? gridParams.dependencyaddeddate : ""),
            dependencyupdateduser: (gridParams ? gridParams.dependencyupdateduser : ""),
            dependencyupdateduserkey: (gridParams ? gridParams.dependencyupdateduserkey : ""),
            dependencyupdateddate: (gridParams ? gridParams.dependencyupdateddate : ""),
            dependencyactive: (gridParams ? gridParams.dependencyactive : true)
        })
    }, [reloadForm])

    const onSubmit = async (data: FormData) => {
        var finalData = {
            woid: workOrderObj.woid,
            readonly: data.readonly,
            canedit: data.canedit,
            candelete: data.candelete,
            dependencyid: data.dependencyid,
            dependencydivisionid: data.dependencydivisionid,
            dependencydivision: data.dependencydivision,
            dependencyscheduleid: data.dependencyscheduleid,
            dependencytypeid: data.dependencytypeid,
            dependencytype: data.dependencytype,
            dependencycategoryid: data.dependencycategoryid,
            dependencycategory: data.dependencycategory,
            dependencyaction: data.dependencyaction,
            dependencydateactiontaken: data.dependencydateactiontaken,
            dependencydatecleartowork: data.dependencydatecleartowork,
            dependencydateexpiration: data.dependencydateexpiration,
            dependencyreferenceinformation: data.dependencyreferenceinformation,
            dependencypermanentlycleared: data.dependencypermanentlycleared,
            dependencyaddeduser: data.dependencyaddeduser,
            dependencyaddeduserkey: data.dependencyaddeduserkey,
            dependencyaddeddate: data.dependencyaddeddate,
            dependencyupdateduser: data.dependencyupdateduser,
            dependencyupdateduserkey: data.dependencyupdateduserkey,
            dependencyupdateddate: data.dependencyupdateddate,
            dependencyactive: data.dependencyactive
        }
        console.log(finalData)
        const { data: response } = await updateDependencies.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }

    const closeDialog = () => {
        setDialogMsg("")
    }


    const getDivisionOptions = () => {
        return dependencyDropdowns?.divisionlist?.map(item => {
            return { label: item.divisionname, value: item.divisionid }
        })
    }

    const getScheduleOptions = () => {
        if (dependencyDropdowns && dependencyDropdowns?.schedulelist) {
            return dependencyDropdowns?.schedulelist?.map(item => {
                return { label: item.schedulename, value: item.scheduleid }
            })
        }
        else {
            return [{ label: "", value: "" }]
        }
    }

    const getDependencyTypeOptions = () => {
        return dependencyDropdowns?.dependencytypelist?.map(item => {
            return { label: item.dependencytype, value: item.dependencytypeid }
        })
    }

    const getDependencyCategoryOptions = () => {
        return dependencyDropdowns?.dependencycategorylist?.map(item => {
            return { label: item.dependencycategory, value: item.dependencycategoryid }
        })
    }

    return (
        <Dialog
            open={isOpen}
            fullWidth={true}
            maxWidth="xl"
            className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={true}/>*/}
             <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{id: 'items-form'}}>
                    <div className={styles.multipleInputContainer}>
                        <SelectInput
                            name="dependencydivisionid"
                            label="Division"
                            options={getDivisionOptions()}
                            required
                            disabled={gridParams}
                        />
                        <SelectInput
                            name="schedlueid"
                            label="Schedule"
                            options={getScheduleOptions()}
                        />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <SelectInput
                            name="dependencytypeid"
                            label="Type"
                            options={getDependencyTypeOptions()}
                            required
                        />
                        <SelectInput
                            name="dependencycategoryid"
                            label="Category"
                            options={getDependencyCategoryOptions()}
                            required
                        />
                    </div>

                    <div className={styles.multipleInputContainer}>
                        <TextInput
                            name="dependencyaction"
                            label="Action"
                        />
                        <TextInput
                            name="dependencyreferenceinformation"
                            label="Reference Information"
                        />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <DateTimeInput name="dependencydateactiontaken" label="Action Taken"
                                       defaultValue={new Date().toISOString()} required={true}/>
                        <DateTimeInput name="dependencydatecleartowork" label="Clear to Work"
                                       defaultValue={new Date().toISOString()} required={true}/>
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <div className={styles.multipleInputContainer}>
                            <SelectInput
                                name="dependencypermanentlycleared"
                                label="Permanently Cleared"
                                options={[
                                    {label: 'True', value: true},
                                    {label: 'False', value: false}
                                ]}
                                required
                            />
                            <SelectInput
                                name="dependencyactive"
                                label="Division Active"
                                options={[
                                    {label: 'Active', value: true},
                                    {label: 'Inactive', value: false}
                                ]}
                                required
                            />
                        </div>
                        <DateTimeInput name="dependencydateexpiration" label="Expiration"
                                       defaultValue={new Date().toISOString()} required={true}/>
                    </div>

                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
