import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import styles from './DispatchWIP.module.css'
import TextInput from "../../../components/common/inputs/TextInput";
import SelectInput from "../../../components/common/inputs/SelectInput";
import MaskedInput from "../../../components/common/inputs/MaskedInput";
import DateTimeInput from "../../../components/common/inputs/DateTimeInput";
import DateInput from "../../../components/common/inputs/DateInput";
import {useUpdateDispatchWIP} from "../../../queries/useDispatchWip";
import {POListings} from "../../../queries/usePOListings";
import dayjs from "dayjs";
import AutocompleteSelect from "../../../components/common/inputs/AutoCompleteSelectInput/AutocompleteSelect"

interface FormData {
    wipid: number,
    entityid: number,
    dispatchid: number,
    woid: string,
    workdate:string,
    unitid: number,
    unitname: string,
    quantity: number,
    rate: number,
    contractinfo: string,
    iteminfo: string,
    itemcode: string,
    itemdescription: string,
    itemmeasure: string
}

export const DispatchWIPForm = ({ isOpen, setIsOpen, gridParams, reloadForm, itemlist, dispatchid, defaultWorkDate }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit WIP" : "Create WIP"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const useUpdateWIP = useUpdateDispatchWIP()
    const [amount, setAmount] = useState<number>(0)
    const [amountChange, setAmountChange] = useState<number>(0)
    const [itemOptions, setItemOptions] = useState([])

    useEffect(() => {
        setItemOptions(itemlist?.map(item => {
            return { label: item.iteminfo, value: item.iteminfo }
        }))

        const units = itemlist?.filter((item) => item.unitid === gridParams?.unitid)
        const unitname = (units && units.length > 0 ? units[0].iteminfo : "")
        reset({
            wipid: (gridParams ? gridParams.wipid : 0),
            entityid: (gridParams ? gridParams.entityid : 0),
            dispatchid: dispatchid,
            woid: (gridParams ? gridParams.woid : ""),
            workdate: (gridParams ? gridParams.workdate : dayjs(defaultWorkDate).format("YYYY-MM-DD").toString()),
            unitid: (gridParams ? gridParams.unitid : 1),
            unitname: unitname,
            quantity: (gridParams ? gridParams.quantity : 1),
            rate: (gridParams ? gridParams.rate : 0),
            contractinfo: (gridParams ? gridParams.rate : ""),
            iteminfo: (gridParams ? gridParams.iteminfo : ""),
            itemcode: (gridParams ? gridParams.itemcode : ""),
            itemdescription: (gridParams ? gridParams.itemdescription : ""),
            itemmeasure: (gridParams ? gridParams.itemmeasure : "")
        })
    }, [reloadForm])

    const onSubmit = async (data: FormData) => {
        console.log(data)

        const units = itemlist.filter((item) => item.iteminfo === data.unitname)
        console.log(units)
        var finalData = {
            entityid: data.entityid,
            dispatchid: dispatchid,
            workdate: dayjs(data.workdate).format('MM/DD/YYYY').toString(),
            unitid: units[0].unitid,
            quantity: data.quantity,
            rate: data.rate,
        }
        if (data.wipid > 0) {
            finalData['wipid'] = data.wipid
        }
        const { data: response } = await useUpdateWIP.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const getListItemOptions = () => {
        return itemlist?.map(item => {
            return { label: item.iteminfo, value: item.iteminfo }
        })
    }

    return (
        <>
            <Dialog open={isOpen}
                    fullWidth={true}
                    maxWidth="xl"
                    className={styles.dialog}>
                <DialogTitle>{title}</DialogTitle>
                {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
                <>
                    <Dialog
                        open={dialogMsg.length > 0}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {dialogMsg}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog}>Ok</Button>
                        </DialogActions>
                    </Dialog>
                </>
                <DialogContent>
                    <HookForm
                        methods={formMethods}
                        onSubmit={onSubmit}
                        className={styles.itemsForm}
                        formProps={{ id: 'items-form' }}>
                            
                        <AutocompleteSelect
                            name="unitname"
                            label="Item"
                            options={itemOptions}
                            required
                        />
                        <div className={styles.multipleInputContainer}>
                            <MaskedInput
                                name="quantity"
                                label="Quantity"
                                thousandSeparator={false}
                                thousandsGroupStyle={""}
                                prefix=""
                                rules={{
                                    validate: (val) => (val > 0 && val < 999999) || 'Please enter a valid quantity between 1 and 999999.',
                                }}
                                required />
                            <DateInput name="workdate" label="Work Date" defaultValue={new Date().toISOString()} required={true} />
                        </div>
                    </HookForm>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className={styles.submitButton}
                        type="submit"
                        form="items-form">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
