import React, {useEffect, useState} from "react";
import styles from '../Lookup.module.css'
import {
    DataGridPro,
    GridColDef,
    GridCellParams,
    GridRowParams,
    GridCallbackDetails,
    MuiEvent, useGridApiRef
} from "@mui/x-data-grid-pro";
import {useFetchLookup, useDeleteLookup} from "../../../../queries/useLookup";
import {useUpdateGridState} from "../../../../queries/useGridState";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton} from "@material-ui/core";
import {Add, Save} from "@mui/icons-material";
import {Delete, Edit, SettingsBackupRestore} from "@material-ui/icons";
import {LookupForm} from "../LookupForm";
import {FormGroup, Grid} from "@mui/material";
import {QueryClient} from "react-query";

const queryClient = new QueryClient()

const LookupValuesTable = ({ setLookupCategory, lookupCategoryObj, lookupEntityList, lookupCustomerList }) => {
    let lookupRows = []
    const updateGridState = useUpdateGridState()
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState({})
    const [columnDefs, setColumnDefs] = useState([])
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const deleteLookup = useDeleteLookup()

    const { data } = useFetchLookup(lookupCategoryObj.lookupcategoryid)
    const gridAPIRef = useGridApiRef()


    // React.useEffect(() => {
        if (data && data?.length > 0) {
            lookupRows = data?.map((lookup, i) => {
                return {
                    id: lookup.lookupid,
                    lookupid: lookup.lookupid,
                    lookupcategoryid: lookupCategoryObj?.lookupcategoryid,
                    lookupvalue: lookup?.lookupvalue,
                    notestypeid: lookup?.notestypeid,
                    lookupactive: lookup?.lookupactive,
                    lookupdefault: lookup?.lookupdefault,
                    lookupentid: lookup?.lookupentid,
                    lookupentityname: lookup?.lookupentityname,
                    lookupcustid: lookup?.lookupcustid,
                    lookupcustname: lookup?.lookupcustname,
                    lookupcustinfo: lookup?.lookupcustinfo,
                    lookupaddeduser: lookup?.lookupaddeduser,
                    lookupaddeddate: lookup?.lookupaddeddate,
                    lookupupdateduser: lookup?.lookupupdateduser,
                    lookupupdatedate: lookup?.lookupupdatedate
                }
            })
        }
    // }, [data])

    React.useEffect(() => {
        buildColumnDefs()
        if (data && data?.length > 0 && lookupCategoryObj === null) {
            const catLook = data.filter((listcat) => { return listcat.lookupcategoryid === gridAPIRef.current.getSortedRows()[0].id })
            if (catLook && catLook.length > 0) {
                setLookupCategory(catLook[0])
            }
        }
    }, []);

    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObj({
            msg: 'Are you sure you want to delete \"' + params.row.lookupvalue + '\"?',
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteLookup.mutateAsync({ lookupid: dialogObj.id })
        queryClient.invalidateQueries(['lookup', lookupCategoryObj.lookupcategoryid])
        closeDialog()
    }

    const closeDialog = () => {
        setDialogObj({
            msg: '',
            id: 0
        })
    }

    const buildColumnDefs = () => {
        var additionalColDefs: GridColDef[] = []
        var additionalColDefs1: GridColDef[] = []
        if (lookupCategoryObj.lookupcategoryshowentity) {
            additionalColDefs = [{
                field: 'lookupentityname',
                type: 'string',
                headerName: 'Entity Name',
                width: 175,
                align: "left",
                headerAlign: "left"
            }]
        }
        if (lookupCategoryObj.lookupcategoryshowcustomer) {
            additionalColDefs1 = [{
                field: 'lookupcustinfo',
                type: 'string',
                headerName: 'Customer',
                width: 275,
                align: "left",
                headerAlign: "left"
            }]
        }

        var activeColDefs: GridColDef[] = [{
            field: 'lookupactive',
            type: 'string',
            headerName: 'Active',
            width: 80,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params) =>
                params.value ? "True" : 'False'
        }]
        var gridColumns: GridColDef[] = [
            ...basicColumnDefs,
            ...additionalColDefs,
            ...additionalColDefs1,
            ...activeColDefs,
            ...footerColumnDef
        ]
        setColumnDefs(gridColumns)
        return gridColumns
    }

    const footerColumnDef: GridColDef[] = [
        // {
        //     field: 'lookupcustinfo',
        //     type: 'string',
        //     headerName: 'Customer Name',
        //     width: 350,
        //     align: "left",
        //     headerAlign: "left"
        // },
        {
            field: 'action',
            headerName: '',
            width: 50,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                    <Delete />
                </IconButton>
            )
        }
    ]

    const basicColumnDefs: GridColDef[] = [
        {
            field: 'Edit',
            renderHeader: () => {
                return (
                    <IconButton
                        className={styles.submitButton}
                        onClick={() => handleAdd()}
                        size="small">
                        <Add />
                    </IconButton>
                )
            },
            width: 50,
            align: "center",
            headerAlign: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
                    <Edit />
                </IconButton>
            )
        },
        {
            field: 'id',
            hide: true
        },
        {
            field: 'lookupid',
            type: 'number',
            headerName: 'ID',
            width: 125,
            align: "right",
            headerAlign: "right"
        },
        {
            field: 'lookupvalue',
            type: 'string',
            headerName: 'Value',
            width: 175,
            align: "left",
            headerAlign: "left"
        }
    ]

    const handleRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        // const lookupCatObj = data.filter((lookupCategory) => { return lookupCategory.lookupid === Number(param.id)})
        // console.log(lookupCatObj)
        // if (lookupCatObj && lookupCatObj.length > 0) {
        //     console.log(lookupCatObj[0])
        //     setLookupCategory(lookupCatObj[0])
        // }
    }

    const handleEdit = (cellparams: GridCellParams) => {
        setReloadForm(reloadForm+1)
        setGridParams({
            lookupid: cellparams.row.id,
            lookupcustid: cellparams.row.lookupcustid,
            lookupentid: cellparams.row.lookupentid,
            lookupcustinfo: cellparams.row.lookupcustinfo,
            lookupvalue: cellparams.row.lookupvalue,
            lookupactive: cellparams.row.lookupactive,
            lookupdefault: cellparams.row.lookupdefault,
            lookupcategoryid: cellparams.row.lookupcategoryid
        })
        setIsFormShowing(true)
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <Grid container spacing={1}>
                <Grid item xs={12} style={{marginLeft: '15px', marginTop: '15px'}}>
                    <Grid container spacing={1} >
                        {/*<div className={styles.activeTitle}>{lookupCategoryObj.lookupcategoryid} - {lookupCategoryObj.lookupcategory}</div>*/}

                        <Box style={{ marginLeft: '10px' }} sx={{ flexGrow: 1 }}>
                            <FormGroup>
                                {/*    <Select*/}
                                {/*        onChange={handleDivisionSelectionChange}*/}
                                {/*        style={{ width: '320px', height:'30px' }}*/}
                                {/*        disabled={params.workOrderObj === null}*/}
                                {/*        value={divisionActiveSelect}>*/}
                                {/*        {getDivisionOptions()}*/}
                                {/*    </Select>*/}
                            </FormGroup>
                        </Box>
                        <Box sx={{ flexGrow: 20 }}>
                            <FormGroup>
                            </FormGroup>
                        </Box>

                        <Box style={{marginRight: '10px'}}>
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <LookupForm
                        isOpen={isFormShowing}
                        setIsOpen={setIsFormShowing}
                        gridParams={gridParams}
                        reloadForm={reloadForm}
                        lookupCategoryObj={lookupCategoryObj}
                        lookupEntityList={lookupEntityList}
                        lookupCustomerList={lookupCustomerList} />

                    <DataGridPro
                        apiRef={gridAPIRef}
                        rows={lookupRows}
                        columns={columnDefs}
                        autoHeight={true}
                        density={'compact'}
                        pageSize={10}
                        loading={!lookupRows}
                        disableMultipleSelection={true}
                        onRowClick={handleRowClick}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default LookupValuesTable

