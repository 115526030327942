import React from 'react'
import { useController, useFormContext } from 'react-hook-form'

import {MenuItem, Switch as MuiSwitch, TextField} from '@material-ui/core'

import styles from './SelectInput.module.css'
import {SelectChangeEvent} from "@mui/material";

type Props = {
  name: string
  label: string
  options: { value: any; label: string }[] | string[]
  defaultValue?: string
  rules?: Object
  required?: boolean
  textFieldProps?: Object
  onHandleChange?: Function
  onClick? : Function
  disabled?: boolean
}

const SelectInput = ({
  name,
  label,
  options,
  defaultValue = '',
  required = false,
  textFieldProps,
  onHandleChange,
  onClick,
  disabled = false
}: Props) => {
  const { control, formState } = useFormContext()

  const {
    field: { ref, ...inputProps }
  } = useController({
    name: name as any,
    control,
    defaultValue: defaultValue
  })

  const { errors } = formState
  const handleChange = (event: SelectChangeEvent) => {
    console.log('handleChange')
  }

  return (
    <TextField
      select
      defaultValue={defaultValue}
      onChange={() => {
        console.log('change')
      }}
      onClick={() => {
        onClick && onClick()
      }}
      disabled={disabled}
      variant="outlined"
      size="small"
      label={label}
      // value={'None'}
      error={errors[name] ? true : false}
      FormHelperTextProps={{
        classes: {
          error: styles.error
        },
        error: true
      }}
      InputProps={{
        classes: {
          root: styles.input,
          error: styles.error,
          focused: styles.focus
        }
      }}
      InputLabelProps={{
        classes: {
          root: styles.label,
          error: styles.error,
          focused: styles.focus
        },
        shrink: true
      }}
      helperText={errors[name]?.message}
      required={required}
      {...textFieldProps}
      {...inputProps}>

      {/*<MenuItem value="none">*/}
      {/*  <em>None</em>*/}
      {/*</MenuItem>*/}
      {options && typeof options?.[0] === 'object'
        ? (options as { value: any; label: string }[]).map(cat => (
            <MenuItem
              key={cat.value}
              value={cat.value}
              className={styles.listItem}>
              {cat.label}
            </MenuItem>
          ))
        : (options as string[]).map(cat => (
            <MenuItem key={cat} value={cat} className={styles.listItem}>
              {cat}
            </MenuItem>
          ))}
    </TextField>
  )
}

export default SelectInput
