import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  WorkOrderDataObject {
    status: string
    message: string
    canadd: boolean
    canaddentity: 0
    canadddirectonly: false
    workorderlist: WorkOrderData[]
}

export interface  WorkOrderData {
    woid: number
    canedit: boolean,
    candelete: boolean,
    woentityid: number
    woentity: string
    wodirectbill: boolean
    wocustomercodeid: number
    wocustomercode: string
    wocustomerinfo: string
    wocustomerworkorder: string
    wocustomerrelease: string
    wopolid: number
    wocustomerpo: string
    wofoldername: string
    wocustomerdivisionid: number
    wocustomerdivision: string
    woaddress: string
    woaddress2: string
    wocity: string
    wostate: string
    wozip: string
    wocustomerworktype: string
    wodivisionids: number[]
    wodivision: string
    wostatusid: number
    wostatus: string
    wodatepackagereceived: string
    wodateworeceived: string
    wodatecommitted: string
    wodatejobready: string
    woprojectmanagerid: string,
    woprojectmanager: string
    wopricingmethodid: string
    wopricingmethod: string
    woaddeduser: string
    woaddeduserkey: number
    woaddeddate: string
    woupdateduser: string
    woupdateduserkey: number
    woupdateddate: string
    woactive: boolean
    notestypeid: number,
    notestitle: string,
    noteslinkid: number,
    notescount: number,
    wofirezoneid: number,
    woinsurancecategoryid: number
}

export const WorkOrderDataDefaults = {
    woid: 0,
    canedit: true,
    candelete: true,
    woentityid:1,
    woentity: "OAG",
    wodirectbill: false,
    wocustomercodeid: 0,
    wocustomercode: "",
    wocustomerinfo: "",
    wocustomerworkorder: "",
    wocustomerrelease: "",
    wopolid: 0,
    wocustomerpo: "",
    wofoldername: "",
    wocustomerdivisionid: 0,
    wocustomerdivision: "",
    woaddress: "",
    woaddress2: "",
    wocity: "",
    wostate: "",
    wozip: "",
    wocustomerworktype: "",
    wodivisionids: [],
    wodivision: "",
    wostatusid: 0,
    wostatus: "",
    wodatepackagereceived: "",
    wodateworeceived: "",
    wodatecommitted: "",
    wodatejobready: "",
    woprojectmanagerid: "",
    woprojectmanager: "",
    wopricingmethodid: "",
    wopricingmethod: "",
    woaddeduser: "",
    woaddeduserkey: 0,
    woaddeddate: "",
    woupdateduser: "",
    woupdateduserkey: 0,
    woupdateddate: "",
    woactive: true,
    notestypeid: 0,
    notestitle: "",
    noteslinkid: 0,
    notescount: 0,
    wofirezoneid: 0,
    woinsurancecategoryid: 0,
}

export const useFetchWorkOrder = () => {
    return useQuery<WorkOrderDataObject>(
        ['workorderdata'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdateWorkOrder = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`workorder`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['workorderdata'])
        }
    })
}

export const useDeleteWorkOrder = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`workorder`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['workorderdata'])
        }
    })
}

export const globalFetcher = async () => {
    const { data } = await axios.get('/workorder')
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as WorkOrderDataObject
}
