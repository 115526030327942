import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import TextInput from '../../../components/common/inputs/TextInput'
import MaskedInput from '../../../components/common/inputs/MaskedInput'
import SelectInput from '../../../components/common/inputs/SelectInput'
import styles from './CWALiastingPage.module.css'
import {useUpdateCWAListing} from '../../../queries/useCWAListing'
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";

interface FormData {
    cwalid: number
    cwalentityid: number
    cwalcustomercodeid: string
    cwalnumber: string
    cwaldescription: string
    cwalamountlastupdated: string
    cwaladdeddate: string
    cwalactive: boolean
    cwalamount: number
}

export const CWAListingForm = ({ isOpen, setIsOpen, gridParams, reloadForm, customerCodes, entitylist }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit Listings" : "Create Listing"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateListing = useUpdateCWAListing()
    const [amount, setAmount] = useState<number>(0)
    const [entityActiveSelect, setEntityActiveSelect] = React.useState<string>("")
    const [entityActiveSelectId, setEntityActiveSelectId] = React.useState<number>(0)
    const [customerActiveSelect, setCustomerActiveSelect] = React.useState<string>("")
    const [customerActiveSelectId, setCustomerActiveSelectId] = React.useState<number>(0)
    const [customerOptions, setCustomerOptions] = React.useState()

    // {cwalid: 1, cwalentityid: 1, cwalcustomercodeid: 206, cwalnumber: '1111', cwaldescription: "Scott's Test", …}

    useEffect(() => {
        reset({
            cwalid: (gridParams ? gridParams.cwalid : 0),
            cwalentityid: (gridParams ? gridParams.cwalentityid : 0),
            cwalcustomercodeid: (gridParams ? gridParams.cwalcustomercodeid : ''),
            cwalnumber: (gridParams ? gridParams.cwalnumber : 0),
            cwaldescription: (gridParams ? gridParams.cwaldescription : ''),
            cwalamountlastupdated: (gridParams ? gridParams.cwalamountlastupdated : ''),
            cwaladdeddate: (gridParams ? gridParams.cwaladdeddate : ''),
            cwalactive: (gridParams ? gridParams.cwalactive : true),
            cwalamount: (gridParams ? gridParams.cwalamount : 0)
        })

        var entityid;
        if (gridParams && gridParams.cwalentityid) {
            const filteredList = entitylist?.filter(entity => entity.entityid === gridParams.cwalentityid)
            if (filteredList.length > 0) {
                setEntityActiveSelectId(gridParams.cwalentityid)
                setEntityActiveSelect(filteredList[0].entityname)
                entityid = gridParams.cwalentityid
            }
        }
        else if (entitylist && entitylist.length > 0) {
            const entityname = entitylist[0].entityname
            entityid = entitylist[0].entityid
            setEntityActiveSelectId(entityid)
            setEntityActiveSelect(entityname)
        }

        if (gridParams && gridParams.cwalcustomercodeid) {
            const filteredList = customerCodes?.filter(cust => cust.custid === gridParams.cwalcustomercodeid)
            if (filteredList.length > 0) {
                setCustomerActiveSelectId(filteredList[0].custid)
                setCustomerActiveSelect(filteredList[0].custinfo)
                getCustomerOptions(filteredList[0].entityid)
            }
        }
        else if (customerCodes && customerCodes.length > 0) {
            const custInfo = customerCodes[0].custinfo
            const custId = customerCodes[0].custid
            setCustomerActiveSelectId(custId)
            setCustomerActiveSelect(custInfo)
            getCustomerOptions(entityid)
        }
    }, [reloadForm])

    const handleAmount = (event) => {
        setAmount(event.floatValue)
    }

    const onSubmit = async (data: FormData) => {
        var finalData = {
            cwalentityid: entityActiveSelectId,
            cwalcustomercodeid: customerActiveSelectId,
            cwalnumber: data.cwalnumber,
            cwaldescription: data.cwaldescription,
            cwalamountlastupdated: data.cwalamountlastupdated,
            cwaladdeddate: data.cwaladdeddate,
            cwalactive: data.cwalactive
        }
        if (data.cwalid > 0) {
            finalData['cwalid'] = data.cwalid
        }
        if (!gridParams) {
            finalData['cwalamount'] = amount
        }
        const { data: response } = await updateListing.mutateAsync( finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }

    const handleEntityChange = (event: SelectChangeEvent) => {
        setEntityActiveSelect(event.target.value as string);
        const filteredList = entitylist?.filter(entity => entity.entityname === (event.target.value as string))
        if (filteredList.length > 0) {
            const entityID = filteredList[0].entityid
            setCustomerActiveSelect(null)
            setCustomerActiveSelectId(null)
            setEntityActiveSelectId(entityID)
            getCustomerOptions(entityID)
        }
    }

    const handleCustomerChange = (event: SelectChangeEvent) => {
        setCustomerActiveSelect(event.target.value as string)

        const filteredList = customerCodes?.filter(cust => cust.custinfo === (event.target.value as string) && cust.entityid === entityActiveSelectId)
        if (filteredList.length > 0) {
            const custId = filteredList[0].custid
            setCustomerActiveSelectId(custId)
        }
    }

    const getCustomerOptions = (entityId) => {
        const customerOptions = customerCodes?.filter((item) => (item.entityid === entityId))
        if (customerOptions && customerOptions.length > 0) {
            // setTemplateActiveSelect(lookupOptions[0].scheduletemplate)
            setCustomerOptions(customerOptions?.map(item => (
                <MenuItem key={item.custid} value={item.custinfo}>
                    {item.custinfo}
                </MenuItem>
            )))
        }
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const currencyFormat = (num) => {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    const getEntityListOptions = () => {
        return entitylist?.map(item => (
            <MenuItem key={item.entityid} value={item.entityname}>
                {item.entityname}
            </MenuItem>
        ))
    }

    return (
        <Dialog fullWidth={true} open={isOpen} maxWidth="xl" className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>

                    <div className={styles.multipleInputContainer}>
                        <FormControl fullWidth>
                            <InputLabel>Entity*</InputLabel>
                            <Select
                                name="cwalentityid"
                                label="Entity"
                                value={entityActiveSelect}
                                size={'small'}
                                required={true}
                                variant="outlined"
                                disabled={gridParams !== null}
                                onChange={handleEntityChange}
                            >
                                {getEntityListOptions()}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Customer Code*</InputLabel>
                            <Select
                                name="cwalcustomercodeid"
                                label="Customer Code"
                                value={customerActiveSelect}
                                size={'small'}
                                required={true}
                                variant="outlined"
                                disabled={gridParams !== null}
                                onChange={handleCustomerChange}
                            >
                                {customerOptions}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <TextInput name="cwalnumber" label="CWA No." required />
                        <TextInput
                            name="cwaldescription"
                            label="Description"
                            rules={{
                                required: 'Password field is required',
                                minLength: {
                                    value: 1,
                                    message: 'Must be at least 1 characters long'
                                },
                                maxLength: {
                                    value: 500,
                                    message: 'Must be less than 500 characters long'
                                }
                            }}
                            required
                        />
                    </div>
                    { !gridParams && (
                        <MaskedInput
                        name="cwalamount"
                        label="Amount"
                        defaultValue="0"
                        onValueChange={handleAmount}
                        />
                    )}

                    <SelectInput
                        name="cwalactive"
                        label="Status"
                        options={[
                            { label: 'Active', value: true },
                            { label: 'Inactive', value: false }
                        ]}
                        required
                    />
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
