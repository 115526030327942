import React, {useEffect, useState} from "react";
import styles from '../../CWAListingPage/CWALiastingPage.module.css'
import {IconButton, Box, AppBar} from '@material-ui/core'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { Add } from '@mui/icons-material'
import {
    DataGridPro,
    GridColDef,
    GridCellParams
} from "@mui/x-data-grid-pro";
import {Grid} from "@mui/material";
import {POAmounts, POAmountsDefaults, useDeletePOAmounts, useFetchPOAmounts} from "../../../../queries/usePOAmounts";
import {POAmountsForm} from "./POAmountsForm";
import {GridValueFormatterParams} from "@material-ui/x-grid";
import {formatMoney} from "../../../../util/formatHelpers";
import LoadingBackdrop from "../../../../components/common/LoadingBackdrop";
import dayjs from "dayjs";

const POAmountsTable = (params) => {
    console.log('polobject: ' + params.poListingObject)
    const deletePOAmounts = useDeletePOAmounts()
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [loading, setLoading] = React.useState(true)
    const [gridParams, setGridParams] = useState(POAmountsDefaults)
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })

    var amountRows = []
    const { data } = useFetchPOAmounts(params.poListingObject?.polid)
    if (data && data.length > 0) {
        amountRows = data?.map((poAmounts: POAmounts, i) => {
            return {
                id: poAmounts.poaid,
                poaline: poAmounts.poaline,
                poaworkcategoryids: poAmounts.poaworkcategoryids,
                poadescription: poAmounts.poadescription,
                poaamount: poAmounts.poaamount,
                poacomment: poAmounts.poacomment,
                poaexpirationdate: poAmounts.poaexpirationdate
            }
        })
    }

    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const amountColumns: GridColDef[] = [
        {
            field: 'Edit',
            renderHeader: () => {
                return (
                    <IconButton
                        className={styles.submitButton}
                        onClick={() => handleAdd()}
                        size="small">
                        <Add />
                    </IconButton>
                )
            },
            width: 50,
            align: "center",
            headerAlign: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
                    <Edit />
                </IconButton>
            )
        },
        {
            field: 'id',
            hide: true
        },
        {
            field: 'poaline',
            type: 'string',
            headerName: 'Line Number',
            width: 150,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'poaamount',
            type: 'string',
            headerName: 'Amount',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            field: 'poaworkcategoryids',
            type: 'string',
            headerName: 'Work Type',
            width: 200,
            valueFormatter: (params) =>
                getWorkType(params.value)
        },
        {
            field: 'poadescription',
            type: 'string',
            headerName: 'Description',
            width: 200,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'poacomment',
            type: 'string',
            headerName: 'Comment',
            width: 200,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'poaexpirationdate',
            type: 'date',
            headerName: 'Expiration',
            width: 175,
            valueFormatter: (params) =>
                (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DD").format('MM/DD/YYYY') : 'N/A')
        },
        {
            field: 'action',
            headerName: '',
            width: 50,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                    <Delete />
                </IconButton>
            )
        }
    ]

    const handleEdit = (params: GridCellParams) => {
        const poAmounts = data.filter((item) => item.poaid === Number(params.id))[0]
        setReloadForm(reloadForm+1)
        setGridParams({
            poaid: poAmounts.poaid,
            poaline: poAmounts.poaline,
            poaworkcategoryids: poAmounts.poaworkcategoryids,
            poadescription: poAmounts.poadescription,
            poaamount: poAmounts.poaamount,
            poacomment: poAmounts.poacomment,
            poaexpirationdate: poAmounts.poaexpirationdate
        })
        setIsFormShowing(true)
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObj({
            msg: 'Are you sure you want to delete this line number?\"' + params.getValue(params.id, 'poaline').toString() + "\"?",
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deletePOAmounts.mutateAsync({ poaid: dialogObj.id })
        closeDialog()
    }

    const closeDialog = () => {
        setDialogObj({
            msg: '',
            id: 0
        })
    }

    const getWorkType = (worktypeid) => {
        var strType = ''
        worktypeid.forEach((typeID) => {
            const value = params.worktypelist.filter((e) => e.worktypeid === typeID)

            if (value && value.length > 0) {
                if (strType.length > 0) {
                    strType = strType + ', '
                }
                strType = strType + value[0].worktypecode
            }
        })

        return strType
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <Grid container spacing={2}>
                <Grid item xs={12} style={{ marginLeft: '15px', marginTop: '15px'}}>
                    <div style={{ width: '100%' }}>
                        PO No. {params.poListingObject?.polnumber}
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <POAmountsForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} polid={params.poListingObject?.polid} poWorkTypes={params.worktypelist}/>
                    <DataGridPro
                        // apiRef={gridAmountAPIRef}
                        rows={amountRows}
                        columns={amountColumns}
                        autoHeight={true}
                        density={'compact'}
                        pageSize={10}
                        loading={!data}
                        disableMultipleSelection={true}
                        // onRowClick={handleRowClick}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default POAmountsTable
