import React, {useEffect, useState} from "react";
import styles from './WorkAuthorizationsPage.module.css'
import {IconButton, Box, AppBar} from '@material-ui/core'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { Add } from '@mui/icons-material'
import {GridSelectionModel, GridValueFormatterParams, XGrid} from '@material-ui/x-grid'
import {
    DataGridPro,
    GridColDef,
    GridCellParams,
    GridRowParams,
    GridCallbackDetails,
    MuiEvent, useGridApiRef
} from "@mui/x-data-grid-pro";
import {WorkAuthorizationsData, WorkAuthorizationsDataDefaults, useDeleteWorkAuthorization, useFetchWorkAuthorizations} from "../../../queries/useWorkAuthorizations";
import {WorkAuthorizationsForm} from "./WorkAuthorizationsForm";
import {formatMoney} from "../../../util/formatHelpers";
import {useFetchWADropdowns} from "../../../queries/useWorkAuthorizationsDropdowns";

const WorkAuthorizationTable = ({ workOrderObj, woDropDownData }) => {
    const deleteWorkAuthorizations = useDeleteWorkAuthorization()
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState(WorkAuthorizationsDataDefaults)
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const [listingIDSelected, setListingIDSelected] = React.useState<number>(null);

    const { data: workAuthorizationDropdownData } = useFetchWADropdowns()

    var workAuthRows = []
    const { data, isLoading } = useFetchWorkAuthorizations(workOrderObj.woid)
    if (data && data.length > 0) {
        workAuthRows = data?.map((workAuth: WorkAuthorizationsData, i) => {
            return {
                id: workAuth.waid,
                waid: workAuth.waid,
                wawoid: workAuth.wawoid,
                waisactive: workAuth.waisactive,
                notescount: workAuth.notescount,
                noteslinkid:  workAuth.noteslinkid,
                noteslinkidhas:  workAuth.noteslinkidhas,
                notestitle: workAuth.notestitle,
                notestypeid:  workAuth.notestypeid,
                waaddeddate: workAuth.waaddeddate,
                waaddeduser: workAuth.waaddeduser,
                waaddeduserid: workAuth.waaddeduserid,
                waamount: workAuth.waamount,
                waamountchange: workAuth.waamountchange,
                waamountlastupdated: workAuth.waamountlastupdated,
                wacomments: workAuth.wacomments,
                wadescription: workAuth.wadescription,
                wanumber: workAuth.wanumber,
                warequestdocument: workAuth.warequestdocument,
                wastatus: workAuth.wastatus,
                wastatusid: workAuth.wastatusid,
                waworkorder: workAuth.waworkorder
            }
        })
    }


    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const columns: GridColDef[] = [
        {
            field: 'Edit',
            renderHeader: () => {
                return (
                    <IconButton
                        className={styles.submitButton}
                        onClick={() => handleAdd()}
                        size="small">
                        <Add />
                    </IconButton>
                )
            },
            width: 50,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
                    <Edit />
                </IconButton>
            )
        },
        {
            field: 'id',
            hide: true
        },
        {
            field: 'waid',
            type: 'number',
            headerName: 'WA #',
            width: 100
        },
        {
            field: 'waisactive',
            type: 'string',
            headerName: 'Status',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueGetter: (params) => {
                return (params.value === 'Yes' ? "Active" : "Inactive")
            },
        },
        {
            field: 'waamount',
            type: 'string',
            headerName: 'Amount',
            width: 125
        },
        {
            field: 'waadaterequested',
            type: 'date',
            headerName: 'Submitted',
            width: 175,
            valueFormatter: (params) =>
                params.value ? new Date(params.value as string).toLocaleString() : 'N/A'
        },
        {
            field: 'waadateapproved',
            type: 'date',
            headerName: 'Approved',
            width: 175,
            valueFormatter: (params) =>
                params.value ? new Date(params.value as string).toLocaleString() : 'N/A'
        },
        {
            field: 'waadaterejected',
            type: 'date',
            headerName: 'Rejected',
            width: 175,
            valueFormatter: (params) =>
                params.value ? new Date(params.value as string).toLocaleString() : 'N/A'
        },
        {
            field: 'action',
            headerName: '',
            width: 50,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                    <Delete />
                </IconButton>
            )
        }
    ]

    const handleEdit = (params: GridCellParams) => {
        const workAuthorizationData = data.filter((item) => item.waid === Number(params.id))[0]
        setReloadForm(reloadForm+1)
        setGridParams({
            waid: workAuthorizationData.waid,
            waworkorder: workAuthorizationData.waworkorder,
            wawoid: workAuthorizationData.wawoid,
            wanumber: workAuthorizationData.wanumber,
            wadescription: workAuthorizationData.wadescription,
            waamount: workAuthorizationData.waamount,
            waamountchange: workAuthorizationData.waamountchange,
            wastatus: workAuthorizationData.wastatus,
            wastatusid: workAuthorizationData.wastatusid,
            wasubmitteddate: workAuthorizationData.wasubmitteddate,
            waapproveddate: workAuthorizationData.waapproveddate,
            warejecteddate: workAuthorizationData.warejecteddate,
            wacomments: workAuthorizationData.wacomments,
            warequestdocument: workAuthorizationData.warequestdocument,
            waamountlastupdated: workAuthorizationData.waamountlastupdated,
            waaddeduser: workAuthorizationData.waaddeduser,
            waaddeddate: workAuthorizationData.waaddeddate,
            waupdateduser: workAuthorizationData.waupdateduser,
            waupdatedate: workAuthorizationData.waupdatedate,
            waaddeduserid: workAuthorizationData.waaddeduserid,
            waupdateduserid: workAuthorizationData.waupdateduserid,
            waisactive: workAuthorizationData.waisactive,
            notestypeid: workAuthorizationData.notestypeid,
            noteslinkid: workAuthorizationData.noteslinkid,
            notestitle: workAuthorizationData.notestitle,
            noteslinkidhas: workAuthorizationData.noteslinkidhas,
            notescount: workAuthorizationData.notescount
        })
        setIsFormShowing(true)
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObj({
            msg: 'Are you sure you want to delete this amount \"' + params.row.waaamount.toString() + "\"?",
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        // const { data: response } = await deleteWAAmounts.mutateAsync({ waaid: dialogObj.id })
        closeDialog()
    }

    const closeDialog = () => {
        setDialogObj({
            msg: '',
            id: 0
        })
    }

    const handleListingRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        setListingIDSelected(Number(param.id))
    }

    return (
        <div className={styles.root}>
            <WorkAuthorizationsForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} workOrderObj={workOrderObj} workAuthorizationDropdownData={workAuthorizationDropdownData} />
            <DataGridPro
                // apiRef={gridAmountAPIRef}
                rows={workAuthRows}
                columns={columns}
                autoHeight={true}
                density={'compact'}
                pageSize={10}
                loading={false}
                disableMultipleSelection={true}
                // onRowClick={handleRowClick}
            />
        </div>
    );
}

export default WorkAuthorizationTable
