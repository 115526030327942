import React from 'react'
import { useController, useFormContext } from 'react-hook-form'

import DayJSUtils from '@date-io/dayjs'
import { DateTimePicker, TimePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

type Props = {
  name: string
  label: string
  defaultValue?: string
  required?: boolean
  disabled?: boolean
  rules?: Object
  textFieldProps?: Object
  dateTimeProps?: Object
}

const DateInput = ({
  name,
  label,
  defaultValue = '',
  rules = {},
  required = false,
  disabled = false,
  textFieldProps,
  dateTimeProps
}: Props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  const {
    field: { ref, ...inputProps }
  } = useController({
    name: name as any,
    control,
    rules: { ...rules, required: required ? 'Field is required' : false },
    defaultValue: defaultValue
  })

  return (
    <MuiPickersUtilsProvider utils={DayJSUtils}>
      <KeyboardDatePicker
        size="small"
        disabled={disabled}
        InputLabelProps={{ shrink: true, required }}
        variant="dialog"
        format={"MM/DD/YYYY"}
        inputProps={{ error: Boolean(errors[name]), ...textFieldProps }}
        inputVariant="outlined"
        name={name}
        defaultValue={defaultValue}
        label={label}
        error={Boolean(errors[name])}
        helperText={errors[name]?.message}
        required={required}
        {...dateTimeProps}
        {...inputProps}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DateInput
