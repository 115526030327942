import React, {useEffect} from "react";
import styles from './WorkOrderPage.module.css'
import {AppBar, Avatar, IconButton, Toolbar, Typography} from "@material-ui/core";
import {Grid} from "@mui/material";
import WOListingTable from "./ListingPages/WOListingTable"
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useFetchGridState} from "../../queries/useGridState";
import {useFetchWorkOrderDropdowns} from "../../queries/useWorkOrderDropdowns";
import {WorkOrderData} from "../../queries/useWorkOrder";
import WOFormTabPage from "./WorkOrderFormTabPage/WOFormTabPage";
import WorkAuthorizationsPage from "./WorkAuthorizations/WorkAuthorizationsPage";
import DocumentsPage from "./Documents/DocumentsPage";
import DivisionsPage from "./Divisions/DivisionsPage";
import NotesPage from "./Notes/NotesPage";
import SchedulesPage from "./Schedules/SchedulesPage";
import DependenciesPage from "./Dependencies/DependenciesPage";


const WorkOrderPage = () => {
    const gridStateVersion = 'work-order-listings-v1'
    const { data: gridStateData } = useFetchGridState(gridStateVersion)

    const gridDivisionsStateVersion = 'divisions-grid-v0'
    const { data: gridDivisionsStateData } = useFetchGridState(gridDivisionsStateVersion)

    const gridDependenciesStateVersion = 'dependencies-grid-v0'
    const { data: gridDependenciesStateData } = useFetchGridState(gridDependenciesStateVersion)

    const gridScheduleStateVersion = 'schedules-grid-v0'
    const { data: gridScheduleStateData } = useFetchGridState(gridScheduleStateVersion)

    const gridNotesStateVersion = 'notes-grid-v0'
    const { data: gridNotesStateData } = useFetchGridState(gridNotesStateVersion)

    const [tabSel, setTabSel] = React.useState<number>(0);
    const [reloadTab, setReloadTab] = React.useState<number>(0);
    const { data: woDropDownData } = useFetchWorkOrderDropdowns()
    const [workOrderObj, setWorkOrderObj] = React.useState<WorkOrderData>(null);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setReloadTab(reloadTab + 1)
        setTabSel(newValue)
    }

    useEffect(() => {
        return () => {
            const dataGridPageKey = "wolistingkey"
            localStorage.setItem(dataGridPageKey, "0")
            // console.log('component did unmount')
        }
    }, [])

    return (
        <div className={styles.root}>
            <Grid container spacing={1}>

                <AppBar position="static" color="default">
                    <Typography
                        variant="h6"
                        color="primary"
                        align="center"
                        component="div">
                        Work Order
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                        component="div">
                        NO. {workOrderObj?.woid} - {workOrderObj?.wocustomerinfo}
                    </Typography>
                    <Tabs
                        value={tabSel}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab value={0} label={'Listing'}></Tab>
                        <Tab disabled={workOrderObj === null} value={1} label={'Work Order'}></Tab>
                        <Tab disabled={workOrderObj === null} value={2} label={'Divisions'}></Tab>
                        <Tab disabled={workOrderObj === null} value={5} label={'Schedules'}></Tab>
                        <Tab disabled={workOrderObj === null} value={7} label={'Documents'}></Tab>
                        <Tab disabled={workOrderObj === null} value={6} label={'Notes'}></Tab>
                        <Tab disabled={workOrderObj === null} value={3} label={'Financial'}></Tab>
                        {/*<Tab disabled={true} value={4} label={'Invoices'}></Tab>*/}
                        <Tab disabled={workOrderObj === null} value={8} label={'Dependencies'}></Tab>
                    </Tabs>

                    {tabSel === 0 && (
                        <WOListingTable
                            gridStateData={gridStateData}
                            gridStateVersion={gridStateVersion}
                            woDropDownData={woDropDownData}
                            workOrderObj={workOrderObj}
                            setWorkOrderObj={setWorkOrderObj}
                        />
                    )}
                    {tabSel === 1 && (
                        <WOFormTabPage
                            woDropDownData={woDropDownData}
                            workOrderObj={workOrderObj}
                        />
                    )}
                    {tabSel === 2 && (
                        <DivisionsPage
                            gridStateData={gridDivisionsStateData}
                            gridStateVersion={gridDivisionsStateVersion}
                            woDropDownData={woDropDownData}
                            workOrderObj={workOrderObj}
                        />
                    )}

                    {tabSel === 3 && (
                        <WorkAuthorizationsPage
                            woDropDownData={woDropDownData}
                            workOrderObj={workOrderObj}
                        />
                    )}

                    {tabSel === 5 && (
                        <SchedulesPage
                            gridStateData={gridScheduleStateData}
                            gridStateVersion={gridScheduleStateVersion}
                            woDropDownData={woDropDownData}
                            workOrderObj={workOrderObj}
                        />
                    )}
                      {tabSel === 6 && (
                        <NotesPage
                            gridStateData={gridNotesStateData}
                            gridStateVersion={gridNotesStateVersion}
                            woDropDownData={woDropDownData}
                            workOrderObj={workOrderObj}
                        />
                    )}

                    {tabSel === 7 && (
                        <DocumentsPage
                            woDropDownData={woDropDownData}
                            workOrderObj={workOrderObj}
                            reloadTab={reloadTab}
                        />
                    )}

                    {tabSel === 8 && (
                        <DependenciesPage
                            gridStateData={gridDependenciesStateData}
                            gridStateVersion={gridDependenciesStateVersion}
                            woDropDownData={woDropDownData}
                            workOrderObj={workOrderObj}
                        />
                    )}

                </AppBar>
            </Grid>
        </div>
      )
}

export default WorkOrderPage
