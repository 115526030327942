import React, {useEffect, useState} from "react";
import styles from '../CWAListingPage/CWALiastingPage.module.css'
import {IconButton, Box, AppBar} from '@material-ui/core'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { Add } from '@mui/icons-material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {GridSelectionModel, GridValueFormatterParams, XGrid} from '@material-ui/x-grid'
import {
    DataGridPro,
    GridColDef,
    GridCellParams,
    GridRowParams,
    GridCallbackDetails,
    MuiEvent, useGridApiRef
} from "@mui/x-data-grid-pro";
import {CWAListingForm} from "../CWAListingPage/CWAListForm";

import {CWAListing, useFetchCWAListing, useDeleteCWAListing} from '../../../queries/useCWAListing'
import {TabPanel} from "@material-ui/lab";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {CWAListingDefaults} from '../../../queries/useCWAListing'
import {selectIdValue} from "@reduxjs/toolkit/dist/entities/utils";
import {CWAAmounts, CWAAmountsDefaults, useDeleteCWAAmounts, useFetchCWAAmounts} from "../../../queries/useCWAAmounts";
import {useFetchCWADropdowns} from "../../../queries/useCWADropdowns";
import HookForm from "../../../components/common/HookForm";
import {CWAAmountsForm} from "./CWAAmountsForm";
import {Grid} from "@mui/material";
import {formatMoney} from "../../../util/formatHelpers";
import dayjs from "dayjs";

const CWAAmountsTable = (params) => {
    const deleteCWAAmounts = useDeleteCWAAmounts()
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState(CWAAmountsDefaults)
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const [listingIDSelected, setListingIDSelected] = React.useState<number>(null);

    var amountRows = []
    const { data } = useFetchCWAAmounts(params.id)
    if (data && data.length > 0) {
        amountRows = data?.map((amount: CWAAmounts, i) => {
            return {
                id: amount.cwaaid,
                cwaaamount: amount.cwaaamount,
                cwaastatusid: amount.cwaastatusid,
                cwaadaterequested: amount.cwaadaterequested,
                cwaadateapproved: amount.cwaadateapproved,
                cwaacomment: amount.cwaacomment,
                cwaaamountlastupdated: amount.cwaaamountlastupdated,
                cwaaaddeddate: amount.cwaaaddeddate,
                cwaadateexpire: amount.cwaadateexpire
            }
        })
    }


    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const amountColumns: GridColDef[] = [
        {
            field: 'Edit',
            renderHeader: () => {
                return (
                    <IconButton
                        className={styles.submitButton}
                        onClick={() => handleAdd()}
                        size="small">
                        <Add />
                    </IconButton>
                )
            },
            width: 50,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
                    <Edit />
                </IconButton>
            )
        },
        {
            field: 'id',
            hide: true
        },
        {
            field: 'cwaaaddeddate',
            type: 'date',
            headerName: 'Added',
            width: 175,
            valueFormatter: (params) =>
                (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DD").format('MM/DD/YYYY') : 'N/A')
        },
        {
            field: 'cwaaamount',
            type: 'string',
            headerName: 'Amount',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            field: 'cwaastatusid',
            type: 'string',
            headerName: 'Status',
            width: 125,
            valueGetter: (gridParams) =>
                gridParams.value ? params.cwaamountsstatuslist.filter((item) => { return item.statusid === gridParams.value})[0].statuscode : ''
        },
        {
            field: 'cwaadaterequested',
            type: 'date',
            headerName: 'Requested',
            width: 175,
            valueFormatter: (params) =>
                (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DD").format('MM/DD/YYYY') : 'N/A')
        },
        {
            field: 'cwaadateapproved',
            type: 'date',
            headerName: 'Approved',
            width: 175,
            valueFormatter: (params) =>
                (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DD").format('MM/DD/YYYY') : 'N/A')
        },
        {
            field: 'cwaadateexpire',
            type: 'date',
            headerName: 'Expiration',
            width: 175,
            valueFormatter: (params) =>
                (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DD").format('MM/DD/YYYY') : 'N/A')
        },
        {
            field: 'action',
            headerName: '',
            width: 50,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                    <Delete />
                </IconButton>
            )
        }
    ]

    const handleEdit = (params: GridCellParams) => {
        const cwaAmount = data.filter((item) => item.cwaaid === Number(params.id))[0]
        setReloadForm(reloadForm+1)
        setGridParams({
            cwaaid: cwaAmount.cwaaid,
            cwaaamount: cwaAmount.cwaaamount,
            cwaastatusid: cwaAmount.cwaastatusid,
            cwaadaterequested: cwaAmount.cwaadaterequested,
            cwaadateapproved: cwaAmount.cwaadateapproved,
            cwaacomment: cwaAmount.cwaacomment,
            cwaaamountlastupdated: cwaAmount.cwaaamountlastupdated,
            cwaaaddeddate: cwaAmount.cwaaaddeddate,
            cwaadateexpire: cwaAmount.cwaadateexpire
        })
        setIsFormShowing(true)
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObj({
            msg: 'Are you sure you want to delete this amount \"' + params.getValue(params.id, 'cwaaamount').toString() + "\"?",
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteCWAAmounts.mutateAsync({ cwaaid: dialogObj.id })
        closeDialog()
    }

    const closeDialog = () => {
        setDialogObj({
            msg: '',
            id: 0
        })
    }

    const handleListingRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        setListingIDSelected(Number(param.id))
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CWAAmountsForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} cwalid={params.id} cwaamountsstatuslist={params.cwaamountsstatuslist}/>
                    <DataGridPro
                        // apiRef={gridAmountAPIRef}
                        rows={amountRows}
                        columns={amountColumns}
                        autoHeight={true}
                        density={'compact'}
                        pageSize={10}
                        loading={!data}
                        disableMultipleSelection={true}
                        // onRowClick={handleRowClick}
                    />
                </Grid>
            </Grid>


        </div>
    );
}

export default CWAAmountsTable
