import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  POListings {
    polid: number,
    polnumber: string,
    poldescription: string,
    polamountlastupdated: string,
    poladdeddate: string,
    polstatusid: number,
    polactive: boolean,
    polauthorizedamount: number,
    polbilledamount: number,
    polpendingamount: number,
    polremainingamount: number
}

export const POListingsDefaults = {
    polid: 0,
    polnumber: '',
    poldescription: '',
    polamountlastupdated: '',
    polstatusid: 0,
    poladdeddate: '',
    polactive: true,
    polauthorizedamount: 0,
    polbilledamount: 0,
    polpendingamount: 0,
    polremainingamount: 0
}

export const useFetchPOListings =  (cwalid: number) => {
    return useQuery<POListings[]>(
        ['polisting', cwalid],
        () => globalFetcher(cwalid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdatePOListings = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`polisting`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['polisting'])
        }
    })
}

export const useDeletePOListings = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`polisting`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['polisting'])
        }
    })
}

export const globalFetcher = async (id: number) => {
    const { data } = await axios.post('/polisting', {
        cwalid: id
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.polist as POListings[]
}
