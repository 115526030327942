import React, {useEffect, useMemo, useState} from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import styles from './WorkAuthorizationsPage.module.css'
import WorkAuthorizationTable from "./WorkAuthorizationTable";

export const WorkAuthorizationsPage = ({ workOrderObj, woDropDownData }) => {

    return (
        <div className={styles.root}>
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth={"xl"}>
                    <Box bgcolor={'#d4d2d3'} sx={{ height: '22vh', minWidth: '100vh'}}>
                        <Grid container border={0} direction='row' alignItems="center" height={'100%'} width={'100%'}>
                            <Grid item border={0} xs={4} >
                                <Grid container border={0} xs={12} direction='row'>
                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" marginLeft={"3vh"} gutterBottom>
                                            WA Status:
                                        </Typography>
                                    </Grid>
                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            XXXXXXXX
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container border={0} xs={12} direction='row'>
                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" marginLeft={"3vh"} gutterBottom>
                                            WA Method:
                                        </Typography>
                                    </Grid>
                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" gutterBottom>
                                            XXXXXXXX
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container border={0} xs={12} direction='row'>
                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2" marginLeft={"3vh"}>
                                            Approver:
                                        </Typography>
                                    </Grid>
                                    <Grid item border={0} xs={6}>
                                        <Typography variant="body2">
                                            Scott Leathers
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item border={2} xs={7.80}>
                                <Grid container border={0} direction='row' alignItems="center" height={'100%'} width={'100%'}>
                                    <Grid item border={0} xs={6}>
                                        <Grid container border={0} xs={12} direction='row'>
                                            <Grid item border={0} xs={6}>
                                                <Typography variant="body2" marginLeft={"3vh"} gutterBottom>
                                                    Total WA Approved:
                                                </Typography>
                                            </Grid>
                                            <Grid item border={0} xs={6}>
                                                <Typography variant="body2" gutterBottom>
                                                    $450.000.000
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container border={0} xs={12} direction='row'>
                                            <Grid item border={0} xs={6}>
                                                <Typography variant="body2" marginLeft={"3vh"} gutterBottom>
                                                    Total WA Pending:
                                                </Typography>
                                            </Grid>
                                            <Grid item border={0} xs={6}>
                                                <Typography variant="body2" gutterBottom>
                                                    $3
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container border={0} xs={12} direction='row'>
                                            <Grid item border={0} xs={6}>
                                                <Typography variant="body2" marginLeft={"3vh"}>
                                                    Total WA Estimate:
                                                </Typography>
                                            </Grid>
                                            <Grid item border={0} xs={6}>
                                                <Typography variant="body2">
                                                    $1
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item border={0} xs={6}>
                                        <Grid container border={0} xs={12} direction='row'>
                                            <Grid item border={0} xs={6}>
                                                <Typography variant="body2" marginLeft={"3vh"} gutterBottom>
                                                    Total Cost (To-Date):
                                                </Typography>
                                            </Grid>
                                            <Grid item border={0} xs={6}>
                                                <Typography variant="body2" gutterBottom>
                                                    $540
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container border={0} xs={12} direction='row'>
                                            <Grid item border={0} xs={6}>
                                                <Typography variant="body2" marginLeft={"3vh"} gutterBottom>
                                                    Total WA Approvedd:
                                                </Typography>
                                            </Grid>
                                            <Grid item border={0} xs={6}>
                                                <Typography variant="body2" gutterBottom>
                                                    $100,000
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container border={0} xs={12} direction='row'>
                                            <Grid item border={0} xs={6}>
                                                <Typography variant="body2" marginLeft={"3vh"}>
                                                    Total Available Funds:
                                                </Typography>
                                            </Grid>
                                            <Grid item border={0} xs={6}>
                                                <Typography variant="body2">
                                                    $54,000
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box style={{ marginTop: '4vh' }} sx={{ flexGrow: 1 }}>
                        <WorkAuthorizationTable
                            woDropDownData={woDropDownData}
                            workOrderObj={workOrderObj}/>
                    </Box>
                </Container>
            </React.Fragment>
        </div>
    )
}

export default WorkAuthorizationsPage
