import React, {useEffect, useState} from "react";
import styles from './Employee.module.css'
import {IconButton, Box, AppBar, Typography} from '@material-ui/core'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { Add } from '@mui/icons-material'
import {GridSelectionModel, GridValueFormatterParams, XGrid} from '@material-ui/x-grid'
import {
    DataGridPro,
    GridColDef,
    GridCellParams,
    GridRowParams,
    GridCallbackDetails,
    MuiEvent, useGridApiRef
} from "@mui/x-data-grid-pro";

import {EmployeeDefaults, Employee, useDeleteEmployee, useFetchEmployee} from "../../../queries/useEmployee";
import {useFetchLookupCategory} from "../../../queries/useLookupCategory";
import {EmployeeForm} from "./EmployeeForm";
import {Grid} from "@mui/material";

const EmployeePage = (params) => {
    const deleteEmployee = useDeleteEmployee()
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState(EmployeeDefaults)
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const [listingIDSelected, setListingIDSelected] = React.useState<number>(null);

    var employeeRows = []
    const { data } = useFetchEmployee()
    if (data && data.length > 0) {
        employeeRows = data?.map((employee: Employee, i) => {
            return {
                id: employee.id,
                entityid: employee.entityid,
                code: employee.code,
                title: employee.title,
                isforeman: employee.isforeman,
                isgeneralforeman: employee.isgeneralforeman,
                address1: employee.address1,
                address2: employee.address2,
                address3: employee.address3,
                city: employee.city,
                state: employee.state,
                zip: employee.zip,
                first: employee.first,
                last: employee.last,
                phone: employee.phone,
                email: employee.email,
                active: employee.active
            }
        })
    }
    const { data: dropDownData, isLoading: dropdownLoading } = useFetchLookupCategory()

    const getEntityDescription = (entid: number) => {
        const value = dropDownData.entitylist.filter((e) => e.entityid === entid)
        return value[0]?.entityname
    }
  
    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }
   
    const amountColumns: GridColDef[] = [
        {
            field: 'Edit',
            renderHeader: () => {
                return (
                    <IconButton
                        className={styles.submitButton}
                        onClick={() => handleAdd()}
                        size="small">
                        <Add />
                    </IconButton>
                )
            },
            width: 50,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
                    <Edit />
                </IconButton>
            )
        },
        {
            field: 'id',
            hide: true
        },
        {
            field: 'entityid',
            type: 'string',
            headerName: 'Entity',
            width: 100,
            valueFormatter: (params) =>
                getEntityDescription(Number(params.value))
        },
        {
            field: 'first',
            type: 'string',
            headerName: 'First',
            width: 125,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'last',
            type: 'string',
            headerName: 'Last',
            width: 125,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'phone',
            type: 'string',
            headerName: 'Phone',
            width: 125,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'email',
            type: 'string',
            headerName: 'Email',
            width: 200,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'code',
            type: 'string',
            headerName: 'Code',
            width: 200,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'active',
            type: 'string',
            headerName: 'Active',
            width: 125,
            valueGetter: (params) => {
                return (params.value ? "Active" : "Inactive")
            },
        },
        {
            field: 'action',
            headerName: '',
            width: 50,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                    <Delete />
                </IconButton>
            )
        }
    ]

    const handleEdit = (params: GridCellParams) => {
        const employee = data.filter((item) => item.id === Number(params.id))[0]
        setReloadForm(reloadForm+1)
        setGridParams({
            id: employee.id,
            entityid: employee.entityid,
            code: employee.code,
            title: employee.title,
            isforeman: employee.isforeman,
            isgeneralforeman: employee.isgeneralforeman,
            address1: employee.address1,
            address2: employee.address2,
            address3: employee.address3,
            city: employee.city,
            state: employee.state,
            zip: employee.zip,
            first: employee.first,
            last: employee.last,
            phone: employee.phone,
            email: employee.email,
            active: employee.active
        })
        setIsFormShowing(true)
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObj({
            msg: 'Are you sure you want to delete this employee \"' + params.getValue(params.id, 'first').toString() + "\"?",
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteEmployee.mutateAsync({ id: dialogObj.id })
        closeDialog()
    }

    const closeDialog = () => {
        setDialogObj({
            msg: '',
            id: 0
        })
    }

    const handleListingRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        setListingIDSelected(Number(param.id))
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <Grid container spacing={1}>
                <AppBar position="static" color="default">
                    <Typography
                        variant="h6"
                        color="primary"
                        align="center"
                        component="div">
                        Employees
                    </Typography>
                    <EmployeeForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} />
                    <DataGridPro
                        // apiRef={gridAmountAPIRef}
                        rows={employeeRows}
                        columns={amountColumns}
                        autoHeight={true}
                        density={'compact'}
                        pageSize={20}
                        //onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        //rowsPerPageOptions={[5, 10, 20]}
                        pagination
                        loading={!data}
                        disableMultipleSelection={true}
                        // onRowClick={handleRowClick}
                    />
                </AppBar>
            </Grid>
        </div>
    );
}

export default EmployeePage
