import React, {useEffect, useMemo, useState} from "react";
import styles from './Listing.module.css'
import {
    useFetchEntitySchedules,
    SchedulesData,

    SchedeulesDataObject,
    SchedulesDataDefaults
} from "../../../queries/useSchedules";
import {GridSelectionModel, GridSortItem, GridValueFormatterParams} from "@material-ui/x-grid";
import {useQueryClient} from 'react-query'
import {
    DataGridPro,
    GridCallbackDetails,
    GridCellParams,
    GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
    GridRowParams, gridSortedRowIdsSelector,
    GridSortModel, GridToolbarContainer,
    MuiEvent, useGridApiContext,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import {useUpdateGridState} from "../../../queries/useGridState";
import {Alert, Badge, BadgeProps, FormGroup, Grid, MenuItem, Select, SelectChangeEvent, styled} from "@mui/material";
import {NotesIcon} from "../../../components/common/NotesLayout/NotesLayout";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Typography
} from "@material-ui/core";
import {Delete, PhoneIphone, Edit, SettingsBackupRestore} from "@material-ui/icons";
import {Add, Save, PictureAsPdf} from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";

import {SchedulesForm} from "../../WorkOrderPage/Schedules/Schedules.Form";
import {
    DispatchData,
    useFetchEntityProcess,
    useDeleteProcess,
    ProcessDataObject
} from "../../../queries/useEntityProcessList";
import {ButtonProps} from "@mui/material/Button";
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {dispatchfielddoc} from "../../../queries/useSendDispatch";
import LoadingBackdrop from "../../../components/common/LoadingBackdrop";
import axios from "axios";
import {TEItemListData, WIPListData} from "../../../queries/useDispatchWip";
import dayjs from "dayjs";

const ListingPage = (params) => {
    const deleteProcess = useDeleteProcess()
    let _sortModelState: any = null
    const [gridShowing, setGridShowing] = useState(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState({})
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObjDel, setDialogObjDel] = React.useState({msg: "", id: 0})
    const [dialogObj, setDialogObj] = React.useState({msg: "", gridState: "", okButtonText: "Save"})
    const [snackbarOpen, setSnackbarOpen] = React.useState({ open: false, message: '' })
    const [dataRows, setDataRows] = React.useState([]);
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([1]);
    const gridAPIRef = useGridApiRef()
    const updateGridState = useUpdateGridState()
    const [scheduleDropdowns, setScheduleDropdowns] = React.useState({});

    const gridSortItem: GridSortItem = {
        field: 'dispatchwoid',
        sort: 'asc'
    }
    const defaultGridSortModel: GridSortModel = [gridSortItem]
    const [gridSortModel, setGridSortModel] = React.useState<GridSortModel>(defaultGridSortModel)

    useEffect(() => {
        console.log(selectionModel)
    }, [selectionModel])

    useEffect(() => {

        const dispatchObjList = params?.data?.dispatchlist[0]
        // console.log(dispatchObjList)
        if (dispatchObjList) {
            params.setDispatchList(dispatchObjList)
        }

        setGridSortModel(getColumnSortModel())
        if (params?.data?.dispatchlist && params?.data?.dispatchlist?.length > 0) {
            const localDataRows = getMappedRecords()
            setDataRows(localDataRows)
        }
        else {
            setDataRows([])
        }
    }, [params?.data])

    React.useEffect(() => {
        console.log('UPDATED DATAROWS')
        if (dataRows && dataRows.length > 0) {
            if (params.dispatchListRow) {
                setTimeout(() => {
                    gridAPIRef.current?.selectRows([params.dispatchListRow])
                })
            }
            else {
                params.setDispatchList(dataRows[0])
                setTimeout(() => {
                    gridAPIRef.current?.selectRows([dataRows[0].dispatchid])
                })
            }

        }
    }, [dataRows]);

    const getMappedRecords = () => {
        return params?.data?.dispatchlist.map((processList: DispatchData, i) => {
            return {
                id: processList.dispatchid,
                readonly: processList.readonly,
                canedit: processList.canedit,
                candelete: processList.candelete,
                dispatchid: processList.dispatchid,
                dispatchwoid: processList.dispatchwoid,
                dispatchdivision: processList.dispatchdivision,
                dispatchschedulenumber: processList.dispatchschedulenumber,
                dispatchcustomerinfo: processList.dispatchcustomerinfo,
                dispatchcontractinfo: processList.dispatchcontractinfo,
                dispatchjobinfo: processList.dispatchjobinfo,
                dispatchsentdate: processList.dispatchsentdate,
                dispatchSentTo: processList.dispatchSentTo,
                dispatchsentuser: processList.dispatchsentuser,
                dispatchstatus: processList.dispatchstatus,
                notestypeid: processList.notestypeid,
                notestitle: processList.notestitle,
                noteslinkid: processList.noteslinkid,
                notescount: processList.notescount,
                lastwipposted: processList.lastwipposted,
                lasttimeposted: processList.lasttimeposted,
                dispatchsubmitteddate: processList.dispatchsubmitteddate,
                dispatchsubmittedemployee: processList.dispatchsubmittedemployee
            }
        })
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen({ open: false, message: ""  });
    }

    const basicProps = {
        disableColumnMenu: true,
        sortable: false,
        disableExport: true,
        disableReorder: true,
        editable: false,
        filterable: false,
        groupable: false,
        hideable: false,
        pinnable: false
    }

    const HandleEdit = async (params: GridCellParams) => {
        setReloadForm(reloadForm + 1)
        setIsFormShowing(true)
    }

    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const canEdit = (params: GridCellParams) => {
        return params.row.canedit
    }

    const canDelete = (params: GridCellParams) => {
        return params.row.candelete
    }

    const canAdd = (params: GridCellParams) => {
        return params.row.canadd
    }

    const buildColumnDefs = () => {
        const gridObj = parseGridObject()
        const parsedGridColumns = gridObj.gridDefs
        var gridColumns: GridColDef[] = [
            ...parsedGridColumns,
            gridButtonsDef
        ]
        return gridColumns
    }

    const gridButtonsDef: GridColDef = {
        field: 'Delete',
        headerName: '',
        width: 100,
        align: "center",
        headerAlign: "center",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <>

                    <IconButton className={styles.button} onClick={() => { handleReprint(params) }}>
                        <PictureAsPdf />
                    </IconButton>
                    <NotesIcon
                        id={Number(params.id)}
                        notesid={params.row.notesid}
                        notestitle={params.row.notestitle}
                        notestypeid={params.row.notestypeid}
                        noteslinkid={params.row.noteslinkid}
                        notescount={params.row.notescount}
                        queryType={['processlist']}
                    />
                    {params.row.candelete &&
                        <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                            <Delete />
                        </IconButton>
                    }
                </>
            )
        }
    }

    const handleReprint = (params: GridCellParams) => {
        sendDispatchFieldDoc(params.id)
    }

    const sendDispatchFieldDoc = async (dispatchId) => {
        setIsLoading(true)
        const response = await dispatchfielddoc(dispatchId)
        setIsLoading(false)
        setSnackbarOpen({ open: true, message: response.message })
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObjDel({
            msg: 'Are you sure you want to delete this wo# \"' + params.row.dispatchwoid.toString() + "\" Sched # \"" + params.row.dispatchschedulenumber.toString() + "\"?",
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteProcess.mutateAsync({ dispatchid: dialogObjDel.id })
        params.setReloadListingData(params.reloadListingData + 1)
        closeDialog()
    }

    const getColumnSortModel = () => {
        const gridObj = parseGridObject()
        return gridObj.sortModel
    }

    const formatDate = (params: GridValueFormatterParams) => {
        return (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DDTHH:mm:ss").format('MM/DD hh:mm A') : '')
    }

    const formatLongDate = (params: GridValueFormatterParams) => {
        return (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DDTHH:mm:ss").format('MM/DD hh:mm A') : '')
    }

    const parseGridObject = () => {
        var sortModel: GridSortModel = [gridSortItem]
        var gridStateString = JSON.stringify({gridType: "processlist", gridState: JSON.stringify(gridDefaults)})
        if (params.gridStateData && params.gridStateData.length > 0) {
            gridStateString = params.gridStateData[0].gridState
            const gridStateData = JSON.parse(gridStateString)
            const sendObj = {
                gridType: 'processlist',
                gridState: JSON.stringify(gridStateData.columnDefs)
            }
            gridStateString = JSON.stringify(sendObj)

            if (gridStateData && gridStateData.sortModel !== null && gridStateData.sortModel.length > 0) {
                // gridAPIRef?.current?.setSortModel(gridStateData.sortModel)
                const dataSort = gridStateData.sortModel
                sortModel = dataSort
            }
        }

        var gridDefs: GridColDef[] = [];
        if (gridStateString.length > 0) {
            const gridObject = JSON.parse(gridStateString)
            if (gridObject.gridType === 'processlist') {
                const gridState = JSON.parse(gridObject?.gridState)
                gridState?.forEach(state => {
                    var col: GridColDef = {...state}
                    if (col.field === 'dispatchsubmitteddate') {
                        col.valueFormatter = formatLongDate
                    }
                    else if (col.field === 'lastwipposted') {
                        col.valueFormatter = formatDate
                    }
                    else if (col.field === 'lasttimeposted') {
                        col.valueFormatter = formatDate
                    }
                    gridDefs.push(col)
                })
            }
        }
        return {gridDefs: gridDefs, sortModel: sortModel}
    }

    const gridDefaults: GridColDef[] = [
        {
            "field": "dispatchwoid",
            "headerName": "WO #",
            "headerAlign": "left",
            "type": "string",
            "width": 100,
            "align": "left"
        },
        {
            "field": "dispatchschedulenumber",
            "headerName": "Sched #",
            "headerAlign": "left",
            "type": "string",
            "width": 100,
            "align": "left"
        },
        {
            "field": "dispatchdivision",
            "headerName": "Division",
            "headerAlign": "left",
            "type": "string",
            "width": 100,
            "align": "left"
        },
        {
            "field": "dispatchcustomerinfo",
            "headerName": "Cust#/Name",
            "headerAlign": "left",
            "type": "string",
            "width": 400,
            "align": "left"
        },
        {
            "field": "dispatchjobinfo",
            "headerName": "Job Info",
            "headerAlign": "left",
            "type": "string",
            "width": 200,
            "align": "left"
        },
        {
            "field": "dispatchcontractinfo",
            "headerName": "Contract Info",
            "headerAlign": "left",
            "type": "string",
            "width": 200,
            "align": "left"
        },
        {
            "field": "dispatchstatus",
            "headerName": "Status",
            "headerAlign": "left",
            "type": "string",
            "width": 140,
            "align": "left"
        },
        {
            "field": "dispatchsubmitteddate",
            "headerName": "Dispatch Submitted",
            "headerAlign": "left",
            "type": "string",
            "width": 140,
            "align": "left",
            valueFormatter: formatLongDate
        },
        {
            "field": "dispatchsubmittedemployee",
            "headerName": "Employee",
            "headerAlign": "left",
            "type": "string",
            "width": 200,
            "align": "left",
        },
        {
            "field": "lastwipposted",
            "headerName": "Last WIP Posted",
            "headerAlign": "left",
            "type": "string",
            "width": 140,
            "align": "left",
            valueFormatter: formatDate
        },
        {
            "field": "lasttimeposted",
            "headerName": "Last Posted",
            "headerAlign": "left",
            "type": "string",
            "width": 140,
            "align": "left",
            valueFormatter: formatDate
        },

    ]

    const gridEditSaveButtonDef: GridColDef = {
        field: '',
        renderHeader: () => {
            return (
                <>
                    {(canAdd(params)) &&
                        <IconButton
                            className={styles.submitButton}
                            onClick={() => handleAdd()}
                            size="small">
                            <Add />
                        </IconButton>
                    }
                </>
            )
        },
        width: 50,
        align: "center",
        headerAlign: "center",
        pinnable: true,
        cellClassName: 'pinnedColumn',
        ...basicProps,
        renderCell: (params) => (
            <>
                {(canEdit(params)) &&
                    <IconButton className={styles.button} onClick={() => {
                        HandleEdit(params)
                    }}>
                        <Edit/>
                    </IconButton>
                }
            </>
        )
    }

    const getColumnStringToSave = () => {
        var fieldObjects = []
        const gridState = gridAPIRef.current.getAllColumns()
        gridState.forEach(stateColumnDef => {
            if (stateColumnDef.sortable && stateColumnDef.pinnable && stateColumnDef.groupable) {
                fieldObjects.push({
                    field: stateColumnDef.field,
                    headerName: stateColumnDef.headerName,
                    headerAlign: stateColumnDef.headerAlign,
                    type: stateColumnDef.type,
                    width: stateColumnDef.width,
                    align: stateColumnDef.align,
                    description: stateColumnDef.description,
                    hide: stateColumnDef.hide,
                    valueFormatter: stateColumnDef.valueFormatter
                })
            }
        })

        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: fieldObjects, sortModel: sortModelDefs})
        return strObject
    }

    const handleRestore = () => {
        setGridShowing(false)
        const sortModelDefs = gridAPIRef.current.getSortModel()
        const strObject = JSON.stringify({columnDefs: gridDefaults, sortModel: sortModelDefs})
        setDialogObj({
            msg: 'Are you sure you want to reset the grid state to default?',
            gridState: strObject,
            okButtonText: 'RESET'
        })
    }

    const handleSave = () => {
        setGridShowing(false)
        const columnObjsting = getColumnStringToSave()
        setDialogObj({
            msg: 'Are you sure you want to save the current grid state?',
            gridState: columnObjsting,
            okButtonText: 'Save'
        })
    }

    const handleOkConfirm = async () => {
        var finalData = {
            gridType: params.gridStateVersion,
            gridState: dialogObj.gridState
        }
        const {data: response} = await updateGridState.mutateAsync(finalData)
        closeDialog()
        setGridShowing(true)
        setSnackbarOpen({ open: true, message: "Schedule settings have been saved" })
    }

    const closeDialog = () => {
        setGridShowing(true)
        setDialogObj({
            msg: '',
            gridState: '',
            okButtonText: ''
        })
        setDialogObjDel({
            msg: '',
            id: 0
        })
    }

    const handleRowClick = (param: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        const dispatchObjList = params?.data.dispatchlist.filter((dispatchList) => { return dispatchList.dispatchid === Number(param.id)})
        console.log(dispatchObjList)
        if (dispatchObjList && dispatchObjList.length > 0) {
            params.setDispatchListRow(dispatchObjList[0].dispatchid)
            params.setDispatchList(dispatchObjList[0])
        }
    }

    const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
        gridSortedRowIdsSelector(apiRef);

    function CustomGridToolbar() {
        const apiRef = useGridApiContext();

        const handleExport = (options: GridCsvExportOptions) =>
            apiRef.current.exportDataAsCsv(options);

        const buttonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <FileDownloadOutlinedIcon />,
        };

        const saveButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <Save />,
        };

        const restoreButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <SettingsBackupRestore />,
        };

        return (
            <GridToolbarContainer >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        {...saveButtonBaseProps}
                        onClick={() => handleSave()}
                    >
                        SAVE GRID SETTINGS
                    </Button>
                    <Button
                        {...restoreButtonBaseProps}
                        onClick={() => handleRestore()}
                    >
                        RESTORE GRID SETTINGS
                    </Button>
                    <Button
                        {...buttonBaseProps}
                        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
                    >
                        EXPORT
                    </Button>
                    {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                </Grid>
            </GridToolbarContainer>
        )
    }

    return (
        <div className={styles.root}>
            <LoadingBackdrop open={isLoading} />
            {params?.data && params?.data.status === 'failure' ?
                <Typography
                    variant="h6"
                    color="primary"
                    align="center"
                    component="div">
                    {params?.data.message}
                </Typography>
                :
                <>
                    <>
                        <SchedulesForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams}
                                       scheduleDropdowns={scheduleDropdowns} reloadForm={reloadForm} isClone={false} woid={null}/>
                    </>
                    <>
                        <Snackbar
                            open={snackbarOpen.open}
                            autoHideDuration={4000}
                            onClose={handleClose}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        >
                            <Alert severity="success">
                                {snackbarOpen.message}
                            </Alert>
                        </Snackbar>
                    </>
                    <>
                        <Dialog
                            open={dialogObj.msg.length > 0}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {dialogObj.msg}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDialog}>Cancel</Button>
                                <Button onClick={handleOkConfirm}>{dialogObj.okButtonText}</Button>
                            </DialogActions>
                        </Dialog>
                    </>

                    <>
                        <Dialog
                            open={dialogObjDel.msg.length > 0}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {dialogObjDel.msg}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDialog}>Cancel</Button>
                                <Button onClick={handleDeleteConfirm}>DELETE</Button>
                            </DialogActions>
                        </Dialog>
                    </>

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                        <Box sx={{ height: '71vh', width: '100%' }}>

                            {/*<div style={{ height: 100, width: '100%' }}>*/}
                            <DataGridPro
                                sx={{
                                    "& .MuiDataGrid-pinnedColumns": {
                                        boxShadow: "none",
                                        backgroundColor: "transparent"
                                    },
                                    "& .MuiDataGrid-pinnedColumnHeaders": {
                                        boxShadow: "none",
                                        backgroundColor: "transparent"
                                    }
                                }}
                                apiRef={gridAPIRef}
                                rows={dataRows}
                                columns={buildColumnDefs()}
                                //autoHeight={true}
                                density={'compact'}
                                pageSize={10}
                                sortModel={gridSortModel}
                                disableMultipleSelection={true}
                                onSortModelChange={(model: GridSortModel) => setGridSortModel(model)}
                                onStateChange={(state) => {
                                    _sortModelState = state.sorting.sortModel

                                    return state
                                }}
                                initialState={{
                                    pinnedColumns: {right: ['Notes', 'Delete']},
                                    sorting: {
                                        sortModel: gridSortModel
                                    }
                                }}
                                onRowClick={handleRowClick}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                selectionModel={selectionModel}
                                components={{
                                    Toolbar: CustomGridToolbar,
                                }}
                            />
                            </Box>
                            {/*</div>*/}
                        </Grid>
                    </Grid>
                </>
            }
        </div>
    );
}
export default ListingPage
