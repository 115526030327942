import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface workAuthorizationStatusList {
    statusid: number
    statuscode: string
}

export interface  WorkAuthorizationsDropdowns {
    statuslist: workAuthorizationStatusList[]
}

export const WorkAuthorizationsDropdownsDefaults = {
    statuslist: []
}

export const useFetchWADropdowns =  () => {
    return useQuery<WorkAuthorizationsDropdowns>(
        ['workorderdropdowns'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            placeholderData: WorkAuthorizationsDropdownsDefaults,
            keepPreviousData: false
        }
    )
}

export const globalFetcher = async () => {
    const { data } = await axios.get('/workauthorizationdropdowns')
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as WorkAuthorizationsDropdowns
}
