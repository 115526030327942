import CalendarPage from "../DivisionTabPage/Calendar/CalendarPage";
import React from "react";
import {useParams} from "react-router";
import styles from "./CalendarDispatchPage.module.css";
import {Grid} from "@mui/material";
import {Typography} from "@material-ui/core";
import AppBar from "@mui/material/AppBar";


interface Params {
    calendarGuid: string
}

const CalendarDispatchPage = (params) => {
    const { calendarGuid } = useParams<Params>()
    const [title, setTitle] = React.useState('Calendar');
    return (
        <Grid container>
            <AppBar position="static" color="default">
                <Typography
                    variant="h6"
                    color="primary"
                    align="center"
                    component="div">
                    {title}
                </Typography>
            <CalendarPage
                entityId={null}
                calendarGuid={calendarGuid}
                setTitle={setTitle}
            />
            </AppBar>
        </Grid>
    )
}
export default CalendarDispatchPage