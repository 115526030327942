import React, {useEffect, useMemo, useState} from 'react'
import {useFieldArray, useForm} from 'react-hook-form'
// import MultiSelect from  'react-multiple-select-dropdown-lite'
// import  'react-multiple-select-dropdown-lite/dist/index.css'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQueryClient} from 'react-query'


import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import styles from './Schedules.module.css'

import TextInput from "../../../components/common/inputs/TextInput";
import SelectInput from "../../../components/common/inputs/SelectInput";
import DateTimeInput from "../../../components/common/inputs/DateTimeInput";
import {useFetchScheduleFields, SchedeuleFieldsDataObject, useUpdateScheduleFields} from "../../../queries/useScheduleFields";
import {FormControl, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {Skeleton} from "@mui/lab";
import axios from "axios";
import MaskedInput from "../../../components/common/inputs/MaskedInput";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
// import dayjs from "dayjs";
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc'
import MultiSelectInput from "../../../components/common/inputs/MultiSelectInput";
import DateInput from "../../../components/common/inputs/DateInput";
import TimeInput from "../../../components/common/inputs/TimeInput";
import LoadingBackdrop from '../../../components/common/LoadingBackdrop'
import DayJSUtils from "@date-io/dayjs";
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import AutocompleteSelect from "../../../components/common/inputs/AutoCompleteSelectInput/AutocompleteSelect"
import { QueryClient } from 'react-query';

interface FormData {
    readonly: boolean
    canedit: boolean
    candelete: boolean
    scheduleid: number
    schedulenumber: number
    scheduledivisionid: number
    scheduledivision: string
    scheduletemplateid: number
    scheduletemplate: string
    scheduledivisionwoid: number
    schedulestatusid: number
    schedulestatus: string
    schedulestartdate: string
    scheduleenddate: string
    scheduleworklocation: string
    schedulegeneralforemanid: number
    schedulegeneralforeman: string
    scheduleforemanid: number
    scheduleforeman: string
    scheduleaddeddate: string
    scheduleactive: boolean
    notestypeid: number
    notestitle: string
    noteslinkid: number
    notescount: number
}

export const SchedulesForm = ({ isOpen, setIsOpen, gridParams, scheduleDropdowns, reloadForm, isClone, woid }) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };  
    const queryClient = useQueryClient()


    const formMethods = useForm()
    const {setValue, watch, getValues, control} = formMethods
    const title = isClone ? "Clone Schedule - " + gridParams?.schedulenumber + ' WO# ' + gridParams?.scheduledivisionwoid : gridParams ? "Edit Schedule - " + gridParams?.schedulenumber + ' WO# ' + gridParams?.scheduledivisionwoid : "Create Schedule"
    const {reset} = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateSchedulesFields = useUpdateScheduleFields()
    const [formLoading, setFormLoading] = useState<boolean>(false)
    const [templateActiveSelect, setTemplateActiveSelect] = React.useState<string>("")
    const [divisionActiveSelect, setDivisionActiveSelect] = React.useState<string>("")
    const [templateActiveSelectId, setTemplateActiveSelectId] = React.useState<number>(0)
    const [divisionActiveSelectId, setDivisionActiveSelectId] = React.useState<number>(0)
    const [fieldsData, setFieldsData] = React.useState<SchedeuleFieldsDataObject>()
    const [moneyValue, setMoneyValue] = React.useState()
    const [divisionOptions, setDivisionOptions] = React.useState()
    const [templateOptions, setTemplateOptions] = React.useState()
    const [canEditTemplate, setCanEditTemplate] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [submitValues, setSubmitValues] = useState<{ schedulefieldid: number, value: {lookup: string, lookupid: number, lookupheaderid: number, uniqueid: string}[]}[]>([])
    const [dateTimeValues, setDateTimeValues] = useState<{ schedulefieldid: number, dateTimeValue: Dayjs }[]>([])
    const [currencyValues, setCurrencyValues] = useState<{ schedulefieldid: number, currencyValue: number }[]>([])
    const [formSchedData, setFormSchedData] = useState([])

    const handleDateTimeChange = (newValue: Dayjs | null, schedulefieldid: number) => {
        const updatedSubmit = dateTimeValues.filter(val => (val.schedulefieldid !== schedulefieldid))
        const updateDates = [...updatedSubmit, { schedulefieldid: schedulefieldid,
            dateTimeValue: newValue }]
        setDateTimeValues(updateDates)
    }

    const handleChangeTime = (e, schedulefieldid: number) => {
        // console.log(e.target.value)
        const updatedSubmit = dateTimeValues.filter(val => (val.schedulefieldid !== schedulefieldid))
        const updateDates = [...updatedSubmit, { schedulefieldid: schedulefieldid,
            dateTimeValue: e.target.value }]
            // dateTimeValue: dayjs('2022-09-10T' + e.target.value+ 'z', 'YYYY-MM-DDTHH:mmz') }]
        // console.log(updateDates)
        setDateTimeValues(updateDates)
    }

    const getTimeValue = (schedulefieldid: number) => {
        const dates = dateTimeValues.filter((e) => e.schedulefieldid === schedulefieldid)
        if (dates && dates.length > 0) {
            return dates[0].dateTimeValue
            // return dayjs(dates[0].dateTimeValue).format('HH:mm').toString()
        }
        else {
            return null
        }
    }

    const getDateTimeValue = (schedulefieldid: number) => {
        const dates = dateTimeValues.filter((e) => e.schedulefieldid === schedulefieldid)
        if (dates && dates.length > 0) {
            return dates[0].dateTimeValue
            // return dayjs(dates[0].dateTimeValue).format('HH:mm').toString()
        }
        else {
            return null
        }
    }

    const handleCurrencyChange = (newValue: number | null, schedulefieldid: number) => {
        const updatedSubmit = currencyValues.filter(val => (val.schedulefieldid !== schedulefieldid))
        const updateCurrency = [...updatedSubmit, { schedulefieldid: schedulefieldid,
            currencyValue: newValue }]
        setCurrencyValues(updateCurrency)
    }

    const getCurrencyValue = (schedulefieldid: number) => {
        const currency = currencyValues.filter((e) => e.schedulefieldid === schedulefieldid)
        if (currency && currency.length > 0) {
            return currency[0].currencyValue
        }
        else {
            return null
        }
    }

    useEffect(() => {
        if (fieldsData && fieldsData?.schedulefieldslist) {
            var arraySubmitted = []
            fieldsData.schedulefieldslist.forEach(schedField => {
                if (schedField.schedulefieldcontrol === 7) {
                    arraySubmitted.push({schedulefieldid: schedField.schedulefieldid, value: getDefaultsForSelect(schedField.schedulefieldid, schedField.schedulefieldheaderid)})
                }
            })
            // console.log(arraySubmitted)
            setSubmitValues(arraySubmitted)
        }
    }, [fieldsData])


    useEffect(() => {
        // console.log('reloadForm')
        // setFormSchedData([])
        setDivisionActiveSelect('')
        setDivisionActiveSelectId(0)
        setTemplateActiveSelect('')
        setTemplateActiveSelectId(0)

        // Set default division and template if none are set
        getDivisionOptions()
        var divisionName = ''
        var divisionId = 0
        if (gridParams && gridParams.scheduledivision) {
            divisionName = gridParams.scheduledivision
            const division = scheduleDropdowns?.divisionlist?.filter((e) => e.divisionname === divisionName)

            divisionName = division[0].divisionname
            divisionId = division[0].divisionid
            setDivisionActiveSelect(division[0].divisionname)
            setDivisionActiveSelectId(division[0].divisionid)
        }
        else if (scheduleDropdowns && scheduleDropdowns?.divisionlist && scheduleDropdowns?.divisionlist?.length > 0) {
            divisionName = scheduleDropdowns.divisionlist[0].divisionname
            divisionId = scheduleDropdowns.divisionlist[0].divisionid

            setDivisionActiveSelect(divisionName)
            setDivisionActiveSelectId(divisionId)
        }

        getTemplateOptions(divisionName, divisionId)
        var templateName;
        var templateid;
        if (gridParams && gridParams.scheduletemplate) {
            const schedules = scheduleDropdowns?.scheduletemplatelist?.filter((e) => e.scheduletemplate === gridParams.scheduletemplate)
            templateName = schedules[0].scheduletemplate
            templateid = schedules[0].scheduletemplateid
            setTemplateActiveSelect(templateName)
            setTemplateActiveSelectId(templateid)
        }
        else if (scheduleDropdowns && scheduleDropdowns?.scheduletemplatelist) {
            const schedules = scheduleDropdowns?.scheduletemplatelist?.filter((e) => (!e.hasOwnProperty('divisionid') || e.divisionid === divisionId))

            if (schedules && schedules.length > 0) {
                templateName = schedules[0].scheduletemplate
                templateid = schedules[0].scheduletemplateid

                setTemplateActiveSelect(templateName)
                setTemplateActiveSelectId(templateid)
            }
        }
        if (divisionId >= 0 && templateid >= 0) {
            updateScheduleFieldsData(divisionId, templateid)
        }
    }, [reloadForm])

    const updateScheduleFieldsData = (divisionId: number, templateId: number) => {
        if (divisionId >= 0 && templateId >= 0) {
            setFormLoading(true)
            var schedFieldsParams = {
                templateid: templateId,
                divisionid: divisionId
            }
            if (gridParams && gridParams?.scheduleid) {
                schedFieldsParams['scheduleid'] = gridParams?.scheduleid
            }

            axios.post('/schedulefields', schedFieldsParams
            ).then((response) => {

                const dataJson = JSON.parse(response.data.jsonString)
                setFieldsData(dataJson)

                var dataObj = []
                var dateObj = []
                var currencyObj = []
                // var textObj = []
                // console.log(dataJson)
                if (dataJson && dataJson?.schedulefieldslist) {
                    dataJson.schedulefieldslist.map(schedField => {
                        const field = 'schedulefieldvalue' + schedField.schedulefieldcontrol.toString()
                        // dataObj[schedField.schedulefieldid] = schedField[field]
                        if (schedField[field]) {
                            setValue(schedField.schedulefieldid.toString(), schedField[field])
                        }
                        else {
                            // Dan what should I set the defaults
                            if (schedField.schedulefieldcontrol === 5) {
                                setValue(schedField.schedulefieldid.toString(),"")
                            }
                            else if (schedField.schedulefieldcontrol === 2) {
                                setValue(schedField.schedulefieldid.toString(),"")
                            }
                        }
                        
                        if (schedField.schedulefieldcontrol === 9) {
                            dateObj.push({
                                schedulefieldid: schedField.schedulefieldid,
                                dateTimeValue: schedField[field]
                            })
                        }
                        else if (schedField.schedulefieldcontrol === 6) {
                            const dateField = schedField[field] ? dayjs(schedField[field]) : null
                            dateObj.push({
                                schedulefieldid: schedField.schedulefieldid,
                                dateTimeValue: dateField
                            })
                        }

                        if (schedField.schedulefieldcontrol === 4) {
                            currencyObj.push({
                                schedulefieldid: schedField.schedulefieldid,
                                currencyValue: schedField[field]
                            })
                        }

                    })
                }
                setDateTimeValues(dateObj)
                setCurrencyValues(currencyObj)
                setFormLoading(false)
            })
        }
    }

    const onSubmit = async (data: FormData) => {
        setIsLoading(true)
        // console.log(fieldsData)
        var schedulesData = []
        if (fieldsData && fieldsData?.schedulefieldslist) {
            fieldsData.schedulefieldslist.forEach(schedField => {
                if (data[schedField.schedulefieldid] || (schedField.schedulefieldcontrol === 4 || schedField.schedulefieldcontrol === 1 || schedField.schedulefieldcontrol === 6 || schedField.schedulefieldcontrol === 7 || schedField.schedulefieldcontrol === 9)) {
                    var dataToSave = data[schedField.schedulefieldid]
                    if ((schedField.schedulefieldcontrol === 1 || schedField.schedulefieldcontrol === 2 || schedField.schedulefieldcontrol === 3) && dataToSave.toString().length === 0) {
                        console.log('fail -  ' + dataToSave)
                    }
                    else {
                        if (schedField.schedulefieldcontrol === 6) {
                            const updatedSubmit = dateTimeValues.filter(val => (val.schedulefieldid === schedField.schedulefieldid))
                            dataToSave = dayjs(updatedSubmit[0].dateTimeValue, "YYYY-MM-DD")
                        }
                        else  if (schedField.schedulefieldcontrol === 4) {
                            const updatedSubmit = currencyValues.filter(val => (val.schedulefieldid === schedField.schedulefieldid))
                            dataToSave = 0
                            if (updatedSubmit && updatedSubmit.length > 0 && Number(updatedSubmit[0].currencyValue) >= 0) {
                                dataToSave = Number(updatedSubmit[0].currencyValue)
                            }
                        }
                        else if (schedField.schedulefieldcontrol === 9) {
                            // console.log(dateTimeValues)
                            const updatedSubmit = dateTimeValues.filter(val => (val.schedulefieldid === schedField.schedulefieldid))
                            // console.log(updatedSubmit[0].dateTimeValue)
                            // console.log(dayjs(updatedSubmit[0].dateTimeValue, "HH:mmZ").toString())
                            // dataToSave = dayjs(updatedSubmit[0].dateTimeValue, "hh:mm")
                            dataToSave = updatedSubmit[0].dateTimeValue
                            // console.log(schedField.schedulefieldid)
                            // console.log(dataToSave)
                        }
                        else if(schedField.schedulefieldcontrol === 7) {
                            const submittedData = submitValues.filter(val => (val.schedulefieldid === schedField.schedulefieldid))
                            if (submittedData && submittedData.length > 0) {
                                dataToSave = submittedData[0].value.map(dataItems => {
                                    return dataItems.lookupid
                                }).toString()
                            }
                            else {
                                dataToSave = ''
                            }
                        }

                        const keySched = 'schedulefieldvalue' + schedField.schedulefieldcontrol
                        var newScheduleRec = {
                            'schedulefieldid': schedField.schedulefieldid
                        }

                        newScheduleRec[keySched] = dataToSave
                        schedulesData.push(newScheduleRec)
                    }
                }
                // else {
                //     console.log('Failure')
                //     console.log(schedField.schedulefieldcontrol)
                //     console.log(schedField.schedulefieldid)
                //     console.log(data[schedField.schedulefieldid])
                //     console.log('end Failure')
                // }
            })
        }
        var finalData = {
            'divisionid':divisionActiveSelectId,
            'templateid':templateActiveSelectId,
            'schedulefieldinfo': schedulesData
        }
        if (gridParams?.scheduleid > 0 && !isClone) {
            finalData['scheduleid'] = gridParams?.scheduleid
        }
        console.log(woid)
        if (woid) {
            finalData['woid'] = woid
        }

        const {data: response} = await updateSchedulesFields.mutateAsync(finalData)
        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        } else {
            queryClient.invalidateQueries(['gdwodocuments'])
            setIsOpen(false)
        }
        setIsLoading(false)
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const handleDivisionChange = (event: SelectChangeEvent) => {
        const schedules = scheduleDropdowns?.divisionlist?.filter((e) => e.divisionname === event.target.value as string)

        if (schedules && schedules.length > 0) {
            setDivisionActiveSelect(schedules[0].divisionname)
            setDivisionActiveSelectId(schedules[0].divisionid)
            setCanEditTemplate(true)
            getTemplateOptions(event.target.value, schedules[0].divisionid)
        }
    }

    const handleTemplateChange = (event: SelectChangeEvent) => {
        const schedules = scheduleDropdowns?.scheduletemplatelist?.filter((e) => e.scheduletemplate === (event.target.value as string))
        if (schedules && schedules[0]) {
            setTemplateActiveSelectId(schedules[0].scheduletemplateid)
            setTemplateActiveSelect(schedules[0].scheduletemplate)
            updateScheduleFieldsData(divisionActiveSelectId, schedules[0].scheduletemplateid)
        }
    }

    const getDivisionOptions = () => {
        setDivisionOptions(scheduleDropdowns?.divisionlist?.map(item => (
            <MenuItem key={item.divisionid} value={item.divisionname}>
                {item.divisionname}
            </MenuItem>
        )))
    }

    const getTemplateOptions = (divisionName, divisionId) => {
        // console.log(divisionName)
        // console.log(divisionId)
        if (divisionName && divisionName.length > 0) {
            const lookupOptions = scheduleDropdowns?.scheduletemplatelist?.filter((item) => (!item.hasOwnProperty('divisionid') || item.divisionname === divisionName))
            if (lookupOptions && lookupOptions.length > 0) {
                setTemplateActiveSelect(lookupOptions[0].scheduletemplate)
                setTemplateActiveSelectId(lookupOptions[0].scheduletemplateid)
                setTemplateOptions(lookupOptions?.map(item => (
                    <MenuItem key={item.scheduletemplateid} value={item.scheduletemplate}>
                        {item.scheduletemplate}
                    </MenuItem>
                )))
                updateScheduleFieldsData(divisionId, lookupOptions[0].scheduletemplateid)
            }
            else {
                setTemplateOptions(null)
            }
        }
        else {
            setTemplateOptions(null)
        }
    }

    const getOptions = (optionTOLookup) => {
        if (fieldsData && fieldsData?.schedulelookuplist) {
            const lookupOptions = fieldsData?.schedulelookuplist.filter((item) => item.lookupheaderid === Number(optionTOLookup))

            return lookupOptions.map(options => (
                {label: options.lookup, value: options.lookupid}
            ))
        }
    }

    const getDefaultsForSelect = (schedulefieldid, schedulefieldheaderid) => {
        var retVal = []
        if (fieldsData && fieldsData?.schedulelookuplist) {
            // if (schedulefieldid === 171 && schedulefieldheaderid === 146) {
            //     // console.log(fieldsData?.schedulelookuplist)
            //     const scheduleFieldData = fieldsData?.schedulefieldslist.filter(field => field.schedulefieldid === schedulefieldid && field.schedulefieldheaderid === schedulefieldheaderid)
            //     console.log(scheduleFieldData)
            // }

            fieldsData?.schedulefieldslist.filter(field => field.schedulefieldid === schedulefieldid && field.schedulefieldheaderid === schedulefieldheaderid).forEach((schedField, index, data) => {
                const field = 'schedulefieldvalue' + schedField.schedulefieldcontrol.toString()
                if (schedField[field] && schedField[field].length > 0) {
                    // console.log(schedField[field])
                    // console.log(fieldsData?.schedulelookuplist)
                    schedField[field].map(fieldItems => {
                        const lookupOptions = fieldsData?.schedulelookuplist.filter((item) => item.lookupid === Number(fieldItems) && item.lookupheaderid === schedulefieldheaderid)
                        if (lookupOptions && lookupOptions.length > 0) {
                            retVal.push({
                                lookup: lookupOptions[0].lookup,
                                lookupid: lookupOptions[0].lookupid,
                                schedulefieldid: schedulefieldid
                            })
                        }
                    })
                }
            })
        }
        return retVal
    }

    const getOptionsForSelect = (keyValue, schedulefieldheaderid) => {
        if (fieldsData && fieldsData?.schedulelookuplist) {
            const lookupOptions = fieldsData?.schedulelookuplist.filter((item) => (item.lookupheaderid === Number(schedulefieldheaderid) && item.lookupactive === true))
            // if (schedulefieldheaderid === 146) {
            //     console.log(lookupOptions)
            // }
            return lookupOptions
        }
    }

    const handleAmountChange = (event) => {
        // setAmountChange(event.floatValue)
    }

    const getFormControl = () => {

        // 1 - boolean
        // 2 - integer Lookup Value @unicef/material-ui-currency-textfield --save
        // 3 - integer
        // 4 - money (double)
        // 5 - text
        // 6 - date
        // 7 - text ([1,3,5] multi lookup values
        // 8 - Datetime
        // 9 - Time

        if (fieldsData && fieldsData?.schedulefieldslist) {
            const formFields = fieldsData.schedulefieldslist.map((schedField: any) => {
                if (schedField.schedulefieldcontrol === 7 && schedField.schedulefieldheaderid === 146) {
                    console.log(schedField)
                }
                return (
                <>
                    {schedField.schedulefieldcontrol === 1 &&
                        <SelectInput
                            name={schedField.schedulefieldid.toString()}
                            label={schedField.schedulefieldlabel}
                            options={[
                                {label: 'Yes', value: true},
                                {label: 'No', value: false}
                            ]}
                            required={schedField.schedulefieldrequired}
                        />
                    }
                    {schedField.schedulefieldcontrol === 2 &&
                        <AutocompleteSelect
                            name={schedField.schedulefieldid.toString()}
                            label={schedField.schedulefieldlabel}
                            options={getOptions(schedField.schedulefieldheaderid)}
                            required={schedField.schedulefieldrequired}
                        />
                    }
                    {schedField.schedulefieldcontrol === 3 &&
                        <MaskedInput
                            name={schedField.schedulefieldid.toString()}
                            label={schedField.schedulefieldlabel}
                            onValueChange={handleAmountChange}
                            thousandSeparator={false}
                            thousandsGroupStyle={""}
                            prefix={""}
                            required={schedField.schedulefieldrequired}
                        />
                    }
                    {schedField.schedulefieldcontrol === 4 &&
                        <CurrencyTextField
                            label={schedField.schedulefieldlabel}
                            name={schedField.schedulefieldid.toString()}
                            // variant="outline"
                            value={getCurrencyValue(schedField.schedulefieldid)}
                            currencySymbol="$"
                            outputFormat="string"
                            textAlign={"left"}
                            onChange={(event, value) => handleCurrencyChange(value, schedField.schedulefieldid)}
                        />
                    }
                    {schedField.schedulefieldcontrol === 5 &&
                        <TextInput
                            name={schedField.schedulefieldid.toString()}
                            label={schedField.schedulefieldlabel}
                            defaultValue={""}
                            rules={{
                                required: ' is required'
                            }}
                            required={schedField.schedulefieldrequired}
                        />
                    }
                    {schedField.schedulefieldcontrol === 6 &&
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker key={schedField.schedulefieldid.toString()}
                                        label={schedField.schedulefieldlabel} value={getDateTimeValue(schedField.schedulefieldid)}
                                        disableCloseOnSelect={false}
                                        onChange={(e: Dayjs | null) => { handleDateTimeChange(e, schedField.schedulefieldid)}}
                                        renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                    }
                    {schedField.schedulefieldcontrol === 7 &&
                        <FormControl fullWidth>
                            {/*<InputLabel id="demo-multiple-checkbox-label">{schedField.schedulefieldlabel}</InputLabel>*/}
                            <MultiSelectInput
                                schedulefieldid={schedField.schedulefieldid}
                                label={schedField.schedulefieldlabel}
                                defaults={getDefaultsForSelect(schedField.schedulefieldid, schedField.schedulefieldheaderid)}
                                lookupOptions={getOptionsForSelect(schedField.schedulefieldid, schedField.schedulefieldheaderid)}
                                setSubmitValues={setSubmitValues}
                                submitValues={submitValues}
                            />
                        </FormControl>
                    }
                    {schedField.schedulefieldcontrol === 8 &&
                        <DateTimeInput name={schedField.schedulefieldid.toString()}
                                       label={schedField.schedulefieldlabel}
                                       defaultValue={null}
                                       required={schedField.schedulefieldrequired}/>
                    }
                    {schedField.schedulefieldcontrol === 9 &&
                        <>
                            {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                            {/*    <TimePicker key={schedField.schedulefieldid.toString()}*/}
                            {/*                label={schedField.schedulefieldlabel}*/}
                            {/*                value={getDateTimeValue(schedField.schedulefieldid)}*/}
                            {/*                disableCloseOnSelect={false}*/}
                            {/*                ampmInClock={true}*/}
                            {/*                inputFormat={'hh:mm A'}*/}
                            {/*                onChange={(e: Dayjs | null) => { handleDateTimeChange(e, schedField.schedulefieldid)}}*/}
                            {/*                renderInput={(params) => <TextField {...params} />}*/}
                            {/*    />*/}
                            {/*</LocalizationProvider>*/}
                            <TextField
                                id={schedField.schedulefieldid.toString()}
                                label={schedField.schedulefieldlabel}
                                type="time"
                                // defaultValue="16:30"
                                defaultValue=""
                                value={getTimeValue(schedField.schedulefieldid)}
                                onChange={(event) => {handleChangeTime(event, schedField.schedulefieldid)}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </>

                    }
                </>
                )});
            return formFields;
        }
    }

    return (
        <Dialog fullWidth={true}
                open={isOpen}
                maxWidth={'xl'}
                className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            <LoadingBackdrop open={isLoading} />
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>
                            {gridParams === null &&
                                <div>Please select division and then a template to continue.</div>
                            }
                            <div className={styles.multipleInputContainer} >
                                <FormControl fullWidth>
                                    <InputLabel>Division*</InputLabel>
                                    <Select
                                        name="scheduledivision"
                                        label="Division"
                                        value={divisionActiveSelect}
                                        size={'small'}
                                        required={true}
                                        variant="outlined"
                                        disabled={gridParams !== null}
                                        onChange={handleDivisionChange}
                                    >
                                        {divisionOptions}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel>Template*</InputLabel>
                                    <Select
                                        label="Template"
                                        value={templateActiveSelect}
                                        size={'small'}
                                        required={true}
                                        variant="outlined"
                                        disabled={gridParams !== null}
                                        onChange={handleTemplateChange}
                                    >
                                        {templateOptions}
                                    </Select>
                                </FormControl>
                                {formLoading &&
                                    <>
                                        <div className={styles.multipleInputContainer}>
                                            <>
                                                <Skeleton height={50} animation="wave"/>
                                                <Skeleton height={50} animation="wave"/>
                                            </>
                                        </div>
                                        <div className={styles.multipleInputContainer}>
                                            <>
                                                <Skeleton height={50} animation="wave"/>
                                                <Skeleton height={50} animation="wave"/>
                                            </>
                                        </div>
                                        <div className={styles.multipleInputContainer}>
                                            <>
                                                <Skeleton height={50} animation="wave"/>
                                                <Skeleton height={50} animation="wave"/>
                                            </>
                                        </div>
                                        <div className={styles.multipleInputContainer}>
                                            <>
                                                <Skeleton height={50} animation="wave"/>
                                                <Skeleton height={50} animation="wave"/>
                                            </>
                                        </div>
                                        <div className={styles.multipleInputContainer}>
                                            <>
                                                <Skeleton height={50} animation="wave"/>
                                                <Skeleton height={50} animation="wave"/>
                                            </>
                                        </div>
                                        <div className={styles.multipleInputContainer}>
                                            <>
                                                <Skeleton height={50} animation="wave"/>
                                                <Skeleton height={50} animation="wave"/>
                                            </>
                                        </div>
                                    </>
                                }
                                {!formLoading &&
                                    getFormControl()
                                }
                            </div>
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
