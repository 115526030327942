import React, {useEffect, useMemo, useState} from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { ScheduleLookupOptions } from '../../../../../src/queries/useScheduleFields';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from "@mui/material";

const useTextStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 100
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 100,
  },
}));

type Props = {
  scheduleid: number
  schedulefieldid: number
  label: string
  defaults: string[]
  lookupOptions: ScheduleLookupOptions[]
  setSubmitValues: Function
  submitValues: { scheduleid: number, schedulefieldid: number, value: {lookup: string, lookupid: number, lookupheaderid: number}[]}[]
}

const SelectInput = ({
  scheduleid,
  schedulefieldid,
  label,
  defaults,
  lookupOptions,
  setSubmitValues,
  submitValues
}: Props) => {
  const [selectedValues, setSelectedValues] = useState([])

  const getDefaults = useMemo(
      () =>
          defaults,
      [defaults, scheduleid, schedulefieldid]
  )

  useEffect(() => {
    if (getDefaults && getDefaults.length > 0 && selectedValues.length === 0) {
      console.log(getDefaults)
      setSelectedValues(getDefaults)
      handleChange(null, getDefaults)
    }
    return () => {
      setSelectedValues([])
    }
  }, [])

  const getOptions = () => {
    if (lookupOptions && lookupOptions.length > 0) {
      return lookupOptions
    }
    else {
      return []
    }
  }

  const handleChange = (event, values) => {

    const updatedSubmit = submitValues.filter(val => (val.schedulefieldid !== schedulefieldid))
    const vals = values.map(item => {
      if (lookupOptions && lookupOptions.length > 0) {
        return lookupOptions.filter(options => (options.lookup === item.lookup))[0]
      }
    })

    // console.log(vals)

    setSubmitValues([...updatedSubmit, { scheduleid: scheduleid, schedulefieldid: schedulefieldid, value: vals}])

    setSelectedValues(values)
  }

  const getTagDescription = (value: any[]) => {
    if (value && value.length > 0) {
      // console.log(value)
      // var displayString = ''
      // if (value && value.length === 1) {
      //   displayString = value[0].lookup
      // }
      // else if (value && value.length > 1) {
      //   value.forEach(val => {
      //     if (displayString.length > 0) {
      //       displayString += ', '
      //     }
      //     displayString += val.lookup
      //   })
      // }

      var additionalCount = ''
      if (selectedValues.length > 1) {
        additionalCount = ' (+' + (selectedValues.length - 1) + ')'
      }
      const lookupObj = value[0]
      return lookupObj.lookup.substring(0,12) + '... ' + additionalCount
      // return displayString
    }
    else {
      return 'None'
    }
  }

  return (
      <Autocomplete
          style={{ width: "100%", height: "100%"}}
          multiple
          id="checkboxes-tags-demo"
          options={getOptions()}
          value={selectedValues}
          isOptionEqualToValue={(option: any, value: any) => {
            // console.log(option)
            // console.log(value)
            return option.uniqueid === value.uniqueid
          }}
          // getLimitTagsText={}
          onChange={handleChange}
          disableCloseOnSelect
          getOptionLabel={(option: any) => option.lookup}
          renderTags={(value: string[], getTagProps) => <div style={{fontSize: '13px'}}>{getTagDescription(value)}</div>}
          renderInput={(params) => (
              <TextField
                  {...params}
                  size='small'
                  style={{ width: "100%", height: "100%"}}
                  margin="none"
                  variant="outlined"
                  hiddenLabel
                  label={label} placeholder=""
              />
          )}
      />
  )
}

export default SelectInput
