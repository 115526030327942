import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'
import HookForm from '../../../components/common/HookForm'
import SelectInput from "../../../components/common/inputs/SelectInput";
import Switch from "../../../components/common/inputs/Switch";
import { checkEndDateAfterStartDate, checkEndDateAfterStartDateorEqual  } from '../../../util/formValidation'

import DateInput from "../../../components/common/inputs/DateInput";
import styles from './BulkDispatchPage.module.css'
import {useFetchBulkDropDowns} from "../../../queries/useBulkDropDowns";
import {usePutBulkDispatch} from "../../../queries/useBulkForm";
import axios from "axios";
import BulkDispatchSelectGridForm from './BulkDispatchSelectGridForm'
import {
    Button,
    createTheme,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    Input,
    ListItemAvatar, MuiThemeProvider
} from "@material-ui/core";
interface FormData {
    entityid: number
    fromdate: string
    todate: string
    customerid: number
    staffid: number
    resend: boolean
}

export const BulkDispatchForm = (params) => {
    const formMethods = useForm<FormData>()
    const { setValue, watch, getValues } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const [amount, setAmount] = useState<number>(0) 
    const {data : bulkDropDowns} = useFetchBulkDropDowns()
    const putDispatch = usePutBulkDispatch()
    const [dispatchData, setdispatchData] = React.useState()
    const [open, setOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
  

    const onSubmit = async (data: FormData) => {
        var finalData = {
            entityid: params.entityid,
            fromdate: data.fromdate,
            todate: data.todate,

            customerid: data.customerid > 0 ? data.customerid : 0,
            staffid: data.staffid > 0 ? data.staffid : 0,
            resend: data.resend   
        }
        axios.post('bulkdispatch', finalData).then((response) => {
            const dataJson = JSON.parse(response.data.jsonString)
            console.log(dataJson)
            
            setdispatchData(dataJson.dispatchlist)
           

        }) .catch((error) => {
            // Handle error case
            console.error("Error during dispatch:", error);
           
          });

    }
    const handleClose = () => {
        setOpen(false);
      };
    const getEmployees = () => {
        if(bulkDropDowns){
            var returnlist =  bulkDropDowns.employeelist.map(item => {
                return { label: item.employeeinfo, value: item.employeeid }
            })
            returnlist.unshift({label: "ALL", value: 0})

            return returnlist
        } else {
            return []
        }
      
    }

    const getCustomers = () => {
        if(bulkDropDowns){
            var returnList = bulkDropDowns.customerlist.map(item => {
                return { label: item.customerinfo, value: item.customerid }
            })
            returnList.unshift({label: "ALL", value: 0})
        return returnList
        }else {
            return []
        }
    }


    return (
       <div>
          <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Dispatch Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
        <HookForm
            methods={formMethods}
            onSubmit={onSubmit}
            className={styles.itemsForm}
            formProps={{ id: 'items-form' }}>
            <div className={styles.multipleInputContainer}>
                <SelectInput
                    name="staffid"
                    label="Employee"
                    options={getEmployees()}
                    defaultValue='0'
                    
                />
               <SelectInput
                    name="customerid"
                    label="Customer"
                    options={getCustomers()}
                    defaultValue='0'
                    
                />
            </div>
            <div className={styles.multipleInputContainer}>
                <DateInput name="fromdate" label="From Date" defaultValue={new Date().toISOString()} required={true} />
                <DateInput name="todate" label="To Date" defaultValue={new Date().toISOString()} required={true} rules={{
            validate: (val) => checkEndDateAfterStartDateorEqual(getValues().fromdate, val)
          }}/>
            </div>
           Resend?
            <Switch name='resend'/>
            <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
      
        </HookForm>
         {dispatchData && <BulkDispatchSelectGridForm ddata={dispatchData} setDialogMessage={setDialogMessage} setOpen={setOpen} />}  
         </div>
    )
}