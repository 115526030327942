import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  POAmounts {
    poaid: number,
    poaline: number,
    poaworkcategoryids: number[],
    poadescription: string,
    poaamount: number,
    poacomment: string,
    poaexpirationdate: string
}

export const POAmountsDefaults = {
    poaid: 0,
    poaline: 0,
    poaworkcategoryids: [],
    poadescription: '',
    poaamount: 0,
    poacomment: '',
    poaexpirationdate: ''
}

export const useFetchPOAmounts =  (polid: number) => {
    return useQuery<POAmounts[]>(
        ['poamounts', polid],
        () => globalFetcher(polid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdatePOAmounts = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`poamounts`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['poamounts'])
        }
    })
}

export const useDeletePOAmounts = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`poamounts`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['poamounts'])
        }
    })
}

export const globalFetcher = async (id: number) => {
    const { data } = await axios.post('/poamounts', {
        polid: id
    })

    const parsedData = JSON.parse(data.jsonString)
    return parsedData.poamounts as POAmounts[]
}
