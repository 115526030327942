import React, {useEffect, useMemo, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import HookForm from '../../../components/common/HookForm'
import styles from './WOFormTabPage.module.css'
import TextInput from "../../../components/common/inputs/TextInput";
import SelectInput from "../../../components/common/inputs/SelectInput";
import {useUpdateUsers} from "../../../queries/useUsersMaint";
import {statesArray} from "../../../util/formValidation";
import ChipsList from "../../../components/common/inputs/ChipsList";
import DateTimeInput from "../../../components/common/inputs/DateTimeInput";
import {useUpdateWorkOrder} from "../../../queries/useWorkOrder";
import WorkOrderPage from "../WorkOrderPage";
import DateInput from "../../../components/common/inputs/DateInput";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import AutocompleteSelect from '../../../components/common/inputs/AutoCompleteSelectInput/AutocompleteSelect'

interface FormData {
    woid: number
    woentityid: number
    woentity: string
    wodirectbill: boolean
    wocustomercodeid: number
    wocustomercode: string
    wocustomerworkorder: string
    wocustomerrelease: string
    wopolid: number
    wocustomerpo: string
    wofoldername: string
    wocustomerdivisionid: number
    wocustomerdivision: string
    woaddress: string
    woaddress2: string
    wocity: string
    wostate: string
    wozip: string
    wocustomerworktype: string
    woworktypeids: number[]
    woworktype: string
    wodivisionids: number[]
    wodivision: string
    wostatusid: number
    wostatus: string
    wodatepackagereceived: string
    wodateworeceived: string
    wodatecommitted: string
    wodatejobready: string
    woprojectmanagerid: string,
    woprojectmanager: string
    wopricingmethodid: string
    wopricingmethod: string
    woaddeduser: string
    woaddeduserkey: number
    woaddeddate: string
    woupdateduser: string
    woupdateduserkey: number
    woupdateddate: string
    woactive: boolean
    wofirezoneid: number
    woinsurancecategoryid: number
}

export const WOFormTabPage = ({ workOrderObj, woDropDownData }) => {
    const formMethods = useForm<FormData>()
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const [divisions, setDivisions] = useState([])
    const [woState, setWoState] = useState<{ value: any; label: string }>()
    const updateWorkOrder = useUpdateWorkOrder()
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    const [customerPOOptions, setCustomerPOOptions] = React.useState([])
    const [mainFieldsShouldBeDisabled, setMainFieldsShouldBeDisabled] = React.useState(true)
    
    useEffect(() => {
        let selectedWO = []
        if (workOrderObj && woDropDownData && (woDropDownData?.worktypelist || woDropDownData?.divisionlist)) {
            let selectedSub = []
            workOrderObj?.wodivisionids?.forEach(wtId => {
                const woToAdd = woDropDownData?.divisionlist?.filter((wtObject) =>  wtObject.divisionid === wtId )
                if (woToAdd && woToAdd.length > 0) {
                    selectedSub.push({value: woToAdd[0].divisionid, label: woToAdd[0].divisionname})
                }
            })
            setDivisions(selectedSub)

            let selectedwoState = {value: "", label:""}
            const selectedState = statesArray?.filter((st) => st.value === workOrderObj?.woState)
            if (selectedState.length > 0){
                selectedwoState = selectedState[0];
            }
            setWoState(selectedwoState)
        }

        reset({
            woid: (workOrderObj ? workOrderObj.woid : 0),
            woentityid: (workOrderObj ? workOrderObj.woentityid : 0),
            woentity: (workOrderObj ? workOrderObj.woentity : 0),
            wodirectbill: (workOrderObj ? workOrderObj.wodirectbill : false),
            wocustomercodeid: (workOrderObj ? workOrderObj.wocustomercodeid : 0),
            wocustomercode: (workOrderObj ? workOrderObj.wocustomercode : ""),
            wocustomerworkorder: (workOrderObj ? workOrderObj.wocustomerworkorder : ""),
            wocustomerrelease: (workOrderObj ? workOrderObj.wocustomerrelease : ""),
            wopolid: (workOrderObj ? workOrderObj.wopolid : 0),
            wocustomerpo: (workOrderObj ? workOrderObj.wocustomerpo : ""),
            wofoldername: (workOrderObj ? workOrderObj.wofoldername : ""),
            wocustomerdivisionid: (workOrderObj ? workOrderObj.wocustomerdivisionid : 0),
            wocustomerdivision: (workOrderObj ? workOrderObj.wocustomerdivision : ""),
            woaddress: (workOrderObj ? workOrderObj.woaddress : ""),
            woaddress2: (workOrderObj ? workOrderObj.woaddress2 : ""),
            wocity: (workOrderObj ? workOrderObj.wocity : ""),
            wostate: (workOrderObj ? workOrderObj.wostate : ""),
            wozip: (workOrderObj ? workOrderObj.wozip : ""),
            wocustomerworktype: (workOrderObj ? workOrderObj.wocustomerworktype : ""),
            woworktypeids: (workOrderObj ? workOrderObj.woworktypeids : []),
            woworktype: (workOrderObj ? workOrderObj.woworktype : ""),
            wodivisionids: (workOrderObj ? workOrderObj.wodivisionids : []),
            wodivision: (workOrderObj ? workOrderObj.wodivision : ""),
            wostatusid: (workOrderObj ? workOrderObj.wostatusid : 0),
            wostatus: (workOrderObj ? workOrderObj.wostatus : ""),
            wodatepackagereceived: (workOrderObj ? workOrderObj.wodatepackagereceived : null),
            wodateworeceived: (workOrderObj ? workOrderObj.wodateworeceived : null),
            wodatecommitted: (workOrderObj ? workOrderObj.wodatecommitted : null),
            wodatejobready: (workOrderObj ? workOrderObj.wodatejobready : null),
            woprojectmanagerid: (workOrderObj ? workOrderObj.woprojectmanagerid : ""),
            woprojectmanager: (workOrderObj ? workOrderObj.woprojectmanager : ""),
            wopricingmethodid: (workOrderObj ? workOrderObj.wopricingmethodid : null),
            wopricingmethod: (workOrderObj ? workOrderObj.wopricingmethod : ""),
            woaddeduser: (workOrderObj ? workOrderObj.woaddeduser : ""),
            woaddeduserkey: (workOrderObj ? workOrderObj.woaddeduserkey : 0),
            woaddeddate: (workOrderObj ? workOrderObj.woaddeddate : ""),
            woupdateduser: (workOrderObj ? workOrderObj.woupdateduser : ""),
            woupdateduserkey: (workOrderObj ? workOrderObj.woupdateduserkey : 0),
            woupdateddate: (workOrderObj ? workOrderObj.woupdateddate : ""),
            woactive: (workOrderObj ? workOrderObj.woactive : true),
            wofirezoneid: (workOrderObj ? workOrderObj.wofirezoneid : 0),
            woinsurancecategoryid: (workOrderObj ? workOrderObj.woinsurancecategoryid : 0),
        })

    }, [workOrderObj])

    useEffect(() => {
            getPOListOptions()
        }, [watch('wocustomercodeid'), workOrderObj]
    )

    useEffect(() => {
        const woStatusId = getValues('wostatusid')
        const woCustomerCodeID = getValues('wocustomercodeid')
        const status = woDropDownData?.statuslist?.find(item => item.statusid === woStatusId)
        const isEmergency = status.statuscode === 'Emergency'
        const isCustomerEmpty = woCustomerCodeID === -1 || woCustomerCodeID === null || woCustomerCodeID === undefined
        var disabled = true
        if (isEmergency && isCustomerEmpty) {
            disabled = false
        }

        setMainFieldsShouldBeDisabled(disabled)
    }, [watch('wostatusid')])

    const onSubmit = async (data: FormData) => {
        console.log(data)

        const divisionsToSave = divisions.map(div => {
            return div.value
        })

        var finalData = {
            wocustomercodeid: data.wocustomercodeid,
            wocustomercode: data.wocustomercode,
            wocustomerworkorder: data.wocustomerworkorder,
            wocustomerrelease: data.wocustomerrelease,
            wopolid: data.wopolid,
            wocustomerpo: data.wocustomerpo,
            wofoldername: data.wofoldername,
            wocustomerdivisionid: Number(data.wocustomerdivisionid),
            wocustomerdivision: data.wocustomerdivision,
            woaddress: data.woaddress,
            woaddress2: data.woaddress2,
            wocity: data.wocity,
            wostate: woState.value,
            wozip: data.wozip,
            wocustomerworktype: data.wocustomerworktype,
            wodivisionids: divisionsToSave,
            wodivision: getDivisions(divisionsToSave),
            wostatusid: data.wostatusid,
            wostatus: data.wostatus,
            wodatepackagereceived: data.wodatepackagereceived,
            wodateworeceived: data.wodateworeceived,
            wodatecommitted: data.wodatecommitted,
            wodatejobready: data.wodatejobready,
            woprojectmanagerid: data.woprojectmanagerid,
            woprojectmanager: data.woprojectmanager,
            wopricingmethodid: (data.wopricingmethodid && data.wopricingmethodid.length > 0 ? data.wopricingmethodid : null),
            wopricingmethod: data.wopricingmethod,
            woaddeduser: data.woaddeduser,
            woaddeduserkey: data.woaddeduserkey,
            woaddeddate: data.woaddeddate,
            woupdateduser: data.woupdateduser,
            woupdateduserkey: data.woupdateduserkey,
            woupdateddate: data.woupdateddate,
            woactive: data.woactive,
            wofirezoneid: Number(data.wofirezoneid),
            woinsurancecategoryid: Number(data.woinsurancecategoryid),
        }
        if (data.woid > 0) {
            finalData['woid'] = data.woid
        }
        const { data: response } = await updateWorkOrder.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setDialogMsg("Work order saved successfully!")
        }
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const getcustomerCodeOptions = () => {
        if (woDropDownData && woDropDownData?.custlist?.length > 0) {
            return woDropDownData?.custlist?.map(item => {
                return { label: item.custinfo, value: item.custid }
            })
        }
        else {
            return { label: '', value: '' }
        }
    }

    const getentityCodeOptions = () => {
        if (woDropDownData && woDropDownData?.entitylist?.length > 0) {
            return woDropDownData?.entitylist?.map(item => {
                return { label: item.entityname, value: item.entityid }
            })
        }
        else {
            return { label: '', value: '' }
        }
    }

    const getstatusListOptions = () => {
        if (woDropDownData && woDropDownData?.statuslist?.length > 0) {
            return woDropDownData?.statuslist?.map(item => {
                return { label: item.statuscode, value: item.statusid }
            })
        }
        else {
            return { label: '', value: '' }
        }
    }

    const getCustomerDivisionListOptions = () => {
        return woDropDownData?.customerdivisionlist?.map(item => {
            return { label: item.customerdivisioncode, value: item.customerdivisionid }
        })
    }

    const getPricingMethodListOptions = () => {
        return woDropDownData?.pricingmethodlist?.map(item => {
            return { label: item.pricingmethodcode, value: item.pricingmethodid }
        })
    }

    const getCustomerProjectManagerListOptions = () => {
        return woDropDownData?.customerprojectmanagerlist?.map(item => {
            return { label: item.customerprojectmanagername, value: item.customerprojectmanagerid }
        })
    }

    const getPOListOptions = () => {
        setCustomerPOOptions([])
        const poOptions = woDropDownData?.polist?.filter((item) => ((!item.hasOwnProperty('entityid') || !item.hasOwnProperty('custid')) || item.entityid === workOrderObj.woentityid && item.custid === watch('wocustomercodeid')))
        if (poOptions && poOptions.length > 0) {
            const poOptionsMap = poOptions?.map(item => {
                    return { label: item.polnumber, value: item.polid }
            })
            setCustomerPOOptions(poOptionsMap)
        }
    }
    const getFireZoneOptions = () => {
        return woDropDownData?.firezonelist?.map(item => {
            return { label: item.firezonecode, value: item.firezoneid }
        })
    }

    const getInsuranceCategoryOptions = () => {
        return woDropDownData?.insurancecategorylist?.map(item => {
            return { label: item.insurancecategorycode, value: item.insurancecategoryid }
        })
    }

    const getWorkTypesOptions = useMemo(
        () =>
            woDropDownData?.worktypelist?.map((item) => {
                return { label: item.worktypecode, value: item.worktypeid }
            }),
        [woDropDownData?.worktypelist]
    )

    // const getDivisionListOptions = useMemo(
    //     () =>
    //         woDropDownData?.divisionlist?.map((item) => {
    //             return { label: item.divisionname, value: item.divisionid }
    //         }),
    //     [woDropDownData?.divisionlist]
    // )

    const GetDivisionListOptions = (): { value: any; label: string }[] => {
        const divisionList = woDropDownData?.divisionlist?.map((item) => {
            return { label: item.divisionname, value: item.divisionid }
        })
        return divisionList
    }

    const getWorkType = (worktypeid) => {
        var strType = ''
        worktypeid.forEach((typeID) => {
            const value = woDropDownData?.worktypelist?.filter((e) => e.worktypeid === typeID)

            if (value && value.length > 0) {
                if (strType.length > 0) {
                    strType = strType + ', '
                }
                strType = strType + value[0].worktypecode
            }
        })

        return strType
    }

    const getDivisions = (divisionId) => {
        var strType = ''
        divisionId.forEach((typeID) => {
            const value = woDropDownData?.divisionlist?.filter((e) => e.divisionid === typeID)

            if (value && value.length > 0) {
                if (strType.length > 0) {
                    strType = strType + ', '
                }
                strType = strType + value[0].divisionname
            }
        })

        return strType
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <Box style={{ marginLeft: '20px', marginRight: '20px' }} sx={{ flexGrow: 1 }}>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'work-order-form' }}>
                    <div className={styles.multipleInputContainer}>
                        <SelectInput
                            name="woentityid"
                            label="Entity"
                            options={getentityCodeOptions()}
                            disabled={mainFieldsShouldBeDisabled}
                            required
                        />
                        <SelectInput
                            name="wocustomercodeid"
                            label="Customer"
                            options={getcustomerCodeOptions()}
                            disabled={mainFieldsShouldBeDisabled}
                            required
                        />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <AutocompleteSelect
                            name="wopolid"
                            label="Customer PO"
                            options={customerPOOptions}
                            required
                        />
                        <SelectInput
                            name="wocustomerdivisionid"
                            label="Customer Division"
                            options={getCustomerDivisionListOptions()}
                        />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <div className={styles.multipleInputContainer}>
                            <SelectInput
                                name="woactive"
                                label="Record Status"
                                options={[
                                    { label: 'Active', value: true },
                                    { label: 'Inactive', value: false }
                                ]}
                                required
                            />
                            <SelectInput
                                name="wostatusid"
                                label="Work Order Status"
                                options={getstatusListOptions()}
                                required
                            />
                        </div>
                        <div className={styles.multipleInputContainer}>
                            <TextInput
                                name="wocustomerworkorder"
                                label="Customer Work Order"
                                rules={{
                                    required: 'Customer Work Order is required',
                                    minLength: {
                                        value: 1,
                                        message: 'Must be at least 1 characters'
                                    },
                                    maxLength: {
                                        value: 200,
                                        message: 'Must be 200 or less characters'
                                    }
                                }}
                                required
                            />
                            <TextInput
                                name="wocustomerrelease"
                                label="Customer Release"
                            />
                        </div>
                    </div>

                    <div className={styles.multipleInputContainer}>
                        <TextInput
                            name="woaddress"
                            label="Location"
                        />
                        <div className={styles.multipleInputContainer}>
                            <TextInput
                                name="wocity"
                                label="City"
                            />
                            <Autocomplete
                            value={woState}
                            options={statesArray}
                            getOptionLabel={(option) => option.value}
                            onChange={(event, newValue) => {
                                //@ts-ignore
                                setWoState(newValue)
                            }}
                            renderInput = {params => (
                                <TextField
                                {...params}
                                label="State"
                                name="wostate"
                                />
                            )}
                            />
                        </div>
                    </div>

                    <div className={styles.multipleInputContainer}>
                        <TextInput
                            name="wocustomerworktype"
                            label="Work Order Type"
                        />
                        <Autocomplete
                            multiple
                            value={divisions}
                            options={GetDivisionListOptions()}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.value === value}
                            onChange={(event, newValue) => {
                                setDivisions(newValue)
                            }}
                            renderInput = {params => (
                                <TextField
                                    {...params}
                                    label="Enter a Division"
                                    name="wodivisionid"
                                />
                            )}
                        />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <SelectInput
                            name="wopricingmethodid"
                            label="Pricing Method"
                            options={getPricingMethodListOptions()}
                        />
                        <AutocompleteSelect
                            name="woprojectmanagerid"
                            label="Customer PM"
                            options={getCustomerProjectManagerListOptions()}
                        />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <DateInput name="wodateworeceived" label="Received" defaultValue={null} required={true} />
                        <DateInput name="wodatepackagereceived" label="Package Received" defaultValue={new Date().toISOString()} />
                    </div>

                    <DateInput name="wodatecommitted" label="Committed" defaultValue={new Date().toISOString()} />
                    <div className={styles.multipleInputContainer}>
                        <SelectInput
                            name="wofirezoneid"
                            label="Fire Zone"
                            options={getFireZoneOptions()}
                        />
                        <SelectInput
                            name="woinsurancecategoryid"
                            label="Insurance Category"
                            options={getInsuranceCategoryOptions()}
                        />
                    </div>
                    <Button type="submit" disabled={!workOrderObj.canedit} variant="contained" className={styles.submitButton}>
                        Save
                    </Button>
                </HookForm>
            </Box>
        </div>
    )
}

export default WOFormTabPage
