import React from "react";
import styles from './HomePage.module.css'
import {AppBar, Typography} from "@material-ui/core";

const HomePage = () => {

    return (
        <div className={styles.root}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left'
                }}>
                {/*<Typography*/}
                {/*    variant="h6"*/}
                {/*    color="primary"*/}
                {/*    align="center"*/}
                {/*    component="div">*/}
                {/*    Welcome!*/}
                {/*</Typography>*/}
            </div>
        </div>
      )
}

export default HomePage
