import axios from 'axios'
import {useQuery} from 'react-query'

export interface  MenuData {
    RowNumber: number
    title: string
    link: string
    submenuitems: []
}


export interface  submenuitems {
    RowNumber: number
    title: string
    link: string
}

export const MenuDataDefaults = {
    "RowNumber": null,
    "title": "",
    "link": "",
    "submenuitems": null
}

export const useFetchMenu = () => {
    return useQuery<MenuData[]>(
        ['menu'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const globalFetcher = async () => {
    const { data } = await axios.get('/menu', {})
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.menuitems as MenuData[]
}
