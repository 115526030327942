import { Backdrop, CircularProgress } from '@material-ui/core'

const LoadingBackdrop = ({ open = false }) => {
  return (
    <Backdrop style={{ zIndex: 999 }} open={open} onClick={() => {}}>
      <CircularProgress color="primary" size={70} />
    </Backdrop>
  )
}

export default LoadingBackdrop
