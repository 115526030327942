import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  Lookup {
    lookupid: number
    lookupcategoryid: number
    lookupvalue: string
    notestypeid: number
    lookupactive: boolean
    lookupdefault: boolean
    lookupentid: number
    lookupentityname: string
    lookupcustid: number
    lookupcustname: string
    lookupcustinfo: string
    lookupaddeduser: string
    lookupaddeddate: string
    lookupupdateduser: string
    lookupupdatedate: string
}

export const LookupDefaults = {
    lookupid: 0,
    lookupcategoryid: 0,
    lookupvalue: "",
    notestypeid: 0,
    lookupactive: true,
    lookupdefault: true,
    lookupentid: 0,
    lookupentityname: "",
    lookupcustid: 0,
    lookupcustname: "",
    lookupcustinfo: "",
    lookupaddeduser: "",
    lookupaddeddate: "",
    lookupupdateduser: "",
    lookupupdatedate: ""
}

export const useFetchLookup =  (lookupcategoryid: number) => {
    return useQuery<Lookup[]>(
        ['lookup', lookupcategoryid],
        () => globalFetcher(lookupcategoryid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const useUpdateLookup = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`lookup`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['lookup'])
        }
    })
}

export const useDeleteLookup = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`lookup`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['lookup'])
        }
    })
}

export const globalFetcher = async (lookupcategoryid: number) => {
    const { data } = await axios.post('/lookup', {lookupcategoryid : lookupcategoryid})
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    return parsedData.lookuplist as Lookup[]
}
