import React, { useEffect, useState } from "react";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, Typography } from "@material-ui/core";
import axios from "axios";

const VacationDetails = ({ employee }) => {
  const [vacationDetails, setVacationDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState({
    startDate: "",
    endDate: "",
  });
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  const validateDates = () => {
    // const currentDate = new Date().toISOString().slice(0, 10);
    let isValid = true;
    let newErrors = {
      startDate: "",
      endDate: "",
    };
  
    // if (startDate < currentDate) {
    //   newErrors.startDate = "Start date must be in the future.";
    //   isValid = false;
    // }
  
    // if (endDate < currentDate) {
    //   newErrors.endDate = "End date must be in the future.";
    //   isValid = false;
    // }
  
    if (endDate < startDate) {
      newErrors.endDate = "End date must be after the start date.";
      isValid = false;
    }
  
    setErrors(newErrors);
    return isValid;
  };
  const fetchVacationDetails = async () => {
    if (employee) {
      try {
        const response = await axios.post("/vacationemployeelist", {
          entityid: "4",
          employeeid: employee.employeeid,
        });

        const data = JSON.parse(response.data.jsonString);
        setVacationDetails(data.vacationlist);
      } catch (error) {
        console.error("Error fetching vacation details:", error);
      }
    }
  };
  useEffect(() => {

    fetchVacationDetails();
  }, [employee]);
  const addEmployeeVacation = async (employeeId, startDate, endDate, comment) => {
    try {
      const response = await axios.put("/vacationemployeelist", {
        entityid: 4,
        employeeid: employeeId,
        startdate: startDate,
        enddate: endDate,
        comment : comment
      });
  
      if (response.data.status === "success") {
        // Handle success
      } else {
        // Handle error
      }
    } catch (error) {
      // Handle network error
    }
  };
  const handleAdd = () => {
    if (validateDates()) {
      handleAddVacation(employee.employeeid, startDate, endDate, comment);
      handleClose();
    }
  };
  const handleDeleteVacation = async (vacationId) => {
    try {
      await axios.delete('/vacationemployeelist', {
        data: {
          vacationid: vacationId,
        },
      });
      // Refresh the vacation details after deleting
      fetchVacationDetails();
    } catch (error) {
      console.error('Error while deleting vacation:', error);
    }
  };
  
  const handleAddVacation = (employeeId, startDate, endDate, comment) => {
    // Add the new vacation to the UI
    setVacationDetails([
      ...vacationDetails,
      {
        RowNumber: vacationDetails.length + 1,
        vacationid: "",
        vacationstartdate: startDate,
        vacationenddate: endDate,
        vacationcomment: comment,
      },
    ]);
      // Call the API to add the new vacation to the database
  addEmployeeVacation(employeeId, startDate, endDate, comment);
};

  const columns: GridColDef[] = [
    // { field: "RowNumber", headerName: "Row Number", width: 120 },
    // { field: "vacationid", headerName: "Vacation ID", width: 120 },
    { field: "vacationstartdate", headerName: "Start Date", width: 250 },
    { field: "vacationenddate", headerName: "End Date", width: 250 },
    { field: "vacationcomment", headerName: "Comment", width: 350 },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleDeleteVacation(params.row.vacationid)}

        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
  <DialogTitle id="form-dialog-title">Add Vacation</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Please enter the vacation details.
    </DialogContentText>
    <TextField
  autoFocus
  margin="dense"
  id="startDate"
  label="Start Date"
  type="date"
  fullWidth
  value={startDate}
  onChange={(e) => setStartDate(e.target.value)}
  error={!!errors.startDate}
  helperText={errors.startDate}
  InputLabelProps={{ shrink: true }}
/>
<TextField
  margin="dense"
  id="endDate"
  label="End Date"
  type="date"
  fullWidth
  value={endDate}
  onChange={(e) => setEndDate(e.target.value)}
  error={!!errors.endDate}
  helperText={errors.endDate}
  InputLabelProps={{ shrink: true }}
/>
<TextField
  margin="dense"
  id="comment"
  label="Comment"
  type="text"
  fullWidth
  value={comment}
  onChange={(e) => setComment(e.target.value)}
/>

  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose} color="primary">
      Cancel
    </Button>
    <Button onClick={handleAdd} color="primary">
  Add
</Button>
  </DialogActions>
</Dialog>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h6" color="primary" component="div">
          Vacation Details
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
        >
          Add Vacation
        </Button>
      </Grid>
      <DataGridPro
        rows={vacationDetails}
        columns={columns}
        autoHeight={true}
        density={"compact"}
        pageSize={10}
        disableMultipleSelection={true}
        getRowId={(row) => row.RowNumber}
      />
    </div>
  );
};

export default VacationDetails;
