import React, { useEffect, useState } from "react";
import styles from "./Vacation.module.css";
import { Typography, Grid } from "@material-ui/core";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import axios from "axios";
import { useFetchVacationList } from "../../../queries/useVacationList";
import { Tab, Tabs, Box } from "@material-ui/core";
import VacationDetails from "./VacationDetails";

const VacationEmployeeGridPage = () => {
  const { data: vacationList } = useFetchVacationList(4);
  const [employeeVacationData, setEmployeeVacationData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const fetchAllVacationDetails = async () => {
      if (vacationList) {

        const activeVacationList = vacationList
        .filter(row => row.active) // Filtering active employees
        .map(employee => {
          // Assign a blank string if nextvacationdate is null, otherwise use the first found date
          const nextVacation = employee.qVAC.find(vac => vac.nextvacationdate != null)?.nextvacationdate || '';
          return { ...employee, nextVacation };
        });
        setEmployeeVacationData(activeVacationList);

      }
    };

    fetchAllVacationDetails();
  }, [vacationList]);

  const columns: GridColDef[] = [
    // { field: "RowNumber", headerName: "Row Number", width: 120, hide: true },
    { field: "employeeid", headerName: "Employee ID", width: 120, hide: true },
    { field: "employee", headerName: "Employee", width: 250 },
    { field: "nextVacation", headerName: "Next Vacation Date", width: 200 },
    //{ field: "hasvacation", headerName: "Has Vacation", width: 250 },
    // { field: "active", headerName: "Active", width: 100, },
    // { field: "hasvacation", headerName: "Has Vacation", width: 150 }
  ];

  return (
    <div className={styles.root}>
      <Grid container >
        <Grid item xs={12}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Vacation Employee Listing" />
            <Tab label="Vacation Details" disabled={true} />
          </Tabs>
        </Grid>
        <Grid item  xs={12}>
          <TabPanel value={activeTab} index={0}>
            {/* DataGridPro and related components */}
            <Grid item style={{ height: '78vh', width: '100%' }} xs={12}>

            <DataGridPro
              rows={employeeVacationData}
              columns={columns}
              //autoHeight={true}
              density={"compact"}
              pageSize={10}
              getRowId={(row) => row.RowNumber}
              onRowClick={(params) => {
                setSelectedEmployee(params.row);
                setActiveTab(1);
              }}
            />
      </Grid>

          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            {/* Display vacation details of the selected employee here */}
            <VacationDetails employee={selectedEmployee} />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );


};

export default VacationEmployeeGridPage;
