import React, {useEffect, useState} from "react";
import styles from '../WAListingPage/WALiastingPage.module.css'
import {IconButton, Box, AppBar} from '@material-ui/core'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { Add } from '@mui/icons-material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {GridSelectionModel, GridValueFormatterParams, XGrid} from '@material-ui/x-grid'
import {
    DataGridPro,
    GridColDef,
    GridCellParams,
    GridRowParams,
    GridCallbackDetails,
    MuiEvent, useGridApiRef
} from "@mui/x-data-grid-pro";
import {WAListingForm} from "../WAListingPage/WAListForm";

import {WAListing, useFetchWAListing, useDeleteWAListing} from '../../../queries/useWAListing'
import {TabPanel} from "@material-ui/lab";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {WAListingDefaults} from '../../../queries/useWAListing'
import {selectIdValue} from "@reduxjs/toolkit/dist/entities/utils";
import {WAAmounts, WAAmountsDefaults, useDeleteWAAmounts, useFetchWAAmounts} from "../../../queries/useWAAmounts";
import {useFetchWADropdowns} from "../../../queries/useWADropdowns";
import HookForm from "../../../components/common/HookForm";
import {WAAmountsForm} from "./WAAmountsForm";
import {Grid} from "@mui/material";
import {formatMoney} from "../../../util/formatHelpers";

const WAAmountsTable = (params) => {
    const deleteWAAmounts = useDeleteWAAmounts()
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState(WAAmountsDefaults)
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const [listingIDSelected, setListingIDSelected] = React.useState<number>(null);

    var amountRows = []
    const { data, isLoading } = useFetchWAAmounts(params.id)
    console.log(data)
    if (data && data.length > 0) {
        amountRows = data?.map((amount: WAAmounts, i) => {
            return {
                id: amount.waaid,
                waaamount: amount.waaamount,
                waastatusid: amount.waastatusid,
                waadaterequested: amount.waadaterequested,
                waadateapproved: amount.waadateapproved,
                waacomment: amount.waacomment,
                waaamountlastupdated: amount.waaamountlastupdated,
                waaaddeddate: amount.waaaddeddate
            }
        })
    }


    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }

    const amountColumns: GridColDef[] = [
        {
            field: 'Edit',
            renderHeader: () => {
                return (
                    <IconButton
                        className={styles.submitButton}
                        onClick={() => handleAdd()}
                        size="small">
                        <Add />
                    </IconButton>
                )
            },
            width: 50,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
                    <Edit />
                </IconButton>
            )
        },
        {
            field: 'id',
            hide: true
        },
        {
            field: 'waaaddeddate',
            type: 'date',
            headerName: 'Added',
            width: 175,
            valueFormatter: (params) =>
                params.value ? new Date(params.value as string).toLocaleString() : 'N/A'
        },
        {
            field: 'waaamount',
            type: 'string',
            headerName: 'Amount',
            width: 125,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return formatMoney(Number(params.value))
            },
        },
        {
            field: 'waastatusid',
            type: 'string',
            headerName: 'Status',
            width: 125,
            valueGetter: (gridParams) =>
                gridParams.value ? params.waamountsstatuslist.filter((item) => { return item.statusid === gridParams.value})[0].statuscode : ''
        },
        {
            field: 'waadaterequested',
            type: 'date',
            headerName: 'Requested',
            width: 175,
            valueFormatter: (params) =>
                params.value ? new Date(params.value as string).toLocaleString() : 'N/A'
        },
        {
            field: 'waadateapproved',
            type: 'date',
            headerName: 'Approved',
            width: 175,
            valueFormatter: (params) =>
                params.value ? new Date(params.value as string).toLocaleString() : 'N/A'
        },
        {
            field: 'action',
            headerName: '',
            width: 50,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                    <Delete />
                </IconButton>
            )
        }
    ]

    const handleEdit = (params: GridCellParams) => {
        const waAmount = data.filter((item) => item.waaid === Number(params.id))[0]
        setReloadForm(reloadForm+1)
        setGridParams({
            waaid: waAmount.waaid,
            waaamount: waAmount.waaamount,
            waastatus: waAmount.waastatus,
            waastatusid: waAmount.waastatusid,
            waadaterequested: waAmount.waadaterequested,
            waadateapproved: waAmount.waadateapproved,
            waacomment: waAmount.waacomment,
            waarequestdocument:waAmount.waarequestdocument,
            waaamountlastupdated: waAmount.waaamountlastupdated,
            waaaddeddate: waAmount.waaaddeddate
        })
        setIsFormShowing(true)
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObj({
            msg: 'Are you sure you want to delete this amount \"' + params.getValue(params.id, 'waaamount').toString() + "\"?",
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteWAAmounts.mutateAsync({ waaid: dialogObj.id })
        closeDialog()
    }

    const closeDialog = () => {
        setDialogObj({
            msg: '',
            id: 0
        })
    }

    const handleListingRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        setListingIDSelected(Number(param.id))
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <Grid container spacing={2}>
                <Grid item xs={12} style={{ marginLeft: '15px', marginTop: '15px'}}>
                    <div style={{ width: '100%' }}>
                        WA No. {params.listingDisplay}
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <WAAmountsForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} walid={params.id} waamountsstatuslist={params.waamountsstatuslist}/>
                    <DataGridPro
                        // apiRef={gridAmountAPIRef}
                        rows={amountRows}
                        columns={amountColumns}
                        autoHeight={true}
                        density={'compact'}
                        pageSize={10}
                        loading={false}
                        disableMultipleSelection={true}
                        // onRowClick={handleRowClick}
                    />
                </Grid>
            </Grid>


        </div>
    );
}

export default WAAmountsTable
