import cn from 'classnames'
import { FC, useEffect, useState } from 'react'

import { Chip, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab/'

import styles from './ChipsList.module.css'

type Props = {
  className?: string
  list: { value: any; label: string }[]
  setList: Function
  defaultValues?: string[]
  options: { value: any; label: string }[]
  label: string,
  keyValue?: number,
  required?: boolean
}

const ChipsList: FC<Props> = ({
                                className,
                                list,
                                setList,
                                defaultValues,
                                options,
                                label,
                                keyValue,
                                required
                              }) => {
  const [value, setValue] = useState<{ lookupheaderid: any; value: any; label: string }>((list?.length > 0 ? {lookupheaderid: keyValue, label: list[0].label, value: list[0].value} : null))
  const [inputValue, setInputValue] = useState('')

  // useEffect(() => {
  //   setList(defaultValues)
  // }, [defaultValues?.length])

  const onKeyPress = (e) => {
    if (e.key === 'Enter' && value) {
      e.preventDefault()
      const foundItem = list.find((o) => o.value === value.value)
      if (!foundItem) {
          var valuesToSave = undefined
          if (keyValue === undefined) {
              valuesToSave = value
          }
          else {
              if (keyValue === value.lookupheaderid) {
                  valuesToSave = {lookupheaderid: keyValue, label: value.label, value: value.value}
              }
          }
          setList((c) => [...c, valuesToSave])
      }
      setInputValue('')
      setValue({ lookupheaderid: 0, value: '', label: '' })
    }
  }

  const onSelChange = (e) => {
    if (value) {
      e.preventDefault()
      const foundItem = list.find((o) => o.value === value.value)
      if (!foundItem) {
        var valuesToSave = undefined
        if (keyValue === undefined) {
            valuesToSave = value
        }
        else {
            valuesToSave = {lookupheaderid: keyValue, label: value.label, value: value.value}
        }
        setList((c) => [...c, valuesToSave])
      }
    }
  }

  const onDelete = (index) => {
    setList(list.filter((v, i) => i !== index))
  }

  return (
      <div className={cn(styles.root, className)}>
        <Autocomplete
            clearOnBlur
            options={options}
            getOptionLabel={(option) => option.label}
            renderOption={(option) => option.label}
            value={value}
            onChange={(event: any, option: any) => {
              setValue({lookupheaderid: keyValue, label: option.label, value: option.value})
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    onKeyPress={onKeyPress}
                    onSelect={onSelChange}
                    required={required}
                    size="small"
                />
            )}
        />
        <div className={styles.chipContainer}>
          {list?.map((option, i) => {
            return (
                <Chip
                    key={option.value}
                    label={option.label}
                    onDelete={() => onDelete(i)}
                    style={{ marginRight: '0.25rem', marginTop: '0.25rem' }}
                />
            )
          })}
        </div>
      </div>
  )
}

export default ChipsList

