
import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface VacationEmployee {
    rownumber: number;
    employeeid: number;
    employee: string;
    active: boolean;
    hasvacation: boolean;
    qVAC: VacationInfo[];
  }
  
export interface VacationInfo {
  nextvacationdate?: string;
}
  export const useFetchVacationList = (entityid: number) => {
    return useQuery<VacationEmployee[]>(
      ["vacationlist", entityid],
      () => fetchVacationList(entityid),
      {
        staleTime: 120000,
        keepPreviousData: false,
      }
    );
  };
  
  const fetchVacationList = async (entityid: number, ) => {
    const { data } = await axios.post(
      "/vacationlist",
      { entityid: entityid }
    );
    const parsedData = JSON.parse(data.jsonString);
    return parsedData.employeelist as VacationEmployee[];
  };