import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  NotesEmail {
    emaillocation: string
    email: string
    firstname: string
    lastname: string
}

export const NotesDataDefaults = {
    "emaillocation": "",
    "email": "",
    "firstname": "",
    "lastname": ""
}

export const useFetchNotesEmail = () => {
    return useQuery<NotesEmail[]>(
        ['notesemail'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const globalFetcher = async () => {
    const { data } = await axios.get('/notesemail', {})
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.emaillist as NotesEmail[]
}
