import React, {useEffect, useMemo, useState} from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { ScheduleLookupOptions } from '../../../../../src/queries/useScheduleFields';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

type Props = {
  schedulefieldid: number
  label: string
  defaults: string[]
  lookupOptions: ScheduleLookupOptions[]
  setSubmitValues: Function
  submitValues: { schedulefieldid: number, value: {lookup: string, lookupid: number, lookupheaderid: number}[]}[]
}

const SelectInput = ({
  schedulefieldid,
  label,
  defaults,
  lookupOptions,
  setSubmitValues,
  submitValues
}: Props) => {
  const { control, formState } = useFormContext()

  const {
    field: { ref, ...inputProps }
  } = useController({
    name: schedulefieldid.toString(),
    control
  })

  const [selectedValues, setSelectedValues] = useState([])

  const getDefaults = useMemo(
      () =>
          defaults,
      [defaults, schedulefieldid]
  )

  useEffect(() => {
    if (getDefaults && getDefaults.length > 0 && selectedValues.length === 0) {
      setSelectedValues(getDefaults)
      handleChange(null, getDefaults)
    }
    return () => {
      setSelectedValues([])
    }
  }, [])

  const getOptions = () => {
    if (lookupOptions && lookupOptions.length > 0) {
      return lookupOptions
    }
    else {
      return []
    }
  }

  const handleChange = (event, values) => {
    const updatedSubmit = submitValues.filter(val => (val.schedulefieldid !== schedulefieldid))
    const vals = values.map(item => {
      if (lookupOptions && lookupOptions.length > 0) {
        return lookupOptions.filter(options => (options.lookup === item.lookup))[0]
      }
    })

    setSubmitValues([...updatedSubmit, { schedulefieldid: schedulefieldid, value: vals}])

    setSelectedValues(values)
  }

  return (
      <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={getOptions()}
          value={selectedValues}
          onChange={handleChange}
          disableCloseOnSelect
          getOptionLabel={(option: any) => option.lookup}
          renderInput={(params) => (
              <TextField {...params} label={label} placeholder="" />
          )}
      />
  )
}

export default SelectInput
