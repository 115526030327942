import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface divisionList {
    divisionid: number
    divisionname: string
}

export interface scheduleList {
    scheduleid: number
    schedulename: string
}

export interface dependencyTypeList {
    dependencytypeid: number
    dependencytypename: string
}

export interface dependencyCategoryList {
    dependencycategoryid: number
    dependencycategoryname: string
}

export interface  DependencyDropdowns {
    divisionlist: divisionList[]
    schedulelist: scheduleList[]
    dependencytypelist: dependencyTypeList[]
    dependencycategorylist: dependencyCategoryList[]
}

export const useFetchDependencyDropdowns =  (woid: number) => {
    return useQuery(
        ['dependencydropdowns'],
        () => globalFetcher(woid),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const globalFetcher = async (id: number) => {
    const { data } = await axios.post('/dependencydropdowns', {
        woid: id
    })
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    return parsedData as DependencyDropdowns
}
