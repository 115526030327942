import React, { useEffect, useRef, useState } from 'react'
import styles from './DispatchPage.module.css'
import {
    AppBar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton
} from '@material-ui/core'
import SignatureCanvas from 'react-signature-canvas'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import {CardHeader, FormControl, Grid, ImageList, ImageListItem, InputLabel, MenuItem, Select} from '@mui/material'
import { useParams } from 'react-router'
import { useFetchDispatch, useUpdateDispatch } from '../../queries/useDispatch'
import HookForm from '../../components/common/HookForm'
import { useForm } from 'react-hook-form'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import WorkAuthorizationTable from '../WorkOrderPage/WorkAuthorizations/WorkAuthorizationTable'
import cn from 'classnames'
import { Wrapper, Status } from '@googlemaps/react-wrapper'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';

import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro'
import { Add } from '@mui/icons-material'
import { Delete, Edit } from '@material-ui/icons'
import { EquipmentForm } from './EquipmentForm'
import { TimeEntryForm } from './TimeEntryForm'
import Dropzone from "react-dropzone";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import axios from "axios";
import PdfViewerComponent from "../../components/PdfViewerComponent";
import dayjs from "dayjs";

interface Params {
    id: string
}

const DispatchViewerPage = () => {
    const { id } = useParams<Params>()
    const [watchLogoData, setWatchLogoData] = React.useState(null)
    const [watchLogoDataURL, setWatchLogoDataURL] = React.useState(null)
    const [openViewer, setOpenViewer] = React.useState(false)


    console.log('DispatchViewerPage')
    useEffect(() => {
        handleDocumentDownload()
    })

    const handleDocumentDownload = () => {
        const body = {
            id: decodeURIComponent(id),
            // filename: decodeURIComponent(path),
            // dispatchsendid: Number(dispatchsendid)
        }

        axios.post(`gdwodocumentsfile`, body).then((response) => {

            const dataJson = JSON.parse(response.data.jsonString)
            var contentType = 'image/png';
            if (dataJson.fileext === 'png') {
                contentType = 'image/png'
            }
            else if (dataJson.fileext === 'pdf') {
                contentType = 'application/pdf'
            }
            else if (dataJson.fileext === 'jpg' || dataJson.fileext === 'jpg') {
                contentType = 'image/jpeg'
            }
            else if (dataJson.fileext === 'docx') {
                contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            }
            else if (dataJson.fileext === 'xlsx') {
                contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
            else if (dataJson.fileext === 'xls') {
                contentType = 'application/vnd.ms-excel'
            }
            setWatchLogoData(dataJson.filedata)

            const byteCharacters = atob(dataJson.filedata)
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: contentType});
            const url = window.URL.createObjectURL(blob);
            setWatchLogoDataURL(url);
            setOpenViewer(true)
        })
    }

    return (
        <>
            <Grid item xs={6} className={styles.selectcontrol}>
                <>
                    {watchLogoDataURL && (
                        <Dialog
                            open={true}
                            fullWidth={true}
                            maxWidth="xl"
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent>
                                <div className="PDF-viewer">
                                    <PdfViewerComponent
                                        document={watchLogoDataURL}
                                    />
                                </div>
                            </DialogContent>
                            {/*<DialogActions>*/}
                            {/*    <Button>Save</Button>*/}
                            {/*</DialogActions>*/}
                        </Dialog>
                    )}
                </>
            </Grid>
        </>
    )
}

export default DispatchViewerPage