import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {Lookup} from "./useLookup";

export interface  LookupCategoryObject {
    lookupcategorylist: LookupCategory[]
    entitylist: EntityList[]
    custlist: CustomerList[]
}

export interface  LookupCategory {
    lookupcategoryid: number
    lookupcategory: string
    lookupcategoryrequireentity: boolean
    lookupcategoryrequirecustomer: boolean
    lookupcategoryshowentity: boolean
    lookupcategoryshowcustomer: boolean
}

export interface EntityList {
    entityid: number
    entityname: string
}

export interface CustomerList {
    entityid: number
    custid: number
    custcode: string
    custinfo: string
}

export const LookupDefaults = {
    lookupid: 0,
    lookupcategoryid: 0,
    lookupvalue: '',
    lookupcustid: 0,
    lookupentid: 0,
    lookupdefault: true,
    lookupactive: true
}

export const LookupCategoryDefaults = {
    lookupcategorylist: [],
    entitylist: [],
    custlist: []
}

export const useFetchLookupCategory =  () => {
    return useQuery<LookupCategoryObject>(
        ['udlookupcategory'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            keepPreviousData: false
        }
    )
}

export const globalFetcher = async () => {
    const { data } = await axios.get('/udlookupcategory', {})
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as LookupCategoryObject
}
