import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import styles from '../CWAListingPage/CWALiastingPage.module.css'
import {useUpdateCWAAmounts} from "../../../queries/useCWAAmounts";
import TextInput from "../../../components/common/inputs/TextInput";
import SelectInput from "../../../components/common/inputs/SelectInput";
import MaskedInput from "../../../components/common/inputs/MaskedInput";
import DateTimeInput from "../../../components/common/inputs/DateInput";

interface FormData {
    cwaaid: number
    cwaaamount: number
    cwaastatusid: string
    cwaadaterequested: string
    cwaadateapproved: string
    cwaacomment: string
    cwaaamountlastupdated: string
    cwaaaddeddate: string
    cwaadateexpire: string
}

export const CWAAmountsForm = ({ isOpen, setIsOpen, gridParams, reloadForm, cwalid, cwaamountsstatuslist }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit Amounts" : "Create Amounts"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateAmounts = useUpdateCWAAmounts()
    const [amount, setAmount] = useState<number>(0)

    useEffect(() => {
        reset({
            cwaaid: (gridParams ? gridParams.cwaaid : 0),
            cwaaamount: (gridParams ? gridParams.cwaaamount : 0),
            cwaastatusid: (gridParams ? gridParams.cwaastatusid : 1),
            cwaadaterequested: (gridParams ? gridParams.cwaadaterequested : null),
            cwaadateapproved: (gridParams ? gridParams.cwaadateapproved : null),
            cwaacomment: (gridParams ? gridParams.cwaacomment : ''),
            cwaaamountlastupdated: (gridParams ? gridParams.cwaaamountlastupdated : ""),
            cwaaaddeddate: (gridParams ? gridParams.cwaaaddeddate : null),
            cwaadateexpire: (gridParams ? gridParams.cwaadateexpire : null),
        })
    }, [reloadForm])

    const handleAmount = (event) => {
        setAmount(event.floatValue)
    }

    const onSubmit = async (data: FormData) => {
        var finalData = {
            cwalid: cwalid,
            cwaaamount: amount,
            cwaastatusid: data.cwaastatusid,
            cwaadaterequested: data.cwaadaterequested,
            cwaadateapproved: data.cwaadateapproved,
            cwaacomment: data.cwaacomment,
            cwaadateexpire: data.cwaadateexpire
        }
        if (data.cwaaid > 0) {
            finalData['cwaaid'] = data.cwaaid
        }
        const { data: response } = await updateAmounts.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }

    const getStatusCodes = () => {
        return cwaamountsstatuslist.map(item => {
                return { label: item.statuscode, value: item.statusid }
            })

    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const currencyFormat
        = (num) => {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return (
        <Dialog open={isOpen} maxWidth="xl" className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>
                    <div className={styles.multipleInputContainer}>
                        <SelectInput
                            name="cwaastatusid"
                            label="Status Code"
                            options={getStatusCodes()}
                            required
                        />
                        <MaskedInput
                            name="cwaaamount"
                            label="Amount"
                            defaultValue="0"
                            onValueChange={handleAmount}
                        />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <DateTimeInput name="cwaadaterequested" label="Requested Date" defaultValue={null} required={false} />
                        <DateTimeInput name="cwaadateapproved" label="Approved Date" defaultValue={null} required={false} />
                    </div>

                    <div className={styles.multipleInputContainer}>
                        <DateTimeInput name="cwaadateexpire" label="Expiration Date" defaultValue={null} required={false} />
                        <TextInput
                            name="cwaacomment"
                            label="Comment"
                            rules={{
                                required: 'Password field is required',
                                minLength: {
                                    value: 1,
                                    message: 'Must be at least 1 characters long'
                                },
                                maxLength: {
                                    value: 500,
                                    message: 'Must be less than 500 characters long'
                                }
                            }}
                        />
                    </div>
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
