import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'
import MaskedInput from "../../../components/common/inputs/MaskedInput";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'
import { emailRegex, phoneRegex } from '../../../util/formValidation'

import HookForm from '../../../components/common/HookForm'
import styles from './Contact.module.css'
import TextInput from "../../../components/common/inputs/TextInput";
import SelectInput from "../../../components/common/inputs/SelectInput";
import {useUpdateContact} from "../../../queries/useContact";

interface FormData {
    contactid: number
    contactname: string
    contactphone: string
    contactemail: string
    contactlocation: string
    contactcompany: string
    contactactive: boolean
}

export const ContactForm = ({ isOpen, setIsOpen, gridParams, reloadForm }) => {
    const formMethods = useForm<FormData>()
    const title = gridParams ? "Edit Contact" : "Create Contact"
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateContact = useUpdateContact()
    const [amount, setAmount] = useState<number>(0)

    useEffect(() => {
        reset({
            contactid: (gridParams ? gridParams.contactid : 0),
            contactname: (gridParams ? gridParams.contactname : ''),
            contactphone: (gridParams ? gridParams.contactphone : ''),
            contactemail: (gridParams ? gridParams.contactemail : ''),
            contactlocation: (gridParams ? gridParams.contactlocation : ''),
            contactcompany: (gridParams ? gridParams.contactcompany : ''),
            contactactive: (gridParams ? gridParams.contactactive : true)
        })
    }, [reloadForm])

    const onSubmit = async (data: FormData) => {
        var finalData = {
            contactname: data.contactname,
            contactphone: data.contactphone,
            contactemail: data.contactemail,
            contactlocation: data.contactlocation,
            contactcompany: data.contactcompany,
            contactactive: data.contactactive
        }
        if (data.contactid > 0) {
            finalData['contactid'] = data.contactid
        }
        const { data: response } = await updateContact.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            setIsOpen(false)
        }
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    return (
        <Dialog open={isOpen} maxWidth="xl" className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>

                    <div className={styles.multipleInputContainer}>
                        <TextInput
                            name="contactname"
                            label="Name"
                            rules={{
                                required: 'Name is required',
                                minLength: {
                                    value: 1,
                                    message: 'Must be at least 1 characters'
                                },
                                maxLength: {
                                    value: 200,
                                    message: 'Must be 200 or less characters'
                                }
                            }}
                            required
                        />
                        <MaskedInput
                            name="contactphone"
                            label="Phone"
                            defaultValue=""
                            thousandSeparator={false}
                            thousandsGroupStyle={""}
                            prefix={""}
                            format={"(###) ###-####"}
                            mask={"_"}
                            rules={{
                                pattern: {
                                    value: phoneRegex,
                                    message: 'Please enter a valid phone number.'
                                }
                            }}
                        />

                    </div>
                    <div className={styles.multipleInputContainer}>
                        <TextInput
                            name="contactemail"
                            label="Email"
                            rules={{
                                pattern: {
                                  value: emailRegex,
                                  message: 'Enter a valid email'
                                }
                              }}
                        />
                        <TextInput
                            name="contactlocation"
                            label="Location"
                        />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <TextInput
                            name="contactcompany"
                            label="Company"
                            rules={{
                                required: 'Company is required'
                            }}
                            required
                        />
                       
                    </div>
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
