import React, {useEffect, useMemo, useState} from 'react'
import { useForm } from 'react-hook-form'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, Grid
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import styles from './WOListings.module.css'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import TextInput from "../../../components/common/inputs/TextInput";
import SelectInput from "../../../components/common/inputs/SelectInput";
import {useUpdateUsers} from "../../../queries/useUsersMaint";
import {statesArray} from "../../../util/formValidation";
import {useUpdateWorkOrder} from "../../../queries/useWorkOrder";
import DateInput from "../../../components/common/inputs/DateInput";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import LoadingBackdrop from "../../../components/common/LoadingBackdrop";
import AutocompleteSelect from '../../../components/common/inputs/AutoCompleteSelectInput/AutocompleteSelect'

interface FormData {
    woid: number
    woentityid: number
    woentity: string
    wodirectbill: boolean
    wocustomercodeid: number
    wocustomercode: string
    wocustomerinfo: string
    wocustomerworkorder: string
    wocustomerrelease: string
    wopolid: number
    wocustomerpo: string
    wofoldername: string
    wocustomerdivisionid: number
    wocustomerdivision: string
    woaddress: string
    woaddress2: string
    wocity: string
    wostate: string
    wozip: string
    wocustomerworktype: string
    wodivisionids: number[]
    wodivision: string
    wostatusid: number
    wostatus: string
    wodatepackagereceived: string
    wodateworeceived: string
    wodatecommitted: string
    wodatejobready: string
    woprojectmanagerid: string,
    woprojectmanager: string
    wopricingmethodid: string
    wopricingmethod: string
    woaddeduser: string
    woaddeduserkey: number
    woaddeddate: string
    woupdateduser: string
    woupdateduserkey: number
    woupdateddate: string
    woactive: boolean
    wofirezoneid: number
    woinsurancecategoryid: number
}

export const WOListingForm = ({ isOpen, setIsOpen, gridParams, reloadForm, woDropDownData, canaddentity, isClone, setNewWOID }) => {
    const formMethods = useForm<FormData>()
    const title = isClone ? "Clone Work Order" : (gridParams ? "Edit Work Order - " + gridParams?.woid : "Create Work Order")
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const [dialogMsg, setDialogMsg] = React.useState("")
    const updateWorkOrder = useUpdateWorkOrder()
    const [divisions, setDivisions] = useState([])
    const [woState, setWoState] = useState<{ value: any; label: string }>()
    const [entityActiveSelect, setEntityActiveSelect] = React.useState<string>("")
    const [entityActiveSelectId, setEntityActiveSelectId] = React.useState<number>(0)
    //const [customerActiveSelect, setCustomerActiveSelect] = React.useState<string>("")
    //const [customerActiveSelectId, setCustomerActiveSelectId] = React.useState<number>(0)
    const [customerOptions, setCustomerOptions] = React.useState()
    const [customerDivisionOptions, setCustomerDivisionOptions] = React.useState([])
    //const [customerPOActiveSelect, setCustomerPOActiveSelect] = React.useState<string>("")
    const [customerPOOptions, setCustomerPOOptions] = React.useState([])
    //const [customerPOActiveSelectId, setCustomerPOActiveSelectId] = React.useState<number>(0)
    // const [customerPOOptions, setCustomerPOOptions] = React.useState()
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const statesArray1 = statesArray
    const watchCustcodeID = watch("wocustomercodeid"); // you can also target specific fields by their names
    const [mainFieldsShouldBeDisabled, setMainFieldsShouldBeDisabled] = React.useState(true)

    useEffect(() => {
        const woStatusId = getValues('wostatusid')
        var disabled = true
        if (woStatusId > 0) {
            const woCustomerCodeID = getValues('wocustomercodeid')
            const status = woDropDownData?.statuslist?.find(item => item.statusid === woStatusId)
            const isEmergency = status.statuscode === 'Emergency'
            const isCustomerEmpty = woCustomerCodeID === -1 || woCustomerCodeID === null || woCustomerCodeID === undefined
            
            if (isEmergency && isCustomerEmpty) {
                disabled = false
            }
        }

        setMainFieldsShouldBeDisabled(disabled)
    }, [watch('wostatusid')])

    useEffect(() => {
        setEntityActiveSelect("")
        setEntityActiveSelectId(0)
        //setCustomerActiveSelect("")
        //setCustomerActiveSelectId(0)

        let selectedwoState = {value: "", label:""}
        const selectedState = statesArray1?.filter((st) => st.value === gridParams?.wostate)
        if (selectedState.length > 0){
            selectedwoState = selectedState[0];
        }
        setWoState(selectedwoState)
        // if (gridParams) {
        //     //setCustomerPOActiveSelectId(gridParams.wopolid)
        // }
        // else {
        //    // setCustomerPOActiveSelectId(0)
        // }

        console.log(gridParams)

        reset({
            woid: (gridParams ? gridParams.woid : 0),
            woentityid: (gridParams ? gridParams.woentityid : ""),
            woentity: (gridParams ? gridParams.woentity : 0),
            wodirectbill: (gridParams ? gridParams.wodirectbill : false),
            wocustomercodeid: (gridParams ? gridParams.wocustomercodeid : ""),
            wocustomercode: (gridParams ? gridParams.wocustomercode : ""),
            wocustomerinfo: (gridParams ? gridParams.wocustomerinfo : ""),
            wocustomerworkorder: (gridParams ? gridParams.wocustomerworkorder : ""),
            wocustomerrelease: (gridParams ? gridParams.wocustomerrelease : ""),
            wopolid: (gridParams ? gridParams.wopolid : null),
            wocustomerpo: (gridParams ? gridParams.wocustomerpo : ""),
            wofoldername: (gridParams ? gridParams.wofoldername : ""),
            wocustomerdivisionid: (gridParams ? gridParams.wocustomerdivisionid : 0),
            wocustomerdivision: (gridParams ? gridParams.wocustomerdivision : ""),
            woaddress: (gridParams ? gridParams.woaddress : ""),
            woaddress2: (gridParams ? gridParams.woaddress2 : ""),
            wocity: (gridParams ? gridParams.wocity : ""),
            wostate: (gridParams ? gridParams.wostate : ""),
            wozip: (gridParams ? gridParams.wozip : ""),
            wocustomerworktype: (gridParams ? gridParams.wocustomerworktype : ""),
            wodivisionids: (gridParams ? gridParams.wodivisionids : []),
            wodivision: (gridParams ? gridParams.wodivision : ""),
            wostatusid: (gridParams ? gridParams.wostatusid : null),
            wostatus: (gridParams ? gridParams.wostatus : ""),
            wodatepackagereceived: (gridParams ? gridParams.wodatepackagereceived : null),
            wodateworeceived: (gridParams ? gridParams.wodateworeceived : new Date().toISOString()),
            wodatecommitted: (gridParams ? gridParams.wodatecommitted : null),
            wodatejobready: (gridParams ? gridParams.wodatejobready : null),
            woprojectmanagerid: (gridParams ? gridParams.woprojectmanagerid : ""),
            woprojectmanager: (gridParams ? gridParams.woprojectmanager : ""),
            wopricingmethodid: (gridParams ? gridParams.wopricingmethodid : 0),
            wopricingmethod: (gridParams ? gridParams.wopricingmethod : ""),
            woaddeduser: (gridParams ? gridParams.woaddeduser : ""),
            woaddeduserkey: (gridParams ? gridParams.woaddeduserkey : 0),
            woaddeddate: (gridParams ? gridParams.woaddeddate : ""),
            woupdateduser: (gridParams ? gridParams.woupdateduser : ""),
            woupdateduserkey: (gridParams ? gridParams.woupdateduserkey : 0),
            woupdateddate: (gridParams ? gridParams.woupdateddate : ""),
            woactive: (gridParams ? gridParams.woactive : true),
            wofirezoneid: (gridParams ? gridParams.wofirezoneid : 0),
            woinsurancecategoryid: (gridParams ? gridParams.woinsurancecategoryid : 0),
        })

        let selectedDivision = []
        gridParams?.wodivisionids?.forEach(wtId => {
            const woToAdd = woDropDownData?.divisionlist?.filter((wtObject) =>  wtObject.divisionid === wtId )
            if (woToAdd && woToAdd.length > 0) {
                selectedDivision.push({value: woToAdd[0].divisionid, label: woToAdd[0].divisionname})
            }
        })
        setDivisions(selectedDivision)


        if (gridParams && gridParams.woentityid) {
            const filteredList = woDropDownData?.entitylist?.filter(entity => entity.entityid === gridParams.woentityid)
            if (filteredList && filteredList.length > 0) {
                setEntityActiveSelectId(gridParams.woentityid)
                setEntityActiveSelect(filteredList[0].entityname)
                getCustomerOptions(gridParams.woentityid)
            }
        }

        if (gridParams && gridParams.wocustomercodeid) {
            const filteredList = woDropDownData?.custlist?.filter(cust => cust.custid === gridParams.wocustomercodeid)
            if (filteredList.length > 0) {
                // setCustomerActiveSelectId(filteredList[0].custid)
                // setCustomerActiveSelect(filteredList[0].custinfo)
                getCustomerOptions(filteredList[0].entityid)
            }
        }
    }, [reloadForm])

    const onSubmit = async (data: FormData) => {
        setIsLoading(true)
        const divisionsToSave = divisions.map(div => {
            return div.value
        })

        var finalData = {
            woentityid: entityActiveSelectId,
            wocustomercodeid: data.wocustomercodeid,
            wocustomercode: data.wocustomercode,
            wocustomerinfo: data.wocustomerinfo,
            wocustomerworkorder: data.wocustomerworkorder,
            wocustomerrelease: data.wocustomerrelease,
            wopolid: data.wopolid,
            wocustomerpo: data.wocustomerpo,
            wofoldername: data.wofoldername,
            wocustomerdivisionid: Number(data.wocustomerdivisionid),
            wocustomerdivision: data.wocustomerdivision,
            woaddress: data.woaddress,
            woaddress2: data.woaddress2,
            wocity: data.wocity,
            wostate: woState.value,
            wozip: data.wozip,
            wocustomerworktype: data.wocustomerworktype,
            wodivisionids: divisionsToSave,
            wodivision: getDivisions(divisionsToSave),
            wostatusid: data.wostatusid,
            wostatus: data.wostatus,
            wodatepackagereceived: data.wodatepackagereceived,
            wodateworeceived: data.wodateworeceived,
            wodatecommitted: data.wodatecommitted,
            wodatejobready: data.wodatejobready,
            woprojectmanagerid: data.woprojectmanagerid,
            woprojectmanager: data.woprojectmanager,
            wopricingmethodid: data.wopricingmethodid,
            wopricingmethod: data.wopricingmethod,
            woaddeduser: data.woaddeduser,
            woaddeduserkey: data.woaddeduserkey,
            woaddeddate: data.woaddeddate,
            woupdateduser: data.woupdateduser,
            woupdateduserkey: data.woupdateduserkey,
            woupdateddate: data.woupdateddate,
            woactive: data.woactive,
            wofirezoneid: Number(data.wofirezoneid),
            woinsurancecategoryid: Number(data.woinsurancecategoryid),
        }
        console.log(finalData)
        if (!isClone && data.woid > 0) {
            finalData['woid'] = data.woid
        }
        const { data: response } = await updateWorkOrder.mutateAsync(finalData)

        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        else {
            console.log(parsedResponse)
            setNewWOID(parsedResponse.woid)
            setIsOpen(false)
        }
        setIsLoading(false)
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const handleEntityChange = (event: SelectChangeEvent) => {
        setEntityActiveSelect(event.target.value as string);
        const filteredList = woDropDownData?.entitylist?.filter(entity => entity.entityname === (event.target.value as string))
        if (filteredList && filteredList.length > 0) {
            const entityID = filteredList[0].entityid
            setEntityActiveSelectId(entityID)
            getCustomerOptions(entityID)
        }
    }
    
    const getCustomerOptions = (entityId) => {
        setCustomerOptions(null)
        const customerOptions = woDropDownData?.custlist?.filter((item) => (item.entityid === entityId))
        if (customerOptions && customerOptions.length > 0) {
            setCustomerOptions(customerOptions?.map(item => (
                { label: item.custinfo, value: item.custid }
            )))
        }
    }

    // const getPOOptions = (entityId, custId) => {
    //     setCustomerPOOptions(null)
    //     const poOptions = woDropDownData?.polist?.filter((item) => ((!item.hasOwnProperty('entityid') || !item.hasOwnProperty('custid')) || item.entityid === entityId && item.custid === custId))
    //     if (poOptions && poOptions.length > 0) {
    //         setCustomerPOOptions(poOptions?.map(item => (
    //             <Menu Item key={item.polid} value={item.polnumber}>
    //                 {item.polnumber}
    //             </MenuItem>
    //         )))
    //     }
    // }

    // const getPOListOptions = () => {
    //     setCustomerPOOptions([])
    //     const poOptions = woDropDownData?.polist?.filter((item) => ((!item.hasOwnProperty('entityid') || !item.hasOwnProperty('custid')) || item.entityid === entityActiveSelectId && item.custid === customerActiveSelectId))
    //     console.log('poOptions')
    //     console.log(woDropDownData?.polist)
    //     console.log(customerActiveSelectId)
    //     console.log(entityActiveSelectId)
    //     console.log(poOptions)
    //     if (poOptions && poOptions.length > 0) {
    //         const poOptionsMap = poOptions?.map(item => {
    //             return { label: item.polnumber, value: item.polid }
    //         })
    //         setCustomerPOOptions(poOptionsMap)
    //     }
    // }
    
    useEffect(() => {
        setCustomerPOOptions([])
        const poOptions = woDropDownData?.polist?.filter((item) => ((!item.hasOwnProperty('entityid') || !item.hasOwnProperty('custid')) || item.entityid === entityActiveSelectId && item.custid === watchCustcodeID))
        console.log('poOptions')
        // console.log(woDropDownData?.polist)
        // console.log(customerActiveSelectId)
        // console.log(entityActiveSelectId)
        // console.log(poOptions)
        if (poOptions && poOptions.length > 0) {
            const poOptionsMap = poOptions?.map(item => {
                return { label: item.polnumber, value: item.polid }
            })
            setCustomerPOOptions(poOptionsMap)
        }
        getCustomerDivisionListOptions()
        // const subscription = watch((value, { name, type }) => console.log(value, name, type));
        // return () => subscription.unsubscribe();
        }, [getValues("wocustomercodeid"), entityActiveSelect]


    )

    // const handleCustomerChange = (event: SelectChangeEvent) => {
    //     setCustomerActiveSelect(event.target.value as string)

    //     const filteredList = woDropDownData?.custlist?.filter(cust => cust.custinfo === (event.target.value as string))
    //     if (filteredList.length > 0) {
    //         const custId = filteredList[0].custid
    //         setCustomerActiveSelectId(custId)
    //         // getPOOptions(entityActiveSelectId, custId)
    //     }
    // }

    // const handleCustomerPOChange = (event: SelectChangeEvent) => {
    //     setCustomerPOActiveSelect(event.target.value as string)

    //     const filteredList = woDropDownData?.polist?.filter(po => po.polnumber === (event.target.value as string))
    //     if (filteredList.length > 0) {
    //         const polid = filteredList[0].polid
    //         setCustomerPOActiveSelectId(polid)
    //     }
    // }

    const getstatusListOptions = () => {
        return woDropDownData?.statuslist?.map(item => {
            return { label: item.statuscode, value: item.statusid }
        })
    }

    const getEntityListOptions = () => {
        var entityData = []
        if (canaddentity === 0) {
            entityData = woDropDownData?.entitylist?.map(item => (
                <MenuItem key={item.entityid} value={item.entityname}>
                    {item.entityname}
                </MenuItem>
            ))
        }
        else if (canaddentity !== -1) {
            entityData = woDropDownData?.entitylist?.map(item => (
                <MenuItem key={item.entityid} value={item.entityname}>
                    {item.entityname}
                </MenuItem>
            ))
        }

        return entityData
    }

    const getCustomerDivisionListOptions = () => {
        if (woDropDownData && woDropDownData.customerdivisionlist && woDropDownData.customerdivisionlist.length > 0) {
            const wocustomercodeid = getValues("wocustomercodeid")
            setCustomerDivisionOptions(woDropDownData?.customerdivisionlist?.filter((e) => e.custid === wocustomercodeid).map(item => {
                return { label: item.customerdivisioncode, value: item.customerdivisionid }
            }))
        }

    }

    const getPricingMethodListOptions = () => {
        return woDropDownData?.pricingmethodlist?.map(item => {
            return { label: item.pricingmethodcode, value: item.pricingmethodid }
        })
    }

    const getFireZoneOptions = () => {
        return woDropDownData?.firezonelist?.map(item => {
            return { label: item.firezonecode, value: item.firezoneid }
        })
    }

    const getInsuranceCategoryOptions = () => {
        return woDropDownData?.insurancecategorylist?.map(item => {
            return { label: item.insurancecategorycode, value: item.insurancecategoryid }
        })
    }

    const getCustomerProjectManagerListOptions = () => {
        return woDropDownData?.customerprojectmanagerlist?.map(item => {
            return { label: item.customerprojectmanagername, value: item.customerprojectmanagerid }
        })
    }

    // const GetDivisionListOptions = useMemo ((): { value: any; label: string }[] =>
    //     woDropDownData?.divisionlist?.map((item) => {
    //             return { label: item.divisionname, value: item.divisionid }
    //         }), [woDropDownData?.divisionlist])

    const GetDivisionListOptions = (): { value: any; label: string }[] => {
        const divisionList = woDropDownData?.divisionlist?.map((item) => {
            return { label: item.divisionname, value: item.divisionid }
        })
        return divisionList
    }

    const getDivisions = (divisionId) => {
        var strType = ''
        divisionId.forEach((typeID) => {
            const value = woDropDownData?.divisionlist?.filter((e) => e.divisionid === typeID)

            if (value && value.length > 0) {
                if (strType.length > 0) {
                    strType = strType + ', '
                }
                strType = strType + value[0].divisionname
            }
        })

        return strType
    }

    return (
        <Dialog open={isOpen} maxWidth="xl" className={styles.dialog}>
            <DialogTitle>{title}</DialogTitle>
            {/*<LoadingBackdrop open={createMutation?.isLoading} />*/}
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <LoadingBackdrop open={isLoading} />
            <DialogContent>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.itemsForm}
                    formProps={{ id: 'items-form' }}>

                    <div className={styles.multipleInputContainer}>
                        <FormControl fullWidth>
                            <InputLabel>Entity*</InputLabel>
                            <Select
                                name="woentityid"
                                label="Entity"
                                value={entityActiveSelect}
                                size={'small'}
                                required={true}
                                variant="outlined"
                                disabled={gridParams !== null && mainFieldsShouldBeDisabled}
                                onChange={handleEntityChange}
                            >
                                {getEntityListOptions()}
                            </Select> 
                        </FormControl>
                        <FormControl fullWidth>
                        <AutocompleteSelect
                            name='wocustomercodeid'
                            label='Customer'
                            options={customerOptions}
                            required
                            disabled={gridParams !== null && entityActiveSelect?.length <= 0 && mainFieldsShouldBeDisabled}
                        />

                            {/* <InputLabel>Customer*</InputLabel>
                            <Select
                                name="wocustomercodeid"
                                label="Customer"
                                value={customerActiveSelect}
                                size={'small'}
                                required={true}
                                variant="outlined"
                                disabled={gridParams !== null || entityActiveSelect?.length <= 0}
                                onChange={handleCustomerChange}
                            >
                                {customerOptions}
                            </Select> */}
                        </FormControl>
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <FormControl fullWidth>
                        <AutocompleteSelect name='wopolid' label='Customer PO' options={customerPOOptions} required disabled={gridParams !== null || entityActiveSelect?.length <= 0}/> 
                            {/* <InputLabel>Customer PO*</InputLabel>
                            <Select
                                name="wopolid"
                                label="Customer PO"
                                value={customerPOActiveSelect}
                                size={'small'}
                                required={true}
                                variant="outlined"
                                disabled={gridParams !== null || entityActiveSelect?.length <= 0}
                                onChange={handleCustomerPOChange}
                            >
                                {customerPOOptions}
                            </Select> */}
                        </FormControl>
                        <FormControl fullWidth>
                            <AutocompleteSelect
                                name="wocustomerdivisionid"
                                label="Customer Division"
                                disabled={false}
                                options={customerDivisionOptions}
                            />
                        </FormControl>
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <div className={styles.multipleInputContainer}>
                            <SelectInput
                                name="woactive"
                                label="Record Status"
                                options={[
                                    { label: 'Active', value: true },
                                    { label: 'Inactive', value: false }
                                ]}
                                required
                            />
                            <SelectInput
                                name="wostatusid"
                                label="Work Order Status"
                                options={getstatusListOptions()}
                                required
                            />
                        </div>
                        <div className={styles.multipleInputContainer}>
                            <TextInput
                                name="wocustomerworkorder"
                                label="Customer Work Order"
                                rules={{
                                    required: 'Customer Work Order is required',
                                    minLength: {
                                        value: 1,
                                        message: 'Must be at least 1 characters'
                                    },
                                    maxLength: {
                                        value: 200,
                                        message: 'Must be 200 or less characters'
                                    }
                                }}
                                required
                            />
                            <TextInput
                                name="wocustomerrelease"
                                label="Customer Release"
                            />
                        </div>
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <TextInput
                            name="woaddress"
                            label="Location"
                        />
                        <div className={styles.multipleInputContainer}>
                            <TextInput
                                name="wocity"
                                label="City"
                            />
                            <Autocomplete
                            value={woState}
                            options={statesArray}
                            getOptionLabel={(option) => option.value}
                            onChange={(event, newValue) => {
                                //@ts-ignore
                                setWoState(newValue)
                            }}
                            renderInput = {params => (
                                <TextField
                                {...params}
                                label="State"
                                name="wostate"
                                />
                            )}
                            />
                        </div>
                    </div>
                    <div className={styles.multipleInputContainer}>
                        {/*<TextInput*/}
                        {/*    name="wocustomerworktype"*/}
                        {/*    label="Work Order Type"*/}
                        {/*/>*/}
                        <Autocomplete
                            multiple
                            value={divisions}
                            options={GetDivisionListOptions()}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setDivisions(newValue)
                            }}
                            renderInput = {params => (
                                <TextField
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        required: (divisions && divisions.length > 0 ? false : true),
                                    }}
                                    label="Enter a Division"
                                    name="wodivisionid"
                                />
                            )}
                        />
                        <AutocompleteSelect name='woprojectmanagerid' label='Customer PM' options={getCustomerProjectManagerListOptions()} />
                    </div>
                    <div className={styles.multipleInputContainer}>
                        {/*<SelectInput*/}
                        {/*    name="wopricingmethodid"*/}
                        {/*    label="Pricing Method"*/}
                        {/*    options={getPricingMethodListOptions()}*/}
                        {/*/>*/}

                        {/* <SelectInput
                            name="woprojectmanagerid"
                            label="Customer PM"
                            options={getCustomerProjectManagerListOptions()}
                        /> */}
                    </div>
                    <div className={styles.multipleInputContainer}>
                        <DateInput name="wodateworeceived" label="Received" defaultValue={null} required={true} />
                        <DateInput name="wodatepackagereceived" label="Package Received" defaultValue={new Date().toISOString()} />
                    </div>

                        {/*<DateInput name="wodatecommitted" label="Committed" defaultValue={new Date().toISOString()} />*/}
                        {/*<DateInput name="wodatejobready" label="Job Received" defaultValue={new Date().toISOString()} />*/}

                    <div className={styles.multipleInputContainer}>
                        <SelectInput
                            name="wofirezoneid"
                            label="Fire Zone"
                            options={getFireZoneOptions()}
                        />
                        <SelectInput
                            name="woinsurancecategoryid"
                            label="Insurance Category"
                            options={getInsuranceCategoryOptions()}
                        />
                    </div>
                    
                </HookForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className={styles.submitButton}
                    type="submit"
                    form="items-form">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
