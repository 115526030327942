import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { parseJwt } from '../pages/AuthPage/LoginForm/useLoginMutation'
import { useValidToken } from '../queries/useAuthenication'
import { AuthToken, loginUser } from '../slices/authSlice'
import {useNetworkOnline, setNetworkState} from "../slices/authSlice";
import {useEffect} from "react";

export const useNetworkStatus = () => {
  const dispatch = useDispatch()
  const setOnline = () => {
      dispatch(setNetworkState(true))
  };
  const setOffline = () => {
      dispatch(setNetworkState(false))
  };

  useEffect(() => {
      dispatch(setNetworkState(window.navigator.onLine))
      window.addEventListener('offline', setOffline);
      window.addEventListener('online', setOnline);

      // cleanup if we unmount
      return () => {
          window.removeEventListener('offline', setOffline);
          window.removeEventListener('online', setOnline);
      }
  }, []);


  // return true
}
