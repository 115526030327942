import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface workOrderList {
    woid: number
    wocode: string
}

export interface waamountsstatuslist {
    statusid: number
    statuscode: string
}

export interface  WADropdowns {
    wolist: workOrderList[]
    waamountsstatuslist: waamountsstatuslist[]
}

export const WADropdownDefaults = {
    wolist: [],
    waamountsstatuslist: []
}

export const useFetchWADropdowns =  () => {
    return useQuery<WADropdowns>(
        ['wadropdowns'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            placeholderData: WADropdownDefaults,
            keepPreviousData: false
        }
    )
}

export const globalFetcher = async () => {
    const { data } = await axios.get('/wadropdowns')
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as WADropdowns
}
