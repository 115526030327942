import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  SchedeulesDataObject {
    status: string
    message: string
    canadd: boolean
    canaddschedule: 0
    canadddirectonly: false
    schedulelist: SchedulesData[]
    employee: string
    legendlist: legendList[]
}

export interface  legendList {
    color: string
    description: string
    rownumber: number
}

export interface  SchedulesData {
    readonly: boolean
    canedit: boolean
    candelete: boolean
    scheduleid: number
    showemergencybutton: boolean
    schedulenumber: number
    scheduledivisionid: number
    scheduledivision: string
    scheduletemplateid: number
    scheduletemplate: string
    scheduledivisionwoid: number
    schedulestatusid: number
    schedulestatus: string
    schedulestartdate: string
    scheduleenddate: string
    scheduleworklocation: string
    schedulegeneralforemanid: number
    schedulegeneralforeman: string
    scheduleforemanid: number
    scheduleforeman: string
    scheduleaddeddate: string
    scheduleactive: boolean
    notestypeid: number
    notestitle: string
    noteslinkid: number
    notescount: number
    scheduledivisioncustomerinfo: string,
    scheduledivisionwocustomerworkorder: string,
    schedulelastdatedispatchsent: string,
    schedulecandispatch: boolean,
    schedulestaffid: [],
    schedulestaff: string,
    scheduledivisioncustomercodeid: number,
    scheduledispatchsent: boolean,
    scheduledispatchsubmitted: boolean,
    scheduledescription: string,
    schedulehover: string,
    urllink: string,
    schedulecalendarcolor: string
}

export const SchedulesDataDefaults = {
    readonly: false,
    canedit: true,
    candelete: true,
    showemergencybutton: false,
    scheduleid: 0,
    schedulenumber: 0,
    scheduledivisionid: 0,
    scheduledivision: "",
    scheduletemplateid: 0,
    scheduletemplate: "",
    scheduledivisionwoid: 0,
    schedulestatusid: 0,
    schedulestatus: "",
    schedulestartdate: "",
    scheduleenddate: "",
    scheduleworklocation: "",
    schedulegeneralforemanid: 0,
    schedulegeneralforeman: "",
    scheduleforemanid: 0,
    scheduleforeman: "",
    scheduleaddeddate: "",
    scheduleactive: true,
    notestypeid: 0,
    notestitle: "",
    noteslinkid: 0,
    notescount: 0,
    scheduledivisioncustomerinfo:"",
    scheduledivisionwocustomerworkorder:"",
    schedulelastdatedispatchsent:"",
    schedulecandispatch: false,
    schedulestaffid: [],
    schedulestaff: "",
    scheduledivisioncustomercodeid: 0,
    scheduledispatchsent: false,
    scheduledispatchsubmitted: false,
    scheduledescription: "",
    schedulehover: "",
    urllink: "",
    schedulecalendarcolor: ""
}

export const useFetchSchedules = (workOrderId: number) => {
    return useQuery<SchedeulesDataObject>(
        ['schedule'],
        () => globalFetcher(workOrderId),
        {
            staleTime: 12000,
            keepPreviousData: false
        }
    )
}
export const useFetchEntitySchedules = (queryKey: string, entityID: number, calenderGuid: string) => {

    return useQuery<SchedeulesDataObject>(
        [queryKey],
        () => globalFetcher2({
            entityid: entityID,
            GUID: calenderGuid,
            queryKey: queryKey,
            url: (queryKey === 'schedule' ? 'entityschedule' : 'calendar')
        }),
        {
            staleTime: 12000,
            keepPreviousData: false
        }
    )
}

export const useFetchCalendarSchedules = (calendarGuid: string) => {
    return useQuery<SchedeulesDataObject>(
        ['schedule'],
        () => globalFetcherCalendar(calendarGuid),
        {
            staleTime: 12000,
            keepPreviousData: false
        }
    )
}

export const useUpdateSchedules = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`schedule`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['schedule'])
        }
    })
}

export const useDeleteSchedules = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`schedule`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['schedule'])
        }
    })
}

export const globalFetcher = async (workOrderId: number) => {
    const { data } = await axios.post('/schedule', {
        woid: workOrderId
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as SchedeulesDataObject
}

export const globalFetcher2 = async (body: any) => {
    var parsedData;
    if (body.queryKey === 'calendar') {
        const {data} = await axios.get(body.url + '/' + body.GUID, body)
        var newstring = data.jsonString.replace(/ 0+(?![\. }])/g, '');
        parsedData = JSON.parse(newstring)
    }
    else {
        const {data} = await axios.post(body.url, body)
        var newstring = data.jsonString.replace(/ 0+(?![\. }])/g, '');
        parsedData = JSON.parse(newstring)
    }
    console.log(parsedData)
    return parsedData as SchedeulesDataObject
}

export const globalFetcherCalendar = async (calendarGuid: string) => {
    const { data } = await axios.post('/calendar', {
        GUID: calendarGuid
    })
    var newstring = data.jsonString.replace(/ 0+(?![\. }])/g, '');
    const parsedData = JSON.parse(newstring)
    return parsedData as SchedeulesDataObject
}