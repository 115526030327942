import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  WAAmounts {
    waaid: number
    waaamount: number
    waastatus: string
    waastatusid: number
    waadaterequested: string
    waadateapproved: string
    waacomment: string
    waarequestdocument: string
    waaamountlastupdated: string
    waaaddeddate: string
}

export const WAAmountsDefaults = {
    waaid: 0,
    waaamount: 0,
    waastatus:'',
    waastatusid: 0,
    waadaterequested: '',
    waadateapproved: '',
    waacomment: '',
    waaamountlastupdated: '',
    waarequestdocument:'',
    waaaddeddate: ''
}

export const useFetchWAAmounts =  (walid: number) => {
    return useQuery<WAAmounts[]>(
        ['waamounts', walid],
        () => globalFetcher(walid),
        {
            staleTime: 120000,
            placeholderData: [WAAmountsDefaults],
            keepPreviousData: false
        }
    )
}

export const useUpdateWAAmounts = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`waamounts`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['waamounts'])
        }
    })
}

export const useDeleteWAAmounts = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`waamounts`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['waamounts'])
        }
    })
}

export const globalFetcher = async (id: number) => {
    const { data } = await axios.post('/waamounts', {
        walid: id
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.waamountlist as WAAmounts[]
}
