import styles from "./Processing.module.css";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import {Alert, Container, Grid} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import React, {useEffect} from "react";
import {useFetchGridState} from "../../../queries/useGridState";
import TimeEntryPage from "../TimeEntry/TimeEntryPage";
import {Button} from "@material-ui/core";
import {dispatchfielddoc, dispatchpwrpush} from "../../../queries/useSendDispatch";
import {GridCellParams} from "@mui/x-data-grid-pro";
import Snackbar from "@mui/material/Snackbar";
import DispatchWIPPage from "../DispatchWIP/DispatchWIPPage";

const ProcessingPage = (params) => {
    const gridTEStateVersion = 'time-entry-dispatch-grid-v1'
    const gridWIPStateVersion = 'wip-dispatch-grid-v0'
    const { data: gridTEStateData } = useFetchGridState(gridTEStateVersion)
    const { data: gridWIPStateData } = useFetchGridState(gridWIPStateVersion)
    const [snackbarOpen, setSnackbarOpen] = React.useState({ open: false, message: '' })

    const handlePWRPush = () => {
        sendDispatchFieldDoc(params.dispatchList.dispatchid)
    }

    const sendDispatchFieldDoc = async (dispatchId) => {
        const response = await dispatchpwrpush(dispatchId)
        setSnackbarOpen({ open: true, message: response.message })
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen({ open: false, message: ""  });
    }

    return (
            <>
                <Snackbar
                    open={snackbarOpen.open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                >
                    <Alert severity="success">
                        {snackbarOpen.message}
                    </Alert>
                </Snackbar>

                <Container maxWidth={"xl"}
                           style={{
                               padding: '0px 0px 0px 0px',
                               // margin: '0px 0px 0px 0px',
                               width: '100%',
                               height: '100%',
                               justifySelf: 'center',
                               display: 'flex',
                               flexDirection: 'column'
                           }}
                >
                        <Accordion expanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Time Entry</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Grid
                                        container
                                        border={0}
                                        direction="row"
                                        alignItems="center"
                                        height={'100%'}
                                        width={'100%'}
                                    >
                                        <TimeEntryPage
                                            gridStateData={gridTEStateData}
                                            gridStateVersion={gridTEStateVersion}
                                            dispatchList={params.dispatchList}
                                            setDispatchList={params.setDispatchList}
                                            entityId={params.entityId}
                                            dispatchSubmittedTImeDate={params.timeData}
                                            reloadData={params.reloadData}
                                            setReloadData={params.setReloadData}
                                        />
                                    </Grid>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>WIP</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Grid
                                        container
                                        border={0}
                                        direction="row"
                                        alignItems="center"
                                        height={'100%'}
                                        width={'100%'}
                                    >
                                        <DispatchWIPPage
                                            gridStateData={gridWIPStateData}
                                            gridStateVersion={gridWIPStateVersion}
                                            dispatchList={params.dispatchList}
                                            setDispatchList={params.setDispatchList}
                                            entityId={params.entityId}
                                            dispatchWIPData={params.timeData}
                                            reloadData={params.reloadData}
                                            setReloadData={params.setReloadData}
                                        />
                                    </Grid>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        {/*<Grid container direction="row" justifyContent="flex-end">*/}
                        {/*    <Button*/}
                        {/*            style={{width: '180px', height: '30px', marginLeft: '40px', marginTop: '20px', marginBottom: '20px'}}*/}
                        {/*            variant="contained" onClick={handlePWRPush}>*/}
                        {/*        PWR Post*/}
                        {/*    </Button>*/}
                        {/*</Grid>*/}
                </Container>
            </>
    );
}

export default ProcessingPage