import React, {useEffect, useState} from "react";
import styles from './Contact.module.css'
import {IconButton, Box, AppBar, Typography} from '@material-ui/core'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { Add } from '@mui/icons-material'
import {GridSelectionModel, GridValueFormatterParams, XGrid} from '@material-ui/x-grid'
import {
    DataGridPro,
    GridColDef,
    GridCellParams,
    GridRowParams,
    GridCallbackDetails,
    MuiEvent, useGridApiRef
} from "@mui/x-data-grid-pro";

import {ContactDefaults, Contact, useDeleteContact, useFetchContact} from "../../../queries/useContact";
import {ContactForm} from "./ContactForm";
import {Grid} from "@mui/material";

const ContactPage = (params) => {
    const deleteContact = useDeleteContact()
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState(ContactDefaults)
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const [listingIDSelected, setListingIDSelected] = React.useState<number>(null);

    var contactRows = []
    const { data } = useFetchContact()
    if (data && data.length > 0) {
        contactRows = data?.map((contact: Contact, i) => {
            return {
                id: contact.contactid,
                contactname: contact.contactname,
                contactphone: contact.contactphone,
                contactemail: contact.contactemail,
                contactlocation: contact.contactlocation,
                contactcompany: contact.contactcompany,
                contactactive: contact.contactactive
            }
        })
    }


    const handleAdd = () => {
        setReloadForm(reloadForm+1)
        setGridParams(null)
        setIsFormShowing(true)
    }
   
    const amountColumns: GridColDef[] = [
        {
            field: 'Edit',
            renderHeader: () => {
                return (
                    <IconButton
                        className={styles.submitButton}
                        onClick={() => handleAdd()}
                        size="small">
                        <Add />
                    </IconButton>
                )
            },
            width: 50,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
                    <Edit />
                </IconButton>
            )
        },
        {
            field: 'id',
            hide: true
        },
        {
            field: 'contactname',
            type: 'string',
            headerName: 'Name',
            width: 125,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'contactphone',
            type: 'string',
            headerName: 'Phone',
            width: 125,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'contactemail',
            type: 'string',
            headerName: 'Email',
            width: 200,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'contactlocation',
            type: 'string',
            headerName: 'Location',
            width: 125,
            align: "left",
            headerAlign: "left"
        },
        {
            field: 'contactcompany',
            type: 'string',
            headerName: 'Company',
            width: 200
        },
        {
            field: 'action',
            headerName: '',
            width: 50,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (params) => (
                <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                    <Delete />
                </IconButton>
            )
        }
    ]

    const handleEdit = (params: GridCellParams) => {
        const contact = data.filter((item) => item.contactid === Number(params.id))[0]
        setReloadForm(reloadForm+1)
        setGridParams({
            contactid: contact.contactid,
            contactname: contact.contactname,
            contactphone: contact.contactphone,
            contactemail: contact.contactemail,
            contactlocation: contact.contactlocation,
            contactcompany: contact.contactcompany,
            contactactive: contact.contactactive
        })
        setIsFormShowing(true)
    }

    const handleDelete = (params: GridCellParams) => {
        setDialogObj({
            msg: 'Are you sure you want to delete this contact \"' + params.getValue(params.id, 'contactname').toString() + "\"?",
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteContact.mutateAsync({ contactid: dialogObj.id })
        closeDialog()
    }

    const closeDialog = () => {
        setDialogObj({
            msg: '',
            id: 0
        })
    }

    const handleListingRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        setListingIDSelected(Number(param.id))
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <Grid container spacing={1}>
                <AppBar position="static" color="default">
                    <Typography
                        variant="h6"
                        color="primary"
                        align="center"
                        component="div">
                        Contacts
                    </Typography>
                    <ContactForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} />
                    <Box sx={{ height: '83vh', width: '100%' }}>

                    <DataGridPro
                        // apiRef={gridAmountAPIRef}
                        rows={contactRows}
                        columns={amountColumns}
                        //autoHeight={true}
                        density={'compact'}
                        pageSize={10}
                        loading={!data}
                        disableMultipleSelection={true}
                        // onRowClick={handleRowClick}
                    />
                    </Box>
                </AppBar>
            </Grid>
        </div>
    );
}

export default ContactPage
