import React, {useEffect, useMemo, useState} from "react";
import styles from './SchedulesDynamic.module.css'

import {
    useFetchEntitySchedules,
    useDeleteSchedules,
    SchedulesData,
} from "../../../queries/useSchedules";
import {GridSelectionModel, GridSortItem, GridValueFormatterParams, GridValueGetterParams} from "@material-ui/x-grid";
import {useQueryClient} from 'react-query'
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Button, { ButtonProps } from '@mui/material/Button';
import {
    MultiSelect,
    MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
    GridAlignment,
    GridCallbackDetails,
    GridCellParams,
    GridColDef,
    GridCsvExportOptions,
    GridCsvGetRowsToExportParams,
    GridEventListener,
    GridRenderEditCellParams,
    GridRowEditStopReasons, GridRowModel, GridRowModes,
    GridRowParams,
    gridSortedRowIdsSelector,
    GridSortModel,
    GridToolbarContainer,
    GridToolbarExport,
    MuiEvent,
    useGridApiContext, useGridApiEventHandler,
    useGridApiRef
} from "@mui/x-data-grid-premium";
import {gridState, useUpdateGridState} from "../../../queries/useGridState";
import {dispatchsend} from "../../../queries/useSendDispatch";
import {
    Alert, autocompleteClasses, DialogTitle,
    Grid, MenuItem, Popper, Select, SelectChangeEvent, styled,
} from "@mui/material";
import {NotesIcon} from "../../../components/common/NotesLayout/NotesLayout";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Typography
} from "@material-ui/core";
import {Delete, Cancel, PhoneIphone, Edit, SettingsBackupRestore, FolderOpen, FolderOpenOutlined} from "@material-ui/icons";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {Add, Save} from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import dayjs, {Dayjs} from "dayjs";
import {SchedulesForm} from "../../WorkOrderPage/Schedules/Schedules.Form";
import {globalSchedulesFetcher} from "../../../queries/useScheduleDropdowns";
import axios from "axios";
import {
    DataGridPremium,
    GridCellEditStopParams,
    GridCellEditStopReasons,
    GridPreProcessEditCellProps,
    GridRowModesModel
} from "@mui/x-data-grid-premium";
import TextField from "@mui/material/TextField";
import {useUpdateDynamicScheduleFields} from "../../../queries/useDynamicScheduleFields";
import MultiGridSelectInput from "../../../components/common/inputs/MultiGridSelectInput";
import Dropzone from "react-dropzone";
import {useUploadSchedeuleDocument} from "../../../queries/useDocuments";
import {FileViewerForm} from "../../FileViewerPage/FileViewerForm";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const SchedulesDynamicPage = (params) => {
    let _sortModelState: any = null
    const [gridShowing, setGridShowing] = useState(false)
    const [isFormShowing, setIsFormShowing] = useState(false)
    const [gridParams, setGridParams] = useState({})
    const [reloadForm, setReloadForm] = useState<number>(0)
    const [dialogObjDel, setDialogObjDel] = React.useState({msg: "", id: 0})
    const [dialogObj, setDialogObj] = React.useState({msg: "", gridState: "", okButtonText: "Save"})
    const [dialogMsg, setDialogMsg] = React.useState("")
    const [snackbarOpen, setSnackbarOpen] = React.useState({ open: false, message: '' })
    const [dataRows, setDataRows] = React.useState([]);
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([1]);
    const gridAPIRef = useGridApiRef()
    const updateGridState = useUpdateGridState()
    const [scheduleDropdowns, setScheduleDropdowns] = React.useState({});
    const [dataGridColumns, setDataGridColumns] = React.useState([]);
    const deleteSchedules = useDeleteSchedules()
    const updateDynamicScheduleFields = useUpdateDynamicScheduleFields()
    const [mainData, setMainData] = React.useState(null);
    const [templateList, setTemplateList] = React.useState(null);
    const [statusList, setStatusList] = React.useState(null);
    const [fieldList, setFieldList] = React.useState(null);
    const [lookupList, setLookupList] = React.useState(null);
    const [statusSelect, setStatusSelect] = React.useState( null);
    const [templateSelect, setTemplateSelect] = React.useState( null);
    const [gridInEditMode, setGridInEditMode] = useState({edit: false, id: 0})
    const [dateTimeValues, setDateTimeValues] = useState<{ scheduleid: number, schedulefieldid: number, dateTimeValue: Dayjs }[]>([])
    const [currencyValues, setCurrencyValues] = useState<{ scheduleid: number, schedulefieldid: number, currencyValue: number }[]>([])
    const [promiseArguments, setPromiseArguments] = React.useState<any>(null);
    const noButtonRef = React.useRef<HTMLButtonElement>(null);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [submitValues, setSubmitValues] = useState<{ scheduleid: number, schedulefieldid: number, value: {lookup: string, lookupid: number, lookupheaderid: number, uniqueid: string}[]}[]>([])
    const [openFileModal, setOpenFileModal] = React.useState( {openDialog: false, scheduledescription: '', woid: 0, scheduleid: 0} );
    const uploadScheduleDocument = useUploadSchedeuleDocument()
    const [loading, setLoading] = React.useState(false)

    // Grid Columns
    // const gridDynamicScheduleStateVersion = 'schedules-dynamic-grid-v0-'
    const [dynamicGridVersion, setDynamicGridVersion] = React.useState<string>(params.gridDynamicScheduleStateVersion);
    const [gridScheduleDynamicStateData, setGridScheduleDynamicStateData] = React.useState<gridState[]>();

    const nextbuttonBaseProps: ButtonProps = {
        color: 'primary',
        size: 'small',
        endIcon: <ArrowForwardIcon />,
    };

    const backButtonBaseProps: ButtonProps = {
        color: 'primary',
        size: 'small',
        startIcon: <ArrowBackIcon />,
    };

    useEffect(() => {
        console.log(dynamicGridVersion)

        axios.post('/gridstate', {
            gridType: dynamicGridVersion
        }).then((response) => {
            const dataJson = JSON.parse(response.data.jsonString)
            const dataToSave = dataJson.gridlist as gridState[]
            console.log(dataToSave)
            setGridScheduleDynamicStateData(dataToSave)
            // buildColumnDefs()
        })
    }, [dynamicGridVersion])

    // useEffect(() => {
    //     buildColumnDefs()
    // }, [gridScheduleDynamicStateData])



    const gridSortItem: GridSortItem = {
        field: 'schedulestartdate',
        sort: 'asc'
    }

    // const handleEvent: GridEventListener<'aggregationModelChange'> = (
    //     params,  // GridAggregationModel
    //     event,   // MuiEvent<{}>
    //     details, // GridCallbackDetails
    // ) => {
    //
    // }

// Imperative subscription
//     gridAPIRef?.current.subscribeEvent(
//         'aggregationModelChange',
//         handleEvent,
//     );

// Hook subscription (only available inside the scope of the grid)
//     useGridApiEventHandler(gridAPIRef, 'aggregationModelChange', handleEvent);

    const handleRowStartEditEvent = (
        params,  // GridCellEditStartParxams
        event   // MuiEvent<React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>>
    ) => {
        event.defaultMuiPrevented = true;
        if (!gridInEditMode.edit) {
            setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
            setGridInEditMode({edit: true, id: params.id})

            if (mainData && mainData?.fieldlist) {
                var arraySubmitted = []
                mainData.fieldlist.forEach(schedField => {
                    if (schedField.schedulefieldcontrol === 7) {
                        arraySubmitted.push({scheduleid: params.row.scheduleid, schedulefieldid: schedField.schedulefieldid, value: getDefaultsForSelect(params.row.scheduleid, schedField.schedulefieldid)})
                    }
                })
                console.log(arraySubmitted)
                setSubmitValues(arraySubmitted)
            }
        }
    }
    const handleRowStopEditEvent = (
        params,  // GridCellEditStartParams
        event   // MuiEvent<React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>>
    ) => {
        // event.defaultMuiPrevented = true;
        // setGridInEditMode({edit: false, id: 0})
        // setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });

        console.log(params.reason)
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
        else {
            setGridInEditMode({edit: false, id: 0})
            setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
        }
    }

    function computeMutation(newRow: GridRowModel, oldRow: GridRowModel) {
        if (newRow.name !== oldRow.name) {
            return `Name from '${oldRow.name}' to '${newRow.name}'`;
        }
        if (newRow.age !== oldRow.age) {
            return `Age from '${oldRow.age || ''}' to '${newRow.age || ''}'`;
        }
        return null;
    }

    const handleNo = () => {
        const { oldRow, resolve } = promiseArguments;
        resolve(oldRow); // Resolve with the old row to not update the internal state
        setPromiseArguments(null);
    };

    const handleYes = async () => {
        const { newRow, oldRow, reject, resolve } = promiseArguments;

        try {
            // Make the HTTP request to save in the backend
            // const response = await mutateRow(newRow);
            // setSnackbar({ children: 'User successfully saved', severity: 'success' });
            // resolve(response);
            setPromiseArguments(null);
        } catch (error) {
            // setSnackbar({ children: "Name can't be empty", severity: 'error' });
            reject(oldRow);
            setPromiseArguments(null);
        }
    };

    const handleEntered = () => {
        // The `autoFocus` is not used because, if used, the same Enter that saves
        // the cell triggers "No". Instead, we manually focus the "No" button once
        // the dialog is fully open.
        // noButtonRef.current?.focus();
    }

    const renderConfirmDialog = () => {
        if (!promiseArguments) {
            return null;
        }

        const { newRow, oldRow } = promiseArguments;
        const mutation = computeMutation(newRow, oldRow);

        return (
            <Dialog
                maxWidth="xs"
                TransitionProps={{ onEntered: handleEntered }}
                open={!!promiseArguments}
            >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent dividers>
                    {`Pressing 'Yes' will change ${mutation}.`}
                </DialogContent>
                <DialogActions>
                    <Button ref={noButtonRef} onClick={handleNo}>
                        No
                    </Button>
                    <Button onClick={handleYes}>Yes</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const getDynamicSchedules = async () => {
        setLoading(true)
        try {
            // data = useFetchEntityDynamicSchedules(params.entityId, templateSelect, statusSelect)

            let body = {
                entityid: params.entityId
            }

            if (templateSelect) {
                body['templateid'] = templateSelect.toString()
            }
            if (statusSelect != null) {
                body['statusid'] = statusSelect.toString()
            }
            if (params.filterWeekDate == true) {
                body['currentweekdate'] = dayjs(params.currentdate, 'MM-DD-YYYY').format('YYYY-MM-DD')
            }

            const { data } = await axios.post('/entitydynamicschedule', body)
            setLoading(false)
            return JSON.parse(data.jsonString)
        }
        catch (err) {
            setLoading(false)
            console.error(err.message);
        }
    }

    React.useEffect(() => {
        console.log('updates')
        getDynamicSchedules().then((response) => {
            console.log(response)
            if (response.currentweekdate) {

                // params.setcurrentdate(dayjs(response.currentweekdate, ).format('MM-DD-YYYY'))
                console.log(dayjs(response.previousweekdate).format('MM-DD-YYYY'))
                params.setpreviousdate(dayjs(response.previousweekdate).format('MM-DD-YYYY'))
                params.setnextdate(dayjs(response.nextweekdate).format('MM-DD-YYYY'))
            }
            setMainData(response)
        })
    }, [params.entityId, templateSelect, statusSelect, params.filterWeekDate === true, params.currentdate])

    React.useEffect(() => {
        if (mainData) {

            console.log(mainData)
            // if (mainData && mainData?.fieldlist) {
            //     var arraySubmitted = []
            //     mainData.fieldlist.forEach(schedField => {
            //         console.log(schedField)
            //         if (schedField.schedulefieldcontrol === 7) {
            //             arraySubmitted.push({scheduleid: schedField.scheduleid, schedulefieldid: schedField.schedulefieldid, value: getDefaultsForSelect(schedField.scheduleid, schedField.schedulefieldid)})
            //         }
            //     })
            //     console.log(arraySubmitted)
            //     setSubmitValues(arraySubmitted)
            // }

            setTemplateList(mainData?.templatelist)
            const selectedTemp = mainData?.templatelist.filter((item) => item.templateid === mainData?.selectedtemplateid)
            if (selectedTemp && selectedTemp.length > 0) {
                setTemplateSelect(selectedTemp[0].templateid)
                setDynamicGridVersion(params.gridDynamicScheduleStateVersion + selectedTemp[0].templateid)
            }

            if (mainData?.fieldlist && mainData?.fieldlist.length > 0) {
                setFieldList(mainData?.fieldlist)
            }

            if (mainData?.statuslist && mainData?.statuslist.length > 0) {
                setStatusList(mainData?.statuslist)
            }

            if (mainData?.lookuplist && mainData?.lookuplist.length > 0) {
                setLookupList(mainData?.lookuplist)
            }

            const selectedTempStatus = mainData?.statuslist.filter((item) => item.statusid === mainData?.selectedstatusid)
            if (selectedTempStatus && selectedTempStatus.length > 0) {
                setStatusSelect(selectedTempStatus[0].statusid)
            }

            // setGridSortModel(getColumnSortModel())
            if (mainData?.schedulelist && mainData?.schedulelist?.length > 0) {
                const dataRows = getMappedRecords()
                setDataRows(dataRows)
            }
            else {
                setDataRows([])
            }
        }
    }, [mainData])

    const defaultGridSortModel: GridSortModel = [gridSortItem]
    const [gridSortModel, setGridSortModel] = React.useState<GridSortModel>(defaultGridSortModel)


    const canDispatch = (params: GridCellParams) => {
        return params.row.schedulecandispatch
    }
    const getMappedRecords = () => {
        console.log('getMappedRecords')
        var schedulesDataTemp = []
        const schedList = mainData?.schedulelist
        var dataObj = []
        var dateObj = []
        var currencyObj = []
        schedList.forEach((schedule: any, i) => {
            var newScheduleRec = {
                id: schedule.scheduleid
            }

            mainData?.fieldlist.forEach((field: any) => {
                var dataToSave = schedule[field.schedulefieldid]
                if (field.schedulefieldcontrol === 6) {
                    dataToSave = dayjs(dataToSave, "YYYY-MM-DD")
                }
                else if (field.schedulefieldcontrol === 2) {
                    if (schedule[field.schedulefieldid]) {
                        const newLookup = lookupList?.find(lookup => lookup.lookupid === schedule[field.schedulefieldid] && lookup.lookupheaderid === field.schedulefieldheaderid)
                        if (newLookup) {
                            dataToSave = newLookup.uniqueid
                            schedule[field.schedulefieldid] = dataToSave
                        }
                    }
                }
                else if( field.schedulefieldcontrol === 7) {
                    if (schedule[field.schedulefieldid]) {
                        var newUniqueIds = ''
                        const idsToLookup = schedule[field.schedulefieldid].split(',')
                        // console.log(idsToLookup)
                        idsToLookup.map(id => {
                            const newLookup = lookupList?.find(lookup => lookup.lookupid === Number(id) && lookup.lookupheaderid === field.schedulefieldheaderid)
                            if (newLookup) {
                                if (newUniqueIds.length > 0) {
                                    newUniqueIds += ','
                                }
                                newUniqueIds += newLookup.uniqueid.toString()
                            }
                        })
                        // console.log(newUniqueIds)
                        schedule[field.schedulefieldid] = newUniqueIds
                    }
                }
                else if (field.schedulefieldcontrol === 9) {
                    dateObj.push({
                        scheduleid: schedule.scheduleid,
                        schedulefieldid: field.schedulefieldid,
                        dateTimeValue: schedule[field.schedulefieldid]
                    })
                }

                newScheduleRec[field.schedulefieldid] = dataToSave
            })

            schedulesDataTemp.push(newScheduleRec)
        })
        setDateTimeValues(dateObj)

        return mainData?.schedulelist.map((schedulesData: any, i) => {
            return {
                id: schedulesData.scheduleid,
                ...schedulesData
            }
        })
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen({ open: false, message: ""  });
    }

    const getFieldData = (fieldid) => {
        var fieldObj = null
        fieldList?.forEach(field => {
            if (field.schedulefieldid.toString() === fieldid.toString()) {
                fieldObj = field
            }
        })
        return fieldObj
    }

    const basicProps = {
        disableColumnMenu: true,
        sortable: false,
        disableExport: true,
        disableReorder: true,
        editable: false,
        filterable: false,
        groupable: false,
        hideable: false,
        pinnable: false
    }

    const HandleEdit = async (params: GridCellParams) => {

        const scheduleData = mainData?.schedulelist.filter((item) => item.scheduleid === Number(params.id))[0]
        const scheduleDropdowns = await globalSchedulesFetcher(scheduleData?.scheduledivisionwoid)
        if (scheduleDropdowns) {
            setScheduleDropdowns(scheduleDropdowns)
        }

        // console.log(scheduleData)
        setGridParams({
            id: scheduleData.scheduleid,
            readonly: scheduleData.readonly,
            canedit: scheduleData.canedit,
            scheduleid: scheduleData.scheduleid,
            schedulenumber: scheduleData.schedulenumber,
            scheduledivisionid: scheduleData.scheduledivisionid,
            scheduledivision: scheduleData.scheduledivision,
            scheduletemplateid: scheduleData.scheduletemplateid,
            scheduletemplate: scheduleData.scheduletemplate,
            scheduledivisionwoid: scheduleData.scheduledivisionwoid,
            schedulestatusid: scheduleData.schedulestatusid,
            schedulestatus: scheduleData.schedulestatus,
            schedulestartdate: scheduleData.schedulestartdate,
            scheduleenddate: scheduleData.scheduleenddate,
            scheduleworklocation: scheduleData.scheduleworklocation,
            schedulegeneralforemanid: scheduleData.schedulegeneralforemanid,
            schedulegeneralforeman: scheduleData.schedulegeneralforeman,
            scheduleforemanid: scheduleData.scheduleforemanid,
            scheduleforeman: scheduleData.scheduleforeman,
            scheduleaddeddate: scheduleData.scheduleaddeddate,
            scheduleactive: scheduleData.scheduleactive,
            notestypeid: scheduleData.notestypeid,
            notestitle: scheduleData.notestitle,
            noteslinkid: scheduleData.noteslinkid,
            notescount: scheduleData.notescount,
            scheduledivisioncustomerinfo: scheduleData.scheduledivisioncustomerinfo,
            scheduledivisionwocustomerworkorder: scheduleData.scheduledivisionwocustomerworkorder,
            schedulelastdatedispatchsent: scheduleData.schedulelastdatedispatchsent,
            schedulecandispatch: scheduleData.schedulecandispatch,
            scheduledispatchsent: scheduleData.scheduledispatchsent

        })
        setReloadForm(reloadForm + 1)
        setIsFormShowing(true)
    }

    const resetGridParams = (id) => {
        const scheduleData = mainData?.schedulelist.filter((item) => item.scheduleid === Number(id))[0]
        console.log(scheduleData)
        // const scheduleDropdowns = await globalSchedulesFetcher(scheduleData?.scheduledivisionwoid)
        // if (scheduleDropdowns) {
        //     setScheduleDropdowns(scheduleDropdowns)
        // }

        // console.log(scheduleData)
        setGridParams({
            id: scheduleData.scheduleid,
            readonly: scheduleData.readonly,
            canedit: scheduleData.canedit,
            scheduleid: scheduleData.scheduleid,
            schedulenumber: scheduleData.schedulenumber,
            scheduledivisionid: scheduleData.scheduledivisionid,
            scheduledivision: scheduleData.scheduledivision,
            scheduletemplateid: scheduleData.scheduletemplateid,
            scheduletemplate: scheduleData.scheduletemplate,
            scheduledivisionwoid: scheduleData.scheduledivisionwoid,
            schedulestatusid: scheduleData.schedulestatusid,
            schedulestatus: scheduleData.schedulestatus,
            schedulestartdate: scheduleData.schedulestartdate,
            scheduleenddate: scheduleData.scheduleenddate,
            scheduleworklocation: scheduleData.scheduleworklocation,
            schedulegeneralforemanid: scheduleData.schedulegeneralforemanid,
            schedulegeneralforeman: scheduleData.schedulegeneralforeman,
            scheduleforemanid: scheduleData.scheduleforemanid,
            scheduleforeman: scheduleData.scheduleforeman,
            scheduleaddeddate: scheduleData.scheduleaddeddate,
            scheduleactive: scheduleData.scheduleactive,
            notestypeid: scheduleData.notestypeid,
            notestitle: scheduleData.notestitle,
            noteslinkid: scheduleData.noteslinkid,
            notescount: scheduleData.notescount,
            scheduledivisioncustomerinfo: scheduleData.scheduledivisioncustomerinfo,
            scheduledivisionwocustomerworkorder: scheduleData.scheduledivisionwocustomerworkorder,
            schedulelastdatedispatchsent: scheduleData.schedulelastdatedispatchsent,
            schedulecandispatch: scheduleData.schedulecandispatch,
            scheduledispatchsent: scheduleData.scheduledispatchsent

        })
    }

    const HandleDispatchSend = (params: GridCellParams) => {
       sendDispatch(params.id)
    }
    const canEdit = (params: GridCellParams) => {
        return params.row.canedit
    }

    const buildColumnDefs = () => {
        const gridObj = parseGridObject()
        const parsedGridColumns = gridObj.gridDefs
        var gridColumns: GridColDef[] = [
            gridIdDef,
            // gridFolderButtonDef,
            gridFolderNewButtonDef,
            gridSendDispatchButtonDef,
            ...parsedGridColumns,
            gridButtonsDef
        ]
        // setDataGridColumns(gridColumns)
        return gridColumns
    }

    const gridButtonsDef: GridColDef = {
        field: 'Delete',
        headerName: '',
        width: 80,
        align: "center",
        headerAlign: "center",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <>
                    {(gridInEditMode.edit && gridInEditMode.id === params.row.id) &&
                        <>
                            <IconButton style={{ marginRight: '10px'}} className={styles.save} onClick={() => { handleSaveRow(params) }}>
                                <Save color="success" />
                            </IconButton>
                            <IconButton className={styles.button} onClick={() => { handleCancelRow(params) }}>
                                <Cancel color="error"/>
                            </IconButton>
                        </>
                    }
                    {!gridInEditMode.edit &&
                        <>
                            <NotesIcon
                                id={Number(params.id)}
                                notesid={params.row.notesid}
                                notestitle={params.row.notestitle}
                                notestypeid={params.row.notestypeid}
                                noteslinkid={params.row.noteslinkid}
                                notescount={params.row.notescount}
                                queryType={['schedeulePage']}
                            />
                            <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                                <Delete />
                            </IconButton>
                        </>
                    }
                </>
            )
        }
    }

    const handleCancelRow = (params: GridCellParams) => {
        gridAPIRef.current.stopRowEditMode({
            id: params.id,
            ignoreModifications: true, // will also discard the changes made
        });

        setGridInEditMode({edit: false, id: 0})
    }

    const handleDelete = (params: GridCellParams) => {
        const title = 'Are you sure you want to delete \"' + params.row.notestitle + "\"?"
        setDialogObjDel({
            msg: title,
            id: Number(params.id)
        })
    }

    const handleDeleteConfirm = async () => {
        const { data: response } = await deleteSchedules.mutateAsync({ scheduleid: dialogObjDel.id })
        const parsedResponse = JSON.parse(response?.jsonString)
        if (parsedResponse?.status === 'failure') {
            setDialogMsg(parsedResponse.message)
        }
        closeDialog()
    }

    // const getColumnSortModel = () => {
    //     const gridObj = parseGridObject()
    //     return gridObj.sortModel
    // }

    const formatDate = (params: GridValueFormatterParams) => {
        return (params.value ? dayjs(params?.value?.toString(), "YYYY-MM-DD").format('MM/DD/YYYY') : '')
    }

    const valueGetter = (gridParams) => {
        var actualDay = dayjs(gridParams?.value?.toString(), "YYYY-MM-DD")
        const newDate =  dayjs().set('date', actualDay.date()).set('month', actualDay.month()).set('year', actualDay.year())

        return (gridParams.value ? newDate.format('MM/DD/YYYY') : '')
    }

    const valueSingleSelectGetter = (params) => {
        var check = ''
        if (params.value) {
            check = params.value.toString()
        }
        const lookup = lookupList.filter(list => list.uniqueid === check)
        if (lookup && lookup.length > 0) {
            return lookup[0].lookup
        }
        else {
            return params.value ? params.value : 'None'
        }
    }

    const getTagDescription = (selectedValues: any[]) => {

        // var additionalCount = ''
        // if (selectedValues.length > 1) {
        //     additionalCount = ' (+' + (selectedValues.length - 1) + ')'
        // }
        //
        // const lookupObj = selectedValues[0]
        // return lookupObj + additionalCount

        var displayString = ''
        if (selectedValues && selectedValues.length > 1) {
            displayString = selectedValues.join(", ")
        }
        else if (selectedValues.length >= 1) {
            displayString = selectedValues[0]
        }

        return displayString
    }


    const renderDynamicCell = (state: any, params: any) => {
        console.log(state)
        console.log(params)
        return (
            <>
                <MultiGridSelectInput
                    scheduleid={params.row.scheduleid}
                    schedulefieldid={state.field.schedulefieldid}
                    label={''}
                    defaults={getDefaultsForSelect(params.row.scheduleid, state.field.schedulefieldid)}
                    lookupOptions={getOptionsForSelect(state.field.schedulefieldid, state.schedulefieldheaderid)}
                    setSubmitValues={setSubmitValues}
                    submitValues={submitValues}
                />
            </>
        )
    }

    const lookupValue = (params: GridValueFormatterParams) => {
        if (params.value) {
            // console.log(params.value.toString())
            const paramsValue = params.value.toString()
            const tagDescription = processValue(paramsValue)
            return tagDescription
        }
    }

    const processValue = (valuesAsString: string) => {
        var idsToLookup = [valuesAsString]
        if (valuesAsString && typeof valuesAsString == "string" && valuesAsString.length > 0 && valuesAsString.includes(',')) {
            idsToLookup = valuesAsString.split(',')
        }
        const lookupStrings = idsToLookup.map(id => {
            const lookObj = mainData?.lookuplist.filter((item) => item.uniqueid === id)
            // console.log(lookObj)
            if (lookObj && lookObj.length > 0) {
                return lookObj[0].lookup
            }
        })
        const tagDescription = getTagDescription(lookupStrings)
        return tagDescription
    }

    const getOptions = (lookupheaderid: any) => {
        return lookupList.filter(list => Number(list.lookupheaderid) === Number(lookupheaderid)).map((valuesThatMatch) => {
            return  valuesThatMatch.uniqueid
        })
    }

    const getOptionsValue = (lookupheaderid: any) => {
        return lookupList.filter(list => list.lookupheaderid === lookupheaderid).map((valuesThatMatch) => {
            return  valuesThatMatch.lookup
        })
    }

    const valueLookup = (params: GridValueFormatterParams) => {
        var check = ''
        if (params.value) {
            check = params.value.toString()
        }
        const lookup = lookupList.filter(list => list.uniqueid === check)
        if (lookup && lookup.length > 0) {
            return lookup[0].lookup
        }
        else {
            return params.value
        }
    }

    const boolValueLookup = (params: GridValueFormatterParams) => {
        return (params.value === true ? "Yes" : "No")
    }

    const handleCurrencyChange = (newValue: number | null, scheduleid: number, schedulefieldid: number) => {
        const updatedSubmit = currencyValues.filter(val =>  (val.scheduleid !== scheduleid && val.schedulefieldid !== schedulefieldid))
        const updateCurrency = [...updatedSubmit, { scheduleid: scheduleid, schedulefieldid: schedulefieldid,
            currencyValue: newValue }]
        setCurrencyValues(updateCurrency)
    }

    const getCurrencyValue = (scheduleid: number, schedulefieldid: number) => {
        const currency = currencyValues.filter((e) => (e.schedulefieldid === schedulefieldid && e.scheduleid === scheduleid))
        if (currency && currency.length > 0) {
            return currency[0].currencyValue
        }
        else {
            return null
        }
    }

    const handleChangeTime = (e, scheduleid: number, schedulefieldid: number) => {
        const updatedSubmit = dateTimeValues.filter(val => (val.scheduleid !== scheduleid && val.schedulefieldid !== schedulefieldid))
        const updateDates = [...updatedSubmit, { scheduleid: scheduleid, schedulefieldid: schedulefieldid,
            dateTimeValue: e.target.value }]
        setDateTimeValues(updateDates)
    }

    const getTimeValue = (scheduleid: any, schedulefieldid: number) => {
        const dates = dateTimeValues.filter((e) => (e.schedulefieldid === schedulefieldid && e.scheduleid === scheduleid))
        if (dates && dates.length > 0) {
            return dates[0].dateTimeValue
        }
        else {
            return null
        }
    }

    const getDefaultsForSelect = (scheduleid, schedulefieldid) => {
        var retVal = []

        if (mainData && mainData?.fieldlist) {
            // console.log(mainData)
            mainData.fieldlist.forEach((field, index, data) => {
                if (field.schedulefieldid === schedulefieldid) {
                    console.log(field)
                    const lookList = mainData?.lookuplist.filter((lookup) => lookup.lookupheaderid === field.schedulefieldheaderid)
                    mainData?.schedulelist.map(schedList => {
                        if (scheduleid === schedList.scheduleid && schedList[field.schedulefieldid] !== null && schedList[field.schedulefieldid] !== undefined) {
                            const idsToLookup = schedList[field.schedulefieldid].split(',')
                            idsToLookup.map(id => {
                                const lookObj = lookList.filter((item) => item.uniqueid === id)
                                if (lookObj && lookObj.length > 0) {
                                    // rownumber
                                        retVal.push({rownumber: lookObj[0].rownumber, lookup: lookObj[0].lookup, lookupid: lookObj[0].lookupid, uniqueid: lookObj[0].uniqueid, lookupactive: lookObj[0].lookupactive,  lookupheaderid: lookObj[0].lookupheaderid, scheduleid: scheduleid, schedulefieldid: schedulefieldid})
                                }
                            })
                        }
                    })
                }
            })
        }
        return retVal
    }

    const getOptionsForSelect = (keyValue, schedulefieldheaderid) => {
        if (mainData && mainData?.lookuplist) {
            return mainData?.lookuplist.filter((item) => (item.lookupheaderid === Number(schedulefieldheaderid) && item.lookupactive === true))
        }
    }

    const getOptionStringForSelect = (keyValue, schedulefieldheaderid) => {
        if (mainData && mainData?.lookuplist) {
            return mainData?.lookuplist.filter((item) => (item.lookupheaderid === Number(schedulefieldheaderid) && item.lookupactive === true)).map(lookups => { return lookups.lookup })
        }
    }

    function CustomTimeComponent(props: any) {
        const { id, value, field, state } = props;
        const apiRef = useGridApiContext();

        const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value; // The new value entered by the user
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        };

        return <>
            <TextField
                id={state.schedulefieldid.toString()}
                label={state.schedulefieldlabel}
                type="time"
                // defaultValue="16:30"
                defaultValue=""
                value={getTimeValue(id, state.schedulefieldid)}
                onChange={(event) => {handleChangeTime(event, id, state.schedulefieldid)}}
                fullWidth={true}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </>

    }

    const handleMultiChange = (event: MultiSelectChangeEvent) => {
        console.log(event.value);
    }

    const handleSaveRow = async (params: GridCellParams) => {
        // console.log(params)
        setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
        setGridInEditMode({edit: false, id: 0})
    }

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow };
        var schedulesData = []
        var dataToUpdate = {}
        fieldList?.forEach(state => {
            var dataToSave = null
            if (state.schedulefieldcontrol === 1) {
                dataToSave = updatedRow[state.schedulefieldid]
                updatedRow[state.schedulefieldid] = dataToSave
            }
            else if (state.schedulefieldcontrol === 2) {
                if  (updatedRow[state.schedulefieldid]) {
                    const newLook = lookupList.find(lookup => lookup.uniqueid === updatedRow[state.schedulefieldid])
                    if (newLook) {
                        dataToSave = newLook.lookupid
                        updatedRow[state.schedulefieldid] = newLook.uniqueid
                    }
                }
            }
            else if (state.schedulefieldcontrol === 4) {
                const valueToSave = getCurrencyValue(updatedRow.scheduleid, state.schedulefieldid)
                if (Number(valueToSave) >= 0) {
                    dataToSave = Number(valueToSave)
                    updatedRow[state.schedulefieldid] = dataToSave
                }
            }
            else if (state.schedulefieldcontrol === 5) {
                dataToSave = updatedRow[state.schedulefieldid]
                updatedRow[state.schedulefieldid] = dataToSave
            }
            else if (state.schedulefieldcontrol === 6) {
                if (state && state.schedulefieldid) {
                    dataToSave = dayjs(updatedRow[state.schedulefieldid]).format("YYYY-MM-DD")
                    if (dataToSave === 'Invalid Date') {
                        dataToSave = ''
                    }
                    updatedRow[state.schedulefieldid] = dataToSave
                }
            }
            else if(state.schedulefieldcontrol === 7) {
                const submittedData = submitValues.filter(val => (val.schedulefieldid === state.schedulefieldid))
                if (submittedData && submittedData.length > 0) {
                    dataToSave = submittedData[0].value.map(dataItems => {
                        return dataItems.lookupid
                    }).toString()
                    updatedRow[state.schedulefieldid] = submittedData[0].value.map(dataItems => {
                        return dataItems.uniqueid
                    }).toString()

                    updatedRow[state.schedulefieldid + 'd'] = processValue(submittedData[0].value.map(dataItems => {
                        return dataItems.uniqueid
                    }).toString())

                    // Updated Submitted values before reload
                    var oldSubmittedValues = submitValues.find(sv => sv.scheduleid === newRow.scheduleid && sv.schedulefieldid === state.schedulefieldid)
                    var newSubmittedValues = submitValues.filter(sv => sv.scheduleid !== newRow.scheduleid && sv.schedulefieldid !== state.schedulefieldid)

                    oldSubmittedValues.value = submittedData[0].value.map(dataItems => {return dataItems})
                    newSubmittedValues.push(oldSubmittedValues)
                    setSubmitValues(newSubmittedValues)

                    // Update main data with the multi selections before reload
                    var schedulesToUpdate = mainData.schedulelist.find((sl) => (sl.scheduleid === newRow.scheduleid))
                    schedulesToUpdate[state.schedulefieldid] = updatedRow[state.schedulefieldid]
                }
                else {
                    dataToSave = ''
                    updatedRow[state.schedulefieldid] = ''
                }
            }
            else if (state.schedulefieldcontrol === 9) {
                dataToSave = getTimeValue(updatedRow.scheduleid, state.schedulefieldid)
                if (dataToSave === 'Invalid Date') {
                    dataToSave = ''
                }
                updatedRow[state.schedulefieldid] = dataToSave
            }


            if (dataToSave) {
                var newScheduleRec = {
                    'schedulefieldid': state.schedulefieldid
                }
                const keySched = 'schedulefieldvalue' + state.schedulefieldcontrol

                newScheduleRec[keySched] = dataToSave
                schedulesData.push(newScheduleRec)
                dataToUpdate[state.schedulefieldid] = dataToSave
            }
        })

        //
        var finalData = {
            'schedulefieldinfo': schedulesData,
            'scheduleid': updatedRow.scheduleid
        }

        console.log(schedulesData)

//        const {data: response} = await updateDynamicScheduleFields.mutateAsync(finalData)
        updateDynamicScheduleFields.mutateAsync(finalData).then(res => {
            // getDynamicSchedules().then((response) => {
            //     console.log(response)
            //     setMainData(response)
            // })
        })

        // var newUpdatedRow = [...dataRows]
        // console.log(newUpdatedRow)
        // newUpdatedRow[0]['159'] =
        //     "-5000000000000003,-5000000000000002,-5000000000000005"
        // // newUpdatedRow[1]['159'] = "-5000000000000005"
        // // newUpdatedRow[2]['159'] = "-5000000000000005"
        // setDataRows(newUpdatedRow)

        // updatedRow['159'] = "-5000000000000003"

        // console.log(updatedRow)
        // var schedulesToUpdate = mainData.schedulelist.find((sl) => (sl.scheduleid === updatedRow.scheduleid))
        // Object.keys(schedulesToUpdate).map(keyField => {
        //     console.log(keyField)
        //     console.log(schedulesToUpdate[keyField])
        //     updatedRow[keyField] = schedulesToUpdate[keyField]
        // })
        // console.log(updatedRow)

        // console.log(updatedRow)
        // var schedulesToUpdate = mainData.schedulelist.find((sl) => (sl.scheduleid === updatedRow.scheduleid))
        // schedulesToUpdate['159'] = '-5000000000000001'
        // console.log(mainData)
        // const dataNew = {...mainData}
        // setMainData(dataNew)

        // if (schedulesToUpdate && schedulesToUpdate.length > 0) {
        //     const schedData = schedulesToUpdate[0]
        //     Object.keys(dataToUpdate).map(keyField => {
        //         console.log(keyField)
        //         // keyField['schedulefieldvalue' +
        //         schedData[keyField] = dataToUpdate[keyField]
        //     })
        // }

        // if (mainData?.schedulelist && mainData?.schedulelist?.length > 0) {
        //     const dataRows = getMappedRecords()
        //     setDataRows(dataRows)
        // }
        // else {
        //     setDataRows([])
        // }
        return updatedRow;
    }


    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log(error.message)
        // setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const buildOriginalColumnDefs = () => {
        var gridDefs = [];
        var sortModel: GridSortModel = [gridSortItem]

        fieldList?.forEach(field => {
            // 1 - boolean
            // 2 - Lookup
            // 3 - integer
            // 4 - money (double)
            // 5 - text
            // 6 - date
            // 7 - text ([1,3,5] multi lookup values
            // 8 - Datetime
            // 9 - Time

            var fieldType = 'string'
            var valueOptions = null

            if (field.schedulefieldcontrol === 1) {
                fieldType = 'singleSelect'
                valueOptions = [true, false]
            }
            else if (field.schedulefieldcontrol === 2) {
                fieldType = 'singleSelect'
                valueOptions = getOptions(field.schedulefieldheaderid)
            }
            else if (field.schedulefieldcontrol === 6) {
                fieldType = 'date'
            }
            else if (field.schedulefieldcontrol === 8) {
                fieldType = 'dateTime'
            }
            else if (field.schedulefieldcontrol === 9) {
                fieldType = 'time'
            }

            let gridAlignmentHeader: GridAlignment = field.headeralign
            let gridAlignmentCell: GridAlignment = field.align
            let colWidth = field.width

            var col: GridColDef = {
                "field": field.schedulefieldid.toString(),
                "headerName": field.schedulefieldlabel,
                "editable": !field.schedulefieldreadonly,
                "type": fieldType,
                "headerAlign": gridAlignmentHeader,
                "width": colWidth,
                "align": gridAlignmentCell,
                valueOptions: valueOptions
                // preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                //     const hasError = params.props.value.length < 3;
                //     return { ...params.props, error: hasError };
                // },
            }

            col.renderCell = (params) => {
                const fieldid = field.schedulefieldid
                const fieldcolorId = 'schedulegridcolor' + field.schedulefieldid
                return getCellText(params.row[fieldid], params.row[fieldcolorId])
            }

            // const col = getColumnAddons(state, state.schedulefieldcontrol, state.schedulefieldlabel, state.schedulefieldid, state.schedulefieldheaderid)
            if (field.schedulefieldcontrol === 1) {
                col.valueFormatter = boolValueLookup
            }
            else if (field.schedulefieldcontrol === 2) {
                col.groupingValueGetter = valueSingleSelectGetter
                col.valueFormatter = valueLookup
                col.renderCell = (params) => {
                    const fieldid = field.schedulefieldid
                    const fieldcolorId = 'schedulegridcolor' + field.schedulefieldid
                    const testData = processValue(params.row[fieldid])
                    return getCellText(testData, params.row[fieldcolorId])
                }
            }
            else if (field.schedulefieldcontrol === 4) {
                col.renderEditCell = (params: any) =>  {
                    return (
                        <>
                            <CurrencyTextField
                                label={field.schedulefieldlabel}
                                name={field.schedulefieldid.toString()}
                                value={getCurrencyValue(field.scheduleid, field.schedulefieldid)}
                                currencySymbol="$"
                                outputFormat="string"
                                textAlign={"left"}
                                onChange={(event, value) => handleCurrencyChange(value, field.scheduleid, field.schedulefieldid)}
                            />
                        </>
                    )
                }
            }
            else if (field.schedulefieldcontrol === 5) {
                // col.renderEditCell = (params: any) => {
                //     return (
                //         <>
                //             <TextInput
                //                 name={state.schedulefieldid.toString()}
                //                 label={state.schedulefieldlabel}
                //                 defaultValue={""}
                //                 rules={{
                //                     required: ' is required'
                //                 }}
                //                 required={state.schedulefieldrequired}
                //             />
                //         </>
                //     )
                // }
            }
            else if (field.schedulefieldcontrol === 6) {
                // col.valueFormatter = formatDate
                col.valueGetter = valueGetter
                col.groupingValueGetter = valueGetter
            }
            else if (field.schedulefieldcontrol === 7) {
                col.valueFormatter = lookupValue
                col.renderCell = (params) => {
                    const fieldid = field.schedulefieldid + 'd'
                    const fieldcolorId = 'schedulegridcolor' + field.schedulefieldid
                    return getCellText(params.row[fieldid], params.row[fieldcolorId])
                }
                col.renderEditCell = (params: any) => {
                    return (
                        <>
                            <MultiGridSelectInput
                                scheduleid={params.row.scheduleid}
                                schedulefieldid={field.schedulefieldid}
                                label={''}
                                defaults={getDefaultsForSelect(params.row.scheduleid, field.schedulefieldid)}
                                lookupOptions={getOptionsForSelect(field.schedulefieldid, field.schedulefieldheaderid)}
                                setSubmitValues={setSubmitValues}
                                submitValues={submitValues}
                            />
                        </>
                    )
                }
            }
            else if (field.schedulefieldcontrol === 9) {
                col.renderEditCell = (params: any) =>  { return (
                    <>
                        <TextField
                            id={field.schedulefieldid.toString()}
                            label={field.schedulefieldlabel}
                            type="time"
                            // defaultValue="16:30"
                            defaultValue=""
                            value={getTimeValue(params.row.id, field.schedulefieldid)}
                            onChange={(event) => {handleChangeTime(event, params.row.id, field.schedulefieldid)}}
                            fullWidth={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </>
                ) }
            }

            const fielddata = getFieldData(field.schedulefieldid.toString())

            gridDefs.push({...col, schedulefieldcontrol: fielddata.schedulefieldcontrol,
                schedulefieldheaderid: fielddata.schedulefieldheaderid,
                schedulefieldid: fielddata.schedulefieldid})
        })
        return gridDefs
    }

    const getCellText = (text, color) => {
        const textColor = (color?.length > 0 ? color : '#000000')
        return (
            <div style={{textOverflow: 'ellipsis', overflow: 'hidden', color: textColor}}>{text}</div>
        )
    }

    const getColumnAddons = (state, schedulefieldcontrol, schedulefieldlabel, schedulefieldid, schedulefieldheaderid) => {
        var column: GridColDef = {...state}

        column.renderCell = (params) => {
            const fieldid = schedulefieldid
            const fieldcolorId = 'schedulegridcolor' + schedulefieldid
            return getCellText(params.row[fieldid], params.row[fieldcolorId])
        }

        if (schedulefieldcontrol === 1) {
            column.valueFormatter = boolValueLookup
        }
        else if (schedulefieldcontrol === 2) {
            column.groupingValueGetter = valueSingleSelectGetter
            column.valueFormatter = valueLookup
            column.renderCell = (params) => {
                const fieldid = schedulefieldid
                const fieldcolorId = 'schedulegridcolor' + schedulefieldid
                const testData = processValue(params.row[fieldid])
                return getCellText(testData, params.row[fieldcolorId])
            }
        }
        else if (schedulefieldcontrol === 4) {
            column.renderEditCell = (params: any) =>  {
                return (
                    <>
                        <CurrencyTextField
                            label={schedulefieldlabel}
                            name={schedulefieldid.toString()}
                            value={getCurrencyValue(params.row.scheduleid, schedulefieldid)}
                            currencySymbol="$"
                            outputFormat="string"
                            textAlign={"left"}
                            onChange={(event, value) => handleCurrencyChange(value, params.row.scheduleid, schedulefieldid)}
                        />
                    </>
                )
            }
        }
        else if (schedulefieldcontrol === 6) {
            column.valueGetter = valueGetter
            column.groupingValueGetter = valueGetter
        }
        else if (schedulefieldcontrol === 7) {
            column.valueFormatter = lookupValue
            column.renderCell = (params) => {
                const fieldid = schedulefieldid + 'd'
                const fieldcolorId = 'schedulegridcolor' + schedulefieldid
                return getCellText(params.row[fieldid], params.row[fieldcolorId])
            }
            column.renderEditCell = (params: any) => {
                return (
                    <>
                        <MultiGridSelectInput
                            scheduleid={params.row.scheduleid}
                            schedulefieldid={schedulefieldid}
                            label={''}
                            defaults={getDefaultsForSelect(params.row.scheduleid, schedulefieldid)}
                            lookupOptions={getOptionsForSelect(schedulefieldid, schedulefieldheaderid)}
                            setSubmitValues={setSubmitValues}
                            submitValues={submitValues}
                        />
                    </>
                )
            }
        }
        return column
    }

    const parseGridObject = () => {
        var gridDefs: GridColDef[] = buildOriginalColumnDefs()
        var sortModel: GridSortModel = [gridSortItem]
        // var gridStateString = JSON.stringify({columnDefs: gridDefs, sortModel: sortModel})
        var gridStateString = ''
        // console.log(gridScheduleDynamicStateData)

        if (gridScheduleDynamicStateData && gridScheduleDynamicStateData.length > 0) {
            gridStateString = gridScheduleDynamicStateData[0].gridState
            const gridStateData = JSON.parse(gridStateString)
            // console.log(gridStateData)
            // console.log(dynamicGridVersion)
            const sendObj = {
                gridType: dynamicGridVersion,
                gridState: JSON.stringify(gridStateData.columnDefs)
            }
            gridStateString = JSON.stringify(sendObj)

            if (gridStateData && gridStateData.sortModel !== null && gridStateData.sortModel.length > 0) {
                // gridAPIRef?.current?.setSortModel(gridStateData.sortModel)
                const dataSort = gridStateData.sortModel
                sortModel = dataSort
            }
        }

        if (gridStateString.length > 0) {
            const gridObject = JSON.parse(gridStateString)
            if (gridObject.gridType === dynamicGridVersion) {
                const gridState = JSON.parse(gridObject?.gridState)
                gridDefs = []
                gridState?.forEach(state => {
                    const col = getColumnAddons(state, state.schedulefieldcontrol, state.schedulefieldlabel, state.schedulefieldid, state.schedulefieldheaderid)
                    gridDefs.push(col)
                })
            }
        }

        return {gridDefs: gridDefs}
    }

    const gridSendDispatchButtonDef: GridColDef = {
        field: '.',
        width: 50,
        align: "center",
        headerAlign: "center",
        pinnable: true,
        cellClassName: 'sendDispatch',
        ...basicProps,
        renderCell: (params) => (
            <>
             {(canDispatch(params)) &&
                     <IconButton className={styles.button} onClick={() => {
                        HandleDispatchSend(params)
                    }}>
                        <PhoneIphone/>
                    </IconButton>
                    }
            </>
        )
    }

    const gridFolderButtonDef: GridColDef = {
        field: '..',
        width: 80,
        align: "center",
        headerAlign: "center",
        pinnable: true,
        cellClassName: 'folder',
        ...basicProps,
        renderCell: (params) => (
            <>
                <Dropzone
                    maxFiles={1}
                    // accept={'.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                    onDrop={(files, rejects, e) => onLogoDrop(e, files, params)}
                >
                    {({ getRootProps,
                          getInputProps,
                          isDragActive,
                          isDragAccept,
                          isDragReject,
                          acceptedFiles,
                          fileRejections
                      }) => (
                        <section>
                            <div {...getRootProps()} className={isDragActive ? (isDragAccept ? styles.dropzoneselect : styles.dropzoneinvalid) : styles.dropzone}>
                                <input {...getInputProps()} />
                                <IconButton className={styles.button} >
                                    <FolderOpen />
                                </IconButton>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </>
        )
    }

    const gridFolderNewButtonDef: GridColDef = {
        field: '..',
        width: 80,
        align: "center",
        headerAlign: "center",
        pinnable: true,
        cellClassName: 'folder',
        ...basicProps,
        renderCell: (params) => (
            <>
                <IconButton className={styles.button} onClick={() => {
                    const selectedRowData = dataRows.find((row) => row.id === params.id)
                    console.log(selectedRowData)
                    resetGridParams(params.id)
                    setOpenFileModal({openDialog: true, scheduledescription: selectedRowData['scheduledescription'], woid: selectedRowData['1'], scheduleid: selectedRowData['2']})
                }}>
                    <FolderOpen />
                </IconButton>
            </>
        )
    }

    const gridIdDef: GridColDef = {
        field: 'id',
        hide: true,
        ...basicProps
    }

    const getTemplateOptions = () => {
        return templateList?.map(item => (
            <MenuItem key={item.templateid} value={item.templateid}>
                {item.template}
            </MenuItem>
        ))
    }

    const getStatusOptions = () => {
        return statusList?.map(item => (
            <MenuItem key={item.statusid} value={item.statusid}>
                {item.status}
            </MenuItem>
        ))
    }

    const sendDispatch = async  (scheduleID) => {
        const response = await dispatchsend(scheduleID)
        setSnackbarOpen( { open: true, message: response.message })
    }

    const handleSelectionChange = async (event: SelectChangeEvent) => {
        setTemplateSelect(event.target.value as string);
        setDynamicGridVersion(params.gridDynamicScheduleStateVersion + (event.target.value as string))
        // dynamicData = useFetchEntityDynamicSchedules(params.entityId)
        // localStorage.setItem("woActiveSelection", event.target.value)
        // filterRowsToSelection(event.target.value)
    }

    const handleStatusChange = (event: SelectChangeEvent) => {
        setStatusSelect(event.target.value as string);
        // localStorage.setItem("woActiveSelection", event.target.value)
        // filterRowsToSelection(event.target.value)
    }

    const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
        gridSortedRowIdsSelector(apiRef);

    function CustomGridToolbar() {
        const apiRef = useGridApiContext();

        const handleExport = (options: GridCsvExportOptions) =>
            apiRef.current.exportDataAsCsv(options);

        const buttonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <FileDownloadOutlinedIcon />,
        };

        const saveButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <Save />,
        };

        const restoreButtonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <SettingsBackupRestore />,
        };

        const handleRestore = () => {
            setGridShowing(false)
            const gridDefs = buildOriginalColumnDefs()
            const sortModelDefs = gridAPIRef.current.getSortModel()
            const strObject = JSON.stringify({columnDefs: gridDefs, sortModel: sortModelDefs})
            setDialogObj({
                msg: 'Are you sure you want to reset the grid state to default?',
                gridState: strObject,
                okButtonText: 'RESET'
            })
        }

        const handleSave = () => {
            const columnObjsting = getColumnStringToSave()
            console.log(columnObjsting)
            setGridShowing(false)
            setDialogObj({
                msg: 'Are you sure you want to save the current grid state?',
                gridState: columnObjsting,
                okButtonText: 'Save'
            })
        }

        const getColumnStringToSave = () => {
            var fieldObjects = []
            const gridState = gridAPIRef.current.getAllColumns()
            gridState.forEach(stateColumnDef => {
                if (stateColumnDef.sortable && stateColumnDef.pinnable && stateColumnDef.groupable) {
                    const fielddata = getFieldData(stateColumnDef.field)

                    fieldObjects.push({
                        field: stateColumnDef.field,
                        headerName: stateColumnDef.headerName,
                        headerAlign: stateColumnDef.headerAlign,
                        type: stateColumnDef.type,
                        width: stateColumnDef.width,
                        align: stateColumnDef.align,
                        description: stateColumnDef.description,
                        hide: stateColumnDef.hide,
                        // valueFormatter: stateColumnDef.valueFormatter,
                        schedulefieldcontrol: fielddata.schedulefieldcontrol,
                        schedulefieldheaderid: fielddata.schedulefieldheaderid,
                        schedulefieldid: fielddata.schedulefieldid,
                    })
                }
            })

            const sortModelDefs = gridAPIRef.current.getSortModel()
            const strObject = JSON.stringify({columnDefs: fieldObjects, sortModel: sortModelDefs})
            return strObject
        }

        return (
            <GridToolbarContainer>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-begin"
                    alignItems="center"
                    // border={2}
                    xs={5} sm={4} md={2}
                >
                    <Select
                        onChange={handleSelectionChange}
                        style={{ width: '100%', height:'30px' }}
                        disabled={params.workOrderObj === null}
                        value={templateSelect}>
                        {getTemplateOptions()}
                    </Select>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-begin"
                    alignItems="flex-start"
                    // border={2}
                    xs={5} sm={4} md={2}
                >
                    <Select
                        onChange={handleStatusChange}
                        style={{ width: '100%', height:'30px', marginLeft:'10px' }}
                        disabled={params.workOrderObj === null}
                        value={statusSelect}>
                        {getStatusOptions()}
                    </Select>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={2.5} sm={4} md={6}
                >
                    <Button
                        {...saveButtonBaseProps}
                        onClick={() => handleSave()}
                    >
                        SAVE GRID SETTINGS
                    </Button>
                    <Button
                        {...restoreButtonBaseProps}
                        onClick={() => handleRestore()}
                    >
                        RESTORE GRID SETTINGS
                    </Button>
                    <Button
                        {...buttonBaseProps}
                        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
                    >
                        EXPORT
                    </Button>
                    {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                </Grid>
            </GridToolbarContainer>
        )
    }

    const handleOkConfirm = async () => {
        var finalData = {
            gridType: dynamicGridVersion,
            gridState: dialogObj.gridState
        }
        console.log(finalData)
        const {data: response} = await updateGridState.mutateAsync(finalData)
        closeDialog()
        setGridShowing(true)
        if (dialogObj.okButtonText === 'RESET') {
            setSnackbarOpen({ open: true, message: "Schedule settings have been reset" })
        }
        else {
            setSnackbarOpen({ open: true, message: "Schedule settings have been saved" })
        }
    }

    const closeDialog = () => {
        setGridShowing(true)
        setDialogObj({
            msg: '',
            gridState: '',
            okButtonText: ''
        })
        setDialogObjDel({
            msg: '',
            id: 0
        })
        setDialogMsg("")
    }
    React.useEffect(() => {
        // gridAPIRef.current.applySorting()
        console.log(params.schedObj)
        if (params.schedObj) {
            setTimeout(() => {
                gridAPIRef.current?.selectRows([params.schedObj])
            })
        }
    }, [params.schedObj]);

    const onFileDrop = (files: File[]) => {
        console.log('logo drop')
        // const fileReader = new FileReader()
        // fileReader.onload = function (event) {
        //     const data = event.target.result as string
        //     setValue('logo', data.split(';base64,')[1])
        // }
        // fileReader.readAsDataURL(files[0])
    }
    const handleRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        const schedList = mainData?.schedulelist.filter((item) => item.scheduleid === Number(param.id))
        if (schedList && schedList.length > 0) {
            params.setschedObj(schedList[0].scheduleid)
        }
    }
    const onLogoDrop = async (event: any, files: File[], params: GridCellParams) => {
        console.log('logo drop ')
        // console.log(params)
        // console.log(params.id)
        // alert(params.id)
        const selectedRowData = dataRows.filter((row) => row.id === params.id)
        // console.log(selectedRowData[0][1])

        // console.log(selectedRowData)
        const scheduleData = mainData?.schedulelist.filter((item) => item.scheduleid === Number(params.id))[0]
        console.log(scheduleData['1'])
        console.log(scheduleData)

        const fileReader = new FileReader()
        fileReader.onload = function (event) {
            const data = event.target.result as string
            // // setValue('logo', data.split(';base64,')[1])
            const body = {
                id: 'novalueonpurpose',
                filename: files[0].name,
                filedata: data.split(';base64,')[1],
                woid: scheduleData['1'],
                mimetype:  files[0].type,
                scheduleid: scheduleData['scheduleid'].toString(),
                scheduleNumber: scheduleData['2'].toString(),
                dynamicUpload: true
            }
            console.log('loading data')
            console.log(body)
            uploadScheduleDocument.mutateAsync(body)
        }

        fileReader.readAsDataURL(files[0])
        event.stopPropagation()
    }

    const onSave = async (event: any, files: File[], tree: any, topFolderName: any, params: GridCellParams) => {
        const fileReader = new FileReader()
        fileReader.onload = function (event) {
            const data = event.target.result as string
            const body = {
                parentFolderID: tree.Id, // Folder the file is dropped on
                rootFolderName: topFolderName, // root folder name
                filename: tree.path + "/" + files[0].name,
                filedata: data.split(';base64,')[1],
                woid: params.id,
                mimetype:  files[0].type,
            }
            // uploadDocument.mutateAsync(body)
        }

        fileReader.readAsDataURL(files[0])
        event.stopPropagation()
    }

    return (
         // <div  className={styles.root} style={{ height: 520, width: '100%' }}>
        <div className={styles.root}>
            <>
                <FileViewerForm isOpen={openFileModal} setIsOpen={setOpenFileModal} ></FileViewerForm>
            </>

            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>

            {mainData && mainData.status === 'failure' ?
                <Typography
                    variant="h6"
                    color="primary"
                    align="center"
                    component="div">
                    {mainData.message}
                </Typography>
                :
                <>
                    <>
                        <Snackbar
                            open={snackbarOpen.open}
                            autoHideDuration={4000}
                            onClose={handleClose}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        >
                            <Alert severity="success">
                                {snackbarOpen.message}
                            </Alert>
                        </Snackbar>
                    </>
                    <>
                        <Dialog
                            open={dialogObj.msg.length > 0}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {dialogObj.msg}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDialog}>Cancel</Button>
                                <Button onClick={handleOkConfirm}>{dialogObj.okButtonText}</Button>
                            </DialogActions>
                        </Dialog>
                    </>

                    <>
                        <Dialog
                            open={dialogObjDel.msg.length > 0}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth={true}
                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {dialogObjDel.msg}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDialog}>Cancel</Button>
                                <Button onClick={handleDeleteConfirm}>DELETE</Button>
                            </DialogActions>
                        </Dialog>
                    </>

                    <Grid container >
                        {/*<Grid item xs={12} style={{marginLeft: '15px', marginTop: '15px'}}>*/}
                        {/*    <Grid container spacing={1} >*/}
                        {/*        <Box style={{ marginLeft: '10px' }} sx={{ flexGrow: 1 }}>*/}
                        {/*            <FormGroup>*/}
                        {/*            </FormGroup>*/}
                        {/*        </Box>*/}
                        {/*        <Box sx={{ flexGrow: 20 }}>*/}
                        {/*            <FormGroup>*/}
                        {/*            </FormGroup>*/}
                        {/*        </Box>*/}

                        {/*        <Box style={{marginRight: '10px'}}>*/}                {/*<Grid item xs={12} style={{marginLeft: '15px', marginTop: '15px'}}>*/}
                        {/*    <Grid container spacing={1} >*/}
                        {/*        <Box style={{ marginLeft: '10px' }} sx={{ flexGrow: 1 }}>*/}
                        {/*            <FormGroup>*/}
                        {/*            </FormGroup>*/}
                        {/*        </Box>*/}
                        {/*        <Box sx={{ flexGrow: 20 }}>*/}
                        {/*            <FormGroup>*/}
                        {/*            </FormGroup>*/}
                        {/*        </Box>*/}

                        {/*        <Box style={{marginRight: '10px'}}>*/}
                        {/*            <IconButton*/}
                        {/*                className={styles.saveButton}*/}
                        {/*                onClick={() => handleSave()}*/}
                        {/*                style={{marginRight: '10px'}}*/}
                        {/*                size="small">*/}
                        {/*                <Save/>*/}
                        {/*            </IconButton>*/}
                        {/*            <IconButton*/}
                        {/*                className={styles.saveButton}*/}
                        {/*                onClick={() => handleRestore()}*/}
                        {/*                style={{marginRight: '10px'}}*/}
                        {/*                size="small">*/}
                        {/*                <SettingsBackupRestore/>*/}
                        {/*            </IconButton>*/}
                        {/*        </Box>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>
                        {/*            <IconButton*/}
                        {/*                className={styles.saveButton}*/}
                        {/*                onClick={() => handleSave()}*/}
                        {/*                style={{marginRight: '10px'}}*/}
                        {/*                size="small">*/}
                        {/*                <Save/>*/}
                        {/*            </IconButton>*/}
                        {/*            <IconButton*/}
                        {/*                className={styles.saveButton}*/}
                        {/*                onClick={() => handleRestore()}*/}
                        {/*                style={{marginRight: '10px'}}*/}
                        {/*                size="small">*/}
                        {/*                <SettingsBackupRestore/>*/}
                        {/*            </IconButton>*/}
                        {/*        </Box>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}


                        <Grid style={{ height: '70vh', width: '100%' }} item xs={12}>
                            {renderConfirmDialog()}
                            <DataGridPremium
                                sx={{
                                    "& .MuiDataGrid-pinnedColumns": {
                                        boxShadow: "none",
                                        backgroundColor: "transparent"
                                    },
                                    "& .MuiDataGrid-pinnedColumnHeaders": {
                                        boxShadow: "none",
                                        backgroundColor: "transparent"
                                    },
                                    // '& .MuiDataGrid-row': {
                                    //     backgroundColor: 'primary.light',
                                    // }
                                }}
                                editMode={"row"}
                                rowModesModel={rowModesModel}
                                onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                                processRowUpdate={processRowUpdate}
                                onProcessRowUpdateError={handleProcessRowUpdateError}
                                experimentalFeatures={{ newEditingApi: true }}
                                apiRef={gridAPIRef}
                                rows={dataRows}
                                loading={loading}
                                columns={buildColumnDefs()}
                                //autoHeight={true}
                                density={'compact'}
                                // pageSize={10}
                                // sortModel={gridSortModel}
                                disableMultipleSelection={true}
                                // onSortModelChange={(model: GridSortModel) => setGridSortModel(model)}
                                // onStateChange={(state) => {
                                //     _sortModelState = state.sorting.sortModel
                                //
                                //     return state
                                // }}
                                initialState={{
                                    pinnedColumns: {left: ['Edit'], right: ['Notes', 'Delete', 'Folder']},
                                    // sorting: {
                                    //     sortModel: gridSortModel
                                    // }
                                }}
                                onRowClick={handleRowClick}
                                onRowEditStart={handleRowStartEditEvent}
                                onRowEditStop={handleRowStopEditEvent}
                                // getRowClassName={(params) => `super-app-theme--${params.row.status}`}
                                // getRowClassName={
                                //     {backgroundColor: 'green'}
                                // }


                                onCellEditStart={(params, event, details) => {
                                    // Prevent's default 'edit cell' behavior.
                                    // params.preventDefault = true;
                                    event.defaultMuiPrevented = true
                                }}
                                onSelectionModelChange={(newSelectionModel) => {
                                    const selectedIDs = new Set(newSelectionModel);
                                    const selectedRowData = dataRows.filter((row) =>
                                        selectedIDs.has(row.id)
                                    )
                                    // console.log(selectedRowData)
                                    setSelectionModel(newSelectionModel);
                                }}
                                selectionModel={selectionModel}
                                components={{
                                    Toolbar: CustomGridToolbar,
                                }}
                            />
                            {/*<div className={styles.fileUpload}>*/}
                            {/*    <Dropzone*/}
                            {/*        maxFiles={1}*/}
                            {/*        // accept={'.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}*/}
                            {/*        onDrop={onSave}*/}
                            {/*    >*/}
                            {/*        {({ getRootProps,*/}
                            {/*              getInputProps,*/}
                            {/*              isDragActive,*/}
                            {/*              isDragAccept,*/}
                            {/*              isDragReject,*/}
                            {/*              acceptedFiles,*/}
                            {/*              fileRejections*/}
                            {/*          }) => (*/}
                            {/*            <section>*/}
                            {/*                <div {...getRootProps()} className={isDragActive ? (isDragAccept ? styles.dropzoneselect : styles.dropzoneinvalid) : styles.dropzone}>*/}
                            {/*                    <input {...getInputProps()} />*/}
                            {/*                    {isDragAccept && (<p>File is a valid. Go ahead and drop.</p>)}*/}
                            {/*                    {!isDragActive && (<p>Drag 'n' drop files here, or click to select files</p>)}*/}
                            {/*                    {isDragReject && (<p>File cannot be dropped. It's an invalid type.</p>)}*/}
                            {/*                    /!*<em>(The file must be of type .xls, .xlsx, or .csv.)</em>*!/*/}
                            {/*                </div>*/}
                            {/*                <div className={styles.selectedFile}>*/}
                            {/*                </div>*/}
                            {/*            </section>*/}
                            {/*        )}*/}
                            {/*    </Dropzone>*/}
                            {/*</div>*/}
                        </Grid>

                    </Grid>
                </>
            }
        </div>
    );
}
export default SchedulesDynamicPage
